<template>
  <div class="container-fluid">
    <!-- Bread-crumb-->
    <div class="block-header">
      <bread-crumb></bread-crumb>
    </div>
    <div class="row clearfix">
      <div class="col-lg-12">
        <div class="card card-top-line">
          <!-- Header -->
          <div class="header d-flex justify-content-between align-items-center">
            <h2>User List</h2>
          </div>
          <!-- User Listing-->
          <div class="body pt-0">
            <!-- Filters/Buttons -->
            <div class="chart-top-action d-lg-flex justify-content-end py-3">
              <!-- SearchBox Filters -->
              <div
                class="d-flex align-items-center flex-column flex-md-row-reverse justify-content-center justify-content-md-start mb-3 payment-dropdown"
              >
                <div class="input-group mr-md-3">
                  <input
                    type="text"
                    class="form-control"
                    v-model="searchText"
                    placeholder="Enter Keywords"
                    aria-label="Enter Keywords"
                    aria-describedby="search-transactions"
                    v-on:keyup.enter="refreshPersonnelTable"
                  />
                  <div
                    class="input-group-append"
                    @click="refreshPersonnelTable()"
                  >
                    <span class="input-group-text" id="search-mail"
                      ><i class="icon-magnifier"></i
                    ></span>
                  </div>
                  <div
                    class="input-group-append"
                    @click="clearSearch()"
                    v-if="this.searchText"
                  >
                    <span class="input-group-text" id="search-mail"
                      ><i class="icon-close"></i
                    ></span>
                  </div>
                </div>
              </div>
              <div class="">
                <b-button variant="primary btn-lg btn-mw" @click="createUser()"
                  >Add User<i class="fa fa-plus-square ml-1"></i
                ></b-button>
                <!-- <router-link to="/admin/setting/user-add" class="btn btn-primary btn-lg btn-mw">Add User <i class="fa fa-plus-square ml-1"></i></router-link> -->
              </div>
              <b-modal
                id="add_user"
                :title="modaltitle"
                hide-footer
                no-stacking
                no-close-on-backdrop
                @shown="modalShow()"
              >
                <!--  @shown="resetForm" -->
                <div class="modal-area">
                  <Form
                    class="form-auth-small"
                    @submit="addUserForm"
                    :validation-schema="schema"
                    v-slot="{ errors }"
                    id="addFund"
                    ref="addFund" autocomplete="off"
                  >
                    <Field
                      name="select_country"
                      :rules="{ required: !user.country }"
                      v-slot="{ handleChange }"
                    >
                      <div class="country-dropdown">
                        <div
                          class="form-group form-select multiselect-grp"
                          :class="{
                            errors: errors.select_country && !user.country,
                            success: !errors.select_country && user.country,
                          }"
                        >
                          <span class="float-label">
                            <label for="Country">Select Country*</label>
                            <multiselect
                              placeholder=""
                              v-model="user.country"
                              :show-labels="false"
                              :options="country_options"
                              :searchable="true"
                              label="name"
                              track-by="name"
                              @input="handleChange"
                              @select="setPrimaryNumberCountryCode"
                            >
                              <template v-slot:singleLabel="{ option }">{{
                                option.name
                              }}</template>
                              <template v-slot:option="{ option }">
                                <span :class="option.flagClass"></span>
                                <div class="option__desc">
                                  <span class="option__title">{{
                                    option.name
                                  }}</span>
                                </div>
                              </template>
                            </multiselect>
                            <i
                              class="fa fa-times"
                              v-if="errors.select_country && !user.country"
                            ></i>
                            <i
                              class="fa fa-check"
                              v-if="!errors.select_country && user.country"
                            ></i>
                          </span>
                        </div>
                      </div>
                    </Field>

                    <Field
                      name="first_name"
                      :rules="!user.first_name ? 'required' : ''"
                      v-slot="{ handleChange }"
                    >
                      <div
                        class="form-group"
                        :class="{
                          errors: errors && errors.first_name,
                          success: !errors && !errors.first_name,
                        }"
                      >
                        <span class="float-label">
                          <input
                            type="text"
                            @update:model-value="handleChange"
                            v-model="user.first_name"
                            class="form-control"
                            id="first_name"
                            placeholder="First Name*"
                          />
                          <label for="first_name" class="control-label"
                            >First Name*</label
                          >
                          <div class="line"></div>
                          <i
                            class="fa fa-times"
                            v-if="errors && errors.first_name"
                          ></i>
                          <i
                            class="fa fa-check"
                            v-if="!errors.first_name && user.first_name"
                          ></i>
                        </span>
                      </div>
                    </Field>
                    <Field
                      name="last_name"
                      :rules="!user.last_name ? 'required' : ''"
                      v-slot="{ handleChange }"
                    >
                      <div
                        class="form-group"
                        :class="{
                          errors: errors && errors.last_name,
                          success: !errors && !errors.last_name,
                        }"
                      >
                        <span class="float-label">
                          <input
                            type="text"
                            @update:model-value="handleChange"
                            v-model="user.last_name"
                            class="form-control"
                            id="last_name"
                            placeholder="Last Name*"
                          />
                          <label for="last_name" class="control-label"
                            >Last Name*</label
                          >
                          <div class="line"></div>
                          <i
                            class="fa fa-times"
                            v-if="errors && errors.last_name"
                          ></i>
                          <i
                            class="fa fa-check"
                            v-if="!errors.last_name && user.last_name"
                          ></i>
                        </span>
                      </div>
                    </Field>
                    <Field
                      name="contact_email"
                      :rules="!user.contact_email ? 'required|email' : ''"
                      v-slot="{ handleChange }"
                    >
                      <div
                        class="form-group"
                        :class="{
                          errors: errors && errors.contact_email,
                          success: !errors && !errors.contact_email,
                        }"
                      >
                        <span class="float-label">
                          <input
                            type="email"
                            @update:model-value="handleChange"
                            v-model="user.contact_email"
                            class="form-control"
                            name="email1"
                            id="contact_email"
                            placeholder="Contact Email Address*"
                          />
                          <label for="contact_email" class="control-label"
                            >Contact Email Address*</label
                          >
                          <div class="line"></div>
                          <i
                            class="fa fa-times"
                            v-if="errors && errors.contact_email"
                          ></i>
                          <i
                            class="fa fa-check"
                            v-if="!errors.contact_email && user.contact_email"
                          ></i>
                        </span>
                      </div>
                    </Field>
                    <Field
                      :rules="{ required: !user.mobile_number }"
                      name="mobile_number"
                      v-slot="{ handleChange }"
                    >
                      <div
                        class="form-group"
                        :class="{
                          errors: !mobileInputError,
                          success: !errors.mobile_number && mobileInputError,
                        }"
                      >
                        <span class="float-label">
                          <vue-tel-input
                            v-bind="primaryMobileNumber"
                            v-model="user.mobile_number"
                            @validate="mobileInputValidate"
                            ref="telInput"
                            @input="handleChange"
                            @focus="this.focus = true"
                            @keypress="onlyNumber($event)"
                          >
                          </vue-tel-input>
                          <label for="primary_number" class="control-label"
                            >Contact Mobile Number*</label
                          >
                          <div class="line"></div>
                          <i
                            class="fa fa-times"
                            v-if="
                              (errors.mobile_number && focus) ||
                              mobileInputError == false
                            "
                          ></i>
                          <i
                            class="fa fa-check"
                            v-if="
                              !errors.mobile_number &&
                              user.mobile_number &&
                              mobileInputError
                            "
                          ></i>
                        </span>
                      </div>
                    </Field>

                    <div
                      class="align-items-center d-flex flex-column justify-content-between mt-3 w-100"
                    >
                      <b-button
                        v-if="userID == ''"
                        type="submit"
                        variant="primary btn-mw"
                        size="lg"
                        :disabled="isLoading"
                        >Add User</b-button
                      >
                      <b-button
                        v-if="userID != ''"
                        type="submit"
                        variant="primary btn-mw"
                        size="lg"
                        :disabled="isLoading"
                        >Update User</b-button
                      >
                      <b-button
                        @click="$bvModal.hide('add_user')"
                        size="lg"
                        variant="outline-secondary mt-3 btn-mw"
                        >Cancel</b-button
                      >
                    </div>
                  </Form>
                </div>
              </b-modal>
            </div>
            <!-- All User List -->

            <b-table
              ref="personnelTable"
              responsive
              hover
              outlined
              table-class="js-basic-example table-custom mb-0"
              head-variant="light"
              :items="getPersonnelList"
              :fields="personnel_fields"
              :current-page="currentPage"
              :per-page="perPage"
              :sort-by="sortBy"
              :sort-desc="sortDesc"
              :sort-direction="sortDirection"
              :emptyText="'No data found.'"
              show-empty
            >
              <template #head()="{ label, field: { key } }">
                {{ label }}
                <!-- Custom icons -->
                <div>
                  <i v-if="sortBy !== key" class=""></i>
                  <i
                    v-else-if="sortDesc"
                    class="fa fa-long-arrow-down ml-1"
                  ></i>
                  <i v-else class="fa fa-long-arrow-up ml-1"></i>
                </div>
              </template>
              <template #cell(details)="data">
                <!-- <div class="media-object flag-img mr-2">
                                    <img :src="data.value.user_img" alt="" width="50px" class="rounded-circle">
                                    <span :class="data.value.flagClass"></span>
                                </div> -->
                <span class="user-name">{{ data.value.name }}</span>
              </template>
              <template #cell(action)="data">
                <spam v-if="!data.item.role.includes('owner')">
                  <b-dropdown
                    id="dropdown-1"
                    text="Actions"
                    right
                    variant="outline-primary"
                  >
                    <b-dropdown-item @click="editUser(data.item.id)"
                      >Edit</b-dropdown-item
                    >
                    <b-dropdown-item @click="showAlert(data.item.id)"
                      >Delete</b-dropdown-item
                    >
                    <!-- @click="deleteUser(data.item.id)" -->
                  </b-dropdown>
                </spam>
              </template>
              <template #cell(function)="data">
                <p class="function-badge badge-group-table mb-0">
                  <span
                    class="badge ml-0 badge-success"
                    v-for="(functions, index) in data.value"
                    :key="index"
                    >{{ functions }}</span
                  >
                </p>
              </template>
              <template #cell(role)="data">
                <p class="role-badge badge-group-table mb-0">
                  <span
                    class="badge ml-0 badge-info"
                    v-for="(roles, index) in data.value"
                    :key="index"
                    >{{ roles }}</span
                  >
                </p>
              </template>
              <template #cell(services)="data">
                <p class="services-badge badge-group-table mb-0">
                  <span
                    class="badge ml-0 badge-default"
                    v-for="(services, index) in data.value"
                    :key="index"
                    >{{ services }}</span
                  >
                </p>
              </template>
              <template #cell(type)="data">
                <p class="access-badge badge-group-table mb-0">
                  <span
                    class="badge ml-0 badge-danger"
                    v-for="(access, index) in data.value"
                    :key="index"
                    >{{ access }}</span
                  >
                </p>
              </template>
            </b-table>
            <!-- Table Pagination -->
            <div
              class="py-3 align-items-center justify-content-between table-pagination"
              :class="totalRows > 0 ? ' d-flex' : 'd-none'"
            >
              <div class="per-page">
                <b-form-group
                  label="Per page"
                  label-for="per-page-select"
                  class="mb-0"
                >
                  <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="sm"
                  ></b-form-select>
                </b-form-group>
              </div>
              <div class="number-page">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  hide-goto-end-buttons
                  hide-ellipsis
                  prev-text="Previous page"
                  next-text="Next page"
                  align="right"
                >
                </b-pagination>
              </div>
              <div class="go-page">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">Go to Page</span>
                  </div>
                  <input
                    type="text"
                    class="form-control"
                    v-model="currentPageJump"
                    value=""
                    @keyup.enter="onEnter"
                    onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <main-loader ref="loader"></main-loader>
  </div>
</template>
<script>
import { Form, Field } from "vee-validate";
const LoaderComponent =
  require("@/components/admin/loader/LoaderComponent.vue").default;
// import shared from "@/shared.js";
const { VueTelInput } = require("vue3-tel-input");
import VueMultiselect from "vue-multiselect";
import parsePhoneNumber from "libphonenumber-js";

import { useToast } from "vue-toast-notification";
import "vue-toast-notification/dist/theme-bootstrap.css";
const $toast = useToast();

export default {
  name: "TransactionListComponent",
  components: {
    Form,
    Field,
    BreadCrumb: () => import("@/components/BreadCrumb.vue"),
    Multiselect: VueMultiselect,
    "main-loader": LoaderComponent,
    VueTelInput,
  },
  methods: {
    onEnter(e) {
      var paginationMenu = Math.ceil(this.totalRows / this.perPage);
      if (e.target.value > 0) {
        if (paginationMenu >= e.target.value) {
          this.currentPage = this.currentPageJump = e.target.value;
        } else {
          this.currentPage = this.currentPageJump = paginationMenu;
        }
      } else {
        this.currentPage = this.currentPageJump = 1;
      }
    },

    async getPersonnelList(ctx) {
      try {
        this.$refs.loader.show();
        let currentpage = "";
        let perpage = "";
        let sortby = "";
        let sortdesc = false;
        if (ctx.currentPage != undefined) {
          currentpage = ctx.currentPage;
        } else {
          currentpage = 1;
        }
        if (ctx.perPage != undefined) {
          perpage = ctx.perPage;
        } else {
          perpage = 50;
        }
        if (ctx.sortBy == "") {
          sortby = "createdat";
        } else {
          sortby = ctx.sortBy;
        }
        if (ctx.sortDesc == false) {
          sortdesc = "DESC";
        } else {
          sortdesc = "ASC";
        }

        this.currentPageJump = currentpage;
        this.items = [];
        const response = await this.$http.get(
          this.$baseurl +
            "business/personnels?sorting=" +
            sortdesc +
            "&sortby=" +
            sortby +
            "&pagesize=" +
            perpage +
            "&pagenumber=" +
            currentpage +
            "&businessid=" +
            sessionStorage.getItem("businessId") +
            "&search=" +
            this.searchText,
          {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("jwtToken"), //`Basic ${token}`
            },
          }
        );

        // console.log(response);
        if (response.data.code == "0067-000") {
          response.data.details.forEach((element) => {
            let userItem = {
              details: {
                name: element.firstname + " " + element.lastname,
                user_img: require(`@/assets/xs/avatar4.jpg`),
                flagClass: "flag-icon flag-icon-ca fi-md fi-round",
              },
              country:element.country ? element.country : '',
              email: element.email,
              phone: element.phone,
              function: [element.function],
              role: [element.role],
              services: [element.service],
              type: [element.accessrole],
              id: element.businessuserid,
              action: "",
            };

            this.items.push(userItem);
          });
          this.totalRows = response.data.query.total;
          this.$refs.loader.hide();
          return this.items;
        } else {
          this.$refs.loader.hide();
          // shared.toastrError(response.data.message);
          $toast.error(response.data.message, {
            position: "top-right",
          });
        }
      } catch (error) {
        this.$refs.loader.hide();
        // shared.toastrError(error.response.data.message);
        $toast.error(error.response.data.message, {
          position: "top-right",
        });
      }
    },

    refreshPersonnelTable() {
      this.$refs.personnelTable.refresh();
    },

    modalShow() {
      this.isLoading = false;
      if (this.userID == "") {
        this.modaltitle = "Add User";
        this.user.country = null;
        this.user.first_name = "";
        this.user.last_name = "";
        this.user.contact_email = "";
        this.user.mobile_number = "";
      } else {
        this.modaltitle = "Edit User";
      }
    },

    clearSearch() {
      this.searchText = "";
      this.$refs.personnelTable.refresh();
    },

    async getAvailableCountry() {
      try {
        const response = await this.$http.get(
          this.$baseurl + "countries"
        );
        // console.log(response);
        // JSON responses are automatically parsed.
        // response.data.details.map((item) => {
        response.data.map((item) => {
          let countryData = {
            id: item.countryid,
            isocode: item.isocode,
            code: item.mobilecountrycode,
            name: item.countryname,
            flagClass:
              "flag-icon flag-icon-" +
              item.isocode.toLowerCase() +
              " fi-xl fi-round",
            // isChecked: false,
          };
          this.country_options.push(countryData);
        });
        // console.log(this.country_options);
      } catch (error) {
        console.log(error);
      }
    },

    setPrimaryNumberCountryCode(defaultCountry) {
      this.$refs.telInput.choose(defaultCountry.isocode);
    },
    mobileInputValidate(phoneObject) {
      this.mobileInputError = phoneObject.valid;
    },

    onlyNumber(event) {
      let keyCode = event.keyCode ? event.keyCode : event.which;
      if (keyCode < 48 || keyCode > 57) {
        event.preventDefault();
      }
      if (/^\W$/.test(event.key)) {
        event.preventDefault();
      }
    },

    createUser() {
      this.userID = "";
      this.$bvModal.show("add_user");
    },

    addUserForm() {
      this.isLoading = true;
      if (this.userID != "") {
        this.updateUser();
      } else {
        if (typeof this.mobileInputError == "undefined") {
          this.mobileInputError = false;
        }
        if (this.mobileInputError) {
          // console.log(this.user.mobile_number, this.user.country.isocode);
          const phoneNumber = parsePhoneNumber(
            this.user.mobile_number,
            this.user.country.isocode
          );

          let accessfnctionid = "";
          let roleid = "";
          let accesstype = "";
          let serviceid = "";

          //for staging
          // accessfnctionid = '8d64e0dd-ba3d-4538-9b3e-b23a867ad6f9';
          // roleid = 'b395d8e9-6e3a-4212-aeac-48dc65a0a3eb';
          // accesstype = 'cae7d5f4-2c5a-469b-9fcb-cf5ff71007db';
          // serviceid = '2cb805d6-c7cd-4096-be98-24c065942b9a';

          // for production
          accessfnctionid = "a7b15261-7e90-4ead-8dbb-a00341aab4fe";
          roleid = "b395d8e9-6e3a-4212-aeac-48dc65a0a3eb";
          accesstype = "cae7d5f4-2c5a-469b-9fcb-cf5ff71007db";
          serviceid = "c0ecc9d3-6206-4834-8007-f87b7664adf5";

          let userData = {
            country: this.user.country.isocode,
            email: this.user.contact_email,
            phone: phoneNumber.number,
            businessid: sessionStorage.getItem("businessId"),
            // branchid:this.user.branch_selected!=null?this.user.branch_selected.id:'',
            accessfunction: accessfnctionid,
            role: roleid,
            accesstype: accesstype,
            service: serviceid,
            firstname: this.user.first_name,
            lastname: this.user.last_name,
          };
          // console.log('Form Data',userData);
          this.$http
            .post(this.$baseurl + "business/personnel", userData, {
              headers: {
                Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
              },
            })
            .then((response) => {
              if (response.data.code == "0067-000") {
                this.isLoading = false;
                // shared.toastrSuccess(response.data.message);
                $toast.success("User details successfully created", {
                  position: "top-right",
                });
                this.refreshPersonnelTable();
                this.$bvModal.hide("add_user");
                this.$refs.loader.hide();
              } else {
                this.isLoading = false;
                this.$refs.loader.hide();
                // shared.toastrError(response.data.message);
                $toast.error(response.data.message, {
                  position: "top-right",
                });
              }
            })
            .catch((error) => {
              this.isLoading = false;
              
              // this.apiErrorCode = error.response.data.code;
              if (error.response.data.detail == undefined) {
                // this.apiErrorMsg = error.response.data.message;
                $toast.error(error.response.data.message, {
                  position: "top-right",
                });
              } else {
                // this.apiErrorMsg = error.response.data.detail;
                $toast.error(error.response.data.detail, {
                  position: "top-right",
                });
              }
              this.$refs.loader.hide();
            });
        }
      }
    },

    editUser(businessUserId) {
      this.userID = businessUserId;

      this.$refs.loader.show();
      this.$http
        .get(
          this.$baseurl +
            "business/personnel/" +
            businessUserId +
            "/" +
            sessionStorage.getItem("businessId"),
          {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("jwtToken"), //`Basic ${token}`
            },
          }
        )
        .then((response) => {
          if (response.data.code == "0067-000") {
            // console.log(response.data);
            const phoneNumber = parsePhoneNumber(
              response.data.details.phone,
              response.data.details.conutryofresidence.isocode
            );
            this.user.first_name = response.data.details.firstname;
            this.user.last_name = response.data.details.lastname;
            this.user.contact_email = response.data.details.email;
            this.user.mobile_number = phoneNumber.nationalNumber;

            this.edituser.roles_selected =
              response.data.details.role.roleid != null
                ? {
                    id: response.data.details.role.roleid,
                    name: response.data.details.role.rolename,
                  }
                : null;

            this.edituser.function_selected =
              response.data.details.accessfunction.functionid != null
                ? {
                    id: response.data.details.accessfunction.functionid,
                    name: response.data.details.accessfunction.functionname,
                  }
                : null;

            // this.edituser.businessname         = {id:response.data.details.business.businessid, parentId: response.data.details.business.parentbusinessid,isType: response.data.details.business.parentbusinessid==''?"parent":"child",category: response.data.details.business.businessname};

            this.edituser.services_selected =
              response.data.details.service.serviceid != null
                ? {
                    id: response.data.details.service.serviceid,
                    name: response.data.details.service.servicename,
                  }
                : null;

            this.edituser.access_selected =
              response.data.details.accesstype.accesstypeid != null
                ? {
                    id: response.data.details.accesstype.accesstypeid,
                    name: response.data.details.accesstype.accesstypename,
                  }
                : null;

            let countryData = {
              id: response.data.details.conutryofresidence.id,
              isocode: response.data.details.conutryofresidence.isocode,
              name: response.data.details.conutryofresidence.countryname,
              flagClass:
                "flag-icon flag-icon-" +
                response.data.details.conutryofresidence.isocode.toLowerCase() +
                " fi-xl fi-round",
              isChecked: true,
            };
            this.user.country = countryData;
            this.primaryMobileNumber = {
              mode: "auto",
              defaultCountry: response.data.details.conutryofresidence.isocode,
              autoFormat: false,
              dropdownOptions: {
                showFlags: true,
                disabled: true,
                showDialCodeInSelection: true,
              },
            };
            this.user.readonlyMode = false;
            this.$bvModal.show("add_user");
            this.$refs.loader.hide();
          } else {
            this.$refs.loader.hide();
            // shared.toastrError(response.data.message);
            $toast.error(response.data.message, {
              position: "top-right",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          // shared.toastrError(error.response.data.message);
          $toast.error(error.response.data.message, {
            position: "top-right",
          });
        });

      // sessionStorage.setItem("businessUserId", businessUserId);
      // this.$router.push({ path: '/setting/user-edit' })
    },

    updateUser() {
      this.isLoading = true;
      if (typeof this.mobileInputError == "undefined") {
        this.mobileInputError = false;
      }
      if (this.mobileInputError) {
        //console.log(this.user.branch_selected);
        const phoneNumber = parsePhoneNumber(
          this.user.mobile_number,
          this.user.country.isocode
        );
        let userData = {
          businessid: sessionStorage.getItem("businessId"),
          personnelinfo: {
            firstname: this.user.first_name,
            lastname: this.user.last_name,
            email: this.user.contact_email,
            phone: phoneNumber.number,
            countryid: this.user.country.isocode,
          },
          personnelroles: {
            // branchid:this.user.branch_selected!=null?this.user.branch_selected.id:'',
            functionid: this.edituser.function_selected.id,
            roleid: this.edituser.roles_selected.id,
            accesstypeid: this.edituser.access_selected.id,
            serviceid: this.edituser.services_selected.id,
          },
        };
        // console.log(userData);

        this.$http
          .put(
            this.$baseurl + "business/actions/edit/" + this.userID,
            userData,
            {
              headers: {
                Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
              },
            }
          )
          .then((response) => {
            // console.log(response);
            if (response.data.code == "0102-000") {
              this.isLoading = false;
              this.$bvModal.hide("add_user");
              this.refreshPersonnelTable();
              $toast.success("User details successfully updated", {
                position: "top-right",
              });
              this.$refs.loader.hide();
              // shared.toastrSuccess('User details successfully updated');
            } else {
              this.isLoading = false;
              this.$refs.loader.hide();
              // shared.toastrError(response.data.message);
              $toast.error(response.data.message, {
                position: "top-right",
              });
            }
          })
          .catch((error) => {
            // console.log(error);
            this.isLoading = false;
            this.apiErrorCode = error.response.data.code;
            if (error.response.data.detail == undefined) {
              this.apiErrorMsg = error.response.data.message;
            } else {
              this.apiErrorMsg = error.response.data.detail;
            }
            this.$refs.loader.hide();
            // shared.toastrError(error.response.data.message);
            $toast.error(error.response.data.message, {
              position: "top-right",
            });
          });
      }
    },

    showAlert(userid) {
      this.$swal({
        icon: "warning",
        title: "Are you sure",
        text: `You won't be able to revert this!`,
        confirmButtonText: "Yes, delete it!",
        showCancelButton: true,
        showConfirmButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteUser(userid);
        }
      });
    },

    deleteUser(businessUserId) {
      this.$refs.loader.show();
      let userData = {
        businessuserid: businessUserId,
      };
      this.$http
        .put(
          this.$baseurl + "business/actions/remove/" + businessUserId,
          userData,
          {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("jwtToken"), //`Basic ${token}`
            },
          }
        )
        .then((res) => {
          console.log(res);
          if (res.data.code == "0102-000") {
            // shared.toastrSuccess(res.data.message);
            $toast.success(res.data.message, {
              position: "top-right",
            });
            this.refreshPersonnelTable();
            this.$refs.loader.hide();
          } else {
            this.$refs.loader.hide();
            // shared.toastrError(res.data.message);
            $toast.error(res.data.message, {
              position: "top-right",
            });
          }
        })
        .catch((error) => {
          this.apiErrorCode = error.response.data.code;
          if (this.apiErrorCode == "E0065-005") {
            this.$refs.loader.hide();
            // shared.toastrError(error.response.data.message);
            $toast.error(error.response.data.message, {
              position: "top-right",
            });
          } else {
            this.apiErrorMessage = error.response.data.message;
          }
        });
    },
  },
  data() {
    return {
      items: [],
      isLoading: false,

      personnel_fields: [
        {
          key: "details",
          label: "Name",
          sortable: true,
          thClass: "user-name-img th_sortfix",
        },
        {
          key: "country",
          label: "Country",
          sortable: true,
          thClass: "country th_sortfix",
        },
        {
          key: "phone",
          label: "Phone",
          sortable: true,
          thClass: "phone th_sortfix",
        },
        {
          key: "email",
          label: "Email",
          sortable: true,
          thClass: "email th_sortfix",
        },
        // { key: 'function', label: 'Function',sortable: true,thClass:'function-badge th_sortfix'},
        {
          key: "role",
          label: "Role",
          sortable: true,
          thClass: "role-badge th_sortfix",
        },
        // { key: 'services', label: 'Services',sortable: true,thClass:'services-badge th_sortfix'},
        // { key: 'type', label: 'Access Type',sortable: true,thClass:'access-badge th_sortfix'},
        { key: "action", label: "Action" },
        { sortable: true, sortByFormatted: true, filterByFormatted: true },
      ],

      totalRows: 1,
      currentPage: 1,
      perPage: 50,
      pageOptions: [50, 100, 150, 200, { value: 1000, text: 1000 }],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",

      currentPageJump: "",
      searchText: "",

      country_options: [],
      user: {
        country: null,
        first_name: "",
        last_name: "",
        contact_email: "",
        mobile_number: "",
        readonlyMode: true,
      },
      primaryMobileNumber: {
        mode: "auto",
        defaultCountry: "GH",
        autoFormat: false,
        dropdownOptions: {
          showFlags: true,
          // disabled: true,
          showDialCodeInSelection: true,
          disabledDialCode: true, tabindex: 0
        },
      },
      modaltitle: "",
      userID: "",
      edituser: {
        function_selected: null,
        roles_selected: null,
        access_selected: null,
        services_selected: null,
      },
    };
  },
  mounted() {
    if(sessionStorage.getItem("jwtToken")){
        this.getAvailableCountry();
    } else {
        this.$swal('Error!', 'Please login to continue', 'error', 'Close');
        this.$router.push({path:'/authentication/page-login'});
    }
  },
};
</script>
<style>
text-grey {
  color: #8a8a8a !important;
}
</style>
