<template>
    <div class="money-exchange">
        <div class="payangel-wallet" v-if="$route.query.type == 'payangel-wallet'">
            <wallet_account></wallet_account>
        </div>
        <div class="mobile-money" v-if="$route.query.type == 'mobile-money'">
            <mobile_money_account></mobile_money_account>
        </div>
        <div class="bank-deposite" v-if="$route.query.type == 'bank-deposits'">
            <bank_deposits_account></bank_deposits_account>
        </div>
        <div class="cash-pickup" v-if="$route.query.type == 'cash-pickup'">
            <case_pickup></case_pickup>
        </div>
        <div class="airtime" v-if="$route.query.type == 'airtime'">
            <airtime></airtime>
        </div>
    </div>
</template>
<script>
import wallet_account from '@/components/admin/send_money/send_myself/WalletAccount.vue';
import mobile_money_account from '@/components/admin/send_money/send_myself/MobileMoneyList.vue';
import bank_deposits_account from '@/components/admin/send_money/send_myself/BankAccountList.vue';
import case_pickup from '@/components/admin/send_money/send_myself/CashPickup.vue';
import airtime from '@/components/admin/send_money/send_myself/Airtime.vue';
export default {
    name:'MoneyExchangeBy',
    components: {
        wallet_account,
        mobile_money_account,
        bank_deposits_account,
        case_pickup,
        airtime,
    }
}
</script>