<template>
    <div class="card card-top-line">
        <div class="header d-flex align-items-center justify-content-between">
            <h2>Business Structure, Condition and Size</h2>
            <i class="fa fa-edit font-20" @click="editDetails()"></i>
        </div>
        <div class="body">
            <div class="edit-form">
                <Form @submit="BStructureSubmit" id="business-structure" ref="form" :validation-schema="schema" v-slot="{ errors }">
                    <div class="d-flex align-items-center justify-content-between mb-3" :class="{'switch-disable':businessStructure.readonlyMode}" v-if="!businessStructure.readonlyMode">
                        <p class="mb-0 mr-3 custom-switch-text">Is your business a parent of another?</p>
                        <div class="custom-switch success-handle">
                            <input type="checkbox" class="custom-switch-input" id="parentBusinessName" v-model="businessStructure.parentBusinessName" :disabled="businessStructure.readonlyMode">
                            <label class="custom-switch-label" for="parentBusinessName">
                                <span class="custom-switch-text1">Yes</span>
                                <span class="custom-switch-text2">No</span>
                            </label>
                        </div>
                    </div>
                    <Field :rules="businessStructure.parentBusinessName ? 'required' : ''" name="Parent_profile" v-slot="{ handleChange }" v-if="businessStructure.parentBusinessName && !businessStructure.readonlyMode">
                        <div class="form-group" :class="{'errors': errors && errors.Parent_profile,'success':!errors.Parent_profile && !errors}">
                            <span class="float-label">
                                <input type="text" @update:model-value="handleChange" v-model="businessStructure.parentProfile" class="form-control" id="Parent_profile" placeholder="Parent Profile*" :disabled="businessStructure.readonlyMode">
                                <label for="Parent_profile" class="control-label">Parent Profile*</label>
                                <div class="line"></div>
                                <i class="fa fa-times" v-if="errors && errors.Parent_profile && !businessStructure.readonlyMode"></i>
                                <i class="fa fa-check" v-if=" !errors.Parent_profile && !businessStructure.readonlyMode"></i>
                            </span>
                        </div>
                    </Field>
                    <div class="d-flex align-items-center justify-content-between mb-3" :class="{'switch-disable':businessStructure.readonlyMode}" v-if="!businessStructure.readonlyMode">
                        <p class="mb-0 mr-3 custom-switch-text">Is your business a subsidiary of another business?</p>
                        <div class="custom-switch success-handle">
                            <input type="checkbox" class="custom-switch-input" id="subsidiary" @update:model-value="handleChange" v-model="businessStructure.subsidiary" :disabled="businessStructure.readonlyMode">
                            <label class="custom-switch-label" for="subsidiary">
                                <span class="custom-switch-text1">Yes</span>
                                <span class="custom-switch-text2">No</span>
                            </label>
                        </div>
                    </div>
                    <Field :rules="businessStructure.subsidiary ? 'required' : ''" name="subsidiary_Name" v-slot="{ handleChange }" v-if="businessStructure.subsidiary && !businessStructure.readonlyMode">
                        <div class="form-group" :class="{'errors': errors && errors.subsidiary_Name,'success':!errors.subsidiary_Name && !errors}">
                            <span class="float-label">
                                <input type="text" @update:model-value="handleChange" v-model="businessStructure.subsidiaryName" class="form-control" id="subsidiary_Name" placeholder="Parent Name/ Business Activity/ Registration Number" :disabled="businessStructure.readonlyMode">
                                <label for="subsidiary_Name" class="control-label">Parent Name/ Business Activity/ Registration Number</label>
                                <div class="line"></div>
                                <i class="fa fa-times" v-if="errors && errors.subsidiary_Name && !businessStructure.readonlyMode"></i>
                                <i class="fa fa-check" v-if=" !errors.subsidiary_Name && !businessStructure.readonlyMode"></i>
                            </span>
                        </div>
                    </Field>
                    <Field rules="required" name="companyStatus">
                        <div class="country-dropdown">
                            <div class="form-group form-select multiselect-grp" :class="{'errors': errors && errors.companyStatus,'success':businessStructure.companyStatus || !errors}">
                                <span class="float-label" :class="{'multiselect--disabled':businessStructure.readonlyMode}">
                                <label for="Country">Company Status*</label>
                                    <multiselect
                                        :disabled="businessStructure.readonlyMode"
                                        placeholder=""
                                        v-model="businessStructure.companyStatus" 
                                        :show-labels="false" 
                                        :options="companyStatus_option" 
                                        :searchable="false">
                                        <template v-slot:singleLabel="{ option }">{{ option }}</template>
                                        <template v-slot:option="{ option }">
                                            <span :class="option.flagClass"></span>
                                            <div class="option__desc">
                                                <span class="option__title">{{ option }}</span>
                                            </div>
                                        </template>
                                    </multiselect>  
                                    <i class="fa fa-times" v-if="errors && errors.companyStatus && !businessStructure.readonlyMode"></i>
                                    <i class="fa fa-check" v-if=" !errors.companyStatus && !businessStructure.readonlyMode"></i>
                                </span>
                            </div>
                        </div>
                    </Field>
                    <Field rules="required" name="NoOfEmp">
                        <div class="country-dropdown">
                            <div class="form-group form-select multiselect-grp" :class="{'errors': errors && errors.NoOfEmp,'success':businessStructure.NoOfEmp || !errors}">
                                <span class="float-label" :class="{'multiselect--disabled':businessStructure.readonlyMode}">
                                <label for="Country">Number of Employees*</label>
                                    <multiselect
                                        :disabled="businessStructure.readonlyMode"
                                        placeholder=""
                                        v-model="businessStructure.NoOfEmp" 
                                        :show-labels="false" 
                                        :options="NoOfEmp_option" 
                                        :searchable="false">
                                        <template v-slot:singleLabel="{ option }">{{ option }}</template>
                                        <template v-slot:option="{ option }">
                                            <span :class="option.flagClass"></span>
                                            <div class="option__desc">
                                                <span class="option__title">{{ option }}</span>
                                            </div>
                                        </template>
                                    </multiselect>  
                                    <i class="fa fa-times" v-if="errors && errors.NoOfEmp && !businessStructure.readonlyMode"></i>
                                    <i class="fa fa-check" v-if=" !errors.NoOfEmp && !businessStructure.readonlyMode"></i>
                                </span>
                            </div>
                        </div>
                    </Field>
                    <Field rules="required" name="CurrentBTurnover">
                        <div class="country-dropdown">
                            <div class="form-group form-select multiselect-grp" :class="{'errors': errors && errors.CurrentBTurnover,'success':businessStructure.CurrentBTurnover || !errors}">
                                <span class="float-label" :class="{'multiselect--disabled':businessStructure.readonlyMode}">
                                <label for="Country">Current Business Turnover*</label>
                                    <multiselect
                                        :disabled="businessStructure.readonlyMode"
                                        placeholder=""
                                        v-model="businessStructure.CurrentBTurnover" 
                                        :show-labels="false" 
                                        :options="CurrentBTurnover_option" 
                                        :searchable="false">
                                        <template v-slot:singleLabel="{ option }">{{ option }}</template>
                                        <template v-slot:option="{ option }">
                                            <span :class="option.flagClass"></span>
                                            <div class="option__desc">
                                                <span class="option__title">{{ option }}</span>
                                            </div>
                                        </template>
                                    </multiselect>  
                                    <i class="fa fa-times" v-if="errors && errors.CurrentBTurnover && !businessStructure.readonlyMode"></i>
                                    <i class="fa fa-check" v-if=" !errors.CurrentBTurnover && !businessStructure.readonlyMode"></i>
                                </span>
                            </div>
                        </div>
                    </Field>
                    <Field rules="required" name="ExpectedBTurnover">
                        <div class="country-dropdown">
                            <div class="form-group form-select multiselect-grp" :class="{'errors': errors && errors.ExpectedBTurnover,'success':businessStructure.ExpectedBTurnover || !errors}">
                                <span class="float-label" :class="{'multiselect--disabled':businessStructure.readonlyMode}">
                                <label for="Country">Expected Business Turnover*</label>
                                    <multiselect
                                        :disabled="businessStructure.readonlyMode"
                                        placeholder=""
                                        v-model="businessStructure.ExpectedBTurnover" 
                                        :show-labels="false" 
                                        :options="ExpectedBTurnover_option" 
                                        :searchable="false">
                                        <template v-slot:singleLabel="{ option }">{{ option }}</template>
                                        <template v-slot:option="{ option }">
                                            <span :class="option.flagClass"></span>
                                            <div class="option__desc">
                                                <span class="option__title">{{ option }}</span>
                                            </div>
                                        </template>
                                    </multiselect>  
                                    <i class="fa fa-times" v-if="errors && errors.ExpectedBTurnover && !businessStructure.readonlyMode"></i>
                                    <i class="fa fa-check" v-if=" !errors.ExpectedBTurnover && !businessStructure.readonlyMode"></i>
                                </span>
                            </div>
                        </div>
                    </Field>
                    <Field rules="required" name="size">
                        <div class="country-dropdown">
                            <div class="form-group form-select multiselect-grp" :class="{'errors': errors && errors.size,'success':businessStructure.size || !errors}">
                                <span class="float-label" :class="{'multiselect--disabled':businessStructure.readonlyMode}">
                                <label for="Country">Size*</label>
                                    <multiselect
                                        :disabled="businessStructure.readonlyMode"
                                        placeholder=""
                                        v-model="businessStructure.size" 
                                        :show-labels="false" 
                                        :options="size_option" 
                                        :searchable="false">
                                        <template v-slot:singleLabel="{ option }">{{ option }}</template>
                                        <template v-slot:option="{ option }">
                                            <span :class="option.flagClass"></span>
                                            <div class="option__desc">
                                                <span class="option__title">{{ option }}</span>
                                            </div>
                                        </template>
                                    </multiselect>  
                                    <i class="fa fa-times" v-if="errors && errors.size && !businessStructure.readonlyMode"></i>
                                    <i class="fa fa-check" v-if=" !errors.size && !businessStructure.readonlyMode"></i>
                                </span>
                            </div>
                        </div>
                    </Field>
                    <div v-if="!businessStructure.readonlyMode" class="justify-content-center d-flex">
                        <b-button size="lg" @click="BStructureSubmit()" class="btn-mw">Cancel</b-button>
                        <b-button type="submit" variant="primary ml-1 btn-mw" size="lg">Update</b-button>
                    </div>
                </Form>
            </div>
        </div>
        
    </div>
    
</template>
<script>
import { Form, Field } from 'vee-validate';
import VueMultiselect from 'vue-multiselect'
import "@/plugins/vee-validate";
export default {
    name: 'BusinessStructure-Profile',
    components:{
        Form,
        Field,
        'Multiselect': VueMultiselect,
    },
    data(){
        return{
            businessStructure:{
                readonlyMode:true,
                parentBusinessName:false,
                parentProfile:'',
                subsidiary:false,
                subsidiaryName:'',
                companyStatus:['Dormant'],
                NoOfEmp:['1 - 5'],
                CurrentBTurnover:['0 - 1 Million GBP'],
                ExpectedBTurnover:['0 - 1 Million GBP'],
                size:['Small']
            },

            companyStatus_option: ['Active', 'Dormant', 'Disqualified', 'Suspended', 'Discontinued', 'Inactive'],
            NoOfEmp_option: ['1 - 5', '5 - 10', '10 - 20', '20 - 50', '50 - 100', '100+'],
            CurrentBTurnover_option: ['0 - 1 Million GBP', '1 - 5 Million GBP', '5 - 10 Million GBP', '10 - 20 Million GBP', '20 - 50 Million GBP', '50 - 100 Million GBP', '100+ Million GBP'],
            ExpectedBTurnover_option: ['0 - 1 Million GBP', '1 - 5 Million GBP', '5 - 10 Million GBP', '10 - 20 Million GBP', '20 - 50 Million GBP', '50 - 100 Million GBP', '100+ Million GBP'],
            size_option: ['Micro', 'Small', 'Medium', 'Large'],
        }
    },methods:{
        editDetails(){
            this.businessStructure.readonlyMode = false
        },

        //submit Personal Details information
        BStructureSubmit(){
            this.businessStructure.readonlyMode  = true
        },
    }
}
</script>
