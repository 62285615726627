// initial state
const state = () => ({
    firstName: '',
    lastName: '',
    middleName: ''
})

// getters
const getters = {}

// actions
const actions = {
    addPersonalDetail ({ commit }, userPersonalDetails) {
        commit('setPersonalDetail', userPersonalDetails)
    },
    clearPersonal({commit}) {
        commit('clearPersonalData')
    }
}

// mutations
const mutations = {
    setPersonalDetail (state, personalDetail) {
        state.firstName = personalDetail.firstname,
        state.lastName = personalDetail.lastname,
        state.middleName = personalDetail.middleName
    },
    clearPersonalData(state) {
        state.firstName = '',
        state.lastName = '',
        state.middleName = ''
    }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}