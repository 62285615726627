<template>
    <div class="card card-top-line">
        <div class="header d-flex align-items-center justify-content-between">
            <h2>Controllers of your Business</h2>
            <i class="fa fa-edit font-20" @click="editDetails()"></i>
        </div>
        <div class="body">
            <div class="account-form">
                <Form @submit="UBODataSubmit" id="logo-info" ref="form" :validation-schema="schema" v-slot="{ errors }">
                    <div class="form-UBO" v-for="(ubodata,index) in UBODatas" :key="index">
                        <h6 class="mb-3 font-15"><i class="fa fa-level-up fa-rotate-90"></i> <strong>Ultimate Beneficial Owner (UBO)</strong></h6>
                        <Field :name="'firstName_'+index" rules="required" v-slot="{ handleChange }">
                            <div class="form-group" :class="{'errors': errors && !ubodata.first_name,'success':ubodata.first_name}">
                                <span class="float-label">
                                    <input type="text" @update:model-value="handleChange" v-model="ubodata.first_name" class="form-control" :id="'firstName_'+index" placeholder="First Name*" :disabled="readonlyMode"/>
                                    <label :for="'firstName_'+index" class="control-label">First Name*</label>
                                    <div class="line"></div>
                                    <i class="fa fa-times" v-if="errors && errors.first_name && !readonlyMode"></i>
                                    <i class="fa fa-check" v-if="!errors && !errors.first_name && !readonlyMode"></i>
                                </span>
                            </div>
                        </Field>
                        <Field :name="'middleName_'+index" rules="required" v-slot="{ handleChange }">
                            <div class="form-group" :class="{'errors': errors && !ubodata.middle_name,'success':ubodata.middle_name}">
                                <span class="float-label">
                                    <input type="text" @update:model-value="handleChange" v-model="ubodata.middle_name" class="form-control" :id="'middleName_'+index" placeholder="Middle Name*" :disabled="readonlyMode"/>
                                    <label :for="'middleName_'+index" class="control-label">Middle Name*</label>
                                    <div class="line"></div>
                                    <i class="fa fa-times" v-if="errors && errors.middle_name && !readonlyMode"></i>
                                    <i class="fa fa-check" v-if="!errors && !errors.middle_name && !readonlyMode"></i>
                                </span>
                            </div>
                        </Field>
                        <Field :name="'lastName_'+index" rules="required" v-slot="{ handleChange }">
                            <div class="form-group" :class="{'errors': errors && !ubodata.last_name,'success':ubodata.last_name}">
                                <span class="float-label">
                                    <input type="text" @update:model-value="handleChange" v-model="ubodata.last_name" class="form-control" :id="'lastName_'+index" placeholder="Last Name*" :disabled="readonlyMode"/>
                                    <label :for="'lastName_'+index" class="control-label">Last Name*</label>
                                    <div class="line"></div>
                                    <i class="fa fa-times" v-if="errors && errors.last_name && !readonlyMode"></i>
                                    <i class="fa fa-check" v-if="!errors && !errors.last_name && !readonlyMode"></i>
                                </span>
                            </div>
                        </Field>
                        <Field :name="'ownership_'+index" rules="required" v-slot="{ handleChange }">
                            <div class="form-group" :class="{'errors': errors && !ubodata.ownership,'success':ubodata.ownership}">
                                <span class="float-label">
                                    <b-input-group append="%" :class="{'ownership-disable':readonlyMode}">
                                        <b-form-input type="text" @update:model-value="handleChange" v-model="ubodata.ownership" :id="'ownership_'+index"  placeholder="Ownership*" :disabled="readonlyMode"></b-form-input>
                                        <i class="fa fa-times" v-if="errors && errors.ownership && !readonlyMode"></i>
                                        <i class="fa fa-check" v-if="!errors && !errors.ownership && !readonlyMode"></i>
                                    </b-input-group>
                                    <label :for="'ownership_'+index" class="control-label">Ownership*</label>
                                    <div class="line"></div>
                                </span>
                            </div>
                        </Field>
                        <Field :name="'occupation_'+index" rules="required" v-slot="{ handleChange }">
                            <div class="form-group" :class="{'errors': errors && !ubodata.occupation,'success':ubodata.occupation}">
                                <span class="float-label">
                                    <input type="text" @update:model-value="handleChange" v-model="ubodata.occupation" class="form-control" :id="'occupation_'+index" placeholder="Occupation*" :disabled="readonlyMode"/>
                                    <label :for="'occupation_'+index" class="control-label">Occupation*</label>
                                    <div class="line"></div>
                                    <i class="fa fa-times" v-if="errors && errors.occupation && !readonlyMode"></i>
                                    <i class="fa fa-check" v-if="!errors && !errors.occupation && !readonlyMode"></i>
                                </span>
                            </div>
                        </Field>
                        <Field :name="'cob_'+index" rules="required">
                            <div class="country-dropdown">
                                <div class="form-group form-select multiselect-grp" :class="{'errors': errors,'success':ubodata.countryofBirth && !errors}">
                                    <span class="float-label" :class="{'multiselect--disabled':readonlyMode}">
                                    <label for="Country">Country of Birth*</label>
                                        <multiselect
                                            :disabled="readonlyMode"
                                            placeholder=""
                                            v-model="ubodata.countryofBirth" 
                                            :show-labels="false" 
                                            :options="countryofBirth_options" 
                                            :searchable="false"
                                            label="name" 
                                            track-by="name">
                                            <template v-slot:singleLabel="{ option }">{{ option.name }}</template>
                                            <template v-slot:option="{ option }">
                                                <span :class="option.flagClass"></span>
                                                <div class="option__desc">
                                                    <span class="option__title">{{ option.name }}</span>
                                                </div>
                                            </template>
                                        </multiselect>  
                                        <i class="fa fa-times" v-if="errors && errors.logo && !readonlyMode"></i>
                                        <i class="fa fa-check" v-if="!errors && !errors.logo && !readonlyMode"></i>
                                    </span>
                                </div>
                            </div>
                        </Field>
                        <Field :name="'nationality_'+index" rules="required">
                            <div class="country-dropdown">
                                <div class="form-group form-select multiselect-grp" :class="{'errors': errors,'success':ubodata.nationality && !errors}">
                                    <span class="float-label" :class="{'multiselect--disabled':readonlyMode}">
                                    <label for="Country">Nationality*</label>
                                        <multiselect
                                            :disabled="readonlyMode"
                                            placeholder=""
                                            v-model="ubodata.nationality" 
                                            :show-labels="false" 
                                            :options="nationality_countrys" 
                                            :searchable="false"
                                            label="name" 
                                            track-by="name">
                                            <template v-slot:singleLabel="{ option }">{{ option.name }}</template>
                                            <template v-slot:option="{ option }">
                                                <span :class="option.flagClass"></span>
                                                <div class="option__desc">
                                                    <span class="option__title">{{ option.name }}</span>
                                                </div>
                                            </template>
                                        </multiselect>  
                                        <i class="fa fa-times" v-if="errors && errors.logo && !readonlyMode"></i>
                                        <i class="fa fa-check" v-if="!errors && !errors.logo && !readonlyMode"></i>
                                    </span>
                                </div>
                            </div>
                        </Field>
                        <Field :name="'residenceCountry_'+index" rules="required">
                            <div class="country-dropdown">
                                <div class="form-group form-select multiselect-grp" :class="{'errors': errors,'success':ubodata.country_of_residence && !errors}">
                                    <span class="float-label" :class="{'multiselect--disabled':readonlyMode}">
                                    <label for="Country">Country of Residence*</label>
                                        <multiselect
                                            :disabled="readonlyMode"
                                            placeholder=""
                                            v-model="ubodata.country_of_residence" 
                                            :show-labels="false" 
                                            :options="residence_countrys" 
                                            :searchable="false"
                                            label="name" 
                                            track-by="name">
                                            <template v-slot:singleLabel="{ option }">{{ option.name }}</template>
                                            <template v-slot:option="{ option }">
                                                <span :class="option.flagClass"></span>
                                                <div class="option__desc">
                                                    <span class="option__title">{{ option.name }}</span>
                                                </div>
                                            </template>
                                        </multiselect>  
                                        <i class="fa fa-times" v-if="errors && errors.logo && !readonlyMode"></i>
                                        <i class="fa fa-check" v-if="!errors && !errors.logo && !readonlyMode"></i>
                                    </span>
                                </div>
                            </div>
                        </Field>
                        <Field :name="'address1_'+index" rules="required" v-slot="{ handleChange }">
                            <div class="form-group" :class="{'errors': errors && !ubodata.address1,'success':ubodata.address1}">
                                <span class="float-label">
                                    <input type="text" @update:model-value="handleChange" v-model="ubodata.address1" class="form-control" :id="'address1_'+index" placeholder="Address Line 1*" :disabled="readonlyMode">
                                    <label :for="'address1_'+index" class="control-label">Address Line 1*</label>
                                    <div class="line"></div>
                                    <i class="fa fa-times" v-if="errors && errors.address1 && !readonlyMode"></i>
                                    <i class="fa fa-check" v-if="!errors && !errors.address1 && !readonlyMode"></i>
                                </span>
                            </div>
                        </Field>
                        <div class="form-group">
                            <span class="float-label">
                                <input type="text" v-model="ubodata.address2" class="form-control" id="address2" placeholder="Address Line 2" :disabled="readonlyMode">
                                <label for="address2" class="control-label">Address Line 2</label>
                                <div class="line"></div>
                            </span>
                        </div>
                        <Field :name="'town_'+index" rules="required" v-slot="{ errors }">
                            <div class="form-group" :class="{'errors': errors,'success':ubodata.town && !errors}">
                                <span class="float-label">
                                    <input type="text" class="form-control" :d="'town_'+index" @update:model-value="handleChange" v-model="ubodata.town" placeholder="Town*" :disabled="readonlyMode">
                                    <label :for="'town_'+index" class="control-label">Town*</label>
                                    <div class="line"></div>
                                    <i class="fa fa-times" v-if="errors && errors.town && !readonlyMode"></i>
                                    <i class="fa fa-check" v-if="!errors && !errors.town && !readonlyMode"></i>
                                </span>
                            </div>
                        </Field>
                        <Field :name="'city_'+index" rules="required" v-slot="{ handleChange }">
                            <div class="form-group" :class="{'errors': errors && !ubodata.city,'success':ubodata.city}">
                                <span class="float-label">
                                    <input type="text" @update:model-value="handleChange" v-model="ubodata.city" class="form-control" :id="'city_'+index" placeholder="City*" :disabled="readonlyMode">
                                    <label :for="'city_'+index" class="control-label">City*</label>
                                    <div class="line"></div>
                                    <i class="fa fa-times" v-if="errors && errors.city && !readonlyMode"></i>
                                    <i class="fa fa-check" v-if="!errors && !errors.city && !readonlyMode"></i>
                                </span>
                            </div>
                        </Field>
                        <Field :name="'state_'+index" rules="required" v-slot="{ handleChange }">
                            <div class="form-group" :class="{'errors': errors && !ubodata.state,'success':ubodata.state}">
                                <span class="float-label">
                                    <input type="text" @update:model-value="handleChange" v-model="ubodata.state" class="form-control" :id="'state_'+index" placeholder="State/Province/Region/County*" :disabled="readonlyMode">
                                    <label :for="'state_'+index" class="control-label">State/Province/Region/County*</label>
                                    <div class="line"></div>
                                    <i class="fa fa-times" v-if="errors && errors.state && !readonlyMode"></i>
                                    <i class="fa fa-check" v-if="!errors && !errors.state && !readonlyMode"></i>
                                </span>
                            </div>
                        </Field>
                        <Field :name="'zipCode_'+index" rules="required" v-slot="{ handleChange }">
                            <div class="form-group" :class="{'errors': errors && !ubodata.zipCode,'success':ubodata.zipCode}">
                                <span class="float-label">
                                    <input type="text" @update:model-value="handleChange" v-model="ubodata.zipCode" class="form-control" :id="'zipCode_'+index" placeholder="Post/Zip Code*" :disabled="readonlyMode">
                                    <label :for="'zipCode_'+index" class="control-label">Post/Zip Code*</label>
                                    <div class="line"></div>
                                    <i class="fa fa-times" v-if="errors && errors.zipCode && !readonlyMode"></i>
                                    <i class="fa fa-check" v-if="!errors && !errors.zipCode && !readonlyMode"></i>
                                </span>
                            </div>
                        </Field>
                        <Field :name="'dob_'+index" rules="required" v-slot="{ errors }">
                            <div class="form-group datepicker" :class="{'errors': errors,'success':ubodata.date_of_birth && !errors}">
                                <span class="float-label">
                                    <b-input-group :class="{'datepicker-disable':readonlyMode}">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">Date of Birth</span>
                                            <span class="input-group-text"><i class="icon-calendar"></i></span>
                                        </div>
                                        <datepicker class="form-control" format="YYYY-MM-DD" placeholder="" @update:model-value="handleChange" v-model="ubodata.date_of_birth" :disabled="readonlyMode" :typeable="true"></datepicker>
                                    </b-input-group>
                                    <i class="fa fa-times" v-if="errors && errors.date_of_birth && !readonlyMode"></i>
                                    <i class="fa fa-check" v-if="!errors && !errors.date_of_birth && !readonlyMode"></i>
                                </span>
                            </div>
                        </Field>
                    </div>
                    <div v-if="!readonlyMode" class="justify-content-center d-flex">
                        <b-button size="lg" @click="UBODataSubmit()" class="btn-mw">Cancel</b-button>
                        <b-button type="submit" variant="primary ml-1 btn-mw" size="lg">Update</b-button>
                    </div>
                </Form>
            </div>
        </div>
    </div>
</template>
<script>
import { Form, Field } from 'vee-validate';
import VueMultiselect from 'vue-multiselect'
export default {
    name: 'UBO-Profile',
    components:{
        Form,
        Field,
        datepicker: () => import('vue-date-picker'),
        'Multiselect': VueMultiselect,
    },
    data(){
        return{
            //ubodata
            readonlyMode:true,
            UBODatas:[
                {
                    first_name:'ubodata - John',
                    middle_name:'ubodata - Charlee',
                    last_name:'ubodata - Smith',
                    occupation:'Accountant',
                    nationality:[{ name: 'United Kingdom', flagClass: "flag-icon flag-icon-gb fi-xl fi-round"}],
                    country_of_residence: [{ name: 'France' , flagClass: "flag-icon flag-icon-fr fi-xl fi-round"}],
                    countryofBirth:[{ name: 'United Kingdom', flagClass: "flag-icon flag-icon-gb fi-xl fi-round"}],
                    address1:'10 Downing Street,LONDON',
                    address2:'1 Bishopthorpe Road',
                    town:'Penllyn',
                    city:'Manchester',
                    state:'England',
                    zipCode:'CF71 0HZ',
                    date_of_birth:'10-Jan-1987',
                    ownership:12
                }
            ],

            nationality_countrys: [
                { name: 'India', flagClass:"flag-icon flag-icon-in fi-xl fi-round"},
                { name: 'United Kingdom', flagClass: "flag-icon flag-icon-gb fi-xl fi-round"},
                { name: 'France' , flagClass: "flag-icon flag-icon-fr fi-xl fi-round"},
                { name: 'United States', flagClass: "flag-icon flag-icon-us fi-xl fi-round"},
            ],

            residence_countrys: [
                { name: 'India', flagClass:"flag-icon flag-icon-in fi-xl fi-round"},
                { name: 'United Kingdom', flagClass: "flag-icon flag-icon-gb fi-xl fi-round"},
                { name: 'France' , flagClass: "flag-icon flag-icon-fr fi-xl fi-round"},
                { name: 'United States', flagClass: "flag-icon flag-icon-us fi-xl fi-round"},
            ],

            countryofBirth_options: [
                { name: 'India', flagClass:"flag-icon flag-icon-in fi-xl fi-round"},
                { name: 'United Kingdom', flagClass: "flag-icon flag-icon-gb fi-xl fi-round"},
                { name: 'France' , flagClass: "flag-icon flag-icon-fr fi-xl fi-round"},
                { name: 'United States', flagClass: "flag-icon flag-icon-us fi-xl fi-round"},
            ],
        }
    },methods:{
        editDetails(){
            this.readonlyMode = false
        },

        UBODataSubmit(){
           this.readonlyMode = true
        },
    }
}
</script>
