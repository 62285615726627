<template>
    <div class="card card-top-line">
        <div class="header d-flex align-items-center justify-content-between">
            <h2>Controllers of your Business</h2>
            <i class="fa fa-edit font-20" @click="editDetails()"></i>
        </div>
        <div class="body">
            <div class="account-form">
                <Form @submit="directorDataSubmit" id="basic-info" ref="form" :validation-schema="schema" v-slot="{ errors }">
                    <div class="form-director" v-for="(director,directorIndex) in directors" :key="directorIndex">
                        <h6 class="mb-3 font-15"><i class="fa fa-level-up fa-rotate-90"></i> <strong>Directors</strong></h6>
                        <Field :name="'firstName_'+directorIndex" rules="required" v-slot="{ handleChange }">
                            <div class="form-group" :class="{'errors': errors && errors.technicalphoneNumber ,'success':!errors.technicalphoneNumber && !errors}">
                                <span class="float-label">
                                    <input type="text" @update:model-value="handleChange" v-model="director.first_name" class="form-control" :id="'firstName_'+directorIndex" placeholder="First Name*" :disabled="readonlyMode"/>
                                    <label :for="'firstName_'+directorIndex" class="control-label">First Name*</label>
                                    <div class="line"></div>
                                    <i class="fa fa-times" v-if="errors && errors.first_name && !readonlyMode"></i>
                                    <i class="fa fa-check" v-if=" !errors.first_name && !readonlyMode"></i>
                                </span>
                            </div>
                        </Field>
                        <Field :name="'middleName_'+directorIndex" rules="required" v-slot="{ handleChange }">
                            <div class="form-group" :class="{'errors': errors && errors.technicalphoneNumber ,'success':!errors.technicalphoneNumber && !errors}">
                                <span class="float-label">
                                    <input type="text" @update:model-value="handleChange" v-model="director.middle_name" class="form-control" :id="'middleName_'+directorIndex" placeholder="Middle Name*" :disabled="readonlyMode"/>
                                    <label :for="'middleName_'+directorIndex" class="control-label">Middle Name*</label>
                                    <div class="line"></div>
                                    <i class="fa fa-times" v-if="errors && errors.middle_name && !readonlyMode"></i>
                                    <i class="fa fa-check" v-if=" !errors.middle_name && !readonlyMode"></i>
                                </span>
                            </div>
                        </Field>
                        <Field :name="'lastName_'+directorIndex" rules="required" v-slot="{ handleChange }">
                            <div class="form-group" :class="{'errors': errors && errors.technicalphoneNumber ,'success':!errors.technicalphoneNumber && !errors}">
                                <span class="float-label">
                                    <input type="text" @update:model-value="handleChange" v-model="director.last_name" class="form-control" :id="'lastName_'+directorIndex" placeholder="Last Name*" :disabled="readonlyMode"/>
                                    <label :for="'lastName_'+directorIndex" class="control-label">Last Name*</label>
                                    <div class="line"></div>
                                    <i class="fa fa-times" v-if="errors && errors.last_name && !readonlyMode"></i>
                                    <i class="fa fa-check" v-if=" !errors.last_name && !readonlyMode"></i>
                                </span>
                            </div>
                        </Field>
                        <Field :name="'doa_'+directorIndex" rules="required" v-slot="{ handleChange }" >
                            <div class="form-group datepicker" :class="{'errors': errors && errors.technicalphoneNumber ,'success':!errors.technicalphoneNumber && !errors}">
                                <span class="float-label">
                                    <b-input-group :class="{'datepicker-disable':readonlyMode}">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">Date of Appointment</span>
                                            <span class="input-group-text"><i class="icon-calendar"></i></span>
                                        </div>
                                        <datepicker class="form-control" format="YYYY-MM-DD" placeholder="" @update:model-value="handleChange" v-model="director.date_of_appointment" :disabled="readonlyMode" :typeable="true"></datepicker>
                                    </b-input-group>
                                    <i class="fa fa-times" v-if="errors && errors.date_of_appointment && !readonlyMode"></i>
                                    <i class="fa fa-check" v-if=" !errors.date_of_appointment && !readonlyMode"></i>
                                </span>
                            </div>
                        </Field>
                        <Field :name="'occupation_'+directorIndex" rules="required"  v-slot="{ handleChange }">
                            <div class="form-group" :class="{'errors': errors && errors.technicalphoneNumber ,'success':!errors.technicalphoneNumber && !errors}">
                                <span class="float-label">
                                    <input type="text" @update:model-value="handleChange" v-model="director.occupation" class="form-control" :id="'occupation_'+directorIndex" placeholder="Occupation*" :disabled="readonlyMode"/>
                                    <label :for="'occupation_'+directorIndex" class="control-label">Occupation*</label>
                                    <div class="line"></div>
                                    <i class="fa fa-times" v-if="errors && errors.occupation && !readonlyMode"></i>
                                    <i class="fa fa-check" v-if=" !errors.occupation && !readonlyMode"></i>
                                </span>
                            </div>
                        </Field>
                        <Field :name="'nationality_'+directorIndex" rules="required" >
                            <div class="country-dropdown">
                                <div class="form-group form-select multiselect-grp" :class="{'errors': errors && errors.technicalphoneNumber ,'success':director.nationality || !errors}">
                                    <span class="float-label" :class="{'multiselect--disabled':readonlyMode}">
                                    <label for="Country">Nationality*</label>
                                        <multiselect
                                            :disabled="readonlyMode"
                                            placeholder=""
                                            v-model="director.nationality" 
                                            :show-labels="false" 
                                            :options="nationality_countrys" 
                                            :searchable="false"
                                            label="name" 
                                            track-by="name">
                                            <template v-slot:singleLabel="{ option }">{{ option.name }}</template>
                                            <template v-slot:option="{option}" >
                                                <span :class="option.flagClass"></span>
                                                <div class="option__desc">
                                                    <span class="option__title">{{ option.name }}</span>
                                                </div>
                                            </template>
                                        </multiselect>  
                                        <i class="fa fa-times" v-if="errors && errors.logo && !readonlyMode"></i>
                                        <i class="fa fa-check" v-if=" !errors.logo && !readonlyMode"></i>
                                    </span>
                                </div>
                            </div>
                        </Field>
                        <Field :name="'residenceCountry_'+directorIndex" rules="required">
                            <div class="country-dropdown">
                                <div class="form-group form-select multiselect-grp" :class="{'errors': errors && errors.technicalphoneNumber ,'success':director.country_of_residence || !errors}">
                                    <span class="float-label" :class="{'multiselect--disabled':readonlyMode}">
                                    <label for="Country">Country of Residence*</label>
                                        <multiselect
                                            :disabled="readonlyMode"
                                            placeholder=""
                                            v-model="director.country_of_residence" 
                                            :show-labels="false" 
                                            :options="residence_countrys" 
                                            :searchable="false"
                                            label="name" 
                                            track-by="name">
                                            <template v-slot:singleLabel="{ option }">{{ option.name }}</template>
                                            <template v-slot:option="{option}">
                                                <span :class="option.flagClass"></span>
                                                <div class="option__desc">
                                                    <span class="option__title">{{ option.name }}</span>
                                                </div>
                                            </template>
                                        </multiselect>  
                                        <i class="fa fa-times" v-if="errors && errors.logo && !readonlyMode"></i>
                                        <i class="fa fa-check" v-if=" !errors.logo && !readonlyMode"></i>
                                    </span>
                                </div>
                            </div>
                        </Field>
                        <Field :name="'cob_'+directorIndex" rules="required" >
                            <div class="country-dropdown">
                                <div class="form-group form-select multiselect-grp" :class="{'errors': errors && errors.technicalphoneNumber ,'success':director.countryofBirth || !errors}">
                                    <span class="float-label" :class="{'multiselect--disabled':readonlyMode}">
                                    <label for="Country">Country of Birth*</label>
                                        <multiselect
                                            :disabled="readonlyMode"
                                            placeholder=""
                                            v-model="director.countryofBirth" 
                                            :show-labels="false" 
                                            :options="countryofBirth_options" 
                                            :searchable="false"
                                            label="name" 
                                            track-by="name">
                                            <template v-slot:singleLabel="{ option }">{{ option.name }}</template>
                                            <template v-slot:option="{option}">
                                                <span :class="option.flagClass"></span>
                                                <div class="option__desc">
                                                    <span class="option__title">{{ option.name }}</span>
                                                </div>
                                            </template>
                                        </multiselect>  
                                        <i class="fa fa-times" v-if="errors && errors.logo && !readonlyMode"></i>
                                        <i class="fa fa-check" v-if=" !errors.logo && !readonlyMode"></i>
                                    </span>
                                </div>
                            </div>
                        </Field>
                        <Field :name="'address1_'+directorIndex" rules="required" v-slot="{ handleChange }" >
                            <div class="form-group" :class="{'errors': errors && errors.technicalphoneNumber ,'success':!errors.technicalphoneNumber && !errors}">
                                <span class="float-label">
                                    <input type="text" @update:model-value="handleChange" v-model="director.address1" class="form-control" :id="'address1_'+directorIndex" placeholder="Address Line 1*" :disabled="readonlyMode">
                                    <label :for="'address1_'+directorIndex" class="control-label">Address Line 1*</label>
                                    <div class="line"></div>
                                    <i class="fa fa-times" v-if="errors && errors.address1 && !readonlyMode"></i>
                                    <i class="fa fa-check" v-if=" !errors.address1 && !readonlyMode"></i>
                                </span>
                            </div>
                        </Field>
                        <div class="form-group" >
                            <span class="float-label">
                                <input type="text" v-model="director.address2" class="form-control" id="address2" placeholder="Address Line 2" :disabled="readonlyMode">
                                <label for="address2" class="control-label">Address Line 2</label>
                                <div class="line"></div>
                            </span>
                        </div>
                        <Field :name="'town_'+directorIndex" rules="required"  v-slot="{ handleChange }">
                            <div class="form-group" :class="{'errors': errors && errors.technicalphoneNumber ,'success':!errors.technicalphoneNumber && !errors}">
                                <span class="float-label">
                                    <input type="text" class="form-control" :d="'town_'+directorIndex" @update:model-value="handleChange" v-model="director.town" placeholder="Town*" :disabled="readonlyMode">
                                    <label :for="'town_'+directorIndex" class="control-label">Town*</label>
                                    <div class="line"></div>
                                    <i class="fa fa-times" v-if="errors && errors.town && !readonlyMode"></i>
                                    <i class="fa fa-check" v-if=" !errors.town && !readonlyMode"></i>
                                </span>
                            </div>
                        </Field>
                        <Field :name="'city_'+directorIndex" rules="required"  v-slot="{ handleChange }">
                            <div class="form-group" :class="{'errors': errors && errors.technicalphoneNumber ,'success':!errors.technicalphoneNumber && !errors}">
                                <span class="float-label">
                                    <input type="text" @update:model-value="handleChange" v-model="director.city" class="form-control" :id="'city_'+directorIndex" placeholder="City*" :disabled="readonlyMode">
                                    <label :for="'city_'+directorIndex" class="control-label">City*</label>
                                    <div class="line"></div>
                                    <i class="fa fa-times" v-if="errors && errors.city && !readonlyMode"></i>
                                    <i class="fa fa-check" v-if=" !errors.city && !readonlyMode"></i>
                                </span>
                            </div>
                        </Field>
                        <Field :name="'state_'+directorIndex" rules="required"  v-slot="{ handleChange }">
                            <div class="form-group" :class="{'errors': errors && errors.technicalphoneNumber ,'success':!errors.technicalphoneNumber && !errors}">
                                <span class="float-label">
                                    <input type="text" @update:model-value="handleChange" v-model="director.state" class="form-control" :id="'state_'+directorIndex" placeholder="State/Province/Region/County*" :disabled="readonlyMode">
                                    <label :for="'state_'+directorIndex" class="control-label">State/Province/Region/County*</label>
                                    <div class="line"></div>
                                    <i class="fa fa-times" v-if="errors && errors.state && !readonlyMode"></i>
                                    <i class="fa fa-check" v-if=" !errors.state && !readonlyMode"></i>
                                </span>
                            </div>
                        </Field>
                        <Field :name="'zipCode_'+directorIndex" rules="required"  v-slot="{ handleChange }">
                            <div class="form-group" :class="{'errors': errors && errors.technicalphoneNumber ,'success':!errors.technicalphoneNumber && !errors}">
                                <span class="float-label">
                                    <input type="text" @update:model-value="handleChange" v-model="director.zipCode" class="form-control" :id="'zipCode_'+directorIndex" placeholder="Post/Zip Code*" :disabled="readonlyMode">
                                    <label :for="'zipCode_'+directorIndex" class="control-label">Post/Zip Code*</label>
                                    <div class="line"></div>
                                    <i class="fa fa-times" v-if="errors && errors.zipCode && !readonlyMode"></i>
                                    <i class="fa fa-check" v-if=" !errors.zipCode && !readonlyMode"></i>
                                </span>
                            </div>
                        </Field>
                        <Field :name="'dob_'+directorIndex" rules="required"  v-slot="{ handleChange }">
                            <div class="form-group datepicker" :class="{'errors': errors && errors.technicalphoneNumber ,'success':!errors.technicalphoneNumber && !errors}">
                                <span class="float-label">
                                    <b-input-group :class="{'datepicker-disable':readonlyMode}">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">Date of Birth</span>
                                            <span class="input-group-text"><i class="icon-calendar"></i></span>
                                        </div>
                                        <datepicker class="form-control" format="YYYY-MM-DD" placeholder="" @update:model-value="handleChange" v-model="director.date_of_birth" :disabled="readonlyMode" :typeable="true"></datepicker>
                                    </b-input-group>
                                    <i class="fa fa-times" v-if="errors && errors.date_of_birth && !readonlyMode"></i>
                                    <i class="fa fa-check" v-if=" !errors.date_of_birth && !readonlyMode"></i>
                                </span>
                            </div>
                        </Field>
                        <Field rules="required" :name="'ownership_'+directorIndex" v-slot="{ handleChange }">
                            <div class="form-group" :class="{'errors': errors && errors.technicalphoneNumber ,'success':!errors.technicalphoneNumber && !errors}">
                                <span class="float-label">
                                    <b-input-group append="%" :class="{'ownership-disable':readonlyMode}">
                                        <b-form-input type="text" @update:model-value="handleChange" v-model="director.ownership" :id="'ownership_'+directorIndex"  placeholder="Ownership*" :disabled="readonlyMode"></b-form-input>
                                        <i class="fa fa-times" v-if="errors && errors.logo && !readonlyMode"></i>
                                        <i class="fa fa-check" v-if=" !errors.logo && !readonlyMode"></i>
                                    </b-input-group>
                                    <label :for="'ownership_'+directorIndex" class="control-label">Ownership Percentage*</label>
                                    <div class="line"></div>
                                    <i class="fa fa-times" v-if="errors && errors.ownership && !readonlyMode"></i>
                                    <i class="fa fa-check" v-if=" !errors.ownership && !readonlyMode"></i>
                                </span>
                            </div>
                        </Field>
                    </div>
                    <div v-if="!readonlyMode" class="justify-content-center d-flex">
                        <b-button size="lg" @click="directorDataSubmit()" class="btn-mw">Cancel</b-button>
                        <b-button type="submit" variant="primary ml-1 btn-mw" size="lg">Update</b-button>
                    </div>
                </Form>
            </div>
        </div>
    </div>
</template>
<script>
import { Form, Field } from 'vee-validate';
import VueMultiselect from 'vue-multiselect'
export default {
    name: 'Director-Profile',
    components:{
        Form,
        Field,
        datepicker: () => import('vue-date-picker'),
        'Multiselect': VueMultiselect,
    },
    data(){
        return{
            //Director
            readonlyMode:true,
            directors:[
                {
                    first_name:'Director - John',
                    middle_name:'Director - Charlee',
                    last_name:'Director - Smith',
                    date_of_appointment:'20-Jan-1990',
                    occupation:'Accountant',
                    nationality:[{ name: 'United Kingdom', flagClass: "flag-icon flag-icon-gb fi-xl fi-round"}],
                    country_of_residence: [{ name: 'France' , flagClass: "flag-icon flag-icon-fr fi-xl fi-round"}],
                    address1:'10 Downing Street,LONDON',
                    address2:'1 Bishopthorpe Road',
                    town:'Penllyn',
                    city:'Manchester',
                    state:'England',
                    zipCode:'CF71 0HZ',
                    date_of_birth:'10-Jan-1987',
                    ownership:12,
                    countryofBirth:[{ name: 'United Kingdom', flagClass: "flag-icon flag-icon-gb fi-xl fi-round"}]
                }
            ],

            nationality_countrys: [
                { name: 'India', flagClass:"flag-icon flag-icon-in fi-xl fi-round"},
                { name: 'United Kingdom', flagClass: "flag-icon flag-icon-gb fi-xl fi-round"},
                { name: 'France' , flagClass: "flag-icon flag-icon-fr fi-xl fi-round"},
                { name: 'United States', flagClass: "flag-icon flag-icon-us fi-xl fi-round"},
            ],

            residence_countrys: [
                { name: 'India', flagClass:"flag-icon flag-icon-in fi-xl fi-round"},
                { name: 'United Kingdom', flagClass: "flag-icon flag-icon-gb fi-xl fi-round"},
                { name: 'France' , flagClass: "flag-icon flag-icon-fr fi-xl fi-round"},
                { name: 'United States', flagClass: "flag-icon flag-icon-us fi-xl fi-round"},
            ],

            countryofBirth_options: [
                { name: 'India', flagClass:"flag-icon flag-icon-in fi-xl fi-round"},
                { name: 'United Kingdom', flagClass: "flag-icon flag-icon-gb fi-xl fi-round"},
                { name: 'France' , flagClass: "flag-icon flag-icon-fr fi-xl fi-round"},
                { name: 'United States', flagClass: "flag-icon flag-icon-us fi-xl fi-round"},
            ],
        }
    },methods:{
        editDetails(){
            this.readonlyMode = false
        },

        directorDataSubmit(){
           this.readonlyMode = true
        },
    }
}
</script>
