<template>
    <div class="container-fluid">
        <div class="block-header">
            <BreadCrumb></BreadCrumb>
        </div>
    </div>
</template>
<script>
import BreadCrumb from'@/components/BreadCrumb.vue'
export default {
    name:'PreferencesComponent',
    components: {
        BreadCrumb,
    }
}
</script>