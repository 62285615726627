<template>
    <div class="container-fluid">
        <div class="block-header">
            <BreadCrumb></BreadCrumb>
        </div>
        <div class="channel-main">

            <!-- Channel Sidebar -->
            <div class="channel-left">
                <div class="card">
                    <div class="body">
                        <!-- Business Model -->
                        <div class="add-business mb-3">
                            <!-- Add Business Channel Button-->
                            <b-button variant="primary w-100" size="lg" v-b-modal.add_channel>Add Business Channel</b-button>

                            <!-- Add Business Channel Business -->
                            <b-modal id="add_channel" title="Add Business Channel" hide-footer>
                                <ul class="list-unstyled mb-0 payment-single-transfer">
                                    <Form @submit="channelSubmitForm" :validation-schema="schema" id="channel-form" ref="form" v-slot="{ errors }" >
                                        <li>
                                            <Field name="businessName" rules="required"  v-slot="{ handleChange }">
                                                <div class="form-group form-select single-multiselect" :class="{'errors': errors && errors.businessName,'success':!errors || singleBusinessDatas.businessName}">
                                                    <span class="float-label">
                                                        <label for="functions">Select Business*</label>
                                                        <multiselect 
                                                            placeholder=""
                                                            v-model="singleBusinessDatas.businessName"
                                                            :show-labels="false" 
                                                            :options="businessNameOptions" 
                                                            :searchable="true"
                                                            label="name"
                                                            @input="handleChange">
                                                            <template v-slot:singleLabel="{ option }">{{ option.name }}</template>
                                                            <template v-slot:option="{ option }">
                                                                <div class="option__desc">
                                                                    <span class="option__title">{{ option.name }}</span>
                                                                </div>
                                                            </template>
                                                        </multiselect> 
                                                        <i class="fa fa-times" v-if="errors && errors.businessName"></i>
                                                        <i class="fa fa-check" v-if="!errors.businessName && singleBusinessDatas.businessName"></i>
                                                    </span>
                                                </div>
                                            </Field>
                                        </li>
                                        <!-- <li>
                                            <div class="d-flex align-items-center justify-content-between mb-3">
                                                <p class="mb-0 mr-3 custom-switch-text">Do you require technical Integration?</p>
                                                <div class="custom-switch success-handle">
                                                    <input type="checkbox" id="display_technical_integration" class="custom-switch-input" v-model="singleBusinessDatas.technicalIntegration">
                                                    <label for="display_technical_integration" class="custom-switch-label">
                                                        <span class="custom-switch-text1">Yes</span><span class="custom-switch-text2">No</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </li> -->
                                        <li>
                                            <Field name="channelName" :rules="{required:true}"  v-slot="{ handleChange }">
                                                <div class="form-group" :class="{'errors': errors && errors.channelName,'success':!errors && !errors.channelName}">
                                                    <span class="float-label">
                                                        <input type="text" @update:model-value="handleChange" v-model="singleBusinessDatas.channelName" class="form-control" name="channelName" id="channelName" placeholder="Trading Name*" @input="checkNameFormat($event,'channelName','Channel Name',0)">
                                                        <label for="channelName" class="control-label">Trading Name*</label>
                                                        <div class="line"></div>
                                                        <i class="fa fa-times" v-if="errors && errors.channelName"></i>
                                                        <i class="fa fa-check" v-if="!errors.channelName && singleBusinessDatas.channelName"></i>
                                                    </span>
                                                    <span class="text-danger error-msg" v-if="errorArr[0] && errorArr[0].fieldName!=''">{{errorArr[0].fieldErrorMsg}}</span>
                                                </div>
                                            </Field>
                                        </li>
                                        <li><!--  v-if="singleBusinessDatas.technicalIntegration" -->
                                            <div class="phone-with-country d-flex justify-content-between align-items-center">
                                                <div class="select-country small border-grey">
                                                    <div class="form-group form-select single-multiselect">
                                                        <span class="float-label">
                                                            <multiselect 
                                                                placeholder=""
                                                                v-model="channels.currentURL"   
                                                                :show-labels="false" 
                                                                :options="channelURL_option"
                                                                :searchable="false">
                                                                <template v-slot:singleLabel="{ option }">{{ option }}</template>
                                                                <template v-slot:option="{ option }">
                                                                    <div class="option__desc">
                                                                        <span class="option__title">{{ option }}</span>
                                                                    </div>
                                                                </template>
                                                            </multiselect> 
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="add-number">
                                                    <Field name="channelURL" :rules="!singleBusinessDatas.channelName ? 'required' : ''"  v-slot="{ handleChange }">
                                                        <div class="form-group" :class="{'errors': errors && errors.channelURL || inValidUrl,'success':!errors && !errors.channelURL}">
                                                            <span class="float-label">
                                                                <input type="text" @update:model-value="handleChange" v-model="singleBusinessDatas.channelURL" class="form-control" name="channelURL" id="channelURL" placeholder="Channel URL*" @input="checkValidUrl($event)">
                                                                <label for="channelURL" class="control-label">Channel URL*</label>
                                                                <div class="line"></div>
                                                                <i class="fa fa-times" v-if="errors && errors.channelURL || inValidUrl"></i>
                                                                <i class="fa fa-check" v-if="!errors.channelURL && singleBusinessDatas.channelURL && !inValidUrl"></i>
                                                            </span>
                                                        </div>
                                                    </Field>
                                                </div>
                                            </div>
                                            <span class="text-danger error-msg mt-1" v-if="inValidUrl">Please provide valid url</span>
                                        </li>
                                        <li><!-- v-if="singleBusinessDatas.technicalIntegration" -->
                                            <Field name="channelIP" :rules="!singleBusinessDatas.channelIP ? 'required' : ''"  v-slot="{ handleChange }">
                                                <div class="form-group" :class="{'errors': errors && errors.channelIP || inValidIp,'success':!errors && !errors.channelIP}">
                                                    <span class="float-label">
                                                        <input type="text" @update:model-value="handleChange" v-model="singleBusinessDatas.channelIP" class="form-control" name="channelIP" id="channelIP" placeholder="Channel IP Address*" @input="validateIp($event)">
                                                        <label for="channelIP" class="control-label">Channel IP Address*</label>
                                                        <div class="line"></div>
                                                        <i class="fa fa-times" v-if="errors && errors.channelIP ||inValidIp"></i>
                                                        <i class="fa fa-check" v-if="!errors.channelIP && singleBusinessDatas.channelIP && !inValidIp"></i>
                                                    </span>
                                                    <span class="text-danger error-msg" v-if="inValidIp">Please provide valid IP</span>
                                                </div>
                                            </Field>
                                        </li>
                                        <b-button size="lg" @click="$bvModal.hide('add_channel')">Cancel</b-button>
                                        <b-button type="submit" variant="primary ml-1" size="lg">Add Channel</b-button>
                                    </Form>
                                </ul>
                            </b-modal>
                        </div>

                        <!-- Business List -->
                        <div class="collapse-business">
                            <div class="business-list" v-for="(busines,index) in businessDatas" :key="index">
                                <!-- Business Collapse Button -->
                                <b-button size="lg" v-b-toggle="'business_'+index">
                                    <span>{{ busines.businessName }}</span>
                                    <strong class="collapse-icon ml-auto"><i class="fa fa-chevron-up"></i></strong>
                                </b-button>

                                <!-- Business Collapse Inner Area -->
                                <b-collapse class="" :id="'business_'+index" accordion="my-accordion" role="tabpanel" :visible="busines.isActive">
                                    <div class="show-area">
                                        <!-- Trade List -->
                                        <ul class="list-unstyled trade-list mb-0">
                                            <li v-for="(tname,index) in busines.tradeLists" :key="index" :class="{'active' : activeIndex == index && activeClass == true, 'cursor-pointer' : tname.url}" @click="tname.url != null ? tradeDetails(tname.channelId,tname.name,'test',index) : ''">
                                                <label :class="{'cursor-pointer' : tname.url}">{{ tname.name }}
                                                    <br/><small class="text-monospace">{{ tname.url }}</small>
                                                </label>
                                            </li>
                                        </ul>
                                    </div>
                                </b-collapse> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Channel Right Body -->
            <div class="channel-right" :class="businessDatas.length == 0 ?' d-flex':'d-none'">
                <div class="card h-100">
                    <div class="justify-content-center align-items-center align-middle" :class="businessDatas.length == 0 ?' d-flex':'d-none'">
                        <h5 class="font-weight-normal text-muted mb-0 mt-5">No Channels Added</h5>
                    </div>
                </div>
            </div>
            <div class="channel-right"  :class="isVisible == false ?'d-none':''">
                <!-- API keys -->
                <div class="card">
                    <div class="header border-bottom">
                        <div class="d-flex justify-content-between align-items-center">
                            <div class="header-left">
                                <h5 class="text-primary font-weight-normal">{{tradeName}}</h5>
                                <span><i class="fa fa-calendar mr-1"></i> {{lastUpdatedDateTime}}</span>
                            </div>
                            <div class="header-right">
                                <div class="custom-switch xlg success-error-bg">
                                    <input type="checkbox" id="test_live" class="custom-switch-input" :value="checkstatus" v-model="checkedCategories" @change="statuscheck($event)">
                                    <label class="custom-switch-label" for="test_live">
                                        <span class="custom-switch-text1">Live</span>
                                        <span class="custom-switch-text2">Test</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="body">
                        <ul class="list-unstyled mb-0 key-list">
                            <li>
                                <h6 class="mb-1">API keys</h6>
                                <p><small>Authenticate to our StandApp API by including your API key in the request. You can manage your API keys from this page. Your API keys carry many privileges;be sure to keep them secret.</small></p>
                            </li>
                            <li>
                                <div class="d-flex justify-content-start mb-3">
                                    <div class="left">
                                        <h6 class="mb-1">Secret Key</h6>
                                        <p class="mb-0"><small>Dont't share this key with anyone</small></p>
                                    </div>
                                    <div class="right flex-fill">
                                        <div class="input-group mb-0">
                                            <span class="form-control key">{{APIkeysInfo.secretKey}}</span>
                                            <div class="input-group-append">
                                                <b-button variant="outline-primary" @click="copyKey(APIkeysInfo.secretKey)" :disabled="pattern.test(APIkeysInfo.secretKey)">Copy</b-button>
                                                <b-button variant="outline-primary" @click="regenerate(tradenameId)">Regenerate</b-button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="d-flex justify-content-start mb-3">
                                    <div class="left">
                                        <h6 class="mb-1">Public Key</h6>
                                        <p class="mb-0"><small>Use this key on your website</small></p>
                                    </div>
                                    <div class="right flex-fill">
                                        <div class="input-group mb-0">
                                            <span class="form-control regenerate-key">{{APIkeysInfo.publickKey}}</span>
                                            <div class="input-group-append">
                                                <b-button variant="outline-primary" @click="copyKey(APIkeysInfo.publickKey)">Copy</b-button>
                                                <b-button variant="outline-primary" disabled>Regenerate</b-button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="d-flex justify-content-start mb-3">
                                    <div class="left">
                                        <h6 class="mb-1">Last updated</h6>
                                        <p class="mb-0"><small>The date and time an API Key was last updated</small></p>
                                    </div>
                                    <div class="right">
                                        <div class="input-group mb-0">
                                            <span class="form-control">{{lastUpdatedDateTime}}</span>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

                <!-- URLs v-slot="{ errors }"-->
                <div class="card">
                    <div class="header border-bottom">
                        <h5 class="text-primary font-weight-normal mb-0">URLs</h5>
                    </div>
                    <div class="body">
                        <Form @submit="urlFormSubmit" :validation-schema="schema" id="url-form" ref="form" v-slot="{ errors }">
                            <ul class="list-unstyled mb-0 key-list">
                                <li>
                                    <div class="d-flex justify-content-start">
                                        <div class="left">
                                            <h6 class="mb-1">Channel URL</h6>
                                            <p class="mb-0"><small>The main URL of the channel</small></p>
                                        </div>
                                        <div class="right flex-fill">
                                            <Field name="url" :rules="{ required: !urlFormData.urlValue, regex:/^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/ }" v-slot="{ handleChange }">
                                                <div class="form-group" :class="{'errors': errors && errors.url,'success':!errors && !errors.url}">
                                                    <span class="float-label">
                                                        <b-input-group>
                                                            <template #prepend>
                                                                <b-dropdown variant="outline-secondary" menu-class="border-0 shadow">
                                                                    <template #button-content>
                                                                        {{ urlFormData.urlPrefixsTitle }}
                                                                    </template>
                                                                    <b-dropdown-item v-for="(prefix,index) in urlSelectList" :key="index" @click="urlSelectedOptions(index,'url')" :active="index == urlFormData.urlActiveIndex">
                                                                        {{ prefix.name }}
                                                                    </b-dropdown-item>
                                                                </b-dropdown>
                                                            </template>
                                                            <b-form-input @update:model-value="handleChange" v-model="urlFormData.urlValue"></b-form-input>
                                                        </b-input-group>
                                                        <i class="fa fa-times" v-if="errors && errors.url"></i>
                                                        <i class="fa fa-check" v-if="!errors.url && urlFormData.urlValue"></i>
                                                    </span>
                                                </div>
                                            </Field>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="d-flex justify-content-start">
                                        <div class="left">
                                            <h6 class="mb-1">Channel IP Address</h6>
                                            <p class="mb-0"><small>The hosting server IP Address</small></p>
                                        </div>
                                        <div class="right flex-fill">
                                            <Field name="ipaddress" :rules="{ required: !urlFormData.ipAddress, regex:/^((\d){1,3}\.){3}(\d){1,3}$/ }"  v-slot="{ handleChange }">
                                                <div class="form-group" :class="{'errors': errors && errors.ipaddress,'success':!errors && !errors.ipaddress}">
                                                    <span class="float-label">
                                                        <input type="text" @update:model-value="handleChange" v-model="urlFormData.ipAddress" class="form-control" id="ipaddress" placeholder="Campaign City">
                                                        <div class="line"></div>
                                                        <i class="fa fa-times" v-if="errors && errors.ipaddress"></i>
                                                        <i class="fa fa-check" v-if="!errors.ipaddress && urlFormData.ipAddress"></i>
                                                    </span>
                                                </div>
                                            </Field>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="d-flex justify-content-start">
                                        <div class="left">
                                            <h6 class="mb-1">Payment Success URL</h6>
                                            <p class="mb-0"><small>The URL displayed after a successful payment</small></p>
                                        </div>
                                        <div class="right flex-fill">
                                                <div class="form-group">
                                                <span class="float-label">
                                                    <b-input-group>
                                                        <template #prepend>
                                                            <b-dropdown variant="outline-secondary" menu-class="border-0 shadow">
                                                                <template #button-content>
                                                                    {{ urlFormData.successUrlPrefixsTitle }}
                                                                </template>
                                                                <b-dropdown-item v-for="(prefix,index) in urlSelectList" :key="index" @click="urlSelectedOptions(index,'successUrl')" :active="index == urlFormData.successUrlActiveIndex">
                                                                    {{ prefix.name }}
                                                                </b-dropdown-item>
                                                            </b-dropdown>
                                                        </template>
                                                        <b-form-input v-model="urlFormData.successURL"></b-form-input>
                                                    </b-input-group>
                                                </span>
                                                </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="d-flex justify-content-start">
                                        <div class="left">
                                            <h6 class="mb-1">Payment Error URL</h6>
                                            <p class="mb-0"><small>The URL displayed after a failed payment</small></p>
                                        </div>
                                        <div class="right flex-fill">
                                            <b-input-group>
                                                <template #prepend>
                                                    <b-dropdown variant="outline-secondary" menu-class="border-0 shadow">
                                                        <template #button-content>
                                                            {{ urlFormData.errorUrlPrefixsTitle }}
                                                        </template>
                                                        <b-dropdown-item v-for="(prefix,index) in urlSelectList" :key="index" @click="urlSelectedOptions(index,'error')" :active="index == urlFormData.errorUrlActiveIndex">
                                                            {{ prefix.name }}
                                                        </b-dropdown-item>
                                                    </b-dropdown>
                                                </template>
                                                <b-form-input v-model="urlFormData.errorURL"></b-form-input>
                                            </b-input-group>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="d-flex flex-column flex-md-row justify-content-center mt-5">
                                        <!-- <b-button size="lg" class="btn-mw">Cancel</b-button> -->
                                        <b-button type="submit" variant="primary ml-md-1 mt-2 mt-md-0 btn-mw" size="lg">Save changes</b-button>
                                    </div>
                                </li>
                            </ul>
                        </Form>
                    </div>
                </div>

                <!-- Webhook -->
                <div class="card d-none">
                    <div class="header d-flex justify-content-between align-items-center">
                        <h2>Webhooks</h2>
                        <!-- Webhook Button -->
                        <b-button variant="primary" size="lg" v-b-modal.add_webhooks class="d-flex justify-content-between align-items-center">New Webhook <i class="fa ml-2"><img src="@/assets/webhook-w.svg" width="18" alt=""></i></b-button>
                        
                        <!-- Webhook Modal -->
                        <b-modal id="add_webhooks" title="Add webhook" hide-footer>
                            <div class="modal-area">
                                <ul class="list-unstyled mb-0 payment-single-transfer">
                                    <Form @submit="webHooksForm" :validation-schema="schema" id="add_webhook" ref="form" v-slot="{ errors }">
                                        <li>   
                                            <Field name="url" rules="required">
                                                <div class="form-group form-select single-multiselect" :class="{'errors': errors && errors.url,'success':!errors || webhooks.url}">
                                                    <span class="float-label">
                                                        <label for="functions">Channel URL*</label>
                                                        <multiselect 
                                                            placeholder=""
                                                            v-model="webhooks.url" 
                                                            :show-labels="false" 
                                                            :options="urlOptions" 
                                                            :searchable="false"
                                                            @select="onSelectURL">
                                                            <template v-slot:singleLabel="{ option }">{{ option }}</template>
                                                            <template v-slot:option="{ option }">
                                                                <div class="option__desc">
                                                                    <span class="option__title">{{ option }}</span>
                                                                </div>
                                                            </template>
                                                        </multiselect> 
                                                        <i class="fa fa-times" v-if="errors && errors.url"></i>
                                                        <i class="fa fa-check" v-if="!errors.url && webhooks.url"></i>
                                                    </span>
                                                </div>
                                            </Field>
                                        </li>
                                        <li>   
                                            <div class="form-group">
                                                <!-- intial input -->
                                                <span class="float-label" v-if="!webhooks.extension">
                                                    <input type="text" class="form-control" name="extension" id="extension" placeholder="Webhook Extension">
                                                    <label for="extension" class="control-label">Webhook Extension</label>
                                                    <div class="line"></div>
                                                </span>
                                                <!-- after select Channel URL input -->
                                                <b-input-group :prepend="webhooks.extension+'/'" class="mb-2" v-if="webhooks.extension">
                                                    <b-form-input aria-label="First name"></b-form-input>
                                                </b-input-group>
                                            </div>
                                        </li>
                                        <li>
                                            <Field name="events" rules="required">
                                                <div class="country-dropdown curancy-dropdown">
                                                    <div class="form-group form-select multiselect-grp" :class="{'errors': errors && errors.events,'success':!errors || webhooks.events}">
                                                        <span class="float-label">
                                                            <label for="roles">Events*</label>
                                                            <multiselect
                                                                placeholder=""
                                                                v-model="webhooks.events" 
                                                                :show-labels="false" 
                                                                :options="events_options" 
                                                                :searchable="false"
                                                                :multiple="true" 
                                                                :close-on-select="true"
                                                                :clear-on-select="false" 
                                                                label="name" 
                                                                track-by="name"  
                                                                @select="isCheckedInput"
                                                                @remove="isCheckedInput">
                                                                <template v-slot:selection="{ values }">
                                                                    <span class="multiselect__single" v-if="values.length == 1"><strong>{{ values[0].name }}</strong></span>
                                                                    <span class="multiselect__single" v-else-if="values.length"><strong>{{ values.length }} Events Selected.</strong></span>
                                                                    <span class="multiselect__single" v-else><strong>0 Events Selected.</strong></span>
                                                                </template>
                                                                <template #beforeList>
                                                                    <span class="multiselect__option" @click="selectAllChanged">
                                                                        <div class="option__desc">
                                                                            <span class="option__title">All</span>
                                                                        </div>
                                                                        <div class="multiselect__element multiselect-all multi-drop">
                                                                            <div class="control-inline fancy-checkbox" for="isChecked">
                                                                                <input type="checkbox" id="checkbox2" name="checkbox2" :checked="webhooks.isAllChecked">
                                                                                <span for="checkbox2"></span>
                                                                            </div>
                                                                        </div>
                                                                    </span>
                                                                </template>
                                                                <template v-slot:option="{ option }">
                                                                    <span :class="option.flagClass"></span>
                                                                    <div class="option__desc">
                                                                        <span class="option__title">{{ option.name }}</span>
                                                                    </div>
                                                                    <div class="multiselect__element multiselect-all multi-drop">
                                                                        <div class="control-inline fancy-checkbox" for="isChecked">
                                                                            <input type="checkbox" id="isChecked" name="isChecked" :checked="option.isChecked == true">
                                                                            <span for="isChecked"></span>
                                                                        </div>
                                                                    </div>
                                                                </template>
                                                            </multiselect>
                                                            <i class="fa fa-times" v-if="errors && errors.events"></i>
                                                            <i class="fa fa-check" v-if="!errors.events && webhooks.events"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </Field>
                                        </li>
                                        <li>
                                            <Field name="status" rules="required">
                                                <div class="form-group form-select single-multiselect" :class="{'errors': errors && errors.status,'success':!errors || webhooks.status}">
                                                    <span class="float-label">
                                                        <label for="functions">Select status*</label>
                                                        <multiselect 
                                                            placeholder=""
                                                            v-model="webhooks.status"  
                                                            :show-labels="false" 
                                                            :options="statusOptions" 
                                                            :searchable="false">
                                                            <template v-slot:singleLabel="{ option }">{{ option }}</template>
                                                            <template v-slot:option="{ option }">
                                                                <div class="option__desc">
                                                                    <span class="option__title">{{ option }}</span>
                                                                </div>
                                                            </template>
                                                        </multiselect> 
                                                        <i class="fa fa-times" v-if="errors && errors.status"></i>
                                                        <i class="fa fa-check" v-if="!errors.status && webhooks.status"></i>
                                                    </span>
                                                </div>
                                            </Field>
                                        </li>
                                        <li>
                                            <div class="d-flex flex-column justify-content-between align-items-center w-100 mt-3">
                                                <b-button type="submit" variant="primary btn-mw" size="lg">Add Webhook</b-button>  
                                                <b-button @click="$bvModal.hide('add_webhooks')" size="lg" variant="outline-secondary mt-3 btn-mw">Cancel</b-button>           
                                            </div>
                                        </li>
                                    </Form>
                                </ul>
                            </div>
                        </b-modal>
                    </div>

                    <div class="body">

                        <!-- Webhook Table -->
                        <b-table 
                            responsive
                            hover 
                            outlined
                            table-class="js-basic-example table-custom mb-0"
                            head-variant="light"
                            :items="items" 
                            :fields="fields"
                            :current-page="currentPage"
                            :per-page="perPage"
                            :sort-by="sortBy"
                            :sort-desc="sortDesc"
                            :sort-direction="sortDirection">
                            <div>
                                {{ label }}
                                <!-- Custom icons -->
                                <div>
                                    <i v-if="sortBy !== key" class=""></i>
                                    <i v-else-if="sortDesc" class="fa fa-long-arrow-down ml-1"></i>
                                    <i v-else class="fa fa-long-arrow-up ml-1"></i>
                                </div>  
                            </div>
                            <template #cell(url)="data">
                                <a href="javascript:void(0);">{{ data.value }}</a>
                            </template>
                            <template #cell(dropdawn)="data">
                                <select @update:model-value="handleChange" v-model="data.value.selected" size="sm" class="btn btn-sm btn-filter" :class="data.value.class" @change="activeStatus($event,(currentPage-1) * perPage + data.index + 1)">
                                    <option v-for="(option,index) in options" :key="index" :value="option.value">{{ option.text }}</option>
                                </select>
                            </template>
                        </b-table>

                        <!-- Table Pagination -->
                        <div class="py-3 d-flex align-items-center justify-content-between table-pagination">
                            <div class="per-page">
                                <b-form-group label="Per page" label-for="per-page-select" class="mb-0">
                                    <b-form-select id="per-page-select" @update:model-value="handleChange" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
                                </b-form-group>
                            </div>
                            <div class="number-page">
                                <b-pagination 
                                    @update:model-value="handleChange" v-model="currentPage" 
                                    :total-rows="totalRows" 
                                    :per-page="perPage"
                                    hide-goto-end-buttons 
                                    hide-ellipsis 
                                    prev-text="Previous page" 
                                    next-text="Next page" 
                                    align="right">
                                </b-pagination>
                            </div>
                            <div class="go-page">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Go To Page</span>
                                    </div>
                                    <input type="text" class="form-control" value="" @keyup.enter="onEnter" onkeypress='return event.charCode >= 48 && event.charCode <= 57'>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <main-loader ref="loader"></main-loader>
    </div>
</template>
<script>
import BreadCrumb from'@/components/BreadCrumb.vue'
import { Form, Field } from 'vee-validate';
import VueMultiselect from 'vue-multiselect'
const LoaderComponent = require('@/components/admin/loader/LoaderComponent.vue').default
const moment = require('moment')
var validator = require('validator');
import {useToast} from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-bootstrap.css';
const $toast = useToast();

export default {
    name:'ChannelsComponent',
    components: {
        BreadCrumb,
        Form,
        Field,
        'Multiselect': VueMultiselect,
        'main-loader':LoaderComponent,
    },data (){
        return{
            isAllChecked:false,
            singleBusinessDatas:{
                businessName:[],
                channelName:'',
                channelURL:'',
                channelIP:'',
                technicalIntegration:false
            },

            businessNameOptions: [],

            text: ' Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry',
            businessDatas:[],

            tradInputs:{
                tradeName:''
            },

            urlFormData:{
                urlValue:'www.payangel.com',
                urlActiveIndex:0,
                urlPrefixsTitle:'https://',
                ipAddress:'192.168.1.1',
                successURL:'www.payangel.com',
                successUrlActiveIndex:0,
                successUrlPrefixsTitle:'https://',
                errorURL:'www.payangel.com',
                errorUrlActiveIndex:0,
                errorUrlPrefixsTitle:'https://',
            },

            urlSelectList:[
                {
                    name:'https://'
                },
                {
                    name:'http://'
                }
            ],

            options: [
                { value: 'accept', text: 'Accept' },
                { value: 'cancel', text: 'Cancel' },
            ],

            items:[
                {url: 'https://www.payangel.com', extension:'https://www.payangel.com', events:3, dropdawn:{selected:'accept',class:'btn-outline-success'}},
                {url: 'https://www.wrraptheme.com', extension:'https://www.wrraptheme.com', events:1, dropdawn:{selected:'accept',class:'btn-outline-success'}},
                {url: 'https://www.thememakker.com', extension:'https://www.thememakker.com', events:5, dropdawn:{selected:'accept',class:'btn-outline-success'}},
                {url: 'https://www.wrraptheme.com', extension:'https://www.wrraptheme.com', events:4, dropdawn:{selected:'accept',class:'btn-outline-success'}},
                {url: 'https://www.thememakker.com', extension:'https://www.thememakker.com', events:2, dropdawn:{selected:'cancel',class:'btn-outline-danger'}},
            ],
           
            fields: [
                { key: 'url', label: 'Channel URL',sortable: true,thClass:'th_sortfix'},
                { key: 'extension', label: 'Webhook Extension',sortable: true,thClass:'th_sortfix'},
                { key: 'events', label: 'No. of events',sortable: true,thClass:'th_sortfix'},
                { key: 'dropdawn', label: 'Status'},
                { sortable: true, sortByFormatted: true, filterByFormatted: true},
            ],

            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10,25, 50, { value: 1000, text: 1000 }],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',

            webhooks:{
                url:'',
                status:'',
                extension:'',
                events: [],
                isAllChecked:false,
            },

            statusOptions: [
                'accept',
                'cancel',
            ],
            urlOptions:['https://www.payangel.com','https://www.thememakker.com','https://www.wrraptheme.com'],

            //All curancy dropdawn option
            events_options: [
                { id:0,name: 'Card verification declined',isChecked: false},
                { id:1,name: 'Card verified' , isChecked: false},
                { id:2,name: 'Dispute canceled', isChecked: false},
                { id:3,name: 'Dispute evidence required',isChecked: false},
            ],
            APIkeysInfo:{
                secretKey:'************************',
                publickKey:'****************************'
            },
            
            channels:{
                currentURL:['https://']
            },

            channelURL_option:['https://','http://'],

            tradeData : [],
            businessUserid : '',
            businessUsername : '',
            isVisible : false,
            tradeName : '',
            tradenameId : '',
            lastUpdatedDateTime : '',
            activeIndex : '',
            activeClass : false,
            secretKey : false,
            secretKeyvalue : '',
            channelurl : '',
            channelId : '',
            pattern : new RegExp("^.*(?:\\*.*){10}$"),
            errorArr : [],
            inValidUrl: false,
            inValidIp: false,

            checkstatus: 'test',
            checkedCategories: ''
        }
    },methods: {
        statuscheck (e) {
            // console.log( e.target.value)
            if(e.target.value == 'test'){
                this.checkstatus = 'live';
            }else{
                this.checkstatus = 'test';
            }
            this.tradeDetails(this.channelId,this.tradeName,this.checkstatus,this.activeIndex);
        },
        checkNameFormat(e, fieldName, elementName, elementIndex) {
            let char = e.target.value; // Get the character
            let charLength = e.target.value.length;

            if(/^[A-Za-z'-\s]+$/.test(char)) {
                var splitStr = e.target.value.split(' ');
                for (var i = 0; i < splitStr.length; i++) {
                    if(splitStr[0]!='')
                    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
                }
                this.singleBusinessDatas[fieldName] = splitStr.join(' ');
                if(charLength >= 2 && charLength <= 35 && splitStr[0]!='') {
                   if(this.errorArr.some(obj => obj.index == elementIndex)) {
                        this.errorArr[elementIndex].fieldName = '';
                        this.errorArr[elementIndex].fieldErrorMsg = '';
                        this.errorArr[elementIndex].index = elementIndex;
                    }     
                    return true; // Match with regex 
                } else {
                    if(this.errorArr.some(obj => obj.index == elementIndex)) {
                        this.errorArr[elementIndex].fieldName = fieldName;
                        this.errorArr[elementIndex].errorMessage = 'Please provide minimum two characters without spaces at start.';
                        this.errorArr[elementIndex].index = elementIndex;
                    }  
                }
            } else {
                this.singleBusinessDatas[fieldName] = char;
                this.fieldErrorMsg = 'Please provide valid value for '+elementName;
                if(!this.errorArr.some(obj => obj.index == elementIndex)) {
                    for(let i=0; i<elementIndex; i++) {
                        if(this.errorArr[i] === undefined){
                            this.errorArr.push({'fieldName': '', fieldErrorMsg:'', 'index': i})                        
                        }
                    }
                    this.errorArr.push({'fieldName': fieldName, 'fieldErrorMsg':this.fieldErrorMsg, 'index': elementIndex})
                } else if(this.errorArr.some(obj => obj.index == elementIndex)) {
                    this.errorArr[elementIndex].fieldName =  fieldName;
                    this.errorArr[elementIndex].fieldErrorMsg = this.fieldErrorMsg;
                    this.errorArr[elementIndex].index = elementIndex;
                } else {
                    // do nothing 
                }
                e.preventDefault(); // If not match, don't add to input text
            }
        },
        checkValidUrl(e) {
            const url = e.target.value;
            // console.log(validator.isURL(url));
            if(validator.isURL(url,{require_host: true,allow_underscores: false,validate_length: true,allow_fragments: true})){
                this.inValidUrl = false;
            } else {
                this.inValidUrl = true;
            }
        },
        validateIp(e){
            var IP = e.target.value;
            if(validator.isIP(IP)){
                this.inValidIp = false;
                return true;
            } else {
                this.inValidIp = true;
                return false;
            }
        },

        clearData(){
            this.singleBusinessDatas.businessName = [];
            this.singleBusinessDatas.channelName = '';
            this.singleBusinessDatas.channelURL = '';
            this.singleBusinessDatas.channelIP = '';
            this.singleBusinessDatas.technicalIntegration = false
        },

        closeDiv(){
            this.secretKey = false;
        },

        async tradeDetails(channelid, tradename, mode, index){
            // console.log(channelid, tradename, mode, index);
            this.$refs.loader.show();
            this.activeIndex = index;
            this.activeClass = true;
            this.channelId = channelid;
            try {
                const response = await this.$http.get(this.$baseurl+"business/tradename/"+channelid+'/'+mode+'?=', { 
                    headers: {
                        'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken")
                    }
                });
                // console.log(response);
                // JSON responses are automatically parsed.
                const tradeDetail = response.data.details;
                this.APIkeysInfo.secretKey = tradeDetail.secretkey;
                this.APIkeysInfo.publickKey = tradeDetail.publickey;
                this.tradeName = tradename;
                this.tradenameId = tradeDetail.tradenameid;
                this.lastUpdatedDateTime = moment(tradeDetail.updatedat).format("DD MMM YYYY - hh:mm:ss");
                // console.log(moment(tradeDetail.updatedat).format("DD MMM YYYY - hh:mm:ss"));
                let channelurl = tradeDetail.channelurl.split('://');
                let successurl = tradeDetail.successurl.split('://');
                let errorurl = tradeDetail.errorurl.split('://');

                this.urlFormData.urlValue = channelurl[1];
                this.urlFormData.urlActiveIndex = channelurl[0] == 'https' ? 0 : 1;
                this.urlFormData.urlPrefixsTitle = channelurl[0]+'://';
                this.urlFormData.ipAddress = tradeDetail.channelip;
                this.urlFormData.successURL = successurl[1];
                this.urlFormData.successUrlActiveIndex = successurl[0] == 'https' ? 0 : 1;
                this.urlFormData.successUrlPrefixsTitle = successurl[0]+'://';
                this.urlFormData.errorURL = errorurl[1];
                this.urlFormData.errorUrlActiveIndex = errorurl[0] == 'https' ? 0 : 1;
                this.urlFormData.errorUrlPrefixsTitle = errorurl[0]+'://';
                
                this.isVisible = true;
                this.$refs.loader.hide();
                // shared.toastrSuccess('Your channel successfully created');
            } catch (error) {
                this.$refs.loader.hide();
                console.log(error);
            }
        },

        urlFormSubmit(){
            this.$refs.loader.show();
            let channelData = {
                channelurl : this.urlFormData.urlPrefixsTitle+this.urlFormData.urlValue,
                channelip : this.urlFormData.ipAddress,
                successurl : this.urlFormData.successUrlPrefixsTitle+this.urlFormData.successURL,
                errorurl : this.urlFormData.errorUrlPrefixsTitle+this.urlFormData.errorURL,
            };
            this.$http.put(this.$baseurl+'business/tradename/'+this.tradenameId, channelData,{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken")
                }
            })
            .then((res) => {
                //Perform Success Action
                // console.log(res.data);
                if(res.data.code == '0050-000') {
                    // console.log(res.data.message);
                    this.$refs.loader.hide();
                        $toast.success('URL successfully updated',  {
                        position:'top-right'
                    });
                } else {
                    this.$refs.loader.hide();
                    $toast.error(res.message,  {
                        position:'top-right'
                    });
                    // this.$swal('Error!', res.message, 'error', 'Close');
                }
            })
            .catch((error) => {
                // error.response.status Check status code
                this.$refs.loader.hide();
                console.log(error.response);
                // shared.toastrError(error.response.data.message);
                // this.$swal('Error!', error.response.data.message, 'error', 'Close');
            });
        },

        regenerate(tradenameid){
            this.$refs.loader.show();
            this.$http.get(this.$baseurl+'business/tradename/generate/'+tradenameid,{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken")
                }
            })
            .then((res) => {
                //Perform Success Action
                
                if(res.data.code === '0050-000') {
                    console.log(res.data.secretkey);
                    this.APIkeysInfo.secretKey = res.data.secretkey;
                    this.$refs.loader.hide();
                    $toast.success('Your secret key successfully regenerated',  {
                        position:'top-right'
                    });
                } else {
                    this.$refs.loader.hide();
                    $toast.error(res.message,  {
                        position:'top-right'
                    });
                }
            })
            .catch((error) => {
                // error.response.status Check status code
                this.$refs.loader.hide();
                console.log(error.response);
                // shared.toastrError(error.response.data.message);
                // this.$swal('Error!', error.response.data.message, 'error', 'Close');
            });
        },

        //single business submit form data
        channelSubmitForm(){
            this.$refs.loader.show();
            if(this.inValidUrl || this.inValidIp){
                this.$refs.loader.hide();
            } else {
                var channelData = '';
                if(this.singleBusinessDatas.technicalIntegration){
                    channelData = {
                        businessid : this.singleBusinessDatas.businessName.id,
                        name : this.singleBusinessDatas.channelName,
                        integration : this.singleBusinessDatas.technicalIntegration,
                        channelurl : this.channels.currentURL+this.singleBusinessDatas.channelURL,
                        ipaddress : this.singleBusinessDatas.channelIP
                    }
                } else {
                    channelData = {
                        businessid : this.singleBusinessDatas.businessName.id,
                        name : this.singleBusinessDatas.channelName,
                        integration : this.singleBusinessDatas.technicalIntegration
                    }
                }
                
                this.$http.post(this.$baseurl+'business/tradename', channelData,{
                    headers: {
                        'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken")
                    }
                })
                .then((res) => {
                    //Perform Success Action
                    // console.log(res.data);
                    if(res.data.code == '0050-000') {
                        // console.log(res.data.message);
                        this.getChannelData();
                        this.$bvModal.hide('add_channel');
                        this.$refs.loader.hide();
                        if(res.data.details.secretkey != '' && res.data.details.secretkey != undefined){
                            this.secretKey  = true;
                            this.secretKeyvalue = res.data.details.secretkey;
                            this.channelurl = res.data.details.successurl
                        }
                        $toast.success('Your channel successfully created',  {
                            position:'top-right'
                        });
                    } else {
                        this.$refs.loader.hide();
                        $toast.error(res.message,  {
                            position:'top-right'
                        });
                        // this.$swal('Error!', res.message, 'error', 'Close');
                    }
                })
                .catch((error) => {
                    // error.response.status Check status code
                    this.$refs.loader.hide();
                    console.log(error.response);
                    // shared.toastrError(error.response.data.message);
                    // this.$swal('Error!', error.response.data.message, 'error', 'Close');
                });
            }
            
        },

        //trade submit form data
        tradSubmitForm(index){
            if(this.tradInputs.tradeName){
                this.$bvModal.hide('trade_model'+index);
            }
        },

        //set active prefix
        activePrefix(menuIndex,urlId){
            this.urlLists[urlId].activePrefixs.active_index = menuIndex;
            this.urlLists[urlId].activePrefixs.prefixsTitle = this.urlLists[urlId].activePrefixs.prefixsItems[menuIndex].name;
        },

        //status change
        activeStatus(event,index) {
          var classVar =''
            return this.items.map((item,i) => {
                if(index == i+1){
                    if(event.target.value == 'accept'){
                        classVar = 'btn-outline-success'
                    }else if(event.target.value == 'cancel'){
                        classVar = 'btn-outline-danger'
                    }
                    item.dropdawn.class = classVar
                }
            });
        },

        //add webhooks form submit
        webHooksForm(){
            this.$bvModal.hide('add_webhooks');
        },

        //copy secret key
        copyKey(keyvalue){
            // creating input of html
            var inputofKey = document.createElement("input");
            //adding span tag text to input 
            inputofKey.value = keyvalue;
            document.body.appendChild(inputofKey);
            inputofKey.select();
            document.execCommand("Copy");

            // removing input after copy
            inputofKey.remove();
            $toast.success('Your key successfully copied',  {
                position:'top-right'
            });
        },

        //
        onSelectURL (selectedAction) {
            this.webhooks.extension = selectedAction
        },

        //Events dropdawn checkbox checked(one or more)
        selectAllChanged() {
            if (this.isAllSelected) {
                this.webhooks.isAllChecked = false;
                this.webhooks.events = [];
                this.events_options.map((data)=>{
                    data.isChecked = false
                });
            }else{
                this.webhooks.isAllChecked = true;
                this.webhooks.events = this.events_options.slice();
                this.events_options.map((data)=>{
                    data.isChecked = true
                });
            }
        },

        //All Events dropdawn selection message is no of Events selected
        displayText(count){
            if (count === 0){
                return ''
            }else if (count === 1){
                return this.webhooks.events[0].name
            }else if(count == this.events_options.length){
                this.webhooks.isAllChecked = true;
                return count + ' Events Selected.'
              
            }else {
                this.webhooks.isAllChecked = false;
                return  count + ' Events Selected.'
            }
        },

        //all Events dropdawn checkbox checked
        isCheckedInput (actionName) {
            this.events_options[actionName.id].isChecked = !this.events_options[actionName.id].isChecked
        },

        async getChannelData(){
            try {
                const response = await this.$http.get(this.$baseurl+"business/tradenames",{
                    headers: {
                        'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken")
                    }
                });
                // JSON responses are automatically parsed.
                // console.log(response);
                if(response.data.code == '0050-000'){
                    let businessidData = [];
                    response.data.details.map((item) => {
                        if(!businessidData.includes(item.businessid)){
                            businessidData.push(item.businessid);
                        }
                    })
                    // console.log(businessidData);
                    let channelData = '';
                    this.businessDatas = [];
                    businessidData.forEach((element) => {
                        this.tradeData = [];
                        this.businessUserid = '';
                        this.businessUsername = '';
                        response.data.details.forEach((item1) => {
                            if(element == item1.businessid){
                                let tradeLists = {
                                    name: item1.name,
                                    url: item1.channelurl,
                                    channelId : item1.channelid,
                                    isActive: false //item1.channelurl ? true : false
                                };
                                this.tradeData.push(tradeLists);
                                
                                this.businessUserid = item1.businessid;
                                this.businessUsername = item1.businessname;
                            }
                            // console.log('tradedata',this.tradeData);
                            
                            channelData = {
                                id:this.businessUserid,
                                businessName:this.businessUsername,
                                tradebuttonText:'Add Trade Name',
                                isActive:true,
                                tradeLists:this.tradeData
                            }
                        })  
                        this.businessDatas.push(channelData); 
                        // this.businessDatas = [];
                    })                    
                    
                }
                
            } catch (error) {
                console.log(error);
            }
        },

        async businessData(){
            try {
                let paramData = {
                    sorting : 'asc',
                    sortby : 'id',
                    pagesize : '2000',
                    pagenumber : '1'
                }
                const response = await this.$http.get(this.$baseurl+"business/list", { 
                    params: paramData,
                    headers: {
                        'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken")
                    }
                });
                // JSON responses are automatically parsed.
                // console.log(response);
                response.data.details.map((item) => {
                    let businessLists = {
                        id : item.businessid,
                        name: item.name
                    };
                    this.businessNameOptions.push(businessLists);
                });                
            } catch (error) {
                console.log(error);
            }
        }
    },mounted(){
        if(sessionStorage.getItem("jwtToken")){
            this.totalRows = this.items.length;
            this.getChannelData();
            this.businessData();
        } else {
            this.$swal('Error!', 'Please login to continue', 'error', 'Close');
            this.$router.push({path:'/standapp-login'});
        }
    },computed: {
        //check how many Events dropdawn checkbox checked
        isAllSelected: function() {
            return  (this.webhooks.events.length === this.events_options.length)
        }
    }
}

</script>