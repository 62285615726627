<template>
    <div class="card card-top-line">
        <div class="header d-flex align-items-center justify-content-between">
            <h2>{{businessType}} Contact Details</h2>
            <i class="fa fa-edit font-20" @click="editDetails()"></i>
        </div>
        <div class="body">
            <div class="account-form">
                <Form @submit="businessContactSubmit" id="basic-info" ref="form" :validation-schema="schema" v-slot="{ errors }">
                    <Field rules="required" name="Address_Line_1" v-slot="{ handleChange }">
                        <div class="form-group" :class="{'errors': errors && errors.Address_Line_1 ,'success':!errors.Address_Line_1 && !errors}">
                            <span class="float-label">
                                <input type="text" @update:model-value="handleChange" v-model="businessContact.addressLine1" class="form-control" id="address1" placeholder="Address Line 1*" :disabled="businessContact.readonlyMode"/>
                                <label for="address1" class="control-label">Address Line 1*</label>
                                <div class="line"></div>
                                <i class="fa fa-times" v-if="errors && errors.Address_Line_1"></i>
                                <i class="fa fa-check" v-if="!errors.Address_Line_1"></i>
                            </span>
                        </div>
                    </Field>
                    <div class="form-group">
                        <span class="float-label">
                            <input type="text" v-model="businessContact.addressLine2" class="form-control" id="address2" placeholder="Address Line 2" :disabled="businessContact.readonlyMode"/>
                            <label for="address2" class="control-label">Address Line 2</label>
                            <div class="line"></div>
                        </span>
                    </div>
                    <Field rules="required" name="town" v-slot="{ handleChange }">
                        <div class="form-group" :class="{'errors': errors && errors.town ,'success':!errors.town && !errors}">
                            <span class="float-label">
                                <input type="text" @update:model-value="handleChange" v-model="businessContact.town" class="form-control" id="town" placeholder="Town*" :disabled="businessContact.readonlyMode"/>
                                <label for="town" class="control-label">Town*</label>
                                <div class="line"></div>
                                <i class="fa fa-times" v-if="errors && errors.town"></i>
                                <i class="fa fa-check" v-if="!errors.town"></i>
                            </span>
                        </div>
                    </Field>
                    <Field rules="required" name="city" v-slot="{ handleChange }">
                        <div class="form-group" :class="{'errors': errors && errors.city ,'success':!errors.city && !errors}">
                            <span class="float-label">
                                <input type="text" @update:model-value="handleChange" v-model="businessContact.city" class="form-control" id="city" placeholder="City*" :disabled="businessContact.readonlyMode"/>
                                <label for="city" class="control-label">City*</label>
                                <div class="line"></div>
                                <i class="fa fa-times" v-if="errors && errors.city"></i>
                                <i class="fa fa-check" v-if="!errors.city"></i>
                            </span>
                        </div>
                    </Field>
                    <Field rules="required" name="sprc" v-slot="{ handleChange }">
                        <div class="form-group" :class="{'errors': errors && errors.sprc ,'success':!errors.sprc && !errors}">
                            <span class="float-label">
                                <input type="text" @update:model-value="handleChange" v-model="businessContact.sprc" class="form-control" id="sprc" placeholder="State/Province/Region/County*" :disabled="businessContact.readonlyMode"/>
                                <label for="sprc" class="control-label">State/Province/Region/County*</label>
                                <div class="line"></div>
                                <i class="fa fa-times" v-if="errors && errors.sprc"></i>
                                <i class="fa fa-check" v-if="!errors.sprc"></i>
                            </span>
                        </div>
                    </Field>
                    <Field rules="required" name="postCode" v-slot="{ handleChange }">
                        <div class="form-group" :class="{'errors': errors && errors.postCode ,'success':!errors.postCode && !errors}">
                            <span class="float-label">
                                <input type="text" @update:model-value="handleChange" v-model="businessContact.postCode" class="form-control" id="postCode" placeholder="Post/Zip Code*" :disabled="businessContact.readonlyMode"/>
                                <label for="postCode" class="control-label">Post/Zip Code*</label>
                                <div class="line"></div>
                                <i class="fa fa-times" v-if="errors && errors.postCode"></i>
                                <i class="fa fa-check" v-if="!errors.postCode"></i>
                            </span>
                        </div>
                    </Field>
                    <div class="d-flex align-items-center justify-content-between mb-3" :class="{'switch-disable':businessContact.readonlyMode}">
                        <p class="mb-0 mr-3 custom-switch-text">Is the registered {{businessType}} address the same as where you normally operate your {{businessType}}? <span class="text-danger">*</span></p>
                        <div class="custom-switch success-handle">
                            <input type="checkbox" class="custom-switch-input" id="tradingAddress" v-model="businessContact.tradingAddress" :disabled="businessContact.readonlyMode">
                            <label class="custom-switch-label" for="tradingAddress">
                                <span class="custom-switch-text1">Yes</span>
                                <span class="custom-switch-text2">No</span>
                            </label>
                        </div>
                    </div>
                    <Field :rules="businessContact.tradingAddress ? 'required' : ''" name="tradingAddress1" v-slot="{ handleChange }" v-if="businessContact.tradingAddress && !businessContact.readonlyMode">
                        <div class="form-group" :class="{'errors': errors && errors.tradingAddress1 ,'success':!errors.tradingAddress1 && !errors}">
                            <span class="float-label">
                                <input type="text" @update:model-value="handleChange" v-model="businessContact.tradingAddressInput1" class="form-control" id="tradingaddress1" placeholder="Address Line 1*" :disabled="businessContact.readonlyMode"/>
                                <label for="tradingaddress1" class="control-label">Address Line 1*</label>
                                <div class="line"></div>
                                <i class="fa fa-times" v-if="errors && errors.tradingAddress1"></i>
                                <i class="fa fa-check" v-if="!errors.tradingAddress1"></i>
                            </span>
                        </div>
                    </Field>
                    <div class="form-group" v-if="businessContact.tradingAddress && !businessContact.readonlyMode">
                        <span class="float-label">
                            <input type="text" v-model="businessContact.tradingAddressInput2" class="form-control" id="tradingaddress2" placeholder="Address Line 2"/>
                            <label for="tradingaddress2" class="control-label">Address Line 2</label>
                            <div class="line"></div>
                        </span>
                    </div>
                    <Field :rules="businessContact.tradingAddress ? 'required' : ''" name="tradingTown" v-slot="{ handleChange }" v-if="businessContact.tradingAddress && !businessContact.readonlyMode">
                        <div class="form-group" :class="{'errors': errors && errors.tradingTown ,'success':!errors.tradingTown && !errors}">
                            <span class="float-label">
                                <input type="text" @update:model-value="handleChange" v-model="businessContact.tradingTown" class="form-control" id="tradingTown" placeholder="Town*" :disabled="businessContact.readonlyMode"/>
                                <label for="tradingTown" class="control-label">Town*</label>
                                <div class="line"></div>
                                <i class="fa fa-times" v-if="errors && errors.tradingTown"></i>
                                <i class="fa fa-check" v-if="!errors.tradingTown"></i>
                            </span>
                        </div>
                    </Field>
                    <Field :rules="businessContact.tradingAddress ? 'required' : ''" name="tradingCity" v-slot="{ handleChange }" v-if="businessContact.tradingAddress && !businessContact.readonlyMode">
                        <div class="form-group" :class="{'errors': errors && errors.tradingCity ,'success':!errors.tradingCity && !errors}">
                            <span class="float-label">
                                <input type="text" @update:model-value="handleChange" v-model="businessContact.tradingCity" class="form-control" id="tradingCity" placeholder="City*" :disabled="businessContact.readonlyMode"/>
                                <label for="tradingCity" class="control-label">City*</label>
                                <div class="line"></div>
                                <i class="fa fa-times" v-if="errors && errors.tradingCity"></i>
                                <i class="fa fa-check" v-if="!errors.tradingCity"></i>
                            </span>
                        </div>
                    </Field>
                    <Field :rules="businessContact.tradingAddress ? 'required' : ''" name="tsprc" v-slot="{ handleChange }" v-if="businessContact.tradingAddress && !businessContact.readonlyMode">
                        <div class="form-group" :class="{'errors': errors && errors.tsprc ,'success':!errors.tsprc && !errors}">
                            <span class="float-label">
                                <input type="text" @update:model-value="handleChange" v-model="businessContact.tradingSprc" class="form-control" id="tsprc" placeholder="State/Province/Region/County*" :disabled="businessContact.readonlyMode"/>
                                <label for="tsprc" class="control-label">State/Province/Region/County*</label>
                                <div class="line"></div>
                                <i class="fa fa-times" v-if="errors && errors.tsprc"></i>
                                <i class="fa fa-check" v-if="!errors.tsprc"></i>
                            </span>
                        </div>
                    </Field>
                    <Field :rules="businessContact.tradingAddress ? 'required' : ''" name="tradingpostCode" v-slot="{ handleChange }" v-if="businessContact.tradingAddress && !businessContact.readonlyMode">
                        <div class="form-group" :class="{'errors': errors && errors.tradingpostCode ,'success':!errors.tradingpostCode && !errors}">
                            <span class="float-label">
                                <input type="text" @update:model-value="handleChange" v-model="businessContact.tradingPostCode" class="form-control" id="tradingpostCode" placeholder="Post/Zip Code*" :disabled="businessContact.readonlyMode"/>
                                <label for="tradingpostCode" class="control-label">Post/Zip Code*</label>
                                <div class="line"></div>
                                <i class="fa fa-times" v-if="errors && errors.tradingPostCode"></i>
                                <i class="fa fa-check" v-if="!errors.tradingPostCode"></i>
                            </span>
                        </div>
                    </Field>
                    <Field rules="required" name="Mobile_Number" v-slot="{ handleChange }">
                        <div class="form-group" :class="{'errors': errors && errors.Mobile_Number ,'success':!errors.Mobile_Number && !errors}">
                            <span class="float-label">
                                <input type="text" @update:model-value="handleChange" v-model="businessContact.mobileNumber" class="form-control" id="mobile-number" placeholder="Mobile Number*" :disabled="businessContact.readonlyMode"/>
                                <label for="mobile-number" class="control-label">Mobile Number*</label>
                                <div class="line"></div>
                                <i class="fa fa-times" v-if="errors && errors.Mobile_Number && !businessContact.readonlyMode"></i>
                                <i class="fa fa-check" v-if="!errors.Mobile_Number && !businessContact.readonlyMode"></i>
                            </span>
                        </div>
                    </Field>
                    <Field rules="required|email" name="email_address" v-slot="{ handleChange }">
                        <div class="form-group" :class="{'errors': errors && errors.email_address ,'success':!errors.email_address && !errors}">
                            <span class="float-label">
                                <input type="email" @update:model-value="handleChange" v-model="businessContact.email" class="form-control" name="email" id="email-address" placeholder="Email*" :disabled="businessContact.readonlyMode">
                                <label for="email-address" class="control-label">Email*</label>
                                <div class="line"></div>
                                <i class="fa fa-times" v-if="errors && errors.email_address && !businessContact.readonlyMode"></i>
                                <i class="fa fa-check" v-if="!errors.email_address && !businessContact.readonlyMode"></i>
                            </span>
                        </div>
                    </Field>
                    <div class="d-flex align-items-center justify-content-between mb-3" :class="{'switch-disable':businessContact.readonlyMode}">
                        <p class="mb-0 mr-3 custom-switch-text">Do you have a website? <span class="text-danger">*</span></p>
                        <div class="custom-switch success-handle">
                            <input type="checkbox" class="custom-switch-input" id="websiteURLdis" @update:model-value="handleChange" v-model="businessContact.websiteURLDisplay" :disabled="businessContact.readonlyMode">
                            <label class="custom-switch-label" for="websiteURLdis">
                                <span class="custom-switch-text1">Yes</span>
                                <span class="custom-switch-text2">No</span>
                            </label>
                        </div>
                    </div>
                    <Field :rules="businessContact.websiteURLDisplay ? 'required' : ''" name="Website_URL" v-slot="{ handleChange }" v-if="businessContact.websiteURLDisplay && !businessContact.readonlyMode">
                        <div class="form-group" :class="{'errors': errors && errors.Website_URL ,'success':!errors.Website_URL && !errors}">
                            <span class="float-label">
                                <input type="text" @update:model-value="handleChange" v-model="businessContact.websiteURL" class="form-control" name="websiteURL" id="website_URL" placeholder="Website URL*" :disabled="businessContact.readonlyMode">
                                <label for="websiteURL" class="control-label">Website URL*</label>
                                <div class="line"></div>
                                <i class="fa fa-times" v-if="errors && errors.Website_URL"></i>
                                <i class="fa fa-check" v-if="!errors.Website_URL"></i>
                            </span>
                        </div>
                    </Field>
                    <div v-if="!businessContact.readonlyMode" class="justify-content-center d-flex">
                        <b-button size="lg" @click="businessContactSubmit()" class="btn-mw">Cancel</b-button>
                        <b-button type="submit" variant="primary ml-1 btn-mw" size="lg">Update</b-button>
                    </div>
                </Form>
            </div>
        </div>
    </div>
</template>
<script>
import "@/plugins/vee-validate";
import { Form, Field } from 'vee-validate';
export default {
    name: 'BusinessContactDetails-Profile',
    components:{
        Form,
        Field,
    },
    props: {
        businessType: {
            type: String,
            default: function () {
                return 'Business'
            }
        },
    },
    data(){
        return{
           //Business Contact Details
            businessContact:{
                readonlyMode: true,
                addressLine1:'2 St Dunstans Street,United Kingdom',
                addressLine2:'99 Warner Close',
                town:'Warlingham',
                city:'Hastingwood',
                sprc:'United Kingdom',
                postCode:'CR6 6AL',
                tradingAddress:false,
                tradingAddressInput1:'',
                tradingAddressInput2:'',
                tradingTown:'',
                tradingCity:'',
                tradingSprc:'',
                tradingPostCode:'',
                mobileNumber:'070 2009 9424',
                email:'alizee.info@yourdomain.com',
                websiteURLDisplay:false,
                websiteURL:'',
            }
        }
    },methods:{
        editDetails(){
            this.businessContact.readonlyMode = false;
        },

        //Business Contact Details Data
        businessContactSubmit(){
                if(this.businessContact.addressLine1 ==''){
                    this.businessContact.addressLine1 = '2 St Dunstans Street,United Kingdom'
                }else if(this.businessContact.addressLine2  == ''){
                    this.businessContact.addressLine2 = '99 Warner Close'
                }else if(this.businessContact.town == ''){
                    this.businessContact.town ='Warlingham'
                }else if(this.businessContact.city == ''){
                    this.businessContact.city ='Hastingwood'
                }else if(this.businessContact.sprc == ''){
                    this.businessContact.sprc ='United Kingdom'
                }else if(this.businessContact.postCode == ''){
                    this.businessContact.postCode ='CR6 6AL'
                }else if(this.businessContact.tradingAddressInput1 == ''){
                    this.businessContact.tradingAddressInput1 ='2 St Dunstans Street,United Kingdom'
                }else if(this.businessContact.tradingAddressInput2 == ''){
                    this.businessContact.tradingAddressInput2 = '101 Warner Close'
                }else if(this.businessContact.tradingTown == ''){
                    this.businessContact.tradingTown = 'Warlingham'
                }else if(this.businessContact.tradingCity == ''){
                    this.businessContact.tradingCity ='Hastingwood'
                }else if(this.businessContact.tradingSprc == ''){
                    this.businessContact.tradingSprc = 'United Kingdom'
                }else if(this.businessContact.tradingPostCode == ''){
                    this.businessContact.tradingPostCode = 'CR6 6AL'
                }else if(this.businessContact.mobileNumber == ''){
                    this.businessContact.mobileNumber = '070 2009 9424'
                }else if(this.businessContact.email == ''){
                    this.businessContact.email = 'alizee.info@yourdomain.com'
                }else if(this.businessContact.websiteURL == ''){
                    this.businessContact.websiteURL = 'www.goggle.com'
                }
                this.businessContact.readonlyMode = true;
        },
    }
}
</script>
