// initial state
const state = () => ({
    contactDetail: {
        userEmail: '',
        userMobile: ''
    }
})

// getters
const getters = {}

// actions
const actions = {
    addContact ({ commit }, userContactDetails) {
        commit('setContact', userContactDetails)
    },
    clearContact({commit}) {
      commit('clearContactData')
  }
}

// mutations
const mutations = {
  setContact (state, contactDetail) {
    state.contactDetail.userEmail = contactDetail.email,
    state.contactDetail.userMobile = contactDetail.phone
  },
  clearContactData(state) {
    state.contactDetail.userEmail = '',
    state.contactDetail.userMobile = ''
  }

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}