<template>
  <div>
    <FrontHeader/>
        <slot></slot>
    <AuthFooterV2Component/>
  </div>
</template>
<script>
export default {
    name: "FrontendLayout",
    components: {
        FrontHeader: () => import('@/components/layouts/frontend/FrontHeader.vue'),
        AuthFooterV2Component: () => import('@/components/admin/layouts/auth_footer/AuthFooterV2Component.vue'),
    }
}
</script>