<template>
    <div class="container-fluid">
        <div class="block-header">
            <BreadCrumb></BreadCrumb>
        </div>
        <div class="row clearfix">
            <div class="col-md-12">
                <div class="card">
                    <div class="header">
                        <h2>User Management</h2>
                    </div>
                    <div class="body">
                        <Form @submit="userSubmitData" :validation-schema="schema" id="basic-form" ref="form" v-slot="{ errors }">
                            <div class="row">
                                <div class="col-md-6 col-12">
                                    <Field name="first_name" rules="required" v-slot="{ handleChange }">
                                        <div class="form-group" :class="{'errors': errors && errors.first_name,'success':!errors && !errors.first_name}">
                                            <span class="float-label">
                                                <input type="text" @update:model-value="handleChange" v-model="user.first_name" class="form-control" id="first_name" placeholder="First Name*">
                                                <label for="first_name" class="control-label">First Name*</label>
                                                <div class="line"></div>
                                                <i class="fa fa-times" v-if="errors && errors.first_name"></i>
                                                <i class="fa fa-check" v-if="!errors.first_name && user.first_name"></i>
                                            </span>
                                        </div>
                                    </Field>
                                    <Field name="last_name" rules="required" v-slot="{ handleChange }">
                                        <div class="form-group" :class="{'errors': errors && errors.last_name,'success':!errors && !errors.last_name}">
                                            <span class="float-label">
                                                <input type="text" @update:model-value="handleChange" v-model="user.last_name" class="form-control" id="last_name" placeholder="Last Name*">
                                                <label for="last_name" class="control-label">Last Name*</label>
                                                <div class="line"></div>
                                                <i class="fa fa-times" v-if="errors && errors.last_name"></i>
                                                <i class="fa fa-check" v-if="!errors.last_name && user.last_name"></i>
                                            </span>
                                        </div>
                                    </Field>
                                    <Field name="business_name" rules="required">
                                            <div class="form-group form-select single-multiselect select-option-custom country-dropdown" :class="{'errors': errors && errors.business_name,'success':!errors || businessname_options}">
                                            <span class="float-label">
                                            <label for="functions">Select Business Name*</label>
                                                <multiselect 
                                                    placeholder=""
                                                    v-model="user.businessname" 
                                                    :options="businessname_options" 
                                                    :show-labels="false"
                                                    label="category" 
                                                    track-by="category">
                                                    <template v-slot:singleLabel="{ option }">{{ option.category }}</template>
                                                    <template v-slot:option="{ option }">
                                                        <div>
                                                            <span :class="option.isType"> 
                                                                <span v-if="option.isType == 'child'">-</span>
                                                                {{option.category}} 
                                                            </span>
                                                        </div>
                                                    </template>
                                                </multiselect>
                                                <i class="fa fa-times" v-if="errors && errors.business_name"></i>
                                                <i class="fa fa-check" v-if="!errors.business_name && user.businessname"></i>
                                            </span>
                                        </div>
                                    </Field>
                                    <Field name="contact_email" rules="required|email" v-slot="{ handleChange }">
                                        <div class="form-group" :class="{'errors': errors && errors.contact_email,'success':!errors && !errors.contact_email}">
                                            <span class="float-label">
                                                <input type="email" @update:model-value="handleChange" v-model="user.contact_email" class="form-control" name="email1" id="contact_email" placeholder="Contact Email Address*">
                                                <label for="contact_email" class="control-label">Contact Email Address*</label>
                                                <div class="line"></div>
                                                <i class="fa fa-times" v-if="errors && errors.contact_email"></i>
                                                <i class="fa fa-check" v-if="!errors.contact_email && user.contact_email"></i>
                                            </span>
                                        </div>
                                    </Field>
                                    <Field name="mobile_number" rules="required" v-slot="{ handleChange }">
                                        <div class="form-group" :class="{'errors': errors && errors.mobile_number,'success':!errors && !errors.mobile_number}">
                                            <span class="float-label">
                                                <input type="text" class="form-control" id="mobile_number" @update:model-value="handleChange" v-model="user.mobile_number" placeholder="Contact Mobile Number">
                                                <label for="mobile_number" class="control-label">Contact Mobile Number*</label>
                                                <div class="line"></div>
                                                <i class="fa fa-times" v-if="errors && errors.mobile_number"></i>
                                                <i class="fa fa-check" v-if="!errors.mobile_number && user.mobile_number"></i>
                                            </span>
                                        </div>
                                    </Field>
                                </div>
                                <div class="col-md-6 col-12">
                                    <Field name="select_country" rules="required">
                                        <div class="country-dropdown">
                                            <div class="form-group form-select multiselect-grp" :class="{'errors': errors && errors.select_country,'success':!errors || user.country}">
                                                <span class="float-label">
                                                <label for="Country">Select Country*</label>
                                                    <multiselect
                                                        placeholder=""
                                                        v-model="user.country" 
                                                        :show-labels="false" 
                                                        :options="countrys_options" 
                                                        :searchable="false"
                                                        :multiple="true" 
                                                        :close-on-select="true"
                                                        :clear-on-select="false" 
                                                        label="name" 
                                                        track-by="name"  
                                                        @select="isCheckedInput"
                                                        @remove="isCheckedInput">
                                                        <template v-slot:selection="{ values }">
                                                            <span class="multiselect__single" v-if="values.length == 1"><strong>{{ values[0].name }}</strong></span>
                                                            <span class="multiselect__single" v-else-if="values.length"><strong>{{ values.length }} Currencies Selected.</strong></span>
                                                            <span class="multiselect__single" v-else><strong>0 Currencies Selected.</strong></span>
                                                        </template>
                                                        <template #beforeList>
                                                            <span class="multiselect__option" @click="selectAllChanged">
                                                                <div class="option__desc">
                                                                    <span class="option__title">All</span>
                                                                </div>
                                                                <div class="multiselect__element multiselect-all multi-drop">
                                                                    <div class="control-inline fancy-checkbox" for="isChecked">
                                                                        <input type="checkbox" id="checkbox2" name="checkbox2" :checked="isAllChecked">
                                                                        <span for="checkbox2"></span>
                                                                    </div>
                                                                </div>
                                                            </span>
                                                        </template>
                                                        <template v-slot:option="{ option }">
                                                            <span :class="option.flagClass"></span>
                                                            <div class="option__desc">
                                                                <span class="option__title">{{ option.name }}</span>
                                                            </div>
                                                            <div class="multiselect__element multiselect-all multi-drop">
                                                                <div class="control-inline fancy-checkbox" for="isChecked">
                                                                    <input type="checkbox" id="isChecked" name="isChecked" :checked="option.isChecked == true">
                                                                    <span for="isChecked"></span>
                                                                </div>
                                                            </div>
                                                        </template>
                                                    </multiselect>
                                                    <i class="fa fa-times" v-if="errors && errors.select_country"></i>
                                                    <i class="fa fa-check" v-if="!errors.select_country && user.country"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </Field>
                                    <Field name="functions" rules="required">
                                        <div class="form-group form-select multiselect-grp country-dropdown" :class="{'errors': errors && errors.functions,'success':!errors || user.function_selected}">
                                            <span class="float-label">
                                            <label for="functions">Select Function*</label>
                                                <multiselect
                                                    v-model="user.function_selected" 
                                                    placeholder=""
                                                    label="name" 
                                                    track-by="name"
                                                    :options="function_options" 
                                                    :multiple="true"
                                                    :taggable="true"  
                                                    @tag="addNewTag"
                                                    @remove="removetag">
                                                    <template v-slot:selection="{ values,remove }">
                                                        <span class="multiselect__tag" v-for="item in values" :key="item.id">
                                                            {{ item.name }}<i aria-hidden="true" class="multiselect__tag-icon" @click="remove(item)"></i>
                                                        </span>
                                                    </template>
                                                    <template v-slot:option="{ option }">
                                                        <div class="option__desc">
                                                            <span class="option__title">{{ option.name }}</span>
                                                        </div>
                                                    </template>
                                                </multiselect> 
                                                <i class="fa fa-times" v-if="errors && errors.functions"></i>
                                                <i class="fa fa-check" v-if="!errors.functions && user.function_selected"></i>
                                            </span>
                                        </div>
                                    </Field>
                                    <Field name="roles" rules="required">
                                        <div class="form-group form-select multiselect-grp country-dropdown" :class="{'errors': errors && errors.roles,'success':!errors || user.roles_selected}">
                                            <span class="float-label">
                                                <label for="roles">Select Roles*</label>
                                                <multiselect
                                                    v-model="user.roles_selected" 
                                                    placeholder=""
                                                    label="name" 
                                                    track-by="name"
                                                    :options="roles_options" 
                                                    :multiple="true"
                                                    :taggable="true"  
                                                    @tag="addNewTag"
                                                    @remove="removetag">
                                                    <template v-slot:selection="{ values,remove }">
                                                        <span class="multiselect__tag" v-for="item in values" :key="item.id">
                                                            {{ item.name }}<i aria-hidden="true" class="multiselect__tag-icon" @click="remove(item)"></i>
                                                        </span>
                                                    </template>
                                                    <template v-slot:option="{ option }">
                                                        <div class="option__desc">
                                                            <span class="option__title">{{ option.name }}</span>
                                                        </div>
                                                    </template>
                                                </multiselect>
                                                <i class="fa fa-times" v-if="errors && errors.roles"></i>
                                                <i class="fa fa-check" v-if="!errors.roles && user.roles_selected"></i>
                                            </span>
                                        </div>
                                    </Field>
                                    <Field name="services" rules="required">
                                        <div class="form-group form-select multiselect-grp country-dropdown" :class="{'errors': errors && errors.services,'success':!errors || user.services_selected}">
                                            <span class="float-label">
                                                <label for="Services">Select Services*</label>
                                                <multiselect
                                                    v-model="user.services_selected" 
                                                    placeholder=""
                                                    label="name" 
                                                    track-by="name"
                                                    :options="services_options" 
                                                    :multiple="true"
                                                    :taggable="true"  
                                                    @tag="addNewTag"
                                                    @remove="removetag">
                                                    <template v-slot:selection="{ values,remove }">
                                                        <span class="multiselect__tag" v-for="item in values" :key="item.id">
                                                            {{ item.name }}<i aria-hidden="true" class="multiselect__tag-icon" @click="remove(item)"></i>
                                                        </span>
                                                    </template>
                                                    <template v-slot:option="{ option }">
                                                        <div class="option__desc">
                                                            <span class="option__title">{{ option.name }}</span>
                                                        </div>
                                                    </template>
                                                </multiselect>
                                                <i class="fa fa-times" v-if="errors && errors.services"></i>
                                                <i class="fa fa-check" v-if="!errors.services && user.services_selected"></i>
                                            </span>
                                        </div>
                                    </Field>
                                    <Field name="access" rules="required">
                                        <div class="form-group form-select multiselect-grp country-dropdown" :class="{'errors': errors && errors.access,'success':!errors || user.access_selected}">
                                            <span class="float-label">
                                                <label for="Access">Select Access Type*</label>
                                                <multiselect
                                                    v-model="user.access_selected" 
                                                    placeholder=""
                                                    label="name" 
                                                    track-by="name"
                                                    :options="access_type_options" 
                                                    :multiple="true"
                                                    :taggable="true"  
                                                    @tag="addNewTag"
                                                    @remove="removetag">
                                                    <template v-slot:selection="{ values,remove }">
                                                        <span class="multiselect__tag" v-for="item in values" :key="item.id">
                                                            {{ item.name }}<i aria-hidden="true" class="multiselect__tag-icon" @click="remove(item)"></i>
                                                        </span>
                                                    </template>
                                                    <template v-slot:option="{ option }">
                                                        <div class="option__desc">
                                                            <span class="option__title">{{ option.name }}</span>
                                                        </div>
                                                    </template>
                                                </multiselect>
                                                <i class="fa fa-times" v-if="errors && errors.access"></i>
                                                <i class="fa fa-check" v-if="!errors.access && user.access_selected"></i>
                                            </span>
                                        </div>
                                    </Field>
                                </div>
                            </div>
                            <div class="d-flex flex-column flex-md-row justify-content-center">
                                <b-button variant="secondary btn-mw" size="lg">Cancel</b-button>
                                <b-button type="submit" variant="primary ml-md-1 mt-2 mt-md-0 btn-mw" size="lg">Submit</b-button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import BreadCrumb from'@/components/BreadCrumb.vue'
import { Form, Field } from 'vee-validate';
import VueMultiselect from 'vue-multiselect'
export default {
    name: "AddUserComponent",
    components: {
        Form, 
        Field,
        'Multiselect': VueMultiselect,
        BreadCrumb
    },
    data: () => ({
        isAllChecked:false,
        function_options: [{name:'Admin'}, {name:'Tech'},{name: 'HR'}, {name:'Risk'}, {name:'Compliance'}, {name:'Finance'}, {name:'Sales'}, {name:'Service'}, {name:'Teller'},{name:'allowEmpty'}],
        roles_options:[{name:'Owner'}, {name:'Admin'}, {name:'Manager'}, {name:'User'}, {name:'Teller'}],
        services_options:[{name:'Dashboard'}, {name:'Transactions'}, {name:'User Management'}],
        access_type_options:[{name:'Read'}, {name:'Write'}, {name:'Edit'}, {name:'Full'}],
        businessname_options:[
            {
                id: 1,
                parentId: "",
                isType: "parent",
                category:'Company A',
            },
            {
                id: 2,
                parentId: 1,
                isType: "child",
                category:'Sub Company A-a',
            },
            {
                id: 3,
                parentId: 1,
                isType: "child",
                category:'Sub Company A-b',
            },
            {
                id: 4,
                parentId: "",
                isType: "parent",
                category:'Company B',
            },
            {
                id: 5,
                parentId: 4,
                isType: "child",
                category:'Sub Company B-a',
            },
            {
                id: 6,
                parentId: 4,
                isType: "child",
                category:'Sub Company B-b',
            }
        ],
        countrys_options: [
            { id:0,name: 'India', flagClass:"flag-icon flag-icon-in fi-xl fi-round",isChecked:false},
            { id:1,name: 'United Kingdom', flagClass: "flag-icon flag-icon-gb fi-xl fi-round",isChecked:false},
            { id:2,name: 'France' , flagClass: "flag-icon flag-icon-fr fi-xl fi-round",isChecked:false},
            { id:3,name: 'United States', flagClass: "flag-icon flag-icon-us fi-xl fi-round",isChecked:false},
        ],

        user: {
            first_name:'',
            last_name:'',
            country: [],
            contact_email:'',
            mobile_number:'',
            businessname: null,
            function_selected: null,
            roles_selected: null,
            services_selected: null,
            access_selected: null,
        },
    }),
    methods: {
        userSubmitData() {
            if(this.$route.query.from_personnel){
                this.$router.push({path:'/admin/business/details',query:{ active_tab: "personnel" }})
            }else{
                this.$router.push({path:'/admin/setting/user-list'})
            }
        },

        displayText(count){
            if (count === 0){
                return ''
            }else if (count === 1){
                return this.user.country[0].name
            }else if(count == this.countrys_options.length){
                this.isAllChecked = true;
                return count + ' Countrys Selected.'
            }else {
                this.isAllChecked = false;
                return  count + ' Countrys Selected.'
            }
        },

        selectAllChanged() {
            if (this.isAllSelected) {
                this.isAllChecked = false;
                this.user.country = [];
                this.countrys_options.map((data)=>{
                    data.isChecked = false
                });
            }else{
                this.isAllChecked = true;
                this.user.country = this.countrys_options.slice();
                this.countrys_options.map((data)=>{
                    data.isChecked = true
                });
            }
        },

        isCheckedInput (actionName) {
            this.countrys_options[actionName.id].isChecked = !this.countrys_options[actionName.id].isChecked
        },
    },computed: {
        isAllSelected: function() {
            return  (this.user.country.length === this.countrys_options.length)
        }
    }
};
</script>
