<template>
    <div class="card card-top-line">
        <div class="header d-flex align-items-center justify-content-between">
            <h2>Profile Photo</h2>
            <i class="fa fa-edit font-20" @click="editDetails()"></i>
        </div>
        <div class="body">
            <Form @submit="profileSubmit" id="logoIcon-form" ref="form" :validation-schema="schema" v-slot="{ errors }">
                <div class="d-flex user-profile">
                    <div class="user-photo-area mb-4">
                        <img :src="logoIcon.logo" class="user-photo img-thumbnail" alt="User">
                    </div>
                </div>
                <div class="media-body" v-if="!logoIcon.readonlyMode">
                    <Field rules="required" name="logo" v-slot="{ handleChange }">
                        <div class="form-group" :class="{'errors': errors && errors.logo ,'success':!errors.logo && !errors}">
                            <div class="custom-file">
                                <input type="hidden" name="logo" @update:model-value="handleChange" v-model="logoIcon.logo" accept="image/*">
                                <input type="file" ref="resetInputFile" id="logo" class="form-control custom-file-input" placeholder="Upload your photo*" accept="image/*" @change="profileImageChange" />
                                <label class="custom-file-label" for="logoIcon">Upload your photo.*</label>
                                <i class="fa fa-times" v-if="errors && errors.logo"></i>
                                <i class="fa fa-check" v-if="!errors.logo"></i>
                            </div>
                        </div>
                    </Field>
                </div>
                <div v-if="!logoIcon.readonlyMode" class="justify-content-center d-flex">
                    <b-button size="lg" @click="logoIcon.readonlyMode = true" class="btn-mw">Cancel</b-button>
                    <b-button type="submit" variant="primary ml-1 btn-mw" size="lg">Update</b-button>
                </div>
            </Form>
        </div>
    </div>
</template>
<script>
import { Form, Field } from 'vee-validate';
import "@/plugins/vee-validate";

export default {
    name: 'logo-Profile',
    components: {
        Form,
        Field
    },data(){
        return{
            logoIcon:{
                logo: require(`@/assets/payangel_logo.png`),
                readonlyMode : true
            },
        }
    },methods:{
        editDetails(){
            this.logoIcon.readonlyMode = false
        },

        profileSubmit(){
            this.logoIcon.readonlyMode = true
        },

        profileImageChange(e) {
            if(e.target.files.length > 0){
                let file = e.target.files[0];
                this.logoIcon.logo = URL.createObjectURL(file);
            }
        },
    }
}
</script>
