<template>
    <div class="card card-top-line">
        <div class="header d-flex align-items-center justify-content-between">
            <h2>Your Business and Regulations</h2>
            <i class="fa fa-edit font-20" @click="editDetails()"></i>
        </div>
        <div class="body">
            <div class="edit-form">
                <Form @submit="regulationsForm" id="business-structure" ref="form" :validation-schema="schema" v-slot="{ errors }">
                    <Field rules="required" name="Industry_Classification">
                        <div class="country-dropdown">
                            <div class="form-group form-select multiselect-grp" :class="{'errors': errors && errors.Industry_Classification ,'success':regulations.industryClassification || !errors}">
                                <span class="float-label" :class="{'multiselect--disabled':regulations.readonlyMode}">
                                <label for="Country">Industry Classification*</label>
                                    <multiselect
                                        :disabled="regulations.readonlyMode"
                                        placeholder=""
                                        v-model="regulations.industryClassification" 
                                        :show-labels="false" 
                                        :options="industryClassification_option" 
                                        :searchable="false">
                                        <template v-slot:singleLabel="{ option }">{{ option}}</template>
                                        <template v-slot:option="{ option }">
                                            <div class="option__desc">
                                                <span class="option__title">{{ option}}</span>
                                            </div>
                                        </template>
                                    </multiselect>  
                                    <i class="fa fa-times" v-if="errors && errors.Industry_Classification && !regulations.readonlyMode"></i>
                                    <i class="fa fa-check" v-if=" !errors.Industry_Classification && !regulations.readonlyMode"></i>
                                </span>
                            </div>
                        </div>
                    </Field>
                    <Field rules="required" name="ISIC_Code">
                        <div class="country-dropdown">
                            <div class="form-group form-select multiselect-grp" :class="{'errors': errors && errors.ISIC_Code ,'success':regulations.ISIC_code || !errors}">
                                <span class="float-label" :class="{'multiselect--disabled':regulations.readonlyMode}">
                                <label for="Country">Confirm your Businesses ISIC Code*</label>
                                    <multiselect
                                        :disabled="regulations.readonlyMode"
                                        placeholder=""
                                        v-model="regulations.ISIC_code" 
                                        :show-labels="false" 
                                        :options="ISICCode_option" 
                                        :searchable="false">
                                        <template v-slot:singleLabel="{ option }">{{ option}}</template>
                                        <template v-slot:option="{ option }">
                                            <div class="option__desc">
                                                <span class="option__title">{{ option}}</span>
                                            </div>
                                        </template>
                                    </multiselect>  
                                    <i class="fa fa-times" v-if="errors && errors.ISIC_Code && !regulations.readonlyMode"></i>
                                    <i class="fa fa-check" v-if=" !errors.ISIC_Code && !regulations.readonlyMode"></i>
                                </span>
                            </div>
                        </div>
                    </Field>
                    <Field rules="required" name="Registration_Number" v-slot="{ handleChange }">
                        <div class="form-group" :class="{'errors': errors && errors.Registration_Number ,'success':!errors.Registration_Number && !errors}">
                            <span class="float-label">
                                <input type="text" @update:model-value="handleChange" v-model="regulations.registration_Number" class="form-control" id="registration_Number" placeholder="Registration Number*" :disabled="regulations.readonlyMode">
                                <label for="registration_Number" class="control-label">Registration Number*</label>
                                <div class="line"></div>
                                <i class="fa fa-times" v-if="errors && errors.Registration_Number && !regulations.readonlyMode"></i>
                                <i class="fa fa-check" v-if=" !errors.Registration_Number && !regulations.readonlyMode"></i>
                            </span>
                        </div>
                    </Field>
                    <Field rules="required" name="Tax_ID" v-slot="{ handleChange }">
                        <div class="form-group" :class="{'errors': errors && errors.Tax_ID ,'success':!errors.Tax_ID && !errors}">
                            <span class="float-label">
                                <input type="text" @update:model-value="handleChange" v-model="regulations.Tax_ID" class="form-control" id="Tax_ID" placeholder="Business Tax ID*" :disabled="regulations.readonlyMode">
                                <label for="Tax_ID" class="control-label">Business Tax ID*</label>
                                <div class="line"></div>
                                <i class="fa fa-times" v-if="errors && errors.Tax_ID && !regulations.readonlyMode"></i>
                                <i class="fa fa-check" v-if=" !errors.Tax_ID && !regulations.readonlyMode"></i>
                            </span>
                        </div>
                    </Field>
                    <div class="d-flex align-items-center justify-content-between mb-3" :class="{'switch-disable':regulations.readonlyMode}">
                        <p class="mb-0 mr-3 custom-switch-text">Is your business regulated?</p>
                        <div class="custom-switch success-handle">
                            <input type="checkbox" class="custom-switch-input" id="isRegulated" v-model="regulations.isRegulated" :disabled="regulations.readonlyMode">
                            <label class="custom-switch-label" for="isRegulated">
                                <span class="custom-switch-text1">Yes</span>
                                <span class="custom-switch-text2">No</span>
                            </label>
                        </div>
                    </div>
                    <Field :rules="regulations.isRegulated ? 'required' : ''" name="regulated_number" v-slot="{ handleChange }" v-if="regulations.isRegulated">
                        <div class="form-group" :class="{'errors': errors && errors.regulated_number ,'success':!errors.regulated_number && !errors}">
                            <span class="float-label">
                                <input type="text" @update:model-value="handleChange" v-model="regulations.regulatedNumber" class="form-control" id="regulated_number" placeholder="Licence/Regulated Number*" :disabled="regulations.readonlyMode">
                                <label for="regulated_number" class="control-label">Licence/Regulated Number*</label>
                                <div class="line"></div>
                                <i class="fa fa-times" v-if="errors && errors.regulated_number && !regulations.readonlyMode"></i>
                                <i class="fa fa-check" v-if=" !errors.regulated_number && !regulations.readonlyMode"></i>
                            </span>
                        </div>
                    </Field>
                    <div class="d-flex align-items-center justify-content-between mb-3" :class="{'switch-disable':regulations.readonlyMode}">
                        <p class="mb-0 mr-3 custom-switch-text">Is your business registered for VAT/Sales Tax? </p>
                        <div class="custom-switch success-handle">
                            <input type="checkbox" class="custom-switch-input" id="isSalesTax" v-model="regulations.isSalesTax" :disabled="regulations.readonlyMode">
                            <label class="custom-switch-label" for="isSalesTax">
                                <span class="custom-switch-text1">Yes</span>
                                <span class="custom-switch-text2">No</span>
                            </label>
                        </div>
                    </div>
                    <Field :rules="regulations.isSalesTax ? 'required' : ''" name="tax_number" v-slot="{ handleChange }" v-if="regulations.isSalesTax && !regulations.readonlyMode">
                        <div class="form-group" :class="{'errors': errors && errors.tax_number ,'success':!errors.tax_number && !errors}">
                            <span class="float-label">
                                <input type="text" @update:model-value="handleChange" v-model="regulations.taxNumber" class="form-control" id="tax_number" placeholder="VAT/Sales Tax Number*" :disabled="regulations.readonlyMode">
                                <label for="tax_number" class="control-label">VAT/Sales Tax Number*</label>
                                <div class="line"></div>
                                <i class="fa fa-times" v-if="errors && errors.tax_number && !regulations.readonlyMode"></i>
                                <i class="fa fa-check" v-if=" !errors.tax_number && !regulations.readonlyMode"></i>
                            </span>
                        </div>
                    </Field>
                        <div class="d-flex align-items-center justify-content-between mb-3" :class="{'switch-disable':regulations.readonlyMode}">
                        <p class="mb-0 mr-3 custom-switch-text">Do you have an AML Policy?</p>
                        <div class="custom-switch success-handle">
                            <input type="checkbox" class="custom-switch-input" id="isAMLPolicy" v-model="regulations.isAMLPolicy" :disabled="regulations.readonlyMode">
                            <label class="custom-switch-label" for="isAMLPolicy">
                                <span class="custom-switch-text1">Yes</span>
                                <span class="custom-switch-text2">No</span>
                            </label>
                        </div>
                    </div>
                    <Field :rules="regulations.isAMLPolicy ? 'required' : ''" name="policy_number" v-slot="{ handleChange }" v-if="regulations.isAMLPolicy && !regulations.readonlyMode">
                        <div class="form-group" :class="{'errors': errors && errors.policy_number ,'success':!errors.policy_number && !errors}">
                            <span class="float-label">
                                <input type="text" @update:model-value="handleChange" v-model="regulations.AMLPolicyNumber" class="form-control" id="policy_number" placeholder="Registration Number*" :disabled="regulations.readonlyMode">
                                <label for="policy_number" class="control-label">Registration Number*</label>
                                <div class="line"></div>
                                <i class="fa fa-times" v-if="errors && errors.policy_number && !regulations.readonlyMode"></i>
                                <i class="fa fa-check" v-if=" !errors.policy_number && !regulations.readonlyMode"></i>
                            </span>
                        </div>
                    </Field>
                    <div class="d-flex align-items-center justify-content-between mb-3" :class="{'switch-disable':regulations.readonlyMode}">
                        <p class="mb-0 mr-3 custom-switch-text">Is your business registered for Data Protection?</p>
                        <div class="custom-switch success-handle">
                            <input type="checkbox" class="custom-switch-input" id="isDataProtection" v-model="regulations.isDataProtection" :disabled="regulations.readonlyMode">
                            <label class="custom-switch-label" for="isDataProtection">
                                <span class="custom-switch-text1">Yes</span>
                                <span class="custom-switch-text2">No</span>
                            </label>
                        </div>
                    </div>
                    <Field :rules="regulations.isDataProtection ? 'required' : ''" name="dataProtection_number" v-slot="{ handleChange }" v-if="regulations.isDataProtection && !regulations.readonlyMode">
                        <div class="form-group" :class="{'errors': errors && errors.dataProtection_number ,'success':!errors.dataProtection_number && !errors}">
                            <span class="float-label">
                                <input type="text" @update:model-value="handleChange" v-model="regulations.dataProtectionNumber" class="form-control" id="dataProtection_number" placeholder="Registration Number*" :disabled="regulations.readonlyMode">
                                <label for="dataProtection_number" class="control-label">Registration Number*</label>
                                <div class="line"></div>
                                <i class="fa fa-times" v-if="errors && errors.dataProtection_number && !regulations.readonlyMode"></i>
                                <i class="fa fa-check" v-if=" !errors.dataProtection_number && !regulations.readonlyMode"></i>
                            </span>
                        </div>
                    </Field>
                    <div class="d-flex align-items-center justify-content-between mb-3" :class="{'switch-disable':regulations.readonlyMode}">
                        <p class="mb-0 mr-3 custom-switch-text">Is your business PCI Certified? </p>
                        <div class="custom-switch success-handle">
                            <input type="checkbox" class="custom-switch-input" id="isPCICertified" v-model="regulations.isPCICertified" :disabled="regulations.readonlyMode">
                            <label class="custom-switch-label" for="isPCICertified">
                                <span class="custom-switch-text1">Yes</span>
                                <span class="custom-switch-text2">No</span>
                            </label>
                        </div>
                    </div>
                    <Field :rules="regulations.isPCICertified ? 'required' : ''" name="regulations_certification" v-slot="{ handleChange }"  v-if="regulations.isPCICertified && !regulations.readonlyMode">
                        <div class="form-group datepicker" :class="{'errors': errors && errors.regulations_certification ,'success':!errors.regulations_certification && !errors}">
                            <span class="float-label">
                                <b-input-group :class="{'datepicker-disable':regulations.readonlyMode}">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Date of Last Certification</span>
                                    </div>
                                    <datepicker class="form-control" format="YYYY-MM-DD" placeholder="" @update:model-value="handleChange" v-model="regulations.certificationDate" :disabled="regulations.readonlyMode" :typeable="true"></datepicker>
                                    <template #append>
                                        <span class="input-group-text"><i class="icon-calendar"></i></span>
                                    </template>
                                </b-input-group>
                                <i class="fa fa-times" v-if="errors && errors.regulations_certification && !regulations.readonlyMode"></i>
                                <i class="fa fa-check" v-if=" !errors.regulations_certification && !regulations.readonlyMode"></i>
                            </span>
                        </div>
                    </Field>
                    <div class="d-flex align-items-center justify-content-between mb-3" :class="{'switch-disable':regulations.readonlyMode}">
                        <p class="mb-0 mr-3 custom-switch-text">Is your business ISO27001 Certified? </p>
                        <div class="custom-switch success-handle">
                            <input type="checkbox" class="custom-switch-input" id="isISOCertified" v-model="regulations.isISOCertified">
                            <label class="custom-switch-label" for="isISOCertified">
                                <span class="custom-switch-text1">Yes</span>
                                <span class="custom-switch-text2">No</span>
                            </label>
                        </div>
                    </div>
                    <Field :rules="regulations.isISOCertified ? 'required' : ''" name="regulations_ISO" v-slot="{ handleChange }" v-if="regulations.isISOCertified && !regulations.readonlyMode">
                        <div class="form-group datepicker" :class="{'errors': errors && errors.regulations_ISO ,'success':!errors.regulations_ISO && !errors}">
                            <span class="float-label">
                                <b-input-group :class="{'datepicker-disable':regulations.readonlyMode}">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Date of Last Certification</span>
                                    </div>
                                    <datepicker class="form-control" format="YYYY-MM-DD" placeholder="" @update:model-value="handleChange" v-model="regulations.ISOCertifiedDate" :disabled="regulations.readonlyMode" :typeable="true"></datepicker>
                                    <template #append>
                                        <span class="input-group-text"><i class="icon-calendar"></i></span>
                                    </template>
                                </b-input-group>
                                <i class="fa fa-times" v-if="errors && errors.regulations_ISO && !regulations.readonlyMode"></i>
                                <i class="fa fa-check" v-if=" !errors.regulations_ISO && !regulations.readonlyMode"></i>
                            </span>
                        </div>
                    </Field>
                    <div v-if="!regulations.readonlyMode" class="justify-content-center d-flex">
                        <b-button size="lg" @click="regulationsForm()" class="btn-mw">Cancel</b-button>
                        <b-button type="submit" variant="primary ml-1 btn-mw" size="lg">Update</b-button>
                    </div>
                </Form>
            </div>
        </div>
    </div>
</template>
<script>
import { Form, Field } from 'vee-validate';
import VueMultiselect from 'vue-multiselect'
export default {
    name: 'BusinessRegulations-Profile',
    components:{
        datepicker: () => import('vue-date-picker'),
        Form,
        Field,
        'Multiselect': VueMultiselect,
    },
    data(){
        return{
            regulations:{
                readonlyMode:true,
                industryClassification:['Hospitality'],
                ISIC_code: ['NACE – EU'],
                registration_Number:'036895C4',
                Tax_ID:'0785554228682',
                isRegulated:false,
                regulatedNumber:'089654228682',
                isSalesTax:false,
                taxNumber:'',
                isAMLPolicy:false,
                AMLPolicyNumber:'',
                isDataProtection:false,
                dataProtectionNumber:'',
                isPCICertified:false,
                certificationDate:null,
                isISOCertified:false,
                ISOCertifiedDate:''

            },
            industryClassification_option: ['Hospitality', 'Taxes & Legal', 'Industrial & Warehouse', 'Real Estate'],
            ISICCode_option: ['NACE – EU','NAICS – US','UKSIC - UK'],
        }
    },methods:{
        editDetails(){
            this.regulations.readonlyMode = false
        },

        //submit Personal Details information
        regulationsForm(){
            this.regulations.readonlyMode  = true
        },
    }
}
</script>
