<template>
    <div class="container-fluid">
        <div class="block-header">
            <BreadCrumb></BreadCrumb>
        </div>
        <div class="row clearfix justify-content-center">
            <div class="col-xl-9 col-lg-10 col-md-12">
                <div class="card card-top-line no-shadow send-money-area">
                    <div class="header">
                        <h2>Jones, please fill transfer details</h2>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-6">
                                <money_exchange_calculation></money_exchange_calculation>
                            </div>
                            <div class="col-md-6">
                                <div class="card border no-shadow">
                                    <money_exchange_timeline></money_exchange_timeline>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex justify-content-end">
                            <b-button variant="primary btn-mw" size="lg" @click="sendMoneySubmit()">Continue</b-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import BreadCrumb from '@/components/BreadCrumb.vue';
import money_exchange_timeline from '@/components/admin/send_money/send_myself/MoneyExchangeTimeline.vue';
import money_exchange_calculation from '@/components/admin/send_money/send_myself/MoneyExchangeCalculation.vue';
export default {
    name:'ReceiveMoneyBy',
    components: {
        BreadCrumb,
        money_exchange_timeline,
        money_exchange_calculation,
    },methods:{
        sendMoneySubmit() {
            console.log("send money successfully!!");
        }
    }
}

</script>
