<template>
    <ul class="list-unstyled search-list no-search-list send-list">
        <li>
            <a href="javascript:void(0)" v-b-modal.addBankAccount class="btn btn-add-new-recipients d-flex align-items-center mt-0">
                <i class="fa fa-plus"></i> Add New Bank Account
            </a>
            <b-modal id="addBankAccount" title="Add Bank Details" hide-footer>
                <div class="modal-area"> 
                    <Form class="form-auth-small" @submit="addBankAccountSubmit" :validation-schema="schema" v-slot="{ errors }" id="addBankAccount" ref="addBankAccount">
                        <div class="row">
                            <!-- Country -->
                            <div class="col-12">
                                <Field name="country" rules="required">
                                    <div class="country-dropdown">
                                        <div class="form-group form-select multiselect-grp" :class="{'errors': errors && errors.country,'success':!errors && !errors.country}">
                                            <span class="float-label">
                                            <label for="Country">Country of Residence*</label>
                                                <multiselect
                                                    placeholder=""
                                                    v-model="addBankAccount.country"
                                                    :show-labels="false" 
                                                    :options="country_options"
                                                    :searchable="false"
                                                    label="name" 
                                                    track-by="name"
                                                    :custom-label="currencyFlag"
                                                    >
                                                    <template v-slot:singleLabel="{ option }">
                                                        <span class="mr-2" :class="option.flagClass"></span>
                                                        <span class="option-title">
                                                            <span>{{ option.name }}</span>
                                                        </span>
                                                    </template>
                                                    <template v-slot:option="{ option }">
                                                        <div class="option-title">
                                                            <span class="mr-2" :class="option.flagClass"></span>
                                                            <span>{{ option.name }}</span>
                                                        </div>
                                                    </template>
                                                </multiselect>
                                            </span>
                                        </div>
                                    </div>
                                </Field>
                            </div>
                            <div class="col-12">
                                <Field name="destination_bank" rules="required">
                                    <div class="country-dropdown">
                                        <div class="form-group form-select multiselect-grp" :class="{'errors': errors && errors.destination_bank,'success':!errors && !errors.destination_bank}">
                                            <span class="float-label">
                                            <label for="Country">Destination Bank *</label>
                                                <multiselect
                                                    placeholder=""
                                                    v-model="addBankAccount.destination_bank"
                                                    :show-labels="false" 
                                                    :options="bankList"
                                                    :searchable="true"
                                                    label="name" 
                                                    track-by="name"
                                                    >
                                                    <template v-slot:singleLabel="{ option }">
                                                        <span class="mr-2" :class="option.flagClass"></span>
                                                        <span class="option-title">
                                                            <span>{{ option.name }}</span>
                                                        </span>
                                                    </template>
                                                    <template v-slot:option="{ option }">
                                                        <div class="option-title">
                                                            <span class="mr-2" :class="option.flagClass"></span>
                                                            <span>{{ option.name }}</span>
                                                        </div>
                                                    </template>
                                                </multiselect>
                                            </span>
                                        </div>
                                    </div>
                                </Field>
                            </div>
                            <div class="col-12">
                                <Field name="branch" rules="required">
                                    <div class="country-dropdown">
                                        <div class="form-group form-select multiselect-grp" :class="{'errors': errors && errors.branch,'success':!errors && !errors.branch}">
                                            <span class="float-label">
                                            <label for="Country">Branch*</label>
                                                <multiselect
                                                    placeholder=""
                                                    v-model="addBankAccount.bank_branch"
                                                    :show-labels="false" 
                                                    :options="branch_options"
                                                    :searchable="true"
                                                    label="name" 
                                                    track-by="name"
                                                    :custom-label="currencyFlag"
                                                    >
                                                    <template v-slot:singleLabel="{ option }">
                                                        <span class="option-title">
                                                            <span>{{ option.name }}</span>
                                                        </span>
                                                    </template>
                                                    <template v-slot:option="{ option }">
                                                        <div class="option-title">
                                                            <span>{{ option.name }}</span>
                                                        </div>
                                                    </template>
                                                </multiselect>
                                            </span>
                                        </div>
                                    </div>
                                </Field>
                            </div>
                           <div class="col-12">
                                <Field name="currency" rules="required">
                                    <div class="country-dropdown">
                                        <div class="form-group form-select multiselect-grp" :class="{'errors': errors && errors.currency,'success':!errors && !errors.currency}">
                                            <span class="float-label">
                                            <label for="Country">Account Currency*</label>
                                                <multiselect
                                                    placeholder=""
                                                    v-model="addBankAccount.currency"
                                                    :show-labels="false" 
                                                    :options="currency_options"
                                                    :searchable="false"
                                                    label="name" 
                                                    track-by="name"
                                                    :custom-label="currencyFlag"
                                                    >
                                                    <template v-slot:singleLabel="{ option }">
                                                        <span class="mr-2" :class="option.flagClass"></span>
                                                        <span class="option-title">
                                                            <span>{{ option.name }}</span>
                                                        </span>
                                                    </template>
                                                    <template v-slot:option="{ option }">
                                                        <div class="option-title">
                                                            <span class="mr-2" :class="option.flagClass"></span>
                                                            <span>{{ option.name }}</span>
                                                        </div>
                                                    </template>
                                                </multiselect>
                                            </span>
                                        </div>
                                    </div>
                                </Field>
                            </div>
                            <div class="col-12">
                                <Field rules="required" name="account_number" v-slot="{ handleChange }">
                                    <div class="form-group" :class="{'errors': errors && errors.account_number,'success':!errors && !errors.account_number}">
                                        <span class="float-label">
                                            <input type="text" id="account_number" v-model="addBankAccount.account_number" @update:model-value="handleChange" class="form-control" placeholder="Business Name*">
                                            <label for="account_number" class="control-label">Account Number*</label>
                                            <div class="line"></div>
                                            <i class="fa fa-times" v-if="errors && errors.account_number"></i>
                                            <i class="fa fa-check" v-if="addBankAccount.account_number && !errors.account_number"></i>
                                        </span>
                                        <span class="text-danger error-msg" v-if="errors[0]">Please provide unique Account Name</span>
                                    </div>
                                </Field>
                            </div>
                            <div class="col-12">
                                <Field rules="confirmed:@account_number" name="cnfrm_account_number" v-slot="{ handleChange }">
                                    <div class="form-group" :class="{'errors': errors && errors.cnfrm_account_number,'success':!errors && !errors.cnfrm_account_number}">
                                        <span class="float-label">
                                            <input type="text" id="cnfrm_account_number" v-model="addBankAccount.cnfrm_account_number" @update:model-value="handleChange" class="form-control" placeholder="Business Name*">
                                            <label for="cnfrm_account_number" class="control-label">Confirm Account Number*</label>
                                            <div class="line"></div>
                                            <i class="fa fa-times" v-if="errors && errors.cnfrm_account_number"></i>
                                            <i class="fa fa-check" v-if="addBankAccount.cnfrm_account_number && !errors.cnfrm_account_number"></i>
                                        </span>
                                        <span class="text-danger error-msg" v-if="errors[0]">Please provide unique Account Name</span>
                                    </div>
                                </Field>
                            </div>
                        </div>
                        <div class="align-items-center d-flex flex-column justify-content-between mt-3 w-100">
                            <b-button type="submit" block class="btn btn-primary btn-mw btn-lg" size="lg">Verify</b-button>
                            <!-- <b-button type="submit" block class="btn btn-lg btn-outline-secondary" size="lg" @click="$bvModal.hide('addBankAccount')">Cancel</b-button> -->
                        </div>
                    </Form>
                </div>
            </b-modal>
        </li>
        <li v-for="(bank,index) in bankList" :key="index">
            <router-link :to="{ path: '/admin/send-money/receive-money-by',query:{ type: $route.query.type }}" class="send-list-card">
                <div class="d-flex align-items-center">
                    <div class="media-object flag-img">
                        <img :src="bank.image" alt="" width="50" class="rounded-circle">
                        <span :class="bank.flagIcon"></span>
                    </div>
                    <div class="name ml-3">
                        <h6 class="mb-1">{{ bank.name }}</h6>
                        <p class="mb-0 d-flex align-items-center">{{ bank.number }}</p>
                    </div>
                </div>
                <div class="icon-right">
                    <i class="arrow-right" v-html="bank.arrowImage"></i>
                </div>
            </router-link>
        </li>
    </ul>
</template>
<script>
import { Form, Field } from 'vee-validate';
export default {
    name:'BankAccount',
    components: {
        Form, 
        Field,
        Multiselect: () => import('vue-multiselect'),
    },
    data(){
        return{
            addBankAccount : {
                country: { name: 'United Kingdom', flagClass: "flag-icon flag-icon-gb fi-md fi-round",primaryNumberCode:'GB'},
                currency: { name: 'GBP', flagClass: "flag-icon flag-icon-gb fi-md fi-round"},
                destination_bank: null,
                bank_branch: null,
                account_currency: null,
                account_number: null,
                cnfrm_account_number: null
            },
            mobile_network: [
                { name: 'MTN Prepaid Topup'},
                { name: 'Vodafone Prepaid Topup'},
                { name: 'Airtle-Tigo Prepaid Topup'}
            ],
            bankList:[
                {
                    name: 'GCB Bank Limited',
                    number: '00120100001414',
                    image: require(`@/assets/img/send-money/gcb.png`),
                    flagIcon: 'flag-icon flag-icon-ca fi-md fi-round',
                    arrowImage:`<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path class="fill" fill-rule="evenodd" clip-rule="evenodd" d="M1 8.00002C1 7.86741 1.05268 7.74024 1.14645 7.64647C1.24021 7.5527 1.36739 7.50002 1.5 7.50002H13.293L10.146 4.35402C10.0521 4.26013 9.99937 4.1328 9.99937 4.00002C9.99937 3.86725 10.0521 3.73991 10.146 3.64602C10.2399 3.55213 10.3672 3.49939 10.5 3.49939C10.6328 3.49939 10.7601 3.55213 10.854 3.64602L14.854 7.64602C14.9006 7.69247 14.9375 7.74764 14.9627 7.80839C14.9879 7.86913 15.0009 7.93425 15.0009 8.00002C15.0009 8.06579 14.9879 8.13091 14.9627 8.19165C14.9375 8.2524 14.9006 8.30758 14.854 8.35402L10.854 12.354C10.7601 12.4479 10.6328 12.5007 10.5 12.5007C10.3672 12.5007 10.2399 12.4479 10.146 12.354C10.0521 12.2601 9.99937 12.1328 9.99937 12C9.99937 11.8672 10.0521 11.7399 10.146 11.646L13.293 8.50002H1.5C1.36739 8.50002 1.24021 8.44734 1.14645 8.35357C1.05268 8.25981 1 8.13263 1 8.00002Z"/>
                    </svg>`
                },
                {
                    name: 'Ecobank Ghana (EBG)',
                    number: '00120100001414',
                    image: require(`@/assets/img/send-money/ecobank.png`),
                    flagIcon: 'flag-icon flag-icon-ca fi-md fi-round',
                    arrowImage:`<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path class="fill" fill-rule="evenodd" clip-rule="evenodd" d="M1 8.00002C1 7.86741 1.05268 7.74024 1.14645 7.64647C1.24021 7.5527 1.36739 7.50002 1.5 7.50002H13.293L10.146 4.35402C10.0521 4.26013 9.99937 4.1328 9.99937 4.00002C9.99937 3.86725 10.0521 3.73991 10.146 3.64602C10.2399 3.55213 10.3672 3.49939 10.5 3.49939C10.6328 3.49939 10.7601 3.55213 10.854 3.64602L14.854 7.64602C14.9006 7.69247 14.9375 7.74764 14.9627 7.80839C14.9879 7.86913 15.0009 7.93425 15.0009 8.00002C15.0009 8.06579 14.9879 8.13091 14.9627 8.19165C14.9375 8.2524 14.9006 8.30758 14.854 8.35402L10.854 12.354C10.7601 12.4479 10.6328 12.5007 10.5 12.5007C10.3672 12.5007 10.2399 12.4479 10.146 12.354C10.0521 12.2601 9.99937 12.1328 9.99937 12C9.99937 11.8672 10.0521 11.7399 10.146 11.646L13.293 8.50002H1.5C1.36739 8.50002 1.24021 8.44734 1.14645 8.35357C1.05268 8.25981 1 8.13263 1 8.00002Z"/>
                    </svg>`
                },
                {
                    name: 'Zenith Bank Of Ghana',
                    number: '00120100001414',
                    image: require(`@/assets/img/send-money/zenith.png`),
                    flagIcon: 'flag-icon flag-icon-ca fi-md fi-round',
                    arrowImage:`<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path class="fill" fill-rule="evenodd" clip-rule="evenodd" d="M1 8.00002C1 7.86741 1.05268 7.74024 1.14645 7.64647C1.24021 7.5527 1.36739 7.50002 1.5 7.50002H13.293L10.146 4.35402C10.0521 4.26013 9.99937 4.1328 9.99937 4.00002C9.99937 3.86725 10.0521 3.73991 10.146 3.64602C10.2399 3.55213 10.3672 3.49939 10.5 3.49939C10.6328 3.49939 10.7601 3.55213 10.854 3.64602L14.854 7.64602C14.9006 7.69247 14.9375 7.74764 14.9627 7.80839C14.9879 7.86913 15.0009 7.93425 15.0009 8.00002C15.0009 8.06579 14.9879 8.13091 14.9627 8.19165C14.9375 8.2524 14.9006 8.30758 14.854 8.35402L10.854 12.354C10.7601 12.4479 10.6328 12.5007 10.5 12.5007C10.3672 12.5007 10.2399 12.4479 10.146 12.354C10.0521 12.2601 9.99937 12.1328 9.99937 12C9.99937 11.8672 10.0521 11.7399 10.146 11.646L13.293 8.50002H1.5C1.36739 8.50002 1.24021 8.44734 1.14645 8.35357C1.05268 8.25981 1 8.13263 1 8.00002Z"/>
                    </svg>`
                },
                {
                    name: 'Absa Bank  Ghana Limited',
                    number: '00120100001414',
                    image: require(`@/assets/img/send-money/absa.png`),
                    flagIcon: 'flag-icon flag-icon-ca fi-md fi-round',
                    arrowImage:`<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path class="fill" fill-rule="evenodd" clip-rule="evenodd" d="M1 8.00002C1 7.86741 1.05268 7.74024 1.14645 7.64647C1.24021 7.5527 1.36739 7.50002 1.5 7.50002H13.293L10.146 4.35402C10.0521 4.26013 9.99937 4.1328 9.99937 4.00002C9.99937 3.86725 10.0521 3.73991 10.146 3.64602C10.2399 3.55213 10.3672 3.49939 10.5 3.49939C10.6328 3.49939 10.7601 3.55213 10.854 3.64602L14.854 7.64602C14.9006 7.69247 14.9375 7.74764 14.9627 7.80839C14.9879 7.86913 15.0009 7.93425 15.0009 8.00002C15.0009 8.06579 14.9879 8.13091 14.9627 8.19165C14.9375 8.2524 14.9006 8.30758 14.854 8.35402L10.854 12.354C10.7601 12.4479 10.6328 12.5007 10.5 12.5007C10.3672 12.5007 10.2399 12.4479 10.146 12.354C10.0521 12.2601 9.99937 12.1328 9.99937 12C9.99937 11.8672 10.0521 11.7399 10.146 11.646L13.293 8.50002H1.5C1.36739 8.50002 1.24021 8.44734 1.14645 8.35357C1.05268 8.25981 1 8.13263 1 8.00002Z"/>
                    </svg>`
                },
            ],
            branch_options: [
                { name: '1 THORPE ROAD Ghana' },
                { name: 'UNA HOME, AIRPORT CITY Ghana' },
                { name: 'HIGH STREET, ABSA HOUSE Ghana' },
                { name: 'THORPE ROAD Ghana' },
                { name: 'HIGH STREET THORPE, 1, TREASURY DEPARTMENT, FLOOR 3 Ghana' },
            ],
            country_options:[
                { name: 'United Kingdom', flagClass: "flag-icon flag-icon-gb fi-md fi-round",primaryNumberCode:'GB'},
                { name: 'India', flagClass:"flag-icon flag-icon-in fi-md fi-round",primaryNumberCode:'IN'},
                { name: 'France' , flagClass: "flag-icon flag-icon-fr fi-md fi-round",primaryNumberCode:'FR'},
                { name: 'United States', flagClass: "flag-icon flag-icon-us fi-md fi-round",primaryNumberCode:'US'},
            ],
            currency_options:[
                { name: 'GBP', flagClass: "flag-icon flag-icon-gb fi-md fi-round"},
                { name: 'GHS', flagClass: "flag-icon flag-icon-gh fi-md fi-round"},
                { name: 'USD', flagClass: "flag-icon flag-icon-us fi-md fi-round"},
                { name: 'RUB', flagClass:"flag-icon flag-icon-ru fi-md fi-round"},
            ],
        }
    }, methods: {
        currencyFlag ({ name, flagClass }) {
            return `${flagClass} - ${name}`
        },
        addBankAccountSubmit(){
            console.log("submited add mobile number");
        }
    }
}
</script>