<template>
    <div class="payment-link-page campaign-list-page">
        <div class="page-header page-filter">
            <div class="container-fluid">
                <div class="row clearfix">
                    <div class="col-12">
                        <div class="d-flex justify-content-between align-items-center">
                            <div class="left-label"><h5><i class="fa fa-arrow-circle-left text-apple-green"></i> PayAngel Checkout</h5></div>
                            <h6 class="mb-0">Already have an account? <router-link to="/authentication/page-login" class="text-apple-green"> Log in</router-link></h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="page-content mt-4">
            <div class="container-fluid">
                <slot></slot>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "GenericPaymentLayout"
}
</script>