<template>
    <div class="container-fluid">
        <div class="block-header">
            <BreadCrumb></BreadCrumb>
        </div>
        <div class="row clearfix justify-content-center">
           <div class="col-lg-10">
                <div class="card card-top-line no-shadow send-money-area">
                    <div class="header">
                        <h2>Jones, please fill transfer details</h2>
                    </div>
                    <div class="body">
                        <form class="search-form">
                            <div class="form-group">
                                <span class="float-label">
                                    <input type="text" id="recipientName" class="form-control" placeholder="Check availability at a location">
                                    <label for="recipientName" class="control-label">Check availability at a location</label>
                                    <div class="line"></div>
                                </span>
                            </div>
                            <button type="button" class="btn btn-default"><i class="fa fa-search font-16"></i></button>
                        </form>
                        <div class="row">
                            <div class="col-lg-6">
                                <branch_bank_list></branch_bank_list>
                            </div>
                            <div class="col-lg-6">
                                <div class="card border rounded no-shadow address-list">
                                    <ul class="list-unstyled mb-0">
                                        <li>
                                            <label>Street</label>
                                            <strong>Dominase RD</strong>
                                        </li>
                                        <li>
                                            <label>State/Province/Region</label>
                                            <strong>Kumasi, Ghana</strong>
                                        </li>
                                        <li>
                                            <label>Mobile</label>
                                            <strong>+233 30 221 6000</strong>
                                        </li>
                                    </ul>
                                </div>
                                <div class="card border rounded no-shadow">
                                    <GMapMap
                                        :center="center"
                                        :zoom="10"
                                        map-type-id="terrain"
                                        style="width: 100%; height: 298px"
                                    >
                                        <GMapCluster :zoomOnClick="true">
                                        <GMapMarker
                                            :key="index"
                                            v-for="(m, index) in markers"
                                            :position="m.position"
                                            :clickable="true"
                                            :draggable="true"
                                            @click="center = m.position"
                                        />
                                        </GMapCluster>
                                    </GMapMap>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex justify-content-end">
                            <a href="https://www.google.com/maps/@42.378341,-71.031337,8z?hl=en-US" class="btn btn-primary btn-lg btn-mw" target="blank">View in full screen map</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import BreadCrumb from '@/components/BreadCrumb.vue';
import branch_bank_list from '@/components/admin/send_money/send_myself/BranchBankList.vue';
export default {
    name:'SendMoneyByCashPickup',
    components: {
        BreadCrumb,
        branch_bank_list,
    },data(){
        return{
            center: {
                lat: 42.3596567,
                lng: -71.0528855
            },
            markers: [
                {
                    position: {
                        lat: 42.3596560,
                        lng: -71.0528850,
                    },
                }
            ],
        }
    }
}
</script>