<template>
    <div id="footer" class="main-footer">
        <div class="footer-links">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-xl-2 col-sm-4 col-12" v-for="(link,index) in footer_links" :key="index">
                        <h5>{{ link.heading }}</h5>
                        <ul class="list-unstyled">
                            <li v-for="(menu,index) in link.menus" :key="index"><a :href="menu.link" target="_blank">{{ menu.title }}</a></li>
                        </ul>
                    </div>
                    <div class="col-xl-2 col-sm-8 col-12">
                        <div class="footer-social">
                            <h5>{{ follow_Us.title }}</h5>
                            <ul class="list-unstyled d-flex align-items-center">
                                <li v-for="(image,index) in follow_Us.images" :key="index"><a :href="image.link" target="_blank"><img :src="image.name"></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-text">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <p v-html="footer_text.html"></p>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-bottom">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-6 col-12">
                        <p class="copy" v-html="footer_bottom.title"></p>
                    </div>
                    <div class="col-lg-6 col-12">
                        <ul class="list-unstyled d-flex align-items-center justify-content-center justify-content-lg-end">
                            <li v-for="(menu,index) in footer_bottom.menus" :key="index"><a :href="menu.link" target="_blank">{{ menu.title }}</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FooterComponent',
    data() {
        return {
            footer_links:[
                {
                    heading:'Send Money Online',
                    menus:[
                        {link:'#', title:'Send Money to Ghana'},
                        {link:'#', title:'Send Money to Nigeria'},
                        {link:'#', title:'Send Money to South Africa'},
                        {link:'#', title:'Send Money to Kenya'},
                        {link:'#', title:'See Other Supported Countries'},
                    ]
                },
                {
                    heading:'PayAngel',
                    menus:[
                        {link:'https://www.payangel.com/company-overview', title:'About Us'},
                        {link:'https://www.payangel.com/affiliates', title:'PayAngel Rewards'},
                        {link:'https://www.payangel.com/remitcare', title:'PayAngel RemitCare'},
                    ]
                },
                {
                    heading:'Help Centre',
                    menus:[
                        {link:'https://www.payangel.com/how-it-works', title:'How It Works'},
                        {link:'https://www.payangel.com/faq', title:'FAQ'},
                        {link:'https://www.payangel.com/security', title:'Security'},
                        {link:'https://www.payangel.com/customer-complaints', title:'Customer Complaints'},
                    ]
                },
                {
                    heading:'Interact With Us',
                    menus:[
                        {link:'https://www.payangel.com/contact-us', title:'Contact Us'},
                        {link:'https://www.payangel.com/blog', title:'Visit the PM Blog'},
                        {link:'https://www.payangel.com/partner/login', title:'Login / Register for Partners'},
                    ]
                }
            ],

            follow_Us:{
                title:'Follow Us',
                images:[
                    {link:'https://www.facebook.com/payangelmoney/', name:require(`@/assets/home_image/facebook.svg`)},
                    {link:'https://twitter.com/PayAngelMoney', name:require(`@/assets/home_image/twitter.svg`)},
                    {link:'https://www.instagram.com/payangelmoney/', name:require(`@/assets/home_image/instagram.svg`)},
                    {link:'https://www.linkedin.com/company/payangelmoney/', name:require(`@/assets/home_image/linkdin.svg`)},
                    {link:'https://www.youtube.com/channel/UChCgP2RQ0yW1Y5GToSGhSWQ', name:require(`@/assets/home_image/yt.svg`)},
                ]
            },

            footer_text:{
                html:'PAYANGEL®, PAYINC®, BUZZMONEY®, and MONEYTUBE® are trading names of PAYINC GROUP LIMITED, Registered in England and Wales; Registration Number: 8126178. PAYANGEL® is authorised by the Financial Conduct Authority (FCA) under the Payment Service Regulations 2009 for the provision of payment services (FRN: <span class="text-dark-cyan">608108</span>). Money Laundering Regulation Number (MSB): 12690216; Issued by HM Revenue & Customs (HMRC). <span class="text-dark-cyan">Money Laundering Statement</span>. Registered Address: Regus House, Victory Way, Admirals Park, Dartford, Kent, DA2 6QD, United Kingdom.</p><p>PAYANGEL® provides international money transfer from UK, Canada, Europe to sub-Sahara Africa.'
            },

            footer_bottom: {
                title:'Copyright © 2013 - 2024 PAYINC GROUP LIMITED. <br> All Right Reserved.',
                menus:[
                    {link:'https://www.payangel.com/terms-and-conditions', title:'Terms and Conditions'},
                    {link:'https://www.payangel.com/cookies-policy', title:'Cookies policy'},
                    {link:'https://www.payangel.com/privacy-policy', title:'Privacy Policy'},
                    {link:'https://www.payangel.com/acceptable-use-policy', title:'Acceptable Use Policy'}
                ]
            }
        }
    }
}
</script>

