<template>
  <div class="container-fluid">
    <div class="block-header">
      <BreadCrumb
        :showFilter="true"
        :showCountry="true"
        :showInputFilter="true"
        :showDateFilter="true"
      >
      </BreadCrumb>
    </div>
    <div class="row clearfix">
      <div class="col-12">
        <div class="card top_report card-top-line">
          <div class="row clearfix">
            <div
              class="col-lg-3 col-md-6 col-sm-6"
              v-for="(toprow, index) in transactions_toprows"
              :key="index"
            >
              <TransactionsTopRow
                :icon="toprow.icon"
                :text="toprow.text"
                :price="toprow.price"
                :progress_class="toprow.progress_class"
                :progressbar_width="toprow.progressbar_width"
                :compared_text="toprow.compared_text"
              ></TransactionsTopRow>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row clearfix">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <div class="card card-top-line">
          <div class="header">
            <h2>Recent transactions</h2>
          </div>
          <div class="body pt-0">
            <div
              class="d-flex align-items-center flex-column flex-md-row-reverse justify-content-center justify-content-md-start mb-3 payment-dropdown"
            >
              <b-dropdown id="payment" right size="lg" variant="primary btn-mw">
                <template #button-content>
                  Create Payment <i class="fa fa-plus-square"></i>
                </template>
                <b-dropdown-item href="">Single Payment</b-dropdown-item>
                <b-dropdown-item href="">Bulk Upload</b-dropdown-item>
              </b-dropdown>
              <b-dropdown
                class="mr-0 mr-md-3"
                id="download"
                text="Download"
                size="lg"
                variant="outline-primary btn-mw"
              >
                <template #button-content>
                  Download <i class="fa fa-download"></i>
                </template>
                <b-dropdown-item href="">Export as PDF</b-dropdown-item>
                <b-dropdown-item href="">Export as CSV</b-dropdown-item>
              </b-dropdown>
            </div>
            <b-table
              responsive
              hover
              table-class="js-basic-example table-custom mb-0 border"
              head-variant="light"
              :items="items"
              :fields="fields"
              :current-page="currentPage"
              :per-page="perPage"
              :sort-by="sortBy"
              :sort-desc="sortDesc"
              :sort-direction="sortDirection"
            >
              <!-- <template #head()="{label,  field: { key }}"> -->
              <div>
                {{ label }}
                <!-- Custom icons -->
                <div>
                  <i v-if="sortBy !== key" class=""></i>
                  <i
                    v-else-if="sortDesc"
                    class="fa fa-long-arrow-down ml-1"
                  ></i>
                  <i v-else class="fa fa-long-arrow-up ml-1"></i>
                </div>
              </div>
              <template #cell(dropdawn)="data">
                <select
                  v-model="data.value.selected"
                  size="sm"
                  class="btn btn-sm btn-filter"
                  :class="data.value.class"
                  @change="
                    onChange(
                      $event,
                      (currentPage - 1) * perPage + data.index + 1
                    )
                  "
                >
                  <option
                    v-for="(option, index) in options"
                    :key="index"
                    :value="option.value"
                  >
                    {{ option.text }}
                  </option>
                </select>
              </template>
              <template #cell(action)>
                <b-dropdown
                  id="dropdown-1"
                  text="Actions"
                  right
                  variant="outline-primary"
                >
                  <b-dropdown-item>Edit</b-dropdown-item>
                  <b-dropdown-item @click="showAlert">Delete</b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
            <!-- Table Pagination -->
            <div
              class="py-3 d-flex align-items-center justify-content-between table-pagination"
            >
              <div class="per-page">
                <b-form-group
                  label="Per page"
                  label-for="per-page-select"
                  class="mb-0"
                >
                  <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="sm"
                  ></b-form-select>
                </b-form-group>
              </div>
              <div class="number-page">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  hide-goto-end-buttons
                  hide-ellipsis
                  prev-text="Previous page"
                  next-text="Next page"
                  align="right"
                >
                </b-pagination>
              </div>
              <div class="go-page">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">Go To Page</span>
                  </div>
                  <input
                    type="text"
                    class="form-control"
                    value=""
                    @keyup.enter="onEnter"
                    onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <main_loader ref="loader"></main_loader>
  </div>
</template>
<script>
// import "element-ui/lib/theme-chalk/index.css";
// import 'vuen-daterange-picker/node_modules/element-ui/lib/theme-chalk/index.css'
import BreadCrumb from "@/components/BreadCrumb.vue";
import TransactionsTopRow from "@/components/core/TransactionsTopRow.vue";
export default {
  name: "TransactionsComponent",
  components: {
    BreadCrumb,
    TransactionsTopRow,
  },
  data() {
    return {
      transactions_toprows: [
        {
          icon: "fa-dollar",
          text: "Amount",
          price: "$22,500",
          progress_class: "bg-danger",
          progressbar_width: "87",
          compared_text: "19% compared to last week",
        },
        {
          icon: "fa-bar-chart-o",
          text: "Count",
          price: "$500",
          progress_class: "bg-success",
          progressbar_width: "28",
          compared_text: "19% compared to last week",
        },
        {
          icon: "fa-area-chart",
          text: "Avg Anount",
          price: "215",
          progress_class: "bg-info",
          progressbar_width: "41",
          compared_text: "19% compared to last week",
        },
        {
          icon: "fa-users",
          text: "No. of Customers",
          price: "21,215",
          progress_class: "bg-warning",
          progressbar_width: "75",
          compared_text: "19% compared to last week",
        },
      ],

      dashboardActions: [
        {
          icon_class: "fa fa-send-o",
          key: "Export as PDF",
          url: "javascript:void(0);",
        },
        {
          icon_class: "fa fa-send-o",
          key: "Export as CSV",
          url: "javascript:void(0);",
        },
      ],

      paymentActions: [
        {
          icon_class: "fa fa-send-o",
          key: "Single Payment",
          url: "javascript:void(0);",
        },
        {
          icon_class: "fa fa-send-o",
          key: "Bulk Upload",
          url: "javascript:void(0);",
        },
      ],

      options: [
        { value: "accept", text: "Accept" },
        { value: "cancel", text: "Cancel" },
      ],

      items: [
        {
          date: "21-July-2018 06:51:51",
          type: "Buy",
          amount: "0.58647",
          amount2: "0.58647",
          price: "11900.12",
          usd: "$ 1597.78",
          fees: "0.023",
          dropdawn: { selected: "accept", class: "btn-outline-success" },
          action: "",
        },
        {
          date: "22-July-2018 06:50:50",
          type: "Sell",
          amount: "1.38647",
          amount2: "0.38647",
          price: "11905.09",
          usd: "$ 2496.36",
          fees: "0.017",
          dropdawn: { selected: "accept", class: "btn-outline-success" },
          action: "",
        },
        {
          date: "23-July-2018 06:49:51",
          type: "Buy",
          amount: "0.45879",
          amount2: "0.45879",
          price: "11901.85",
          usd: "$ 3165.44",
          fees: "0.013",
          dropdawn: { selected: "accept", class: "btn-outline-success" },
          action: "",
        },
        {
          date: "24-July-2018 06:51:51",
          type: "Buy",
          amount: "0.89877",
          amount2: "0.89877",
          price: "11899.28",
          usd: "$ 25830.6",
          fees: "0.011",
          dropdawn: { selected: "accept", class: "btn-outline-success" },
          action: "",
        },
        {
          date: "25-July-2018 06:51:51",
          type: "Sell",
          amount: "0.45712",
          amount2: "0.45712",
          price: "11908.19",
          usd: "$ 2586.34",
          fees: "0.024",
          dropdawn: { selected: "cancel", class: "btn-outline-danger" },
          action: "",
        },
        {
          date: "26-July-2018 06:51:51",
          type: "Buy",
          amount: "0.58647",
          amount2: "0.58647",
          price: "11900.12",
          usd: "$ 1597.78",
          fees: "0.023",
          dropdawn: { selected: "accept", class: "btn-outline-success" },
          action: "",
        },
        {
          date: "27-July-2018 06:51:51",
          type: "Buy",
          amount: "0.58647",
          amount2: "0.58647",
          price: "11900.12",
          usd: "$ 1597.78",
          fees: "0.023",
          dropdawn: { selected: "accept", class: "btn-outline-success" },
          action: "",
        },
        {
          date: "28-July-2018 06:50:50",
          type: "Sell",
          amount: "1.38647",
          amount2: "0.38647",
          price: "11905.09",
          usd: "$ 2496.36",
          fees: "0.017",
          dropdawn: { selected: "accept", class: "btn-outline-success" },
          action: "",
        },
        {
          date: "29-July-2018 06:49:51",
          type: "Buy",
          amount: "0.45879",
          amount2: "0.45879",
          price: "11901.85",
          usd: "$ 3165.44",
          fees: "0.013",
          dropdawn: { selected: "accept", class: "btn-outline-success" },
          action: "",
        },
        {
          date: "30-July-2018 06:51:51",
          type: "Buy",
          amount: "0.89877",
          amount2: "0.89877",
          price: "11899.28",
          usd: "$ 25830.6",
          fees: "0.011",
          dropdawn: { selected: "accept", class: "btn-outline-success" },
          action: "",
        },
        {
          date: "31-July-2018 06:51:51",
          type: "Sell",
          amount: "0.45712",
          amount2: "0.45712",
          price: "11908.19",
          usd: "$ 2586.34",
          fees: "0.024",
          dropdawn: { selected: "cancel", class: "btn-outline-danger" },
          action: "",
        },
        {
          date: "01-Aug-2018 06:51:51",
          type: "Buy",
          amount: "0.58647",
          amount2: "0.58647",
          price: "11900.12",
          usd: "$ 1597.78",
          fees: "0.023",
          dropdawn: { selected: "accept", class: "btn-outline-success" },
          action: "",
        },
      ],

      fields: [
        { key: "date", label: "Date", sortable: true, thClass: "th_sortfix" },
        { key: "type", label: "Type", sortable: true, thClass: "th_sortfix" },
        {
          key: "amount",
          label: "Amount",
          sortable: true,
          thClass: "th_sortfix",
        },
        {
          key: "amount2",
          label: "Remaining",
          sortable: true,
          thClass: "th_sortfix",
        },
        { key: "price", label: "Price", sortable: true, thClass: "th_sortfix" },
        { key: "usd", label: "USD", sortable: true, thClass: "th_sortfix" },
        {
          key: "fees",
          label: "Fee (%)",
          sortable: true,
          thClass: "th_sortfix",
        },
        { key: "dropdawn", label: "Status" },
        { key: "action", label: "Action" },
        { sortable: true, sortByFormatted: true, filterByFormatted: true },
      ],

      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, { value: 1000, text: 1000 }],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
    };
  },
  methods: {
    onChange(event, index) {
      var classVar = "";
      return this.items.map((item, i) => {
        if (index == i + 1) {
          if (event.target.value == "accept") {
            classVar = "btn-outline-success";
          } else if (event.target.value == "cancel") {
            classVar = "btn-outline-danger";
          }
          item.dropdawn.class = classVar;
        }
      });
    },

    onEnter(e) {
      var paginationMenu = Math.ceil(this.totalRows / this.perPage);
      if (e.target.value > 0) {
        if (paginationMenu >= e.target.value) {
          this.currentPage = e.target.value;
        }
      }
    },

    showAlert() {
      this.$swal({
        icon: "warning",
        title: "Are you sure",
        text: `You won't be able to revert this!`,
        confirmButtonText: "Yes, delete it!",
        showCancelButton: true,
        showConfirmButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$router.push({ path: "/admin/setting/setup-profile" });
        }
      });
    },
  },
  mounted() {
    console.log(this.$route.params);
    this.totalRows = this.items.length;
  },
};
</script>
