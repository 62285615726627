<template>
    <div class="card card-top-line">
        <div class="header d-flex align-items-center justify-content-between">
            <h2>Compliance Declarations</h2>
            <i class="fa fa-edit font-20" @click="editDetails()"></i>
        </div>
        <div class="body">
            <div class="edit-form">
                <h6 class="mb-3 font-15"><i class="fa fa-level-up fa-rotate-90"></i> <strong>Almost there, just a few more regulatory and compliance stuff</strong></h6>
                    <form @submit.prevent="complianceDeclarationsForm" id="business-structure" ref="form">
                        <div class="d-flex align-items-center justify-content-between mb-3" :class="{'switch-disable':complianceDec.readonlyMode}">
                            <p class="mb-0 mr-3 custom-switch-text">Have you or anyone involved in the {{businessType}} been convicted or facing the possibility of conviction for a crime? </p>
                            <div class="custom-switch success-handle">
                                <input type="checkbox" class="custom-switch-input" id="isPossibilityCrime" v-model="complianceDec.isPossibilityCrime" :disabled="complianceDec.readonlyMode">
                                <label class="custom-switch-label" for="isPossibilityCrime">
                                    <span class="custom-switch-text1">Yes</span>
                                    <span class="custom-switch-text2">No</span>
                                </label>
                            </div>
                        </div>
                        <div class="d-flex align-items-center justify-content-between mb-3" :class="{'switch-disable':complianceDec.readonlyMode}">
                            <p class="mb-0 mr-3 custom-switch-text">Are you or anyone involved in the {{businessType}} considered a politically exposed person?</p>
                            <div class="custom-switch success-handle">
                                <input type="checkbox" class="custom-switch-input" id="isPoliticallyExposed" v-model="complianceDec.isPoliticallyExposed" :disabled="complianceDec.readonlyMode">
                                <label class="custom-switch-label" for="isPoliticallyExposed">
                                    <span class="custom-switch-text1">Yes</span>
                                    <span class="custom-switch-text2">No</span>
                                </label>
                            </div>
                        </div>
                        <div class="d-flex align-items-center justify-content-between mb-3" :class="{'switch-disable':complianceDec.readonlyMode}">
                            <p class="mb-0 mr-3 custom-switch-text">Have you or anyone involved in the {{businessType}} been declared insolvent or bankrupt?</p>
                            <div class="custom-switch success-handle">
                                <input type="checkbox" class="custom-switch-input" id="isBankrupt" v-model="complianceDec.isBankrupt" :disabled="complianceDec.readonlyMode">
                                <label class="custom-switch-label" for="isBankrupt">
                                    <span class="custom-switch-text1">Yes</span>
                                    <span class="custom-switch-text2">No</span>
                                </label>
                            </div>
                        </div>
                        <div class="d-flex align-items-center justify-content-between mb-3" :class="{'switch-disable':complianceDec.readonlyMode}">
                            <p class="mb-0 mr-3 custom-switch-text">Are you or anyone involved in the {{businessType}} involved in terrorism or terrorism financing?</p>
                            <div class="custom-switch success-handle">
                                <input type="checkbox" class="custom-switch-input" id="isTerrorism" v-model="complianceDec.isTerrorism" :disabled="complianceDec.readonlyMode">
                                <label class="custom-switch-label" for="isTerrorism">
                                    <span class="custom-switch-text1">Yes</span>
                                    <span class="custom-switch-text2">No</span>
                                </label>
                            </div>
                        </div>
                        <div class="d-flex align-items-center justify-content-between mb-3" :class="{'switch-disable':complianceDec.readonlyMode}">
                            <p class="mb-0 mr-3 custom-switch-text">Are you or anyone involved in the {{businessType}} involved in money laundering?</p>
                            <div class="custom-switch success-handle">
                                <input type="checkbox" class="custom-switch-input" id="isLaundering" v-model="complianceDec.isLaundering" :disabled="complianceDec.readonlyMode">
                                <label class="custom-switch-label" for="isLaundering">
                                    <span class="custom-switch-text1">Yes</span>
                                    <span class="custom-switch-text2">No</span>
                                </label>
                            </div>
                        </div>
                        <div class="d-flex align-items-center justify-content-between mb-3" :class="{'switch-disable':complianceDec.readonlyMode}">
                            <p class="mb-0 mr-3 custom-switch-text">Are you or anyone involved in the {{businessType}} involved in dealing in arms or weapons?</p>
                            <div class="custom-switch success-handle">
                                <input type="checkbox" class="custom-switch-input" id="isWeapons" v-model="complianceDec.isWeapons" :disabled="complianceDec.readonlyMode">
                                <label class="custom-switch-label" for="isWeapons">
                                    <span class="custom-switch-text1">Yes</span>
                                    <span class="custom-switch-text2">No</span>
                                </label>
                            </div>
                        </div>
                        <div v-if="!complianceDec.readonlyMode" class="justify-content-center d-flex">
                            <b-button size="lg" @click="complianceDeclarationsForm()" class="btn-mw">Cancel</b-button>
                            <b-button type="submit" variant="primary ml-1 btn-mw" size="lg">Update</b-button>
                        </div>
                    </form>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ComplianceDeclarations-Profile',
    props: {
        businessType: {
            type: String,
            default: function () {
                return 'Business'
            }
        },
    },
    data(){
        return{
            complianceDec:{
                readonlyMode: true,
                complianceDecSubmit:false,
                isPossibilityCrime:false,
                isPoliticallyExposed:false,
                isBankrupt:false,
                isTerrorism:false,
                isLaundering:false,
                isWeapons:false,
            },
        }
    },methods:{
        editDetails(){
            this.complianceDec.readonlyMode = false
        },

        //submit Personal Details information
        complianceDeclarationsForm(){
            this.complianceDec.readonlyMode  = true
        },
    }
}
</script>
