<template>
    <div class="container-fluid">
        <div class="block-header">
            <BreadCrumb></BreadCrumb>
        </div>
        <div class="row clearfix justify-content-center">
            <div class="col-lg-6 col-md-10">
                <div class="card card-top-line no-shadow">
                    <div class="card-body">
                       <div class="invoice-search">
                            <form class="search-form">
                                <div class="form-group mb-0">
                                    <span class="float-label">
                                        <input type="text" id="businessName" class="form-control" placeholder="Search Business / Organisation" v-model="searchQuery" @input="showLoadingScreen">
                                        <label for="businessName" class="control-label">Search Business / Organisation</label>
                                        <div class="line"></div>
                                    </span>
                                </div>
                                <button type="button" class="btn btn-default"><i class="fa fa-search font-16"></i></button>
                            </form>
                        </div>
                        <ul class="list-unstyled mb-0 search-list no-search-list">
                            <li>
                                <a href="#" v-b-modal.add_business class="btn btn-add-new-recipients d-flex align-items-center justify-content-start w-100">
                                    <i class="fa fa-plus"></i> Add new Business / Organisation
                                </a>
                            </li>
                        </ul>
                        <ul class="list-unstyled mb-0 search-list" v-if="!searchQuery">
                            <li class="title-label">
                                <h6 class="d-inline">Saved Business / Organisation</h6>
                            </li>
                            <li v-for="(business,index) in allBusiness" :key="index">
                                <div class="search-list-area d-flex justify-content-between align-items-center" :class="{'active': business.activeBusiness}">
                                    <div class="user-img-name d-flex align-items-center justify-content-between" @click="setActiveClassOn(business)">
                                        <div class="img-name-grp d-flex align-items-center">
                                            <div class="media-object flag-img mr-3 rounded-circle border p-1">
                                                <img :src="business.orgImage" alt="" width="40" class="rounded-circle">
                                                <span :class="business.flagClass" class="right-0"></span>
                                            </div>
                                            <h5 class="mb-0">{{ business.name }}</h5>
                                        </div>
                                        <div class="ml-3">
                                            <span>{{ business.accounts }} Linked Account</span>
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center">
                                        <div class="font-0 right-icon">
                                            <i v-b-modal.edit_business class="ml-3">
                                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M11 20.625C8.44729 20.625 5.99913 19.6109 4.1941 17.8059C2.38906 16.0009 1.375 13.5527 1.375 11C1.375 8.44729 2.38906 5.99913 4.1941 4.1941C5.99913 2.38906 8.44729 1.375 11 1.375C13.5527 1.375 16.0009 2.38906 17.8059 4.1941C19.6109 5.99913 20.625 8.44729 20.625 11C20.625 13.5527 19.6109 16.0009 17.8059 17.8059C16.0009 19.6109 13.5527 20.625 11 20.625ZM11 22C13.9174 22 16.7153 20.8411 18.7782 18.7782C20.8411 16.7153 22 13.9174 22 11C22 8.08262 20.8411 5.28473 18.7782 3.22183C16.7153 1.15893 13.9174 0 11 0C8.08262 0 5.28473 1.15893 3.22183 3.22183C1.15893 5.28473 0 8.08262 0 11C0 13.9174 1.15893 16.7153 3.22183 18.7782C5.28473 20.8411 8.08262 22 11 22Z" fill="#7D858C"/>
                                                    <path d="M13.5908 6.0918C13.6198 6.0627 13.6543 6.03962 13.6923 6.02386C13.7302 6.00811 13.7709 6 13.812 6C13.8531 6 13.8938 6.00811 13.9318 6.02386C13.9698 6.03962 14.0043 6.0627 14.0333 6.0918L15.9082 7.96672C15.9373 7.99575 15.9604 8.03023 15.9761 8.0682C15.9919 8.10616 16 8.14686 16 8.18796C16 8.22907 15.9919 8.26976 15.9761 8.30773C15.9604 8.34569 15.9373 8.38018 15.9082 8.4092L9.65847 14.6589C9.62848 14.6887 9.59276 14.7121 9.55347 14.7277L6.42861 15.9776C6.37182 16.0004 6.30961 16.0059 6.24969 15.9936C6.18977 15.9813 6.13478 15.9517 6.09153 15.9085C6.04827 15.8652 6.01867 15.8102 6.00637 15.7503C5.99408 15.6904 5.99965 15.6282 6.02238 15.5714L7.27232 12.4465C7.2879 12.4072 7.31129 12.3715 7.34107 12.3415L13.5908 6.0918ZM13.0039 7.56299L14.437 8.99605L15.2451 8.18796L13.812 6.7549L13.0039 7.56299ZM13.9952 9.43791L12.5621 8.00485L8.49977 12.0672V12.2503H8.81226C8.89513 12.2503 8.97461 12.2832 9.03322 12.3418C9.09182 12.4004 9.12474 12.4799 9.12474 12.5628V12.8753H9.43723C9.5201 12.8753 9.59959 12.9082 9.65819 12.9668C9.71679 13.0254 9.74971 13.1049 9.74971 13.1877V13.5002H9.93283L13.9952 9.43791ZM7.8948 12.6721L7.82855 12.7384L6.87359 15.1264L9.26161 14.1715L9.32786 14.1052C9.26825 14.0829 9.21686 14.043 9.18056 13.9907C9.14426 13.9385 9.12479 13.8764 9.12474 13.8127V13.5002H8.81226C8.72938 13.5002 8.6499 13.4673 8.59129 13.4087C8.53269 13.3501 8.49977 13.2706 8.49977 13.1877V12.8753H8.18728C8.12365 12.8752 8.06154 12.8557 8.00928 12.8194C7.95701 12.7831 7.91707 12.7318 7.8948 12.6721Z" fill="#7D858C"/>
                                                </svg>
                                            </i>
                                            <i v-b-modal.view_profile class="ml-3">
                                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M6 12.5C5.60218 12.5 5.22064 12.342 4.93934 12.0607C4.65804 11.7794 4.5 11.3978 4.5 11C4.5 10.6022 4.65804 10.2206 4.93934 9.93934C5.22064 9.65804 5.60218 9.5 6 9.5C6.39782 9.5 6.77936 9.65804 7.06066 9.93934C7.34196 10.2206 7.5 10.6022 7.5 11C7.5 11.3978 7.34196 11.7794 7.06066 12.0607C6.77936 12.342 6.39782 12.5 6 12.5ZM11 12.5C10.6022 12.5 10.2206 12.342 9.93934 12.0607C9.65804 11.7794 9.5 11.3978 9.5 11C9.5 10.6022 9.65804 10.2206 9.93934 9.93934C10.2206 9.65804 10.6022 9.5 11 9.5C11.3978 9.5 11.7794 9.65804 12.0607 9.93934C12.342 10.2206 12.5 10.6022 12.5 11C12.5 11.3978 12.342 11.7794 12.0607 12.0607C11.7794 12.342 11.3978 12.5 11 12.5ZM16 12.5C15.6022 12.5 15.2206 12.342 14.9393 12.0607C14.658 11.7794 14.5 11.3978 14.5 11C14.5 10.6022 14.658 10.2206 14.9393 9.93934C15.2206 9.65804 15.6022 9.5 16 9.5C16.3978 9.5 16.7794 9.65804 17.0607 9.93934C17.342 10.2206 17.5 10.6022 17.5 11C17.5 11.3978 17.342 11.7794 17.0607 12.0607C16.7794 12.342 16.3978 12.5 16 12.5Z" fill="#7D858C"/>
                                                </svg>
                                            </i>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <ul class="list-unstyled mb-0 search-list" v-if="searchQuery" :class="loadingSearch ? 'search-list-recipient':'search-list-blank'">
                            <li class="title-label" v-if="businessList.length != 0">
                                <h6 class="d-inline">Saved Business / Organisation</h6>
                            </li>
                            <li v-for="(business,index) in businessList" :key="index">
                                <div class="search-list-area d-flex justify-content-between align-items-center" :class="{'active': business.activeBusiness}">
                                    <template v-if="loadingSearch">
                                        <div class="user-img-name d-flex align-items-center justify-content-between" @click="setActiveClassOn(business)">
                                            <div class="img-name-grp d-flex align-items-center">
                                                <div class="media-object flag-img mr-3 rounded-circle border p-1">
                                                    <img :src="business.orgImage" alt="" width="40px" class="rounded-circle">
                                                    <span :class="business.flagClass" class="right-0"></span>
                                                </div>
                                                <h5 class="mb-0">{{ business.name }}</h5>
                                            </div>
                                            <div class="ml-3">
                                                <span>{{ business.accounts }} Linked Account</span>
                                            </div>
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <div class="font-0 right-icon">
                                                <i v-b-modal.edit_business class="ml-3">
                                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M11 20.625C8.44729 20.625 5.99913 19.6109 4.1941 17.8059C2.38906 16.0009 1.375 13.5527 1.375 11C1.375 8.44729 2.38906 5.99913 4.1941 4.1941C5.99913 2.38906 8.44729 1.375 11 1.375C13.5527 1.375 16.0009 2.38906 17.8059 4.1941C19.6109 5.99913 20.625 8.44729 20.625 11C20.625 13.5527 19.6109 16.0009 17.8059 17.8059C16.0009 19.6109 13.5527 20.625 11 20.625ZM11 22C13.9174 22 16.7153 20.8411 18.7782 18.7782C20.8411 16.7153 22 13.9174 22 11C22 8.08262 20.8411 5.28473 18.7782 3.22183C16.7153 1.15893 13.9174 0 11 0C8.08262 0 5.28473 1.15893 3.22183 3.22183C1.15893 5.28473 0 8.08262 0 11C0 13.9174 1.15893 16.7153 3.22183 18.7782C5.28473 20.8411 8.08262 22 11 22Z" fill="#7D858C"/>
                                                        <path d="M13.5908 6.0918C13.6198 6.0627 13.6543 6.03962 13.6923 6.02386C13.7302 6.00811 13.7709 6 13.812 6C13.8531 6 13.8938 6.00811 13.9318 6.02386C13.9698 6.03962 14.0043 6.0627 14.0333 6.0918L15.9082 7.96672C15.9373 7.99575 15.9604 8.03023 15.9761 8.0682C15.9919 8.10616 16 8.14686 16 8.18796C16 8.22907 15.9919 8.26976 15.9761 8.30773C15.9604 8.34569 15.9373 8.38018 15.9082 8.4092L9.65847 14.6589C9.62848 14.6887 9.59276 14.7121 9.55347 14.7277L6.42861 15.9776C6.37182 16.0004 6.30961 16.0059 6.24969 15.9936C6.18977 15.9813 6.13478 15.9517 6.09153 15.9085C6.04827 15.8652 6.01867 15.8102 6.00637 15.7503C5.99408 15.6904 5.99965 15.6282 6.02238 15.5714L7.27232 12.4465C7.2879 12.4072 7.31129 12.3715 7.34107 12.3415L13.5908 6.0918ZM13.0039 7.56299L14.437 8.99605L15.2451 8.18796L13.812 6.7549L13.0039 7.56299ZM13.9952 9.43791L12.5621 8.00485L8.49977 12.0672V12.2503H8.81226C8.89513 12.2503 8.97461 12.2832 9.03322 12.3418C9.09182 12.4004 9.12474 12.4799 9.12474 12.5628V12.8753H9.43723C9.5201 12.8753 9.59959 12.9082 9.65819 12.9668C9.71679 13.0254 9.74971 13.1049 9.74971 13.1877V13.5002H9.93283L13.9952 9.43791ZM7.8948 12.6721L7.82855 12.7384L6.87359 15.1264L9.26161 14.1715L9.32786 14.1052C9.26825 14.0829 9.21686 14.043 9.18056 13.9907C9.14426 13.9385 9.12479 13.8764 9.12474 13.8127V13.5002H8.81226C8.72938 13.5002 8.6499 13.4673 8.59129 13.4087C8.53269 13.3501 8.49977 13.2706 8.49977 13.1877V12.8753H8.18728C8.12365 12.8752 8.06154 12.8557 8.00928 12.8194C7.95701 12.7831 7.91707 12.7318 7.8948 12.6721Z" fill="#7D858C"/>
                                                    </svg>
                                                </i>
                                                <i v-b-modal.view_profile class="ml-3">
                                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M6 12.5C5.60218 12.5 5.22064 12.342 4.93934 12.0607C4.65804 11.7794 4.5 11.3978 4.5 11C4.5 10.6022 4.65804 10.2206 4.93934 9.93934C5.22064 9.65804 5.60218 9.5 6 9.5C6.39782 9.5 6.77936 9.65804 7.06066 9.93934C7.34196 10.2206 7.5 10.6022 7.5 11C7.5 11.3978 7.34196 11.7794 7.06066 12.0607C6.77936 12.342 6.39782 12.5 6 12.5ZM11 12.5C10.6022 12.5 10.2206 12.342 9.93934 12.0607C9.65804 11.7794 9.5 11.3978 9.5 11C9.5 10.6022 9.65804 10.2206 9.93934 9.93934C10.2206 9.65804 10.6022 9.5 11 9.5C11.3978 9.5 11.7794 9.65804 12.0607 9.93934C12.342 10.2206 12.5 10.6022 12.5 11C12.5 11.3978 12.342 11.7794 12.0607 12.0607C11.7794 12.342 11.3978 12.5 11 12.5ZM16 12.5C15.6022 12.5 15.2206 12.342 14.9393 12.0607C14.658 11.7794 14.5 11.3978 14.5 11C14.5 10.6022 14.658 10.2206 14.9393 9.93934C15.2206 9.65804 15.6022 9.5 16 9.5C16.3978 9.5 16.7794 9.65804 17.0607 9.93934C17.342 10.2206 17.5 10.6022 17.5 11C17.5 11.3978 17.342 11.7794 17.0607 12.0607C16.7794 12.342 16.3978 12.5 16 12.5Z" fill="#7D858C"/>
                                                    </svg>
                                                </i>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </li>
                        </ul>
                        <ul class="list-unstyled mb-0 search-list no-search-list" v-if="businessList.length == 0 && searchQuery">
                            <li class="title-label">
                                <h6>No business / organisation found</h6>
                            </li>
                        </ul>
                        <div class="align-items-end d-flex flex-column justify-content-end mt-3 w-100" v-if="count == 1">
                            <router-link :to="{ path: '/admin/send-money/send-money-by',query:{ type: 'payangel-wallet', from: 'business-organization' }}" class="btn btn-primary btn-mw btn-lg">Continue</router-link>
                        </div>
                        <div class="align-items-end d-flex flex-column justify-content-end mt-3 w-100" v-if="count > 1">
                            <router-link :to="{ path: '/admin/send-money/business-details',query:{ type: 'payangel-wallet', from: 'business-organization' }}" class="btn btn-primary btn-mw btn-lg">Continue</router-link>
                        </div>
                    </div>
                </div>
                <!-- Business Add -->
                <add_business></add_business>
                <!-- Business Edit -->
                <edit_business></edit_business>
                <!-- Business Profile -->
                <business_profile></business_profile>
                <!-- Business Warning -->
                <business_warning></business_warning>
            </div>
        </div>
    </div>
</template>
<script>
import BreadCrumb from '@/components/BreadCrumb.vue';
import add_business from '@/components/admin/send_money/business_organisation/modals/AddBusiness.vue';
import edit_business from '@/components/admin/send_money/business_organisation/modals/EditBusiness.vue';
import business_profile from '@/components/admin/send_money/business_organisation/modals/BusinessProfile.vue';
import business_warning from '@/components/admin/send_money/business_organisation/modals/BusinessWarning.vue';
export default {
    name:'BusinessList',
    components: {
        BreadCrumb,
        add_business,
        edit_business,
        business_profile,
        business_warning,
    },data(){
        return{
            allBusiness: [
                {
                    id:0,
                    orgImage:require('@/assets/img/business/business-1.png'),
                    flagClass: "flag-icon flag-icon-ca fi-md fi-round",
                    name:'N-Web infoweb',
                    accounts:1,
                    activeBusiness:false,
                    isFilledDetails:false,
                    mobileMoneyId:null,
                    bankId:null,
                    casepickupId:null,
                    airtimeId:null,
                    transferAmountIs:false
                },
                {
                    id:1,
                    orgImage:require('@/assets/img/business/business-2.png'),
                    flagClass: "flag-icon flag-icon-gb fi-md fi-round",
                    name:'Caty Web Solution',
                    accounts:1,
                    activeBusiness:false,
                    isFilledDetails:false,
                    mobileMoneyId:null,
                    bankId:null,
                    casepickupId:null,
                    airtimeId:null,
                    transferAmountIs:false
                },
                {
                    id:2,
                    orgImage:require('@/assets/img/business/business-3.png'),
                    flagClass: "flag-icon flag-icon-us fi-md fi-round",
                    name:'E-Way infoweb Inc.',
                    accounts:2,
                    activeBusiness:false,
                    isFilledDetails:false,
                    mobileMoneyId:null,
                    bankId:null,
                    casepickupId:null,
                    airtimeId:null,
                    transferAmountIs:false
                },
                {
                    id:3,
                    orgImage:require('@/assets/img/business/business-1.png'),
                    flagClass: "flag-icon flag-icon-eu fi-md fi-round",
                    name:'Caty Web Solution',
                    accounts:1,
                    activeBusiness:false,
                    isFilledDetails:false,
                    mobileMoneyId:null,
                    bankId:null,
                    casepickupId:null,
                    airtimeId:null,
                    transferAmountIs:false
                },
                {
                    id:4,
                    orgImage:require('@/assets/img/business/business-2.png'),
                    flagClass: "flag-icon flag-icon-fr fi-md fi-round",
                    name:'E-Way infoweb Inc.',
                    accounts:4,
                    activeBusiness:false,
                    isFilledDetails:false,
                    mobileMoneyId:null,
                    bankId:null,
                    casepickupId:null,
                    airtimeId:null,
                    transferAmountIs:false
                } 
            ],
            
            searchQuery: null,
            loadingSearch:false,
            count:0,
        }
    },methods:{
        showLoadingScreen(e){
            if(e.target.value){
                setTimeout(() => {
                    this.loadingSearch = true 
                }, 2000);
            }
            this.loadingSearch = false
        },

        setActiveClassOn(selectedBusiness){
            this.allBusiness.map((item)=>{
                if(item.id === selectedBusiness.id){
                    item.activeBusiness = !item.activeBusiness
                } 
            })

            this.count = this.allBusiness.filter(( item ) => item.activeBusiness).length
            if(this.count > 3) {
                selectedBusiness.activeBusiness = false
                this.$bvModal.show('business_warning');
            }
            let getActiveUser = this.allBusiness.filter(( item ) => item.activeBusiness)
            window.sessionStorage.setItem('allBusiness',JSON.stringify(getActiveUser));
        }
    },computed: {
        businessList() {
            if (!this.searchQuery) return this.allBusiness
            let searchText = this.searchQuery.toLowerCase()
            return this.allBusiness.filter(p => {
                return p.name.toLowerCase().includes(searchText)  
            })
        }
    },mounted(){
        if(window.sessionStorage.getItem('allBusiness')){
            window.sessionStorage.removeItem('allBusiness');
        }
        if(window.sessionStorage.getItem('activeTab')){
            window.sessionStorage.removeItem('activeTab');
        }
    }
}
</script>