<template>
    <div class="container-fluid">
        <!-- Bread-crumb-->
        <div class="block-header">
            <BreadCrumb></BreadCrumb>
        </div>
        <!-- All Acounts-->
        <div class="row clearfix">
            <div class="col-12">
                <div class="card tab-card p-3 no-shadow">
                    <div class="header p-0 d-flex align-items-center justify-content-between">
                        <h2>All Accounts</h2>
                        <!-- Select Currencies/Pay or transfer/Add Account list/grid Buttons -->
                        <div class="btn-grp d-flex">
                            <!-- Pay or Transfer Button Component-->
                            <!-- <PayorTransferModel></PayorTransferModel> -->
                            <!-- Add Account Model Button-->
                            <b-button size="lg" variant="outline-primary m-1"  v-b-modal.add_account><i class="fa fa-plus"></i></b-button>
                            <b-modal id="add_account" title="Add Wallet" hide-footer no-stacking no-close-on-backdrop @shown="resetForm">
                                <div class="modal-area"> 
                                    <Form class="form-auth-small" @submit="addWalletAccount" :validation-schema="schema" v-slot="{ errors }" id="addWallet" ref="addWallet" autocomplete="off">
                                        <div class="row">
                                            <!-- Currency -->
                                            <div class="col-12">
                                                <Field name="select_currency" :rules="{'required':!account.currency}" v-slot="{ handleChange }">
                                                    <div class="country-dropdown">
                                                        <div class="form-group form-select multiselect-grp" :class="{'errors':errors.select_currency && !account.currency,'success':!errors.select_currency && account.currency}">
                                                            <span class="float-label">
                                                            <label for="Currency">Select Currency*</label>
                                                                <multiselect
                                                                    placeholder=""
                                                                    v-model="account.currency"
                                                                    :show-labels="false" 
                                                                    :options="currency_options" 
                                                                    :searchable="true" 
                                                                    :custom-label="currencyFlag"
                                                                    label="name" 
                                                                    track-by="name"
                                                                    @input="handleChange">
                                                                    <template v-slot:singleLabel="{ option }">
                                                                        {{ option.name }}
                                                                    </template>
                                                                    <template v-slot:option="{ option }">
                                                                        <span :class="option.flagClass"></span>
                                                                        <div class="option__desc">
                                                                            <span class="option__title">{{ option.name }}</span>
                                                                        </div>
                                                                    </template>
                                                                </multiselect> 
                                                                <i class="fa fa-times" v-if="errors.select_currency && !account.currency"></i>
                                                                <i class="fa fa-check" v-if="!errors.select_currency && account.currency"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </Field>
                                            </div>
                                            <!-- Business Name -->
                                            <div class="col-12">
                                                <Field rules="required" name="account_name" v-slot="{ handleChange }">
                                                    <div class="form-group" :class="{'errors': errors && errors.account_name,'success':!errors && !errors.account_name}">
                                                        <span class="float-label">
                                                            <input type="text" id="account_name" v-model="account.accountName" @update:model-value="handleChange" class="form-control" placeholder="Account Name*">
                                                            <label for="account_name" class="control-label">Account Name*</label>
                                                            <div class="line"></div>
                                                            <i class="fa fa-times" v-if="errors && errors.account_name"></i>
                                                            <i class="fa fa-check" v-if="account.accountName && !errors.account_name"></i>
                                                        </span>
                                                        <span class="text-danger error-msg" v-if="errors[0]">Please provide unique Account Name</span>
                                                    </div>
                                                </Field>
                                            </div>
                                        </div>
                                        <div class="align-items-center d-flex flex-column justify-content-between mt-3 w-100">
                                            <b-button type="submit" block class="btn btn-primary btn-mw btn-lg" size="lg">Add Wallet</b-button>
                                            <b-button type="button" block class="btn btn-lg btn-outline-secondary" size="lg" @click="$bvModal.hide('add_account')">Cancel</b-button>
                                        </div>
                                    </Form>
                                </div>
                            </b-modal>
                            <!--  list/grid Buttons -->
                            <b-button class="ml-2" size="lg" :variant="activeTab == 'grid' ? 'primary' : 'outline-primary'" @click="isActiveTab('grid')"><i class="fa fa-th-large"></i></b-button>
                            <b-button class="ml-2" size="lg" :variant="activeTab == 'list' ? 'primary' : 'outline-primary'"  @click="isActiveTab('list')"><i class="fa fa-bars"></i></b-button>
                        </div>
                    </div>

                    <div class="body px-0" v-if="totalRecord < 1">
                        <div class="align-items-center body d-flex flex-column flex-md-row justify-content-between">
                            <p class="font-18 mb-0 mb-5 mb-md-0 w-50">
                                <strong class="d-block font-28">Welcome to PayAngel Business</strong>
                                <strong class="d-block font-24 mt-2">You Don't have any Data yet</strong>
                                Create your wallet account to get Started
                            </p>
                            <img src="@/assets/not-data.svg" width="500" alt="" class="img-fluid">
                        </div>
                    </div>

                    <div class="body px-0" v-if="totalRecord >= 1">
                        <!-- Grid View -->
                        <div class="row grid-view" v-if="activeTab == 'grid'" :class="{'active show': activeTab == 'grid'}">
                            <div class="col-lg-6" v-for="(account_card,index) in account_cards" :key="index">
                                <div class="card card-top-line">
                                    <div class="body d-flex align-items-stretch">
                                        <span :class="account_card.img_class+' fi-xl '"></span>
                                        <div class="content w-100 ml-3">
                                            <div class="d-flex align-items-center justify-content-between">
                                                <h6 class="mb-0">{{ account_card.name }} <i @click="editAccountModal(account_card)" class="fa fa-edit ml-1 text-primary cursor-pointer"></i></h6>
                                                <!-- <label class="fancy-radio"><input type="radio" name="default" :value="account_card.isDefault" :checked="account_card.isDefault" @click="selectAsDefault(account_card)"><span><i></i></span></label> -->
                                            </div>
                                            <span class="d-block text-muted">{{ account_card.currency }} <span v-if="account_card.isDefault" >( Default Account )</span></span>
                                            <div class="d-flex flex-wrap justify-content-between align-items-center pt-4">
                                                <h4 class="number mr-4">
                                                    <!--  :to="getTransaction(account_card.name)" -->
                                                    <router-link class="text-muted"  to="#">{{ account_card.currency_symbol }} {{ account_card.number }}</router-link>
                                                    <!-- {{ formateNumbers(parseFloat(list.amount)) }} -->
                                                </h4>
                                                <div>
                                                    <button class="btn btn-primary btn-sm mb-1" @click="addMoneyModal(account_card)" type="button">Add Money</button>
                                                    <button class="btn btn-secondary btn-sm mb-1 ml-1 mr-1" type="button" @click="openSwapMoneyModal(account_card)" >Swap Money</button>
                                                    <!-- <button class="btn btn-secondary btn-sm mb-1" type="button">Withdraw Money</button> -->
                                                </div>
                                                <!-- <b-button  :variant="account_card.isEnableColor" size="sm">{{ account_card.isEnableText }}</b-button> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row list-view" v-else :class="{'active show': activeTab == 'list'}">
                            <div class="col-12">
                                <b-table 
                                    responsive
                                    hover 
                                    outlined
                                    table-class="js-basic-example table-custom mb-0"
                                    head-variant="light"
                                    :items="items" 
                                    :fields="fields"
                                    :current-page="currentPage"
                                    :per-page="perPage"
                                    :sort-by="sortBy"
                                    :sort-desc="sortDesc"
                                    :sort-direction="sortDirection"
                                    :emptyText="'No data found.'" show-empty>
                                    <!-- Custom icons -->
                                    <!-- #head()="{label,  field: { key }}" -->
                                    {{ label }}
                                    <div>
                                        <i v-if="sortBy !== key" class=""></i>
                                        <i v-else-if="sortDesc" class="fa fa-long-arrow-down ml-1"></i>
                                        <i v-else class="fa fa-long-arrow-up ml-1"></i>
                                    </div>  
                                    <template #cell(currency)="data">
                                        <div class="d-flex align-items-center">
                                            <span :class="data.value.flagClass" class="mr-2"></span>
                                            <span>{{ data.value.cname }}</span>
                                        </div>
                                    </template>
                                    <div>
                                        <b-button variant="outline-primary" size="sm" v-b-tooltip.hover :title="Math.floor(Math.random()*90000) + 10000">
                                            <i class="fa fa-2x fa-barcode"></i>
                                        </b-button>
                                    </div>
                                    <template #cell(action)="row">
                                        <b-dropdown id="dropdown-1" text="Actions" right variant="outline-primary">
                                            <b-dropdown-item  @click="editAccountModal(row)">Edit</b-dropdown-item>
                                            <!-- <b-dropdown-item>Delete</b-dropdown-item> -->
                                        </b-dropdown>
                                    </template>
                                    <template #cell(status)="data">
                                        <select v-model="data.value.selected" size="sm" class="btn btn-sm btn-filter" :class="data.value.class" @change="onChange($event,data.index+1)">
                                            <option v-for="(option,index) in options" :key="index" :value="option.value">{{ option.text }}</option>
                                        </select>
                                    </template>
                                </b-table>

                                <!-- Table Pagination -->
                                <!-- <div class="py-3 d-flex align-items-center justify-content-between table-pagination">
                                    <div class="per-page">
                                        <b-form-group label="Per page" label-for="per-page-select" class="mb-0">
                                            <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
                                        </b-form-group>
                                    </div>
                                    <div class="number-page">
                                        <b-pagination 
                                            v-model="currentPage" 
                                            :total-rows="totalRows" 
                                            :per-page="perPage"
                                            hide-goto-end-buttons 
                                            hide-ellipsis 
                                            prev-text="Previous page" 
                                            next-text="Next page" 
                                            align="right">
                                        </b-pagination>
                                    </div>
                                    <div class="go-page">
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">Go To Page</span>
                                            </div>
                                            <input type="text" class="form-control" value="" @keyup.enter="onEnter" onkeypress='return event.charCode >= 48 && event.charCode <= 57'>
                                        </div>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                    <!-- Transaction Tab -->
                    <b-modal id="edit_account" title="Edit Account" hide-footer>
                        <div class="modal-area">
                            <Form class="form-auth-small" @submit="updateAccountData" :validation-schema="schema" v-slot="{ errors }" id="editWallet" ref="editWallet" autocomplete="off">
                                <div class="row">
                                    <!-- account Name -->
                                    <div class="col-12">
                                        <Field rules="required" name="account_name" v-slot="{ handleChange }">
                                            <div class="form-group" :class="{'errors': errors && errors.account_name,'success':!errors && !errors.account_name}">
                                                <span class="float-label">
                                                    <input type="text" id="account_name" v-model="editAccount.accountName" @update:model-value="handleChange" class="form-control" placeholder="Account Name*">
                                                    <label for="account_name" class="control-label">Account Name*</label>
                                                    <div class="line"></div>
                                                    <i class="fa fa-times" v-if="errors && errors.account_name"></i>
                                                    <i class="fa fa-check" v-if="editAccount.accountName && !errors.account_name"></i>
                                                </span>
                                                <span class="text-danger error-msg" v-if="errors[0]">Please provide unique Account Name</span>
                                            </div>
                                        </Field>
                                    </div>
                                    <!-- <div class="col-12">
                                        <Field rules="required" name="account_name">
                                            <div class="d-flex justify-content-between align-items-center m-b-20">
                                                <p class="mb-0">Enable account</p>
                                                <div class="custom-switch success-error-bg">
                                                    <input type="checkbox" class="custom-switch-input" id="customSwitch1" v-model="editAccount.enableAccount">
                                                    <label class="custom-switch-label" for="customSwitch1">
                                                        <span class="custom-switch-text1">Yes</span>
                                                        <span class="custom-switch-text2">No</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </Field>
                                    </div> -->
                                </div>
                                <div class="align-items-center d-flex flex-column justify-content-between mt-3 w-100">
                                    <b-button type="submit" block class="btn btn-primary btn-mw btn-lg" size="lg">Update Wallet</b-button>
                                    <b-button type="button" block class="btn btn-lg btn-outline-secondary" size="lg" @click="$bvModal.hide('edit_account')">Cancel</b-button>
                                </div>
                            </Form>
                        </div>
                    </b-modal>

                    <b-modal id="add_money" :title="`Add Money to ${addMoneyData.name}`" hide-footer no-stacking no-close-on-backdrop @shown="modalShow()">
                        <!--  @shown="resetForm" -->
                        <div class="modal-area"> 
                            <Form class="form-auth-small" @submit="addFundForm" :validation-schema="schema" v-slot="{ errors }" id="addFund" ref="addFund" autocomplete="off">
                                <Field name="paymentOption" :rules="{'required':!addFundDatas.paymentOption}" v-slot="{ handleChange }">
                                    <div class="country-dropdown">
                                        <div class="form-group form-select multiselect-grp" :class="{'errors':errors.paymentOption && !addFundDatas.paymentOption,'success':!errors.paymentOption && addFundDatas.paymentOption}">
                                            <span class="float-label">
                                                <label for="paymentOption">Payment Option*</label>
                                                <multiselect
                                                    placeholder=""
                                                    v-model="addFundDatas.paymentOption"  
                                                    :show-labels="false" 
                                                    :options="paymentOptions" 
                                                    label="name"
                                                    track-by="name"
                                                    @input="handleChange"
                                                    :searchable="false">
                                                    <template v-slot:singleLabel="{ option }">
                                                        <span class="option-title">
                                                            <span>{{ option.name }}</span>
                                                        </span>
                                                    </template>
                                                    <template v-slot:option="{ option }">
                                                        <div class="option-title">
                                                            <span>{{ option.name }}</span>
                                                        </div>
                                                    </template>
                                                </multiselect> 
                                                <i class="fa fa-times" v-if="errors && errors.paymentOption"></i>
                                                <i class="fa fa-check" v-if="addFundDatas.paymentOption && !errors.paymentOption"></i>
                                            </span>
                                        </div>
                                    </div>
                                </Field>
                            
                                <div class="main-content">
                                    <div class="add-amount mb-3" :class="{'disabled':amountOptions.activeAmountFlag}">
                                        <b-input-group :prepend="addMoneyData.currency_symbol" class="form-group">
                                            <input type="text" id="fundAmout" v-model="addFundDatas.addAmount" placeholder="Enter Amount" class="form-control" @update:model-value="handleChange" maxlength="15" >
                                            <!-- @keyup="formatInputValue($event)"   -->
                                        </b-input-group>
                                        <span class="text-danger" v-if="apiErrorMsg != ''">{{apiErrorMsg}}</span>
                                    </div>
                                </div>

                                <!-- <Field rules="required" name="paymentReference" v-slot="{ handleChange }">
                                    <div class="form-group" :class="{'errors': errors && errors.paymentReference,'success':!errors && !errors.paymentReference}">
                                        <span class="float-label">
                                            <input type="text" id="paymentReference" v-model="addFundDatas.paymentReference" @update:model-value="handleChange" class="form-control" placeholder="Payment Reference*">
                                            <label for="paymentReference" class="control-label">Payment Reference*</label>
                                            <div class="line"></div>
                                            <i class="fa fa-times" v-if="errors && errors.paymentReference"></i>
                                            <i class="fa fa-check" v-if="addFundDatas.paymentReference && !errors.paymentReference"></i>
                                        </span>
                                        <span class="text-danger error-msg" v-if="errors[0]">Please provide Payment Reference</span>
                                    </div>
                                </Field> -->
                                <div class="form-group">
                                    <Field name="fund_proof_file">
                                        <!--  rules="required" -->
                                        <div class="form-group" :class="{'errors': errors && errors.fund_proof_file, 'success': addFundDatas.fundProofFile}">
                                            <div class="custom-file">
                                                <b-form-file
                                                    v-model="addFundDatas.fundProofFile"
                                                    placeholder="Select Pdf for upload Proof of funds"
                                                    drop-placeholder="Drop file here..."
                                                    accept=".pdf"
                                                    ref="file"
                                                    @change="uploadFile"
                                                ></b-form-file>
                                                <span v-if="errors && errors.fund_proof_file" class="text-danger">Please upload a file</span>
                                                <i class="fa fa-times" v-if="errors && errors.fund_proof_file"></i>
                                                <i class="fa fa-check" v-if="addFundDatas.fundProofFile"></i>
                                            </div>
                                        </div>
                                    </Field>
                                    <span class="text-danger" v-if="fundErrorMsg != ''">{{fundErrorMsg}}</span>
                                </div>
                                <div class="align-items-center d-flex flex-column justify-content-between mt-3 w-100">
                                    <b-button type="submit" variant="primary btn-mw" size="lg">Add Money</b-button>
                                    <b-button @click="$bvModal.hide('add_money')" size="lg" variant="outline-secondary mt-3 btn-mw">Cancel</b-button>
                                </div>
                            </form>
                        </div>
                    </b-modal>
                    <b-modal id="swap_money" :title="`Swap Money From ${swapMoneyData.name}`" hide-footer>
                        <div class="modal-area">
                            <div class="main-content">
                                <div class="add-amount" :class="{'disabled':amountOptions.activeAmountFlag}">
                                    <b-input-group :prepend="swapMoneyData.currency_symbol" class="form-group">
                                        <input type="text" id="amount" v-model="amountTotal" class="form-control" placeholder="0.00"  @keypress="OnlyNumber($event)" maxlength="15">
                                        <!-- <input type="text" id="EnterAmout" @input="enterAmountValueGet" v-model="amountTotal" @keyup="inputFormateNumbers('amount',$event)" @keypress="onlyNumber($event)" placeholder="Enter Amout" class="form-control" :disabled="amountOptions.activeAmountFlag"> -->
                                    </b-input-group>
                                </div>
                                <!-- <div class="quick-add d-flex">
                                    <button v-for="(list,index) in buttonList" :key="index" @click="selectAmountKey(index+1,list.amount)" :class="[list.class, amountOptions.activeAmountKey == index+1 && amountOptions.activeAmountFlag ? 'btn-outline-apple-green':'']">
                                        {{swapMoneyData.currency_symbol}} {{ formateNumbers(parseFloat(list.amount)) }}
                                    </button>
                                </div> -->
                            </div>
                            <div class="from-content pt-4">
                                <h6 class="font-weight-bold">Swap To</h6>
                                <div class="card card-top-line mb-2" v-for="(account_card,index) in account_cards" :key="index">
                                    <div class="body d-flex align-items-stretch p-2">
                                        <span :class="account_card.img_class+' fi-xl '"></span>
                                        <div class="content w-100 ml-3">
                                            <div class="d-flex align-items-center justify-content-between">
                                                <h6 class="mb-0">{{ account_card.name }}</h6>
                                                <label class="fancy-radio"><input type="radio" name="default" :value="account_card.isDefault" :checked="account_card.isDefault" @click="selectAccountToSwap(account_card)"><span><i></i></span></label>
                                            </div>
                                            <span class="d-block text-muted">{{ account_card.currency }} <span v-if="account_card.isDefault" >( Default Account )</span></span>
                                            <p class="font-20 mt-3 mb-0">{{ account_card.currency_symbol }} {{ account_card.number }}</p>
                                            <!--<div class="d-flex flex-wrap justify-content-between align-items-center text-muted font-15 pr-3">
                                                <span class="pt-2">PayAngel Exchange Rate</span>
                                                <span class="pt-2" >12.17</span>
                                            </div>-->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="align-items-center d-flex flex-column justify-content-between mt-3 w-100">
                            <b-button type="submit" block class="btn btn-primary btn-mw btn-lg" size="lg" @click="swapMoneySteps()" >Swap Money</b-button>
                        </div>
                    </b-modal>
                    <b-modal id="swap_completed" title="Payment Completed" hide-footer>
                        <div class="modal-area">
                            <p>Swapping money from <strong>{{ swapMoneyData.name }}</strong> to <strong>{{swapTo.name}}</strong> has been completed </p>

                            <div class="payment-detail">
                                <h5>Payment Details</h5>
                                <ul class="list-unstyled send-list p-3 bg-light">
                                    <li class="d-flex justify-content-between">
                                        <lable>You are sending</lable>
                                        <p>{{transfercurrency}} {{transferamount}}</p>
                                    </li>
                                    <!-- <li class="d-flex justify-content-between">
                                        <lable>Transfer Fee</lable>
                                        <p>00.00</p>
                                    </li> -->
                                    <li class="d-flex justify-content-between">
                                        <lable>PayAngel Exchange Rate</lable>
                                        <p>{{conversionrate}}</p>
                                    </li>
                                    <hr>
                                    <li class="d-flex justify-content-between">
                                        <lable>Amount to Swap</lable>
                                        <strong>{{swapTo.currency}} {{swapamount}}</strong>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="align-items-center d-flex flex-column justify-content-between mt-3 w-100">
                            <b-button type="submit" block class="btn btn-primary btn-mw btn-lg" size="lg" @click="$bvModal.hide('swap_completed');$bvModal.show('swap_money')" >Make Another Swap</b-button>
                            <b-button type="button" block class="btn btn-primary btn-mw btn-lg" size="lg" @click="$bvModal.hide('swap_completed')">Go to Dashboard</b-button>
                        </div>
                    </b-modal>
                </div>
            </div>
        </div>
        <main-loader ref="loader"></main-loader>

        <b-modal id="business_list_modal" title="Add Primary Contact" hide-footer centered scrollable no-stacking no-close-on-backdrop header-class="align-items-center">
            <template #modal-header>
                <h5 class="modal-title">{{pageTitle}}</h5>
                <!-- <b-button pill variant="primary" v-b-modal.headre_add_business><i class="fa fa-plus"></i> Add</b-button> -->
            </template>
            <div class="modal-area">
                <ul class="list-unstyled mb-0 transfers-list switch-account-list">
                    <template v-for="(busines,index) in businesses" :key="index">
                        <li class="d-flex align-items-center cursor-pointer" :class="{'active': busines.isActive}" @click="setBusinessid(busines)">
                            <!-- v-on:click="onSelect(index)" -->
                            <div class="left">
                                <i aria-hidden="true" class="fa" v-if="!busines.img">{{ short_hand(busines.title) }}</i>
                                <img class="fa" :src="busines.img" v-if="busines.img"/>
                            </div>
                            <div class="right">
                                <strong class="font-16 text-uppercase">{{ busines.title }}  </strong>
                                <p>{{busines.countryName}}</p>
                            </div>
                        </li>
                    </template>
                </ul>
            </div>
        </b-modal>
    </div>
</template>
<script>
import { Form, Field } from 'vee-validate';
import BreadCrumb from '@/components/BreadCrumb.vue';
// import PayorTransferModel from '@/components/core/PayorTransferModel.vue';  
//import EditAccountModal from '@/components/admin/account/EditAccountModal.vue';
const getSymbolFromCurrency = require('currency-symbol-map')
const LoaderComponent = require('@/components/admin/loader/LoaderComponent.vue').default;
import {useToast} from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-bootstrap.css';
const $toast = useToast();

export default {
    name: 'MyWallet',
    components: {
        Form, Field,
        Multiselect: () => import('vue-multiselect'),
        BreadCrumb,
        "main-loader": LoaderComponent,
        // PayorTransferModel,
        //EditAccountModal
    },data (){
        return{
            amountTotal: '',
            amountOptions:{
                customAmount:null,
                activeAmountKey: 0,
                activeAmountFlag: false
            },
            buttonList:[
                {
                    class: 'btn btn-lg mr-1',
                    amount: 1000,
                },
                {
                    class: 'btn btn-lg mr-1',
                    amount: 2000,
                },
                {
                    class: 'btn btn-lg mr-1',
                    amount: 3000,
                },
                {
                    class: 'btn btn-lg',
                    amount: 4000,
                }
            ],
            typesOfPayments: [
                {
                    icon: 'fa fa-credit-card fa-3x',
                    type: 'Bank Card',
                    text: 'Instant | Addinal Charges',
                    isSelected: true
                },
                {
                    icon: 'fa fa-credit-card fa-3x',
                    type: 'Cradit Card',
                    text: 'Instant | Addinal Charges',
                    isSelected: false
                },
                {
                    icon: 'fa fa-scribd fa-3x',
                    type: 'Sofort Bank',
                    text: 'Instant | Addinal Charges',
                    isSelected: false
                },
                {
                    icon: 'fa fa-bank fa-3x',
                    type: 'Bank Transfer',
                    text: 'Within 30 Min',
                    isSelected: false
                },
            ],

            newSelectOptions: [
                'Batman',
                'Robin',
                'Joker',
            ],
                
            activeTab: 'grid', //current active tab is grid

            //All Acounts grid cards json
            account_cards:[],

            //All Acounts list table json
            items:[],
           
            //All Acounts list table header fields
            fields: [
                { key: 'currency', label: 'CURRENCY',sortable: true,thClass:'th_sortfix'},
                { key: 'name', label: 'NAME',sortable: true,thClass:'th_sortfix'},
                { key: 'balance', label: 'BALANCE',sortable: true,thClass:'th_sortfix'},
                { key: 'action', label: 'ACTION', thStyle:'width:50px'},
                { sortable: true, sortByFormatted: true, filterByFormatted: true},
            ],

            //All Acounts list table status fields option
            options: [
                { value: 'accept', text: 'Accept' },
                { value: 'cancel', text: 'Cancel' },
            ],

            //All Acounts list table pagination options
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10,25, 50, { value: 1000, text: 1000 }],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            isAllChecked:false,
            subSortBy: '',
            subSortDesc: false,
            //All Acounts curancy dropdawn option
            curancy: [{name: 'Ghanaian Cedi'},{name: 'British Pound'},{name: 'United States Dollar'},{name:'Euro'}],
            currency_options: [],

            //Add Account Model currency dropdawn v-model Options
            account:{
                currency:null,
                accountName:''
            },
            business_id : '',
            wallet_id : '',
            editAccount:{
                accountName:'',
                enableAccount:''
            },

            displayAlert: null,
            prevRoute: null,

            addMoneyData: {},
            swapMoneyData: {},
            swapTo: {},
            selectedTab: null,

            addFundDatas:{
                paymentOption:null,
                paymentReference:'',
                addAmount:'',
                fundProofFile:null
            },
            images: null,
            paymentOptions: [],

            apiErrorMsg : '',
            fundErrorMsg:'',

            businesses:[],
            pageTitle:'',
            totalRecord:'',

            transfercurrency:'',
            transferamount:'',
            conversionrate:'',
            swapamount:''
        }
    },methods: {
        setActive(id){
            this.selectedTab = id;
        },
        selectAccountToSwap(accountData){
            this.swapTo = accountData;
        },

        inputFormateNumbers(type,event) {
            var num = event.target.value.replace(/,/gi, "");
            var num2 = num.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            if(type == 'amount'){
                this.amountOptions.customAmount = num2; 
            }else{
                this.tipOptions.customtipAmount = num2;
            }           
        },

        formatInputValue(event) {
            // const value = parseFloat(event.target.value);
            // if (!isNaN(value)) {
            //     this.formattedNumber = value.toFixed(2);
            // } else {
            //     this.formattedNumber = '0.00';
            // }

            // // This updates the inputValue to the formatted version
            // event.target.value = this.formattedNumber;

            const maxLength = 15; // Maximum length you want to allow
            let value = event.target.value;

            // Truncate the value if it exceeds the max length
            if (value.length > maxLength) {
                value = value.slice(0, maxLength);
                event.target.value = value;
            }

            // Parse the value to a float
            const parsedValue = parseFloat(value);

            // Check if the parsed value is a number
            if (!isNaN(parsedValue)) {
                this.formattedNumber = parsedValue.toFixed(2);
            } else {
                this.formattedNumber = '0.00';
            }

            // Update the input value to the formatted version
            event.target.value = this.formattedNumber;
            
        },

        OnlyNumber(event) {
            if (((event.which != 46 || (event.which == 46 && event.target.value == '')) ||
                event.target.value.indexOf('.') != -1) && (event.which < 48 || event.which > 57)) {
                event.preventDefault();
            }
        },


        addMoneyModal(data){
            this.addMoneyData = data;
            this.$bvModal.show('add_money');
        },
        openSwapMoneyModal(data){
            this.swapMoneyData = data;

            
            this.$bvModal.show('swap_money');
        },
        swapMoneySteps(){
            this.$bvModal.hide('swap_money');
            
            this.$swal({
                title: '',
                html:
                    `<h4 class="py-1 mt-4 mb-0">Swap Amount</span></h4>
                    <span class="text-muted">${this.swapMoneyData.currency_symbol} ${parseFloat(this.amountTotal).toFixed(2)}</span>
                    <p class="text-muted mt-4">Are you sure you want to swap money to <strong>${this.swapTo.name}</strong>.</p>
                    </div>`,
                showCloseButton: false,
                showCancelButton: true,
                focusConfirm: false,
                confirmButtonText: 'Yes',
                cancelButtonText: 'Cancel'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$refs.loader.show();
                    // console.log(this.swapMoneyData);
                    let transferdata = {
                        fromWalletId : this.swapMoneyData.walletid,
                        toWalletId : this.swapTo.walletid,
                        amount : parseInt(parseFloat(this.amountTotal).toFixed(2)),
                        currency : this.swapMoneyData.currency
                    }
                    // console.log(transferdata);
                    this.$http.post(this.$baseurl+'wallet/wallets/transfer', transferdata,{
                        headers: {
                            Authorization: "Bearer " + sessionStorage.getItem("jwtToken"), //`Basic ${token}`
                        },
                    }).then((res) => {
                        console.log(res);
                        // this.$refs.loader.hide();
                        // if (result.isConfirmed) {
                            this.getBusinessWiseWallet();
                            this.transfercurrency=res.data.currency;
                            this.transferamount=parseFloat(res.data.amount).toFixed(2);
                            this.conversionrate=parseFloat(res.data.conversionRate).toFixed(2);
                            this.swapamount = parseFloat(res.data.amount * res.data.conversionRate).toFixed(2);
                            this.$bvModal.show('swap_completed');
                            this.$refs.loader.hide();
                        // }
                    }).catch((error) => {
                        // error.response.status Check status code
                        console.log(error.response);
                        this.$refs.loader.hide();
                        if(error.response){
                            var message = '';
                            if(error.response.data.detail){
                                message = error.response.data.detail;
                            } else {
                                message = error.response.data.message;
                            }
                            $toast.error(message,  {
                                position:'top-right'
                            });
                        }
                    });
                }
                
            })
        },
        formateNumbers(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        selectAmountKey(index,selectedAmount){
            if(this.amountOptions.activeAmountKey == index || !this.amountTotal || !this.amountOptions.activeAmountFlag) {
                this.amountOptions.activeAmountFlag = !this.amountOptions.activeAmountFlag
            }
            this.amountOptions.activeAmountKey = index
            if(this.amountOptions.activeAmountFlag){
                this.amountOptions.customAmount = null
                this.amountTotal = Number(selectedAmount)
            }else{
                this.amountTotal = 0
            }
            
        },
        enterAmountValueGet(){
            this.amountTotal = Number(this.amountOptions.customAmount.replace(/,/g, ''))
        },
        getTransaction(param){
            return { name: 'transactions', params: {selected_account: param.replace(" ","_")} }
        },
        selectAsDefault(card){
            
            this.$swal({
                title: '',
                html:
                    `<div><span class="${card.img_class} avatar lg"></span>
                    <h4 class="py-1 mt-4 mb-0">${card.name}</span></h4>
                    <span class="text-muted">${card.currency}</span>
                    <p class="text-muted mt-4">Are you sure you want to set <strong>${card.name}</strong>.</p>
                    </div>`,
                showCloseButton: false,
                showCancelButton: true,
                focusConfirm: false,
                confirmButtonText: 'Yes',
                cancelButtonText: 'Cancel'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.account_cards.map((data) => {
                        if(data.id == card.id){
                            data.isDefault = true;
                        }else{
                            data.isDefault = false;
                        }
                    });
                }
            })
        },
        currencyFlag ({ name, flagClass }) {
            return `${flagClass} - ${name}`
        },
        mobileInputValidate(phoneObject){
            this.mobileInputError = phoneObject.valid
        },
        //All Acounts list table status fields option on select change color/text 
        onChange(event,index) {
          var classVar =''
            return this.items.map((item,i) => {
                if(index == i+1){
                    if(event.target.value == 'accept'){
                        classVar = 'btn-outline-success'
                    }else if(event.target.value == 'cancel'){
                        classVar = 'btn-outline-danger'
                    }
                    item.status.class = classVar
                }
            });
        },

        //check which tab is active(Grid/List)
        isActiveTab(button_title){
            this.activeTab = button_title;
        },

        //check Single Transfer form Account Number field is empty
        onEnter(e) {
            var paginationMenu = Math.ceil(this.totalRows / this.perPage);
            if(e.target.value > 0){
                if(paginationMenu >= e.target.value){
                    this.currentPage = e.target.value
                }
            }
        },

        async getAllCurrency(){
            try {
                let paramData = {
                    sort : 'asc',
                    sortby : 'id',
                    size : '2000',
                    page : '1'
                }
                const response = await this.$http.get(this.$baseurl+"currencies",{
                    params: paramData,
                    headers: {
                        'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken")
                    }
                });
                // JSON responses are automatically parsed.
                // console.log(response.data);
                this.currency_options = [];
                if(response.data.code == '0003-100'){
                    response.data.details.map((item) => {
                        if(item.isocode.toLowerCase() == 'gh'){
                            let currencyData = {
                                name : item.currencycode,
                                flagClass : "flag-icon flag-icon-"+item.isocode.toLowerCase()+" fi-xl fi-round",
                                currency:item.currencycode
                            }
                            this.currency_options.push(currencyData);
                        }                        
                    })
                }
            } catch (error) {
                console.log(error);
            }
        },

        async getBusinessWiseWallet(){
            // alert(businessId);
            this.$refs.loader.show();
            const businessId = sessionStorage.getItem("businessId");
            if(businessId){
                try {
                    const response = await this.$http.get(this.$baseurl+"wallet/business/"+businessId,{
                        headers: {
                            'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken")
                        }
                    });
                    // JSON responses are automatically parsed.
                    // console.log(response);
                    this.account_cards = [];
                    this.items = [];
                    if(response.data.code == '0100-000'){
                        this.totalRecord = response.data.details.length;
                        response.data.details.map((item) => {
                            let accountData = {
                                businessid : item.businessid,
                                walletid : item.walletid,
                                img_class : "flag-icon flag-icon-"+item.isocode.toLowerCase()+" fi-xl fi-round",
                                name : item.name,
                                isEnabled : item.enabled,
                                isEnableText: 'Enabled',
                                isEnableColor : item.enabled ? 'primary  mt-auto' : 'secondary mt-auto', 
                                currency : item.currencycode,//'Ghanaian Cedi',
                                currency_symbol : item.currencycode,//getSymbolFromCurrency(item.currencycode),
                                number: this.formateNumbers(parseFloat(item.availablebalance).toFixed(2)) //'70,548.00'
                            }
                            this.account_cards.push(accountData);

                            let listviewData = {
                                businessid : item.businessid,
                                walletid : item.walletid,
                                currency:{flagClass:"flag-icon flag-icon-"+item.isocode.toLowerCase()+" fi-xl fi-round",cname:item.currencycode},
                                name:item.name,
                                balance:getSymbolFromCurrency(item.currencycode) + this.formateNumbers(parseFloat(item.availablebalance).toFixed(2)),
                                isEnabled : item.enabled
                            }
                            this.items.push(listviewData);
                        })
                        this.$refs.loader.hide();
                    }
                } catch (error) {
                    console.log(error);
                    this.$refs.loader.hide();
                }
            } else {
                return [];
            }
        },

        
        addWalletAccount(){
            this.$refs.loader.show();
            let accountData = {
                businessid: sessionStorage.getItem("businessId"),
                name: this.account.accountName,
                currencycode: this.account.currency.currency
            }
            // console.log(accountData);
            this.$http.post(this.$baseurl+'wallet/business', accountData,{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken")
                }
            })
            .then((res) => {
                //Perform Success Action
                // console.log(res.data);
                if(res.data.code == '0100-000') {
                    this.$bvModal.hide('add_account');
                    this.getBusinessWiseWallet();
                    this.$refs.loader.hide();
                    $toast.success('Your account successfully added',  {
                        position:'top-right'
                    });
                } else {
                    this.$refs.loader.hide();
                    $toast.error(res.data.message,  {
                        position:'top-right'
                    });
                }
            })
            .catch((error) => {
                // error.response.status Check status code
                this.$refs.loader.hide();
                // console.log(error);
                $toast.error(error.response.data.message,  {
                    position:'top-right'
                });
            });
        },
        editAccountModal(row){
            // console.log(row);
            if(row.item){
                this.business_id = row.item.businessid;
                this.wallet_id = row.item.walletid;
                this.editAccount.accountName = row.item.name;
                this.editAccount.enableAccount = row.item.isEnabled;
            } else {
                this.business_id = row.businessid;
                this.wallet_id = row.walletid;
                this.editAccount.accountName = row.name;
                this.editAccount.enableAccount = row.isEnabled;
            }            
            this.$bvModal.show('edit_account');
        },

        updateAccountData(){
            this.$refs.loader.show();
            let updateaccountData = {
                businessid: this.business_id,
                name: this.editAccount.accountName,
                enabled: true //this.editAccount.enableAccount
            }
            // console.log(updateaccountData);
            this.$http.put(this.$baseurl+'wallet/business/'+this.wallet_id, updateaccountData,{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken")
                }
            })
            .then((res) => {
                //Perform Success Action
                // console.log(res.data);
                if(res.data.code == '0100-000') {
                    this.$bvModal.hide('edit_account');
                    this.getBusinessWiseWallet();                    
                    this.$refs.loader.hide();
                    $toast.success('Your account successfully updated',  {
                        position:'top-right'
                    });
                } else {
                    this.$refs.loader.hide();
                    $toast.error(res.message,  {
                        position:'top-right'
                    });
                }
            })
            .catch((error) => {
                // error.response.status Check status code
                this.$refs.loader.hide();
                $toast.error(error.response.data.message,  {
                    position:'top-right'
                });
            });
        },

        modalShow(){
            this.paymentOption();

            this.addFundDatas.paymentOption = null;
            this.addFundDatas.paymentReference = '';
            this.addFundDatas.addAmount = '';
            this.addFundDatas.fundProofFile = null;
        },
        async paymentOption(){
            try {
                const response = await this.$http.get(this.$baseurl+"payment/options", { 
                    headers: {
                        'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken")
                    }
                });
                // JSON responses are automatically parsed.
                // console.log(response);
                this.paymentOptions = [];
                if(response.data.code == '0100-100'){
                    response.data.details.map((item) => {
                        let lists = {
                            id : item.id,
                            name : item.name
                        };
                        this.paymentOptions.push(lists);
                    }); 
                }               
            } catch (error) {
                console.log(error);
            }
        },
        
        resetForm(){
            this.account.currency = null;
            this.account.accountName = '';
        },

        uploadFile(e) {
            let file = e.target.files[0];
            let fileType = file.type;
            if(fileType == 'application/pdf' || fileType == 'application/PDF'){
                this.Images = file;//e.target.files[0];
            } else {
                this.$swal('Error!', 'Please provide pdf file', 'error', 'Close');
            }
        },

        addFundForm(){
            // console.log(!this.addFundDatas.addAmount)
            if(!this.addFundDatas.addAmount){
                this.apiErrorMsg = 'Please provide valid amount';
            } else if (!this.addFundDatas.fundProofFile) {
                this.fundErrorMsg = 'Please provide proof of funds';
            }else{
                this.apiErrorMsg = '';
                this.fundErrorMsg = '';
                this.$refs.loader.show();
                // console.log(this.addMoneyData.name+'_'+this.addMoneyData.currency_symbol);
                
                const randnumber = Math.ceil(Math.random()*10000);
                const paymentRef = this.addMoneyData.name+'_'+this.addMoneyData.currency_symbol+'_'+randnumber;

                const formData = new FormData();
                formData.append('businessid', this.addMoneyData.businessid);
                formData.append('walletid', this.addMoneyData.walletid);
                formData.append('amount', parseInt(this.addFundDatas.addAmount));
                formData.append('paymentreference', paymentRef);//this.addFundDatas.paymentReference);
                formData.append('paymentoptionid', this.addFundDatas.paymentOption.id);
                formData.append('file', this.addFundDatas.fundProofFile ? this.Images : '');
                this.$http.post(this.$baseurl+'wallet/fund/business', formData,{
                    headers: {
                        'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken"),
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then((res) => {
                    //Perform Success Action
                    // console.log('tyy',res.data);
                    if(res.data.code == '0100-000') {
                        this.$bvModal.hide('add_money');
                        this.$refs.loader.hide();
                        $toast.success('Your request will be approved shortly!',  {
                            position:'top-right'
                        });
                    } else {
                        this.$refs.loader.hide();
                        $toast.error(res.message,  {
                            position:'top-right'
                        });
                    }
                })
                .catch((error) => {
                    // error.response.status Check status code
                    this.$refs.loader.hide();
                    // console.log('yuyu',error.response);
                    $toast.error(error.response.data.message,  {
                        position:'top-right'
                    });
                    // this.apiErrorCode = error.response.data.code;
                    // this.apiErrorMsg = error.response.data.detail;
                });
            }
            
        },

        async showmodal(modalTitle){
            let paramData = {
                sorting : 'asc',
                sortby : 'id',
                pagesize : '2000',
                pagenumber : '1'
            }
            const response = await this.$http.get(this.$baseurl+"business/list", { 
                params: paramData,
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken")
                }
            });
            // JSON responses are automatically parsed.
            // console.log(response);
            response.data.details.map((item) => {
                let businessLists = {
                    id : item.businessid,
                    countryid: item.countryofresidence,
                    isocode: item.isocode,
                    img: '',
                    title: item.name,
                    countryName: item.countryname,
                    isActive: false
                };
                this.businesses.push(businessLists);
            });  
            
            this.pageTitle = modalTitle;
            if(this.businesses.length > 1){
                this.$bvModal.show('business_list_modal')
            } else {
                this.businesses.map((item) => {
                    sessionStorage.setItem("businessId",item.id);
                });
            }
        },

        setBusinessid(businessData){
            sessionStorage.setItem("businessId",businessData.id);
            // sessionStorage.setItem("countryId",businessData.countryid);
            // sessionStorage.setItem("isoCode",businessData.isocode);
            this.businesses.forEach(function (business) {
                if (business.id === businessData.id) {
                    business.isActive = true
                } else {
                    business.isActive = false
                }
            });
            
            if(sessionStorage.getItem('setupProfile')  == 'yes'){
                sessionStorage.removeItem('setupProfile');
                this.$router.push({ path: '/admin/setting/setup-profile' })
            } else {
                if(this.$route.path == '/admin/dashboard'){
                    // this.$root.$refs.AccountComponent.getBusinessWiseWallet(businessData.id);
                    this.getBusinessWiseWallet();
                }
            }
            this.$bvModal.hide('business_list_modal')
        },

        short_hand (companyName) {
            let words = companyName.split(" ")
            let short_hand = words[0][0] + words[words.length-1][0]
            return short_hand // <-- The return value as Per logic
        }
    },mounted() {
        //list table total rows
        // this.totalRows = this.items.length
        if(sessionStorage.getItem("jwtToken")){
            this.getAllCurrency();
            if(sessionStorage.getItem("businessId")){
                this.getBusinessWiseWallet()
            }
        } else {
            this.$swal('Error!', 'Please login to continue', 'error', 'Close');
            this.$router.push({path:'/authentication/page-login'});
        }
    },computed: {
        //check how many Currencies dropdawn checkbox checked
        isAllSelected: function() {
            return  (this.curancy.length === this.currency_options.length)
        }
    },beforeRouteEnter(to, from, next) {
        next(that => {
            // that.getBusinessList();
            that.prevRoute = from.name
            if(that.prevRoute == 'login'|| that.prevRoute == 'linkBiz'){
                that.showmodal('Select Your Business');
                
                // that.$swal({
                //     title: "Setup your profile",
                //     icon: 'warning',
                //     showCancelButton: true,
                //     confirmButtonText: 'Setup now',
                //     cancelButtonText: 'Skip for later',
                // }).then((result) => {
                //     if (result.isConfirmed) {
                //         sessionStorage.setItem('setupProfile','yes');
                //         that.$router.push({ path: '/admin/setting/setup-profile' })
                //     } else {
                //         that.showmodal('Select Your Business');
                //         // that.$root.$refs.BusinessModal.showmodal('Select Your Business');
                //     }
                // })  
            }
        })
    }
}
</script>
<style>
.transfers-list {
    max-height: 500px;
    overflow-y: scroll;
}
</style>