<template>
    <div class="container-fluid">
        <div class="block-header">
            <bread-crumb></bread-crumb>
        </div>
        <div class="row clearfix">
            <div class="col-md-12">
                <div class="card">
                    <div class="header">
                        <h2>Transaction Detail</h2>
                    </div>
                    <div class="body">
                        <div class="row">
                            <div class="col-md-6 col-12">
                                <ul class="list-unstyled">
                                    <li class="mb-2">
                                        <div class="row">
                                            <div class="col-md-5">Transaction Creation Channel:</div>
                                            <div class="col-md-7"><span>PA Admin</span></div>
                                        </div>
                                    </li>
                                    <li class="mb-2">
                                        <div class="row">
                                            <div class="col-md-5">Transaction Date:</div>
                                            <div class="col-md-7"><span>2021-11-04 08:51:45</span></div>
                                        </div>
                                    </li>
                                    <li class="mb-2">
                                        <div class="row">
                                            <div class="col-md-5">Transaction Ref:</div>
                                            <div class="col-md-7"><span>PA00036403235</span></div>
                                        </div>
                                    </li>
                                    <li class="mb-2">
                                        <div class="row">
                                            <div class="col-md-5">PayAngel Ref:</div>
                                            <div class="col-md-7"><span>PA-61839f197945c1636015897-1</span></div>
                                        </div>
                                    </li>
                                    <li class="mb-2">
                                        <div class="row">
                                            <div class="col-md-5">Remitter Id:</div>
                                            <div class="col-md-7"><span>10413</span></div>
                                        </div>
                                    </li>
                                    <li class="mb-2">
                                        <div class="row">
                                            <div class="col-md-5">Company Name:</div>
                                            <div class="col-md-7"><span>ABURI GIRLS' OLD GIRLS' ASSOCIATION</span></div>
                                        </div>
                                    </li>
                                    <li class="mb-2">
                                        <div class="row">
                                            <div class="col-md-5">Email:</div>
                                            <div class="col-md-7"><span>info@aburigirlsalumni.org</span></div>
                                        </div>
                                    </li>
                                    <li class="mb-2">
                                        <div class="row">
                                            <div class="col-md-5">Country of Residence:</div>
                                            <div class="col-md-7"><span>United Kingdom</span></div>
                                        </div>
                                    </li>
                                    <li class="mb-2">
                                        <div class="row">
                                            <div class="col-md-5">Mobile:</div>
                                            <div class="col-md-7"><span>+447799691752</span></div>
                                        </div>
                                    </li>
                                    <li class="mb-2">
                                        <div class="row">
                                            <div class="col-md-5">Beneficiary name:</div>
                                            <div class="col-md-7"><span>Test </span></div>
                                        </div>
                                    </li>
                                    <li class="mb-2">
                                        <div class="row">
                                            <div class="col-md-5">Source Transfer Amount:</div>
                                            <div class="col-md-7"><span>£ 5(United Kingdom)</span></div>
                                        </div>
                                    </li>
                                    <li class="mb-2">
                                        <div class="row">
                                            <div class="col-md-5">Destination Amount:</div>
                                            <div class="col-md-7"><span>¢ 25.4305(Ghana)</span></div>
                                        </div>
                                    </li>
                                    <li class="mb-2">
                                        <div class="row">
                                            <div class="col-md-5">Payout Mode:</div>
                                            <div class="col-md-7"><span>Fast Bank Deposit</span></div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div class="col-md-6 col-12">
                                <ul class="list-unstyled">
                                    <li class="mb-2">
                                        <div class="row">
                                            <div class="col-md-5">Transaction Status:</div>
                                            <div class="col-md-7"><span>Awaiting Funds [PENDING_CLEARANCE]</span></div>
                                        </div>
                                    </li>
                                    <li class="mb-2">
                                        <div class="row">
                                            <div class="col-md-5">Individual Transaction Fee:</div>
                                            <div class="col-md-7"><span>£ 1.50</span></div>
                                        </div>
                                    </li>
                                    <li class="mb-2">
                                        <div class="row">
                                            <div class="col-md-5">Individual Charge (Payment Method):</div>
                                            <div class="col-md-7"><span>£ 0.00</span></div>
                                        </div>
                                    </li>
                                    <li class="mb-2">
                                        <div class="row">
                                            <div class="col-md-5">Individual Discount Amount:</div>
                                            <div class="col-md-7"><span>£ 1.50</span></div>
                                        </div>
                                    </li>
                                    <li class="mb-2">
                                        <div class="row">
                                            <div class="col-md-5">Individual Donate Amount:</div>
                                            <div class="col-md-7"><span>£ 0.00</span></div>
                                        </div>
                                    </li>
                                    <li class="mb-2">
                                        <div class="row">
                                            <div class="col-md-5">Individual Charity Amount:</div>
                                            <div class="col-md-7"><span>£ 0.00</span></div>
                                        </div>
                                    </li>
                                    <li class="mb-2">
                                        <div class="row">
                                            <div class="col-md-5">E-Wallet Amount:</div>
                                            <div class="col-md-7"><span>£ 0.00</span></div>
                                        </div>
                                    </li>
                                    <li class="mb-2">
                                        <div class="row">
                                            <div class="col-md-5">Payment Amount:</div>
                                            <div class="col-md-7"><span>£ 0.00</span></div>
                                        </div>
                                    </li>
                                    <li class="mb-2">
                                        <div class="row">
                                            <div class="col-md-5">Reason of Remittance:</div>
                                            <div class="col-md-7"><span>Family/Financial Support</span></div>
                                        </div>
                                    </li>
                                    <li class="mb-2">
                                        <div class="row">
                                            <div class="col-md-5">Funding Option:</div>
                                            <div class="col-md-7"><span>Virtual Card Payment</span></div>
                                        </div>
                                    </li>
                                    <li class="mb-2">
                                        <div class="row">
                                            <div class="col-md-5">Exchange Rate:</div>
                                            <div class="col-md-7"><span>5.0861</span></div>
                                        </div>
                                    </li>
                                    <li class="mb-2">
                                        <div class="row">
                                            <div class="col-md-5">3DS Status:</div>
                                            <div class="col-md-7"><span>N/A</span></div>
                                        </div>
                                    </li>
                                    <li class="mb-2">
                                        <div class="row">
                                            <div class="col-md-5">Cashflow Status:</div>
                                            <div class="col-md-7"><span>N/A</span></div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="d-flex flex-column flex-md-row justify-content-center">
                            <b-button variant="secondary btn-mw" size="lg" @click="cancel()">Ok</b-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "TransactionDetailComponent",
    components: {
        BreadCrumb : () => import('@/components/BreadCrumb.vue'),
    },
    methods: {
        cancel(){
            this.$router.push({ path: '/admin/transaction/list' });
        },
    }
};
</script>