<template>
    <b-modal id="recipient_warning"  hide-footer hide-header centered>
        <div class="recipient-warning-modal">
            <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
            <h4 class="mb-3">You can only send to 3 receivers at a time</h4>
            <p>PayAngel only allows your to send money to only 3 receiver at a time.</p>
            <div class="align-items-center d-flex flex-column justify-content-between mt-3 w-100">
                <b-button @click="$bvModal.hide('recipient_warning')" variant="primary btn-mw" size="lg">Okay</b-button>
            </div>
        </div>
    </b-modal>
</template>
<script>
export default {
    name:'RecipientWarning'
}
</script>