<template>
    <div class="container-fluid my-profile">
        <div class="block-header">
            <BreadCrumb></BreadCrumb>
        </div>
        <div class="body my-profile-tab">
            <b-tabs pills card vertical>
                <b-tab title="Account Holder Information" active>
                    <account_holder_information></account_holder_information>
                </b-tab>
                <b-tab title="Basic Business Information">
                    <!-- <div class="col-md-12 sol-sm-12"> -->
                        <basic_business_information @onBusinessTypeChange="businessTypeChange"></basic_business_information>
                    <!-- </div> -->
                </b-tab>
                <b-tab title="Business Contact Details">
                    <!-- <div class="col-md-12 sol-sm-12"> -->
                        <business_contact_detail :businessType="businessType"></business_contact_detail>
                    <!-- </div> -->
                </b-tab>
                <b-tab title="Tell us about your needs">
                    <!-- <div class="col-md-12 sol-sm-12"> -->
                        <tell_us_about_your_needs></tell_us_about_your_needs>
                    <!-- </div> -->
                </b-tab>
                <b-tab title="Directors">
                    <!-- <div class="col-md-12 sol-sm-12"> -->
                        <directors_component></directors_component>
                    <!-- </div> -->
                </b-tab>
                <b-tab title="Shareholders">
                    <!-- <div class="col-md-12 sol-sm-12"> -->
                    <shareholders_component></shareholders_component>
                <!-- </div> -->
                </b-tab>
                <b-tab title="Ultimate Beneficial Owner (UBO)">
                    <!-- <div class="col-md-12 sol-sm-12"> -->
                        <ubo_component></ubo_component>
                    <!-- </div> -->
                </b-tab>
                <b-tab title="Business Structure, Condition and Size">
                    <!-- <div class="col-md-12 sol-sm-12"> -->
                        <business_structure_condition_size></business_structure_condition_size>
                    <!-- </div> -->
                </b-tab>
                <b-tab title="Your Business and Regulations">
                    <!-- <div class="col-md-12 sol-sm-12"> -->
                        <business_regulations_component></business_regulations_component>
                    <!-- </div> -->
                </b-tab>
                <b-tab title="Compliance Declarations">
                    <!-- <div class="col-md-12 sol-sm-12"> -->
                        <compliance_declarations_component :businessType="businessType"></compliance_declarations_component>
                    <!-- </div> -->
                </b-tab>
                <b-tab title="Supporting Documents">
                    <!-- <div  class="col-lg-12 col-md-12 sol-sm-12"> -->
                        <supporting_documents_component :businessType="businessType"></supporting_documents_component>
                    <!-- </div> -->
                </b-tab>
                <b-tab title="Contact Information">
                    <!-- <div  class="col-lg-12 col-md-12 sol-sm-12"> -->
                        <contact_component></contact_component>
                    <!-- </div> -->
                </b-tab>
                <b-tab title="Business Logo">
                    <!-- <div  class="col-md-12 sol-sm-12"> -->
                        <business_logo_component></business_logo_component>
                    <!-- </div> -->
                </b-tab>
                <b-tab title="Logo Icon">
                    <!-- <div  class="col-md-12 sol-sm-12"> -->
                        <logo_icon_component></logo_icon_component>
                    <!-- </div> -->
                </b-tab>
                <b-tab title="Profile Photo">
                    <!-- <div  class="col-md-12 sol-sm-12"> -->
                        <profile_photo_component></profile_photo_component>
                    <!-- </div> -->
                </b-tab>
        </b-tabs>
        </div>
    </div>
</template>
<script>
import BreadCrumb from '@/components/BreadCrumb.vue';
import account_holder_information from '@/components/core/AccountHolderInfo-Profile.vue';
import basic_business_information from '@/components/core/BasicBusinessInfo-Profile.vue';
import business_contact_detail from '@/components/core/BusinessContactDetails-Profile.vue';
import tell_us_about_your_needs from '@/components/core/YourNeeds-Profile.vue';
import directors_component from '@/components/core/Director-Profile.vue';
import shareholders_component from '@/components/core/Shareholders-Profile.vue';
import ubo_component from '@/components/core/UBO-Profile.vue';
import business_structure_condition_size from '@/components/core/BusinessStructure-Profile.vue';
import compliance_declarations_component from '@/components/core/ComplianceDeclarations-Profile.vue';
import business_logo_component from '@/components/core/BusinessLogo-Profile.vue';
import logo_icon_component from '@/components/core/LogoIcon-Profile.vue';
import profile_photo_component from '@/components/core/ProfilePhoto-Profile.vue';
import supporting_documents_component from '@/components/core/SupportingDocument-Profile.vue';
import contact_component from '@/components/core/Contact-Profile.vue';
import business_regulations_component from '@/components/core/BusinessRegulations-Profile.vue';
//import { Form, Field } from 'vee-validate';
export default {
    name:'MyProfileComponent',
    components: {
        BreadCrumb,
        account_holder_information,
        basic_business_information,
        business_contact_detail,
        tell_us_about_your_needs,
        directors_component,
        shareholders_component,
        ubo_component,
        business_structure_condition_size,
        compliance_declarations_component,
        business_logo_component,
        logo_icon_component,
        profile_photo_component,
        supporting_documents_component,
        contact_component,
        business_regulations_component,
    },
    // data (){
    //     return{
    //        businessType: 'Business'
    //     }
    // },
    // methods:{
    //     businessTypeChange (value) {
    //         this.businessType = value;
    //     }
    // }
    data (){
        return{
            activeTab: 0,
            isAllChecked:false,
            singleBusinessDatas:{
                businessName:[],
                channelName:'',
                channelURL:'',
                channelIP:'',
                technicalIntegration:false
            },

            businessNameOptions: ['PAYINC GROUP LTD','PAYINC CA LLC','PAYINC US LLC','PAYINC GHANA LTD'],

            text: 'Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry',
            businessDatas:[
                {
                    id:'business_0',
                    businessName:'PAYINC GROUP LTD',
                    tradebuttonText:'Add Trade Name',
                    isActive:true,
                    tradeLists:[
                        {
                            id: 0,
                            name: 'Account Holder Information',
                            isActive: true
                        },
                        {
                            id: 1,
                            name: 'Basic Business Information',
                            isActive: false
                        },
                        {
                            id: 2,
                            name: 'Business Contact Details',
                            isActive: false
                        },
                        {
                            id: 3,
                            name: 'Tell us about your needs',
                            isActive: false
                        },
                        {
                            id: 4,
                            name: 'Directors',
                            isActive: false
                        },
                        {
                            id: 5,
                            name: 'Shareholders',
                            isActive: false
                        },
                        {
                            id: 6,
                            name: 'Ultimate Beneficial Owner (UBO)',
                            isActive: false
                        },
                        {
                            id: 7,
                            name: 'Business Structure, Condition and Size',
                            isActive: false
                        },
                        {
                            id: 8,
                            name: 'Your Business and Regulations',
                            isActive: false
                        },
                        {
                            id: 9,
                            name: 'Compliance Declarations',
                            isActive: false
                        },
                        {
                            id: 10,
                            name: 'Supporting Documents',
                            isActive: false
                        },
                        {
                            id: 11,
                            name: 'Contact Information',
                            isActive: false
                        },
                        {
                            id: 12,
                            name: 'Business Logo',
                            isActive: false
                        },
                        {
                            id: 13,
                            name: 'Logo Icon',
                            isActive: false
                        },
                        {
                            id: 14,
                            name: 'Profile Photo',
                            isActive: false
                        }
                    ]
                },
                // {
                //     id:'business_1',
                //     businessName:'PAYINC CA LLC',
                //     tradebuttonText:'Add Trade Name',
                //     isActive:false,
                //     tradeLists:[
                //         {
                //             name: 'StandApp',
                //             url: 'www.standapp.com',
                //             isActive: false
                //         },
                //         {
                //             name: 'PayAngel',
                //             url: 'www.payangel.com',
                //             isActive: false
                //         },
                //         {
                //             name: 'Angel Communities',
                //             url: 'www.payangel.com',
                //             isActive: false
                //         }
                        
                //     ]
                // },
                // {
                //     id:'business_2',
                //     businessName:'PAYINC US LLC',
                //     tradebuttonText:'Add Trade Name',
                //     isActive:false,
                //     tradeLists:[
                //         {
                //             name: 'Angel Communities',
                //             url: 'www.payangel.com',
                //             isActive: false
                //         },
                //         {
                //             name: 'StandApp',
                //             url: 'www.standapp.com',
                //             isActive: false
                //         },
                //         {
                //             name: 'PayAngel',
                //             url: 'www.payangel.com',
                //             isActive: false
                //         }
                //     ]
                // },
                // {
                //     id:'business_3',
                //     businessName:'PAYINC GHANA LTD',
                //     tradebuttonText:'Add Trade Name',
                //     isActive:false,
                //     tradeLists:[
                //         {
                //             name: 'StandApp',
                //             url: 'www.standapp.com',
                //             isActive: false
                //         },
                //         {
                //             name: 'PayAngel',
                //             url: 'www.payangel.com',
                //             isActive: false
                //         }
                //     ]
                // }
            ],

            tradInputs:{
                tradeName:''
            },

            urlFormData:{
                urlValue:'www.payangel.com',
                urlActiveIndex:0,
                urlPrefixsTitle:'https://',
                ipAddress:'192.168.1.1',
                successURL:'www.payangel.com',
                successUrlActiveIndex:0,
                successUrlPrefixsTitle:'https://',
                errorURL:'www.payangel.com',
                errorUrlActiveIndex:0,
                errorUrlPrefixsTitle:'https://',
            },

            urlSelectList:[
                {
                    name:'https://'
                },
                {
                    name:'http://'
                }
            ],

            options: [
                { value: 'accept', text: 'Accept' },
                { value: 'cancel', text: 'Cancel' },
            ],

            items:[
                {url: 'https://www.payangel.com', extension:'https://www.payangel.com', events:3, dropdawn:{selected:'accept',class:'btn-outline-success'}},
                {url: 'https://www.wrraptheme.com', extension:'https://www.wrraptheme.com', events:1, dropdawn:{selected:'accept',class:'btn-outline-success'}},
                {url: 'https://www.thememakker.com', extension:'https://www.thememakker.com', events:5, dropdawn:{selected:'accept',class:'btn-outline-success'}},
                {url: 'https://www.wrraptheme.com', extension:'https://www.wrraptheme.com', events:4, dropdawn:{selected:'accept',class:'btn-outline-success'}},
                {url: 'https://www.thememakker.com', extension:'https://www.thememakker.com', events:2, dropdawn:{selected:'cancel',class:'btn-outline-danger'}},
            ],
           
            fields: [
                { key: 'url', label: 'Channel URL',sortable: true,thClass:'th_sortfix'},
                { key: 'extension', label: 'Webhook Extension',sortable: true,thClass:'th_sortfix'},
                { key: 'events', label: 'No. of events',sortable: true,thClass:'th_sortfix'},
                { key: 'dropdawn', label: 'Status'},
                { sortable: true, sortByFormatted: true, filterByFormatted: true},
            ],

            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10,25, 50, { value: 1000, text: 1000 }],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',

            webhooks:{
                url:'',
                status:'',
                extension:'',
                events: [],
                isAllChecked:false,
            },

            statusOptions: [
                'accept',
                'cancel',
            ],
            urlOptions:['https://www.payangel.com','https://www.thememakker.com','https://www.wrraptheme.com'],

            //All curancy dropdawn option
            events_options: [
                { id:0,name: 'Card verification declined',isChecked: false},
                { id:1,name: 'Card verified' , isChecked: false},
                { id:2,name: 'Dispute canceled', isChecked: false},
                { id:3,name: 'Dispute evidence required',isChecked: false},
            ],
            APIkeysInfo:{
                secretKey:'************************',
                publickKey:'****************************'
            },
            
            channels:{
                currentURL:['https://']
            },

            channelURL_option:['https://','http://'],
        }
    },methods: {
        businessTypeChange (value) {
            this.businessType = value;
        },
        selectTab(activeTab){
            this.businessDatas[0].tradeLists.map((tab, index) => {
                if(index <= activeTab){
                    tab.isActive = true;
                    this.activeTab = activeTab;
                }else{
                    tab.isActive = false;
                }
            });
        },
        //single business submit form data
        channelSubmitForm(){
            this.$bvModal.hide('add_channel');
        },

        //trade submit form data
        tradSubmitForm(index){
            if(this.tradInputs.tradeName){
                this.$bvModal.hide('trade_model'+index);
            }
        },

        urlSelectedOptions(menuIndex,type){
            if(type == 'url'){
                this.urlFormData.urlActiveIndex = menuIndex;
                this.urlFormData.urlPrefixsTitle = this.urlSelectList[menuIndex].name;
            }else if(type == 'successUrl'){
                this.urlFormData.successUrlActiveIndex = menuIndex;
                this.urlFormData.successUrlPrefixsTitle = this.urlSelectList[menuIndex].name;
            }else{
                this.urlFormData.errorUrlActiveIndex = menuIndex;
                this.urlFormData.errorUrlPrefixsTitle = this.urlSelectList[menuIndex].name;
            }
            
        },
        
        urlFormSubmit(){
            console.log("submit");
        },

        //status change
        activeStatus(event,index) {
          var classVar =''
            return this.items.map((item,i) => {
                if(index == i+1){
                    if(event.target.value == 'accept'){
                        classVar = 'btn-outline-success'
                    }else if(event.target.value == 'cancel'){
                        classVar = 'btn-outline-danger'
                    }
                    item.dropdawn.class = classVar
                }
            });
        },

        //add webhooks form submit
        webHooksForm(){
            this.$bvModal.hide('add_webhooks');
        },

        //copy secret key
        copyKey(keyType){
            // creating input of html
            var inputofKey = document.createElement("input");

            //adding span tag text to input 
            inputofKey.value = keyType == 'secretKey' ? 'sk_test_tR3PYbcVNZZ796tH88S4VQ2u' : 'pt_testing_tR3PYbcVNVV796tH89S4VQ8u';
            document.body.appendChild(inputofKey);
            inputofKey.select();
            document.execCommand("Copy");

            // removing input after copy
            inputofKey.remove();
        },

        //
        onSelectURL (selectedAction) {
            this.webhooks.extension = selectedAction
        },

        //Events dropdawn checkbox checked(one or more)
        selectAllChanged() {
            if (this.isAllSelected) {
                this.webhooks.isAllChecked = false;
                this.webhooks.events = [];
                this.events_options.map((data)=>{
                    data.isChecked = false
                });
            }else{
                this.webhooks.isAllChecked = true;
                this.webhooks.events = this.events_options.slice();
                this.events_options.map((data)=>{
                    data.isChecked = true
                });
            }
        },

        //All Events dropdawn selection message is no of Events selected
        displayText(count){
            if (count === 0){
                return ''
            }else if (count === 1){
                return this.webhooks.events[0].name
            }else if(count == this.events_options.length){
                this.webhooks.isAllChecked = true;
                return count + ' Events Selected.'
              
            }else {
                this.webhooks.isAllChecked = false;
                return  count + ' Events Selected.'
            }
        },

        //all Events dropdawn checkbox checked
        isCheckedInput (actionName) {
            this.events_options[actionName.id].isChecked = !this.events_options[actionName.id].isChecked
        },
    },mounted(){
        this.totalRows = this.items.length
    },computed: {
        //check how many Events dropdawn checkbox checked
        isAllSelected: function() {
            return  (this.webhooks.events.length === this.events_options.length)
        }
    }
}
</script>