import { defineRule } from 'vee-validate';
import * as AllRules from '@vee-validate/rules';

// Define all existing rules
Object.keys(AllRules).forEach(rule => {
    if (typeof AllRules[rule] === "function") {
        defineRule(rule, AllRules[rule]);
    }
});

// Define custom alphabetic rule with minimum 2 characters
defineRule('alphabetic_min2', value => {
    if (!value) {
        return 'This field is required';
    }
    if (!/^[a-zA-Z ]+$/.test(value)) {
        return 'This field must contain only alphabetic characters';
    }
    if (value.length < 2) {
        return 'This field must be at least 2 characters long';
    }
    return true;
});

defineRule('optional_alphabetic_min2', value => {
    if (value && !/^[a-zA-Z]+$/.test(value)) {
        return 'This field must contain only alphabetic characters';
    }
    if (value && value.length < 2) {
        return 'This field must be at least 2 characters long';
    }
    return true;
});
