<template>
    <nav class="navbar navbar-fixed-top p-0">
        <div class="container-fluid">
            <div class="navbar-btn">
                 <button type="button" class="btn btn-toggle-offcanvas" v-on:click="toggleSideMenu()"><i class="lnr lnr-menu fa fa-bars"></i></button>
            </div>
            <div class="navbar-right">
                <!-- <form id="navbar-search" class="navbar-form search-form mr-auto d-sm-none d-md-block">
                    <input value="" class="form-control" placeholder="Search here..." type="text">
                    <button type="button" class="btn btn-default"><i class="icon-magnifier"></i></button>
                </form> -->
                 
                <div id="navbar-menu">
                    <ul class="nav navbar-nav">
                         <!-- <li>
                             <router-link to="/admin/uikit/file-documents" class="icon-menu d-none d-sm-block d-md-none d-lg-block"><i class="fa fa-folder-open-o"></i></router-link>
                         </li>
                         <li>
                             <router-link to="/admin/uikit/app-calendar" class="icon-menu d-none d-sm-block d-md-none d-lg-block"><i class="icon-calendar"></i></router-link>
                         </li>
                         <li>
                             <router-link to="/admin/uikit/app-chat" class="icon-menu d-none d-sm-block"><i class="icon-bubbles"></i></router-link>
                         </li> 
                         <li>
                             <router-link to="/admin/uikit/app-inbox" class="icon-menu d-none d-sm-block"><i class="icon-envelope"></i><span class="notification-number">8</span></router-link>
                         </li>
                         
                         <li class="dropdown" v-bind:class="{ 'show': notificationMenu}"  @click="notificationMenu = !notificationMenu" v-click-outside="notificationOutside">
                             <a href="javascript:void(0);" class="dropdown-toggle icon-menu" data-toggle="dropdown">
                                 <i class="icon-bell"></i>
                                 <span :class="notification.length == 0 ? '' : 'notification-number'">{{notification.length == 0 ? '' : notification.length}}</span>
                             </a>
                             <ul class="dropdown-menu notifications" v-bind:class="{ 'show': notificationMenu}" :style="notificationMenu ? 'top: 100%;' : 'top: 80%;'">
                                 <li class="header d-flex justify-content-between align-items-center">
                                     <strong>Notifications ({{notification.length}})</strong>
                                     <a href="javascript:void(0);" @click="readall">Mark all read</a>
                                 </li>
                                 <span></span>
                                 <li v-for="item in notification" :key="item.id">
                                     <a href="javascript:void(0);" v-if="item.unread" @click="NotificationDetails(item)"><strong>{{item.subject}}</strong></a>
                                     <a href="javascript:void(0);" v-else @click="NotificationDetails(item)">{{item.subject}}</a>
                                 </li>
                                 <li class="footer d-flex justify-content-between"><router-link to="/admin/notification">See All Notifications</router-link>
                                 <a class="text-danger" @click="showAlert">Delete All</a></li>
                             </ul>
                         </li>-->
                         <li class="dropdown" v-bind:class="{ 'show': dropActive}"  @click="dropActive = !dropActive" v-click-outside="outside">
                             <a href="javascript:void(0);" class="dropdown-toggle icon-menu" data-toggle="dropdown"><i class="icon-equalizer"></i></a>
                             <ul class="dropdown-menu user-menu menu-icon" v-bind:class="{ 'show': dropActive}" :style="dropActive ? 'top: 100%;' : 'top: 80%;'">
                                 <!-- <li class="menu-heading">ACCOUNT SETTINGS</li>
                                 <li><a href="javascript:void(0);"><i class="icon-note"></i> <span>Basic</span></a></li>
                                 <li><a href="javascript:void(0);"><i class="icon-equalizer"></i> <span>Preferences</span></a></li>
                                 <li><a href="javascript:void(0);"><i class="icon-lock"></i> <span>Privacy</span></a></li>
                                 <li><a href="javascript:void(0);"><i class="icon-bell"></i> <span>Notifications</span></a></li> -->
                                 <li><a href="javascript:void(0);" v-b-modal.business_modal><i class="fa fa-briefcase"></i> <span>Business</span></a></li>
                                 <!-- <li class="menu-heading">BILLING</li>
                                 <li><a href="javascript:void(0);"><i class="icon-credit-card"></i> <span>Payments</span></a></li>
                                 <li><a href="javascript:void(0);"><i class="icon-printer"></i> <span>Invoices</span></a></li>
                                 <li><a href="javascript:void(0);"><i class="icon-refresh"></i> <span>Renewals</span></a></li> -->
                             </ul>
                         </li> 
                         <li>
                             <router-link to="javascript:void(0)" class="icon-menu" @click="logout()"><i class="icon-power"></i></router-link>
                         </li>
                    </ul>
                    <b-modal id="business_modal" title="Add Primary Contact" hide-footer centered scrollable no-stacking header-class="align-items-center">
                        <template #modal-header>
                            <h5 class="modal-title">Switch Accounts</h5>
                            <!-- <b-button pill variant="primary" v-b-modal.headre_add_business><i class="fa fa-plus"></i> Add</b-button> -->
                        </template>
                        <div class="modal-area">
                            <ul class="list-unstyled mb-0 transfers-list switch-account-list">
                                <template v-for="(busines,index) in businesses" :key="index">
                                    <li class="d-flex align-items-center" :class="{'active': busines.isActive}" @click="setBusinessid(busines)">
                                        <div class="left">
                                            <i aria-hidden="true" class="fa" v-if="!busines.img">{{ short_hand(busines.title) }}</i>
                                            <img class="fa" :src="busines.img" v-if="busines.img"/>
                                        </div>
                                        <div class="right">
                                            <strong class="font-16 text-uppercase">{{ busines.title }}</strong>
                                            <p>{{ busines.subTitle }}</p>
                                        </div>
                                    </li>
                                </template>
                            </ul>
                        </div>
                    </b-modal>
                    <!-- <span v-if="GetNotificationDetails">
                        <b-modal id="data1" title="Notification subject Line Here" size="lg" no-stacking >
                            <div class="modal-area">
                                <p>Hello Member,</p>
                                <p><strong>Subject:- </strong>{{GetNotificationDetails.subject}}</p>
                                <p><strong>Date:- </strong>{{GetNotificationDetails.date}}</p>
                                <p>First 100 users get free tickets for events.Sign up fast and now</p>
                                <p>You also get 10% discount on your orders using code : DIC10</p>
                            </div>
                            <template #modal-footer="{ cancel }">
                                <b-button class="btn btn-outline-primary btn-mw mr-1" @click="showAlert">Delete notification</b-button>
                                <b-button class="btn btn-outline-primary btn-mw mr-1" @click="ok(GetNotificationDetails.id)">Mark as Unread</b-button>
                                <b-button class="btn btn-primary btn-mw mr-1" @click="cancel()">Close</b-button>
                            </template>
                        </b-modal>
                    </span> -->
                    <!-- <header-business-model></header-business-model> -->
                </div>
            </div>
        </div>
    </nav>
</template>
<script>
 export default {
     name: 'HeaderComponent',
     components: {
         //'header-business-model': () => import('@/components/admin/business/BusinessModal.vue')
     },
     methods:{
         short_hand (companyName) {
             console.log("companyName > > > > >",companyName);
             let words = companyName.split(" ")
             let short_hand = words[0][0] + words[words.length-1][0]
             return short_hand // <-- The return value as Per logic
         },
         NotificationDetails(data){
             this.GetNotificationDetails = data;
             this.notification.map((item)=>{
                 if(item.id == data.id){
                     item.unread = false
                 }
             })
             this.$bvModal.show('data1');
         },
         readall(){
             this.notification.map((item)=>{
                 if(item.unread){
                     item.unread = false
                 }
             })
         },
         ok(id){
             this.notification.map((item)=>{
                 if(item.id == id && !item.unread){
                     item.unread = true
                 }
             })
             this.$bvModal.hide('data1');
         },
         showAlert() {
             const swalWithBootstrapButtons = this.$swal.mixin({
                 customClass: {
                     confirmButton: 'btn btn-primary btn-lg my-3',
                     cancelButton: 'btn btn-outline-secondary btn-lg'
                 },
                 buttonsStyling: false
             })
             swalWithBootstrapButtons.fire({
                 title: 'Are you sure?',
                 text: "You won't be able to revert this!",
                 icon: 'warning',
                 showCancelButton: true,
                 confirmButtonText: 'Yes, delete it!'
             }).then((result) => {
                 if (result.isConfirmed) {
                     this.notification = null;
                     swalWithBootstrapButtons.fire(
                     'Deleted!',
                     'Your file has been deleted.',
                     'success'
                     )
                     this.notification=[];
                 }
             })
         },
         outside: function() {
            this.dropActive = false
         },
         notificationOutside: function() {
            this.notificationMenu = false
         },
         toggleSideMenu: function () {
             document.body.classList.toggle('offcanvas-active')
         },

        async getBusinessList (){
            try {
                let paramData = {
                    sorting : 'asc',
                    sortby : 'id',
                    pagesize : '2000',
                    pagenumber : '1'
                }
                const response = await this.$http.get(this.$baseurl+"business/list", { 
                    params: paramData,
                    headers: {
                        'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken")
                    }
                });
                // JSON responses are automatically parsed.
                // console.log(response);
                response.data.details.map((item) => {
                    let businessLists = {
                        id : item.businessid,
                        countryid: item.countryofresidence,
                        isocode: item.isocode,
                        img: '',
                        title: item.name,
                        countryName: item.countryname,
                        isActive: false
                    };
                    this.businesses.push(businessLists);
                });    
            } catch (error) {
                console.log(error);
            }
        },
        setBusinessid(businessData){
            sessionStorage.setItem("businessId",businessData.id);
            // sessionStorage.setItem("countryId",businessData.countryid);
            // sessionStorage.setItem("isoCode",businessData.isocode);
            this.businesses.forEach(function (business) {
                if (business.id === businessData.id) {
                    business.isActive = true
                } else {
                    business.isActive = false
                }
            });
            
            if(sessionStorage.getItem('setupProfile')  == 'yes'){
                sessionStorage.removeItem('setupProfile');
                this.$router.push({ path: '/admin/setting/setup-profile' })
            } else {
                if(this.$route.path == '/admin/dashboard'){
                    this.$router.go(this.$router.currentRoute)
                }else{
                    this.$router.push({ path: '/admin/dashboard' })
                }
            }
            this.$bvModal.hide('business_modal')
        },
        logout(){
            // sessionStorage.clear();
            //sessionStorage.removeItem('jwtToken');
            sessionStorage.clear()
            this.$router.push({ path: '/authentication/page-login' })
        },
     },data() {
         return {
             GetNotificationDetails:[],
             dropActive: false,
             notificationMenu: false,
             toggleSidebarClass: false,
             sidebarmini:false,
             businesses:[
                //  {
                //      img: require(`@/assets/img/logo-icon.svg`),
                //      title:'PAYINC GROUP LIMITED',
                //      subTitle:'Business',
                //      isActive: false
                //  },
                //  {
                //      img: '',
                //      title:'GUDEND STRATEGIES LIMITED',
                //      subTitle:'Business',
                //      isActive: true
                //  },
                //  {
                //      img: '',
                //      title:'PAYANGEL FINANCIAL SOLUTIONS',
                //      subTitle:'Business',
                //      isActive: false
                //  },
                //  {
                //      img: '',
                //      title:'PAYINC TECHNOLOGIES LIMITED',
                //      subTitle:'Business',
                //      isActive: false
                //  }
             ],
             notification:[
                 {
                     id:1,
                     subject:'Ongoing events: TTF TTL',
                     date:'1990-05-05',
                     type:'Event',
                     unread:true,
                     action:''
                 },
                 {
                     id:2,
                     subject:'Ongoing Crowdfunding: TTF TTL',
                     date:'1990-05-05',
                     type:'Crowdfunding',
                     unread:true,
                     action:''
                 },
                 {
                     id:3,
                     subject:'Ongoing Invoice: TTF TTL',
                     date:'1990-05-05',
                     type:'Invoice',
                     unread:true,
                     action:''
                 },
                 {
                     id:4,
                     subject:'Ongoing Invoice: TTF TTL',
                     date:'1990-05-05',
                     type:'Invoice',
                     unread:true,
                     action:''
                 },
                 {
                     id:5,
                     subject:'Ongoing Due Reminders: TTF TTL',
                     date:'1990-05-05',
                     type:'Due Reminders',
                     unread:true,
                     action:''
                 },
             ],
         }
     },directives: {
     'click-outside': {
         bind: function(el, binding) {
             // Define Handler and cache it on the element
             const bubble = binding.modifiers.bubble
             const handler = (e) => {
             if (bubble || (!el.contains(e.target) && el !== e.target)) {
                 binding.value(e)
             }
             }
             el.__vueClickOutside__ = handler
 
             // add Event Listeners
             document.addEventListener('click', handler)
             },
         }
     }, 
     mounted() {
        this.getBusinessList();
     }
 }
 </script>
 
 