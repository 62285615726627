<template>
  <header id="header" class="main-header">
    <div class="top-header" v-if=" !(
          currentUrl == 'open-bank-account-variant1' ||
          currentUrl == 'open-bank-account-v1' ||
          currentUrl == 'open-bank-account-variant2' ||
          currentUrl == 'open-bank-account-v2'
        )">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <ul class="list-unstyled d-flex justify-content-end">
              <li>
                <a href="tel:+44 2037-476-765">
                  <img
                    src="@/assets/home_image/outgoing-call.svg"
                    width="18"
                    alt=""
                  />
                  <span>+44 2037-476-765</span>
                </a>
              </li>
              <li>
                <a href="https://wa.me/+447908061086" target="_blank">
                  <img
                    src="@/assets/home_image/outgoing-call.svg"
                    width="18"
                    alt=""
                  />
                  <img
                    src="@/assets/home_image/whatsapp-call.svg"
                    width="18"
                    alt=""
                  />
                  <span>+44 790-806-1086</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="main-menu">
      <div class="container-fluid">
        <b-navbar toggleable="lg" class="bg-light navbar-light p-0">
          <b-navbar-brand
            class="header-name"
            :to="
              currentLayout == 'customer-layout' ? '/customer-home' : '/home'
            "
            v-if="
              !(
                currentUrl == 'open-bank-account-variant1' ||
                currentUrl == 'open-bank-account-v1' ||
                currentUrl == 'open-bank-account-variant2' ||
                currentUrl == 'open-bank-account-v2'
              )
            "
          >
            <img
              :src="navbar_details.brand_logo"
              width="223"
              alt="Payangel Business"
            />
          </b-navbar-brand>
          <b-navbar-toggle target="navbarColor01"></b-navbar-toggle>
          <b-collapse id="navbarColor01" is-nav>
            <b-nav class="navbar-nav ml-auto">
              <!-- <b-nav-item
                v-for="(menu, index) in navbar_details.navbar_menus"
                :key="index"
                >{{ menu }}</b-nav-item
              > -->
              <!-- <b-nav-item-dropdown variant="link" class="country-dropdown">
                <template #button-content>
                  <span
                    :class="navbar_details.country_dropdawn.imageClass"
                  ></span
                  >{{ navbar_details.country_dropdawn.text_title }}
                </template>
                <b-dropdown-item
                  href="#"
                  v-for="(city, index) in navbar_details.country_dropdawn.citys"
                  :key="index"
                  @click="activeCountry(index)"
                  :active="
                    index == navbar_details.country_dropdawn.active_index
                  "
                >
                  <span :class="city.imageClass" alt=""></span>{{ city.name }}
                </b-dropdown-item>
              </b-nav-item-dropdown> -->
              <!-- <b-nav-item-dropdown variant="link" class="lang-dropdown">
                <template #button-content>
                  {{ navbar_details.language_dropdawn.text_title }}
                </template>
                <b-dropdown-item
                  href="#"
                  v-for="(city, index) in navbar_details.language_dropdawn
                    .citys"
                  :key="index"
                  @click="activeLang(index)"
                  :active="
                    index == navbar_details.language_dropdawn.active_index
                  "
                >
                  {{ city.name }}
                </b-dropdown-item>
              </b-nav-item-dropdown> -->
              <!--default Login Button -->
              <b-nav-form
                class="form-inline"
                v-if="
                  !(
                    currentLayout == 'auth-v2-layout' &&
                    (currentUrl == 'open-bank-account-variant1' ||
                      currentUrl == 'open-bank-account-v1' ||
                      currentUrl == 'open-bank-account-variant2' ||
                      currentUrl == 'open-bank-account-v2')
                  ) && currentLayout != 'customer-layout'
                "
              >
                <!-- <a href="#" target="_self" class="nav-link btn btn-outline"
                  >Log in to Personal</a
                > -->
                <router-link
                  to="/authentication/page-login"
                  class="nav-link btn btn-login"
                  >Log in</router-link
                >
              </b-nav-form>
              <!-- customer Login Button -->
              <b-nav-form
                class="form-inline"
                v-if="
                  currentLayout == 'customer-layout' && currentUrl != 'customer'
                "
              >
                <router-link
                  to="/customer/login"
                  class="nav-link btn btn-outline"
                  >Log in to Personal</router-link
                >
                <b-button
                  class="nav-link btn btn-login"
                  @click="openSignInModal"
                  >Login/Register</b-button
                >
              </b-nav-form>
            </b-nav>
          </b-collapse>
        </b-navbar>
      </div>
    </div>
    <!-- Login or Register modal -->
    <!-- <LoginRegisterModal></LoginRegisterModal> -->
    <!-- mobile number varify modal -->
    <MobileNumberVarifyModal></MobileNumberVarifyModal>
    <!-- user details modal -->
    <!-- <UserDetailsModal></UserDetailsModal> -->
    <!-- password modal -->
    <!-- <PasswordModal></PasswordModal> -->
    <!-- email varification modal -->
    <!-- <EmailVarificationModal></EmailVarificationModal> -->
  </header>
</template>
<script>
//import LoginRegisterModal from '@/components/admin/customer_layout/auth_modal/LoginRegisterModal.vue';
import MobileNumberVarifyModal from "@/components/admin/customer_layout/auth_modal/MobileNumberVarifyModal.vue";
// import UserDetailsModal from '@/components/admin/customer_layout/auth_modal/UserDetailsModal.vue';
// import PasswordModal from '@/components/admin/customer_layout/auth_modal/PasswordModal.vue';
// import EmailVarificationModal from '@/components/admin/customer_layout/auth_modal/EmailVarificationModal.vue';

export default {
  name: "AuthHeaderV2Component",
  props: {
    currentLayout: String,
    currentUrl: String,
  },
  components: {
    //LoginRegisterModal,
    MobileNumberVarifyModal,
    //UserDetailsModal,
    //PasswordModal,
    //EmailVarificationModal,
  },
  data() {
    return {
      navbar_details: {
        brand_logo: require(`@/assets/home_image/Payangel_Business_main.svg`),
        navbar_menus: ["Contact Us"],
        country_dropdawn: {
          text_title: "United Kingdom",
          imageClass: "flag-icon flag-icon-gb fs-20 mr-2",
          active_index: 1,
          citys: [
            {
              imageClass: "flag-icon flag-icon-ca fs-20 mr-2",
              name: "Canada",
            },
            {
              imageClass: "flag-icon flag-icon-gb fs-20 mr-2",
              name: "United Kingdom",
            },
          ],
        },

        language_dropdawn: {
          text_title: "EN",
          active_index: 0,
          citys: [
            {
              name: "EN",
            },
            {
              name: "FR",
            },
            {
              name: "ES",
            },
          ],
        },
      },
    };
  },
  methods: {
    activeCountry(index) {
      this.navbar_details.country_dropdawn.active_index = index;
      this.navbar_details.country_dropdawn.text_title =
        this.navbar_details.country_dropdawn.citys[index].name;
      this.navbar_details.country_dropdawn.imageClass =
        this.navbar_details.country_dropdawn.citys[index].imageClass;
    },

    activeLang(index) {
      this.navbar_details.language_dropdawn.active_index = index;
      this.navbar_details.language_dropdawn.text_title =
        this.navbar_details.language_dropdawn.citys[index].name;
    },

    openSignInModal() {
      this.$bvModal.show("login_register");
      var defaultCountrySet =
        document.getElementsByClassName("default-country");
      setTimeout(() => {
        defaultCountrySet[0].children[0].children[0].children[0].classList.add(
          "gb"
        );
      }, 100);
    },
  },
};
</script>
