<template>
    <ul class="list-unstyled mb-0 profile-timeline-list">
        <li v-for="(timelineList,i) in timelineLists" :key="i" class="timeline-list green" :class="{'active':timelineList.isActive}">
            <p>
                <span>{{ timelineList.name }}</span>
                <strong>{{ timelineList.amount }}</strong>
            </p>
        </li>
    </ul>
</template>
<script>
export default {
    name:'MoneyExchangeTimeline',
    data(){
        return{
            timelineLists:[
                {
                    name:'Exchange Rate',
                    amount:'1 GBP = 7.3434',
                    isActive: true
                },
                {
                    name:'Transfer Fee',
                    amount:'GBP 0.00',
                    isActive: true
                },
                {
                    name:'Total Amount to Pay',
                    amount:'GBP 500.00',
                    isActive: false
                }
            ]
        }
    }
}
</script>