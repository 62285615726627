<template>
    <b-modal id="add_business" title="Add New Business" hide-footer no-stacking centered>
            <Form @submit="addBusiness"  :validation-schema="schema" v-slot="{ errors }">
                <div class="row">
                    <div class="col-md-12 col-12">
                        <Field rules="required" name="bname" v-slot="{ handleChange }">
                            <div class="form-group" :class="{'errors': errors.length && !businessData.bname,'success':businessData.bname}">
                                <span class="float-label">
                                    <input type="text" id="bname" @update:model-value="handleChange" v-model="businessData.bname" class="form-control" placeholder="Business Name*">
                                    <label for="bname" class="control-label">Business Name*</label>
                                    <div class="line"></div>
                                    <i class="fa fa-times" v-if="errors && errors.bname"></i>
                                    <i class="fa fa-check" v-if="!errors && !errors.bname"></i>
                                </span>
                            </div>
                        </Field>
                        <Field rules="required" name="Country" v-slot="{ handleChange }">
                            <div class="country-dropdown">
                                <div class="form-group form-select multiselect-grp" :class="{'errors': errors[0],'success':businessData.countryofResidence && businessData.countryofResidence.length != 0  && !errors[0]}">
                                    <span class="float-label">
                                    <label for="Country">Country of Residence*</label>
                                        <multiselect
                                            placeholder=""
                                            v-model="businessData.countryofResidence"
                                            @update:model-value="handleChange"
                                            :show-labels="false" 
                                            :options="countryOptions" 
                                            :searchable="false"
                                            label="name" 
                                            track-by="name"
                                            @select="setPrimaryNumberCountryCode">
                                            <template v-slot:option="{ option }">
                                                <div class="option-title">
                                                    <span class="mr-2" :class="option.flagClass"></span>
                                                    <span>{{ option.name }}</span>
                                                </div>
                                            </template>
                                        </multiselect>
                                        <i class="fa fa-times" v-if="errors && errors.countryofResidence"></i>
                                        <i class="fa fa-check" v-if="!errors && !errors.countryofResidence"></i>
                                        <!-- <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                        <i class="fa fa-check" v-if="businessData.countryofResidence && businessData.countryofResidence.length != 0 && !errors[0]"></i>  -->
                                    </span>
                                </div>
                            </div>
                        </Field>
                        <div class="form-group" :class="{'errors': businessData.mobileInputError == false,'success':businessData.primaryNumber && businessData.mobileInputError}">
                            <div class="float-label">
                                <label for="primary_number" class="control-label">Mobile Number*</label>
                                <vue-tel-input
                                    v-model="businessData.primaryNumber"
                                    v-bind:id="primaryMobileNumber"
                                    ref="primaryNumber"
                                    @keydown="restrictSpecialCharacter($event)"
                                    @validate ="mobileInputValidate"
                                >
                                <template v-slot:arrow-icon>
                                    <span class="vti__dropdown-arrow">{{ '▼' }}</span>
                                </template>
                                </vue-tel-input>
                                <div class="line"></div>
                                <i class="fa fa-times" v-if="businessData.mobileInputError == false"></i>
                                <i class="fa fa-check" v-if="businessData.mobileInputError && businessData.primaryNumber"></i>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between align-items-center m-b-20">
                            <p class="mb-0">Want to receive Email notifications?</p>
                            <div class="custom-switch success-error-bg">
                                <input type="checkbox" class="custom-switch-input" id="customSwitch1" v-model="businessData.emailNotification">
                                <label class="custom-switch-label" for="customSwitch1">
                                    <span class="custom-switch-text1">Yes</span>
                                    <span class="custom-switch-text2">No</span>
                                </label>
                            </div>
                        </div>
                        <Field :rules="businessData.emailNotification ? 'required|email' : ''" v-if="businessData.emailNotification" name="email" v-slot="{ handleChange }">
                            <div class="form-group" :class="{'errors': errors.length && !businessData.email || errors[0],'success':businessData.email && !errors[0]}">
                                <span class="float-label">
                                    <input type="email" @update:model-value="handleChange" v-model="businessData.email" class="form-control" name="email" id="forgot-email">
                                    <label for="forgot-email" class="control-label">Email Address*</label>
                                    <div class="line"></div>
                                    <i class="fa fa-times" v-if="errors && errors.email"></i>
                                    <i class="fa fa-check" v-if="!errors && !errors.email"></i>
                                </span>
                            </div>
                        </Field>
                    </div>
                </div>
                <div class="align-items-center d-flex flex-column justify-content-between mt-3 w-100">
                    <b-button type="submit" variant="primary btn-mw" size="lg">Add Business</b-button>
                    <b-button @click="$bvModal.hide('add_business')" size="lg" variant="outline-secondary mt-3 btn-mw">Cancel</b-button>
                </div>
            </Form>
    </b-modal>
</template>
<script>
import { Form, Field } from 'vee-validate';
import VueMultiselect from 'vue-multiselect'
export default {
    name:'AddBusiness',
    components: {
        'Multiselect': VueMultiselect,
        Form,
        Field,
    },data(){
        return{
            businessData:{
                bname: '',
                countryofResidence:'',
                primaryNumber:'',
                emailNotification:true,
                email:'',
                mobileInputError:'',
            },

            countryOptions: [
                { name: 'Ghana', flagClass:"flag-icon flag-icon-gh fi-md fi-round",primaryNumberCode:'GH'},
                { name: 'United Kingdom', flagClass: "flag-icon flag-icon-gb fi-md fi-round",primaryNumberCode:'GB'},
                { name: 'France' , flagClass: "flag-icon flag-icon-fr fi-md fi-round",primaryNumberCode:'FR'},
                { name: 'United States', flagClass: "flag-icon flag-icon-us fi-md fi-round",primaryNumberCode:'US'},
            ],

            primaryMobileNumber:{
                mode: "auto",
                autoFormat:false,
                defaultCountry:"GB",
                dropdownOptions:{
                    showFlags: true,
                    disabled:true,
                    showDialCodeInSelection:true
                }
            },
        }
    },methods:{
        restrictSpecialCharacter(e) {
            if (/^\W$/.test(e.key)) {
                e.preventDefault();
            }
        },

        mobileInputValidate(phoneObject){
            this.businessData.mobileInputError = phoneObject.valid
        },

        setPrimaryNumberCountryCode(defaultCountry) {
            this.$refs.primaryNumber.choose(defaultCountry.primaryNumberCode);
        },

        addBusiness(){
            if(typeof(this.businessData.mobileInputError) == 'undefined'){
                this.businessData.mobileInputError = false
            }
            if(this.businessData.mobileInputError){
                this.$bvModal.hide('add_business');
            }
        }
    },watch: {
        mobileNumber(val) {
            this.businessData.primaryNumber = val.replace(/\W/g, "");
        },
    }
}
</script>