<template>
    <div class="money-send-by">
        <ul class="list-unstyled send-list">
            <li v-for="(branch,index) in branchList" :key="index" :class="{'active':branchActive == branch.key}" @click="branchActiveIs(branch.key)">
                <a href="#" class="send-list-card">
                    <div class="d-flex align-items-center">
                        <div class="svg-box" v-html="branch.iconImage"></div>
                        <h6 class="mb-0 ml-3">{{ branch.name }}</h6>
                    </div>
                    <div class="icon-right">
                        <i class="arrow-right" v-html="branch.arrowImage"></i>
                    </div>
                </a>
            </li>
        </ul>
    </div>
</template>
<script>
export default {
    name:'BranchBankList',
    data(){
        return{
            branchActive:'kejetia-branch',
            branchList:[
                {
                    name: 'CBG - KEJETIA BRANCH, Kumasi',
                    key:'kejetia-branch',
                    iconImage:`<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15 1.78125L27.3937 7.5H29.0625C29.3111 7.5 29.5496 7.59877 29.7254 7.77459C29.9012 7.9504 30 8.18886 30 8.4375V12.1875C30 12.4361 29.9012 12.6746 29.7254 12.8504C29.5496 13.0262 29.3111 13.125 29.0625 13.125H28.125V26.25C28.3341 26.2501 28.5372 26.32 28.702 26.4488C28.8668 26.5776 28.9838 26.7577 29.0344 26.9606L29.9719 30.7106C30.0063 30.8488 30.0089 30.993 29.9793 31.1323C29.9497 31.2716 29.8888 31.4023 29.8011 31.5146C29.7135 31.6268 29.6014 31.7176 29.4734 31.78C29.3454 31.8425 29.2049 31.875 29.0625 31.875H0.9375C0.795096 31.875 0.654572 31.8425 0.526595 31.78C0.398617 31.7176 0.286549 31.6268 0.198896 31.5146C0.111243 31.4023 0.0503098 31.2716 0.0207204 31.1323C-0.00886906 30.993 -0.00633678 30.8488 0.028125 30.7106L0.965625 26.9606C1.01623 26.7577 1.13323 26.5776 1.298 26.4488C1.46278 26.32 1.66588 26.2501 1.875 26.25V13.125H0.9375C0.68886 13.125 0.450403 13.0262 0.274587 12.8504C0.0987721 12.6746 0 12.4361 0 12.1875V8.4375C0 8.18886 0.0987721 7.9504 0.274587 7.77459C0.450403 7.59877 0.68886 7.5 0.9375 7.5H2.60625L15 1.78125ZM7.08 7.5H22.9181L15 3.84375L7.08 7.5ZM3.75 13.125V26.25H5.625V13.125H3.75ZM7.5 13.125V26.25H12.1875V13.125H7.5ZM14.0625 13.125V26.25H15.9375V13.125H14.0625ZM17.8125 13.125V26.25H22.5V13.125H17.8125ZM24.375 13.125V26.25H26.25V13.125H24.375ZM28.125 11.25V9.375H1.875V11.25H28.125ZM27.3937 28.125H2.60625L2.1375 30H27.8625L27.3937 28.125Z" class="fill"></path>
                    </svg>`,
                    arrowImage:`<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path class="fill" fill-rule="evenodd" clip-rule="evenodd" d="M1 8.00002C1 7.86741 1.05268 7.74024 1.14645 7.64647C1.24021 7.5527 1.36739 7.50002 1.5 7.50002H13.293L10.146 4.35402C10.0521 4.26013 9.99937 4.1328 9.99937 4.00002C9.99937 3.86725 10.0521 3.73991 10.146 3.64602C10.2399 3.55213 10.3672 3.49939 10.5 3.49939C10.6328 3.49939 10.7601 3.55213 10.854 3.64602L14.854 7.64602C14.9006 7.69247 14.9375 7.74764 14.9627 7.80839C14.9879 7.86913 15.0009 7.93425 15.0009 8.00002C15.0009 8.06579 14.9879 8.13091 14.9627 8.19165C14.9375 8.2524 14.9006 8.30758 14.854 8.35402L10.854 12.354C10.7601 12.4479 10.6328 12.5007 10.5 12.5007C10.3672 12.5007 10.2399 12.4479 10.146 12.354C10.0521 12.2601 9.99937 12.1328 9.99937 12C9.99937 11.8672 10.0521 11.7399 10.146 11.646L13.293 8.50002H1.5C1.36739 8.50002 1.24021 8.44734 1.14645 8.35357C1.05268 8.25981 1 8.13263 1 8.00002Z"/>
                    </svg>`
                },
                {
                    name: 'Ghana Commercial Bank',
                    key:'ghana-branch',
                    iconImage:`<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15 1.78125L27.3937 7.5H29.0625C29.3111 7.5 29.5496 7.59877 29.7254 7.77459C29.9012 7.9504 30 8.18886 30 8.4375V12.1875C30 12.4361 29.9012 12.6746 29.7254 12.8504C29.5496 13.0262 29.3111 13.125 29.0625 13.125H28.125V26.25C28.3341 26.2501 28.5372 26.32 28.702 26.4488C28.8668 26.5776 28.9838 26.7577 29.0344 26.9606L29.9719 30.7106C30.0063 30.8488 30.0089 30.993 29.9793 31.1323C29.9497 31.2716 29.8888 31.4023 29.8011 31.5146C29.7135 31.6268 29.6014 31.7176 29.4734 31.78C29.3454 31.8425 29.2049 31.875 29.0625 31.875H0.9375C0.795096 31.875 0.654572 31.8425 0.526595 31.78C0.398617 31.7176 0.286549 31.6268 0.198896 31.5146C0.111243 31.4023 0.0503098 31.2716 0.0207204 31.1323C-0.00886906 30.993 -0.00633678 30.8488 0.028125 30.7106L0.965625 26.9606C1.01623 26.7577 1.13323 26.5776 1.298 26.4488C1.46278 26.32 1.66588 26.2501 1.875 26.25V13.125H0.9375C0.68886 13.125 0.450403 13.0262 0.274587 12.8504C0.0987721 12.6746 0 12.4361 0 12.1875V8.4375C0 8.18886 0.0987721 7.9504 0.274587 7.77459C0.450403 7.59877 0.68886 7.5 0.9375 7.5H2.60625L15 1.78125ZM7.08 7.5H22.9181L15 3.84375L7.08 7.5ZM3.75 13.125V26.25H5.625V13.125H3.75ZM7.5 13.125V26.25H12.1875V13.125H7.5ZM14.0625 13.125V26.25H15.9375V13.125H14.0625ZM17.8125 13.125V26.25H22.5V13.125H17.8125ZM24.375 13.125V26.25H26.25V13.125H24.375ZM28.125 11.25V9.375H1.875V11.25H28.125ZM27.3937 28.125H2.60625L2.1375 30H27.8625L27.3937 28.125Z" class="fill"></path>
                    </svg>`,
                    arrowImage:`<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path class="fill" fill-rule="evenodd" clip-rule="evenodd" d="M1 8.00002C1 7.86741 1.05268 7.74024 1.14645 7.64647C1.24021 7.5527 1.36739 7.50002 1.5 7.50002H13.293L10.146 4.35402C10.0521 4.26013 9.99937 4.1328 9.99937 4.00002C9.99937 3.86725 10.0521 3.73991 10.146 3.64602C10.2399 3.55213 10.3672 3.49939 10.5 3.49939C10.6328 3.49939 10.7601 3.55213 10.854 3.64602L14.854 7.64602C14.9006 7.69247 14.9375 7.74764 14.9627 7.80839C14.9879 7.86913 15.0009 7.93425 15.0009 8.00002C15.0009 8.06579 14.9879 8.13091 14.9627 8.19165C14.9375 8.2524 14.9006 8.30758 14.854 8.35402L10.854 12.354C10.7601 12.4479 10.6328 12.5007 10.5 12.5007C10.3672 12.5007 10.2399 12.4479 10.146 12.354C10.0521 12.2601 9.99937 12.1328 9.99937 12C9.99937 11.8672 10.0521 11.7399 10.146 11.646L13.293 8.50002H1.5C1.36739 8.50002 1.24021 8.44734 1.14645 8.35357C1.05268 8.25981 1 8.13263 1 8.00002Z"/>
                    </svg>`
                },
                {
                    name: 'Fidelity Bank Ghana Ltd. - Adum PZ branch',
                    key:'adum-pickup',
                    iconImage:`<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15 1.78125L27.3937 7.5H29.0625C29.3111 7.5 29.5496 7.59877 29.7254 7.77459C29.9012 7.9504 30 8.18886 30 8.4375V12.1875C30 12.4361 29.9012 12.6746 29.7254 12.8504C29.5496 13.0262 29.3111 13.125 29.0625 13.125H28.125V26.25C28.3341 26.2501 28.5372 26.32 28.702 26.4488C28.8668 26.5776 28.9838 26.7577 29.0344 26.9606L29.9719 30.7106C30.0063 30.8488 30.0089 30.993 29.9793 31.1323C29.9497 31.2716 29.8888 31.4023 29.8011 31.5146C29.7135 31.6268 29.6014 31.7176 29.4734 31.78C29.3454 31.8425 29.2049 31.875 29.0625 31.875H0.9375C0.795096 31.875 0.654572 31.8425 0.526595 31.78C0.398617 31.7176 0.286549 31.6268 0.198896 31.5146C0.111243 31.4023 0.0503098 31.2716 0.0207204 31.1323C-0.00886906 30.993 -0.00633678 30.8488 0.028125 30.7106L0.965625 26.9606C1.01623 26.7577 1.13323 26.5776 1.298 26.4488C1.46278 26.32 1.66588 26.2501 1.875 26.25V13.125H0.9375C0.68886 13.125 0.450403 13.0262 0.274587 12.8504C0.0987721 12.6746 0 12.4361 0 12.1875V8.4375C0 8.18886 0.0987721 7.9504 0.274587 7.77459C0.450403 7.59877 0.68886 7.5 0.9375 7.5H2.60625L15 1.78125ZM7.08 7.5H22.9181L15 3.84375L7.08 7.5ZM3.75 13.125V26.25H5.625V13.125H3.75ZM7.5 13.125V26.25H12.1875V13.125H7.5ZM14.0625 13.125V26.25H15.9375V13.125H14.0625ZM17.8125 13.125V26.25H22.5V13.125H17.8125ZM24.375 13.125V26.25H26.25V13.125H24.375ZM28.125 11.25V9.375H1.875V11.25H28.125ZM27.3937 28.125H2.60625L2.1375 30H27.8625L27.3937 28.125Z" class="fill"></path>
                    </svg>`,
                    arrowImage:`<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path class="fill" fill-rule="evenodd" clip-rule="evenodd" d="M1 8.00002C1 7.86741 1.05268 7.74024 1.14645 7.64647C1.24021 7.5527 1.36739 7.50002 1.5 7.50002H13.293L10.146 4.35402C10.0521 4.26013 9.99937 4.1328 9.99937 4.00002C9.99937 3.86725 10.0521 3.73991 10.146 3.64602C10.2399 3.55213 10.3672 3.49939 10.5 3.49939C10.6328 3.49939 10.7601 3.55213 10.854 3.64602L14.854 7.64602C14.9006 7.69247 14.9375 7.74764 14.9627 7.80839C14.9879 7.86913 15.0009 7.93425 15.0009 8.00002C15.0009 8.06579 14.9879 8.13091 14.9627 8.19165C14.9375 8.2524 14.9006 8.30758 14.854 8.35402L10.854 12.354C10.7601 12.4479 10.6328 12.5007 10.5 12.5007C10.3672 12.5007 10.2399 12.4479 10.146 12.354C10.0521 12.2601 9.99937 12.1328 9.99937 12C9.99937 11.8672 10.0521 11.7399 10.146 11.646L13.293 8.50002H1.5C1.36739 8.50002 1.24021 8.44734 1.14645 8.35357C1.05268 8.25981 1 8.13263 1 8.00002Z"/>
                    </svg>`
                },
                {
                    name: 'Access Bank, Alabar Branch',
                    key:'alabar-branch',
                    iconImage:`<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15 1.78125L27.3937 7.5H29.0625C29.3111 7.5 29.5496 7.59877 29.7254 7.77459C29.9012 7.9504 30 8.18886 30 8.4375V12.1875C30 12.4361 29.9012 12.6746 29.7254 12.8504C29.5496 13.0262 29.3111 13.125 29.0625 13.125H28.125V26.25C28.3341 26.2501 28.5372 26.32 28.702 26.4488C28.8668 26.5776 28.9838 26.7577 29.0344 26.9606L29.9719 30.7106C30.0063 30.8488 30.0089 30.993 29.9793 31.1323C29.9497 31.2716 29.8888 31.4023 29.8011 31.5146C29.7135 31.6268 29.6014 31.7176 29.4734 31.78C29.3454 31.8425 29.2049 31.875 29.0625 31.875H0.9375C0.795096 31.875 0.654572 31.8425 0.526595 31.78C0.398617 31.7176 0.286549 31.6268 0.198896 31.5146C0.111243 31.4023 0.0503098 31.2716 0.0207204 31.1323C-0.00886906 30.993 -0.00633678 30.8488 0.028125 30.7106L0.965625 26.9606C1.01623 26.7577 1.13323 26.5776 1.298 26.4488C1.46278 26.32 1.66588 26.2501 1.875 26.25V13.125H0.9375C0.68886 13.125 0.450403 13.0262 0.274587 12.8504C0.0987721 12.6746 0 12.4361 0 12.1875V8.4375C0 8.18886 0.0987721 7.9504 0.274587 7.77459C0.450403 7.59877 0.68886 7.5 0.9375 7.5H2.60625L15 1.78125ZM7.08 7.5H22.9181L15 3.84375L7.08 7.5ZM3.75 13.125V26.25H5.625V13.125H3.75ZM7.5 13.125V26.25H12.1875V13.125H7.5ZM14.0625 13.125V26.25H15.9375V13.125H14.0625ZM17.8125 13.125V26.25H22.5V13.125H17.8125ZM24.375 13.125V26.25H26.25V13.125H24.375ZM28.125 11.25V9.375H1.875V11.25H28.125ZM27.3937 28.125H2.60625L2.1375 30H27.8625L27.3937 28.125Z" class="fill"></path>
                    </svg>`,
                    arrowImage:`<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path class="fill" fill-rule="evenodd" clip-rule="evenodd" d="M1 8.00002C1 7.86741 1.05268 7.74024 1.14645 7.64647C1.24021 7.5527 1.36739 7.50002 1.5 7.50002H13.293L10.146 4.35402C10.0521 4.26013 9.99937 4.1328 9.99937 4.00002C9.99937 3.86725 10.0521 3.73991 10.146 3.64602C10.2399 3.55213 10.3672 3.49939 10.5 3.49939C10.6328 3.49939 10.7601 3.55213 10.854 3.64602L14.854 7.64602C14.9006 7.69247 14.9375 7.74764 14.9627 7.80839C14.9879 7.86913 15.0009 7.93425 15.0009 8.00002C15.0009 8.06579 14.9879 8.13091 14.9627 8.19165C14.9375 8.2524 14.9006 8.30758 14.854 8.35402L10.854 12.354C10.7601 12.4479 10.6328 12.5007 10.5 12.5007C10.3672 12.5007 10.2399 12.4479 10.146 12.354C10.0521 12.2601 9.99937 12.1328 9.99937 12C9.99937 11.8672 10.0521 11.7399 10.146 11.646L13.293 8.50002H1.5C1.36739 8.50002 1.24021 8.44734 1.14645 8.35357C1.05268 8.25981 1 8.13263 1 8.00002Z"/>
                    </svg>`
                },
                {
                    name: 'GCB Bank,Asafo Market',
                    key:'asafo-market',
                    iconImage:`<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15 1.78125L27.3937 7.5H29.0625C29.3111 7.5 29.5496 7.59877 29.7254 7.77459C29.9012 7.9504 30 8.18886 30 8.4375V12.1875C30 12.4361 29.9012 12.6746 29.7254 12.8504C29.5496 13.0262 29.3111 13.125 29.0625 13.125H28.125V26.25C28.3341 26.2501 28.5372 26.32 28.702 26.4488C28.8668 26.5776 28.9838 26.7577 29.0344 26.9606L29.9719 30.7106C30.0063 30.8488 30.0089 30.993 29.9793 31.1323C29.9497 31.2716 29.8888 31.4023 29.8011 31.5146C29.7135 31.6268 29.6014 31.7176 29.4734 31.78C29.3454 31.8425 29.2049 31.875 29.0625 31.875H0.9375C0.795096 31.875 0.654572 31.8425 0.526595 31.78C0.398617 31.7176 0.286549 31.6268 0.198896 31.5146C0.111243 31.4023 0.0503098 31.2716 0.0207204 31.1323C-0.00886906 30.993 -0.00633678 30.8488 0.028125 30.7106L0.965625 26.9606C1.01623 26.7577 1.13323 26.5776 1.298 26.4488C1.46278 26.32 1.66588 26.2501 1.875 26.25V13.125H0.9375C0.68886 13.125 0.450403 13.0262 0.274587 12.8504C0.0987721 12.6746 0 12.4361 0 12.1875V8.4375C0 8.18886 0.0987721 7.9504 0.274587 7.77459C0.450403 7.59877 0.68886 7.5 0.9375 7.5H2.60625L15 1.78125ZM7.08 7.5H22.9181L15 3.84375L7.08 7.5ZM3.75 13.125V26.25H5.625V13.125H3.75ZM7.5 13.125V26.25H12.1875V13.125H7.5ZM14.0625 13.125V26.25H15.9375V13.125H14.0625ZM17.8125 13.125V26.25H22.5V13.125H17.8125ZM24.375 13.125V26.25H26.25V13.125H24.375ZM28.125 11.25V9.375H1.875V11.25H28.125ZM27.3937 28.125H2.60625L2.1375 30H27.8625L27.3937 28.125Z" class="fill"></path>
                    </svg>`,
                    arrowImage:`<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path class="fill" fill-rule="evenodd" clip-rule="evenodd" d="M1 8.00002C1 7.86741 1.05268 7.74024 1.14645 7.64647C1.24021 7.5527 1.36739 7.50002 1.5 7.50002H13.293L10.146 4.35402C10.0521 4.26013 9.99937 4.1328 9.99937 4.00002C9.99937 3.86725 10.0521 3.73991 10.146 3.64602C10.2399 3.55213 10.3672 3.49939 10.5 3.49939C10.6328 3.49939 10.7601 3.55213 10.854 3.64602L14.854 7.64602C14.9006 7.69247 14.9375 7.74764 14.9627 7.80839C14.9879 7.86913 15.0009 7.93425 15.0009 8.00002C15.0009 8.06579 14.9879 8.13091 14.9627 8.19165C14.9375 8.2524 14.9006 8.30758 14.854 8.35402L10.854 12.354C10.7601 12.4479 10.6328 12.5007 10.5 12.5007C10.3672 12.5007 10.2399 12.4479 10.146 12.354C10.0521 12.2601 9.99937 12.1328 9.99937 12C9.99937 11.8672 10.0521 11.7399 10.146 11.646L13.293 8.50002H1.5C1.36739 8.50002 1.24021 8.44734 1.14645 8.35357C1.05268 8.25981 1 8.13263 1 8.00002Z"/>
                    </svg>`
                }
            ]
        }
    },methods:{
        branchActiveIs(branch){
            this.branchActive = branch
        }
    }
}
</script>