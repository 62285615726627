<template>
    <b-modal id="add_recipients" title="Add Recipients" hide-footer size="lg" no-stacking centered>
        <Form @submit="addRecipients"  :validation-schema="schema" v-slot="{ errors }">
            <div class="row">
                <div class="col-md-6 col-12">
                    <div class="country-dropdown">
                        <div class="form-group form-select multiselect-grp">
                            <span class="float-label">
                                <label for="Country">Title</label>
                                <multiselect
                                    placeholder=""
                                    v-model="recipientsData.title"  
                                    :show-labels="false" 
                                    :options="titleOptions" 
                                    :searchable="false" >
                                </multiselect>
                            </span>
                        </div>
                    </div>
                    <Field rules="required" name="firstName" v-slot="{ handleChange }">
                        <div class="form-group" :class="{'errors': errors.length && !recipientsData.firstName,'success':recipientsData.firstName}">
                            <span class="float-label">
                                <input type="text" id="firstName" v-model="recipientsData.firstName" @update:model-value="handleChange" class="form-control" placeholder="First Name*">
                                <label for="firstName" class="control-label">First Name on ID*</label>
                                <div class="line"></div>
                                <i class="fa fa-times" v-if="errors && errors.firstName"></i>
                                <i class="fa fa-check" v-if="!errors && !errors.firstName"></i>
                                <!-- <i class="fa fa-times" v-if="errors.length && !recipientsData.firstName"></i>
                                <i class="fa fa-check" v-if="recipientsData.firstName"></i> -->
                            </span>
                        </div>
                    </Field>
                    <div class="form-group">
                        <span class="float-label">
                            <input type="text" id="middleName" v-model="recipientsData.middleName" class="form-control" placeholder="Middle Name*">
                            <label for="middleName" class="control-label">Middle Name(Only if on ID)</label>
                            <div class="line"></div>
                        </span>
                    </div>
                    <Field rules="required" name="lastName" v-slot="{ handleChange }">
                        <div class="form-group" :class="{'errors': errors.length && !recipientsData.lastName,'success':recipientsData.lastName}">
                            <span class="float-label">
                                <input type="text" id="lastName" @update:model-value="handleChange" v-model="recipientsData.lastName" class="form-control" placeholder="Last Name*">
                                <label for="lastName" class="control-label">Last Name on ID*</label>
                                <div class="line"></div>
                                <i class="fa fa-times" v-if="errors && errors.lastName"></i>
                                <i class="fa fa-check" v-if="!errors && !errors.lastName"></i>
                                <!-- <i class="fa fa-times" v-if="errors.length && !recipientsData.lastName"></i>
                                <i class="fa fa-check" v-if="recipientsData.lastName"></i> -->
                            </span>
                        </div>
                    </Field>
                    <div class="form-group">
                        <span class="float-label">
                            <input type="text" id="preferredName" v-model="recipientsData.preferredName" class="form-control" placeholder="preferred Name">
                            <label for="preferredName" class="control-label">preferred Name (optional)</label>
                            <div class="line"></div>
                        </span>
                    </div>
                </div>
                <div class="col-md-6 col-12">
                    <div class="country-dropdown">
                        <div class="form-group form-select multiselect-grp">
                            <span class="float-label">
                                <label for="Country">Select a Relationship</label>
                                <multiselect
                                    placeholder=""
                                    v-model="recipientsData.relationship"  
                                    :show-labels="false" 
                                    :options="relationshipOptions" 
                                    :searchable="false" >
                                </multiselect>
                            </span>
                        </div>
                    </div>
                    <div class="country-dropdown">
                        <div class="form-group form-select multiselect-grp" :class="{'errors': errors[0],'success':recipientsData.countryofResidence && recipientsData.countryofResidence.length != 0  && !errors[0]}">
                            <span class="float-label">
                            <label for="Country">Country of Residence*</label>
                                <multiselect
                                    placeholder=""
                                    v-model="recipientsData.countryofResidence"
                                    :show-labels="false" 
                                    :options="countryOptions" 
                                    :searchable="false"
                                    label="name" 
                                    track-by="name"
                                    @select="setPrimaryNumberCountryCode">
                                    <template v-slot:option="{ option }">
                                        <div class="option-title">
                                            <span class="mr-2" :class="option.flagClass"></span>
                                            <span>{{ option.name }}</span>
                                        </div>
                                    </template>
                                </multiselect>
                                <!-- <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                <i class="fa fa-check" v-if="recipientsData.countryofResidence && recipientsData.countryofResidence.length != 0 && !errors[0]"></i>  -->
                            </span>
                        </div>
                    </div>
                    <div class="form-group" :class="{'errors': recipientsData.mobileInputError == false,'success':recipientsData.primaryNumber && recipientsData.mobileInputError}">
                        <div class="float-label">
                            <label for="primary_number" class="control-label">Mobile Number*</label>
                            <vue-tel-input
                                v-model="recipientsData.primaryNumber"
                                v-bind:id="primaryMobileNumber"
                                ref="primaryNumber"
                                @keydown="restrictSpecialCharacter($event)"
                                @validate ="mobileInputValidate"
                            >
                            <template v-slot:arrow-icon>
                                <span class="vti__dropdown-arrow">{{ '▼' }}</span>
                            </template>
                            </vue-tel-input>
                            <div class="line"></div>
                            <i class="fa fa-times" v-if="recipientsData.mobileInputError == false"></i>
                            <i class="fa fa-check" v-if="recipientsData.mobileInputError && recipientsData.primaryNumber"></i>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between align-items-center mb-3 mh-48">
                        <p class="mb-0">Want to receive Email notifications?</p>
                        <div class="custom-switch success-error-bg">
                            <input type="checkbox" class="custom-switch-input" id="customSwitch1" v-model="recipientsData.emailNotification">
                            <label class="custom-switch-label" for="customSwitch1">
                                <span class="custom-switch-text1">Yes</span>
                                <span class="custom-switch-text2">No</span>
                            </label>
                        </div>
                    </div>
                    <Field rules="required" name="email" v-slot="{ handleChange }">
                        <div class="form-group" :class="{'errors': errors.length && !recipientsData.email || errors[0],'success':recipientsData.email && !errors[0]}">
                            <span class="float-label">
                                <input type="email" @update:model-value="handleChange" v-model="recipientsData.email" class="form-control" name="email" id="forgot-email">
                                <label for="forgot-email" class="control-label">Email Address*</label>
                                <div class="line"></div>
                                <i class="fa fa-times" v-if="errors && errors.email"></i>
                                <i class="fa fa-check" v-if="!errors && !errors.email"></i>
                                <!-- <i class="fa fa-times" v-if="errors.length && !recipientsData.email || errors[0]"></i>
                                <i class="fa fa-check" v-if="recipientsData.email && !errors[0]"></i> -->
                            </span>
                        </div>
                    </Field>
                </div>
            </div>
            <div class="align-items-center d-flex flex-column justify-content-between mt-3 w-100">
                <b-button type="submit" variant="primary btn-mw" size="lg">Add Recipients</b-button>
                <b-button @click="$bvModal.hide('add_recipients')" size="lg" variant="outline-secondary mt-3 btn-mw">Cancel</b-button>
            </div>
        </Form>
    </b-modal>
</template>
<script>
import { Form, Field } from 'vee-validate';
import VueMultiselect from 'vue-multiselect'
export default {
    name:'AddRecipients',
    components: {
        Form,
        Field,
        'Multiselect': VueMultiselect
    },data(){
        return{
            recipientsData:{
                title:'',
                firstName: '',
                middleName:'',
                lastName:'',
                countryofResidence:'',
                relationship:'',
                preferredName:'',
                primaryNumber:'',
                emailNotification:true,
                email:'',
                mobileInputError:'',
            },

            titleOptions:['Mr.', 'Mrs.','Miss','Dr.','Ms.','Prof.','Rev.','Lady','Sir','Capt.','Major','Lt.-Col.','Col.','Lady','Lt.-Cmdr.','Hon.','Cmdr.','Flt. Lt.','Brgdr.','Judge','Lord'],
            relationshipOptions:['Wife/Husband/Partner','Mother/Father','Daughter/Son','Sister/Brother','Family-Other','Friend','Business Partner','Employee','Other'],
            countryOptions: [
                { name: 'Ghana', flagClass:"flag-icon flag-icon-gh fi-md fi-round",primaryNumberCode:'GH'},
                { name: 'United Kingdom', flagClass: "flag-icon flag-icon-gb fi-md fi-round",primaryNumberCode:'GB'},
                { name: 'France' , flagClass: "flag-icon flag-icon-fr fi-md fi-round",primaryNumberCode:'FR'},
                { name: 'United States', flagClass: "flag-icon flag-icon-us fi-md fi-round",primaryNumberCode:'US'},
            ],

            primaryMobileNumber:{
                mode: "auto",
                autoFormat:false,
                defaultCountry:"GB",
                dropdownOptions:{
                    showFlags: true,
                    disabled:true,
                    showDialCodeInSelection:true
                }
            },
        }
    },methods:{
        restrictSpecialCharacter(e) {
            if (/^\W$/.test(e.key)) {
                e.preventDefault();
            }
        },

        mobileInputValidate(phoneObject){
            this.recipientsData.mobileInputError = phoneObject.valid
        },

        setPrimaryNumberCountryCode(defaultCountry) {
            this.$refs.primaryNumber.choose(defaultCountry.primaryNumberCode);
        },

        addRecipients(){
            if(typeof(this.recipientsData.mobileInputError) == 'undefined'){
                this.recipientsData.mobileInputError = false
            }
            if(this.recipientsData.mobileInputError){
                this.$bvModal.hide('add_recipients');
            }
        }
    },watch: {
        mobileNumber(val) {
            this.recipientsData.primaryNumber = val.replace(/\W/g, "");
        },
    }
}
</script>