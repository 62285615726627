<template>
    <div class="container-fluid">
        <!-- BreadCrumb-->
        <div class="block-header">
            <BreadCrumb></BreadCrumb>
        </div>
        <!-- All Acounts-->
        <div class="row clearfix">
            <div class="col-12">
                <div class="card tab-card p-3 no-shadow">
                    <b-tabs nav-class="nav-tabs-new2" :no-nav-style="true">
                        <!-- List Tab -->
                        <b-tab title="List" active>
                           <ComplianceListComponent></ComplianceListComponent>
                        </b-tab>

                        <!-- Requirements Tab -->
                        <b-tab title="Requirements">
                            <ComRequirementsComponent></ComRequirementsComponent>
                        </b-tab>
                    </b-tabs>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import BreadCrumb from '@/components/BreadCrumb.vue';
import ComplianceListComponent from '@/components/admin/setting/ComplianceListComponent.vue';
import ComRequirementsComponent from '@/components/admin/setting/ComRequirementsComponent.vue';
export default {
    name:'ComplianceComponent',
    components: {
        BreadCrumb,
        ComRequirementsComponent,
        ComplianceListComponent
    }
}
</script>
