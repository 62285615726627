<template>
    <div class="card card-top-line">
        <div class="header d-flex align-items-center justify-content-between">
            <h2>Account Holder Information</h2>
            <i class="fa fa-edit font-20" @click="editDetails()"></i>
        </div>
        <div class="body">
            <div class="edit-form">
                <Form @submit="accountHolderSubmit" id="personal-info" ref="form" :validation-schema="schema" v-slot="{ errors }">
                    <div class="row">
                        <div class="col-sm-6 col-12">
                            <Field rules="required" name="title" v-slot="{ handleChange }">
                                <div class="form-group" :class="{'errors': errors && errors.title,'success':!errors.title && !errors}">
                                    <span class="float-label">
                                        <input type="text" v-model="accountHolder.title" @update:model-value="handleChange" class="form-control" id="title" placeholder="Title*" :disabled="accountHolder.readonlyMode">
                                        <label for="title" class="control-label">Title*</label>
                                        <div class="line"></div>
                                        <i class="fa fa-times" v-if="errors && errors.title && !accountHolder.readonlyMode"></i>
                                        <i class="fa fa-check" v-if="!errors.title && !accountHolder.readonlyMode"></i>
                                    </span>
                                </div>
                            </Field>
                            <Field rules="required" name="middleName" v-slot="{ handleChange }">
                                <div class="form-group" :class="{'errors': errors && errors.middleName,'success':!errors.middleName && !errors}">
                                    <span class="float-label">
                                        <input type="text" v-model="accountHolder.middleName" @update:model-value="handleChange" class="form-control" id="middleName" placeholder="Middle Name*" :disabled="accountHolder.readonlyMode">
                                        <label for="middleName" class="control-label">Middle Name*</label>
                                        <div class="line"></div>
                                        <i class="fa fa-times" v-if="errors && errors.middleName && !accountHolder.readonlyMode"></i>
                                        <i class="fa fa-check" v-if="!errors.middleName && !accountHolder.readonlyMode"></i>
                                    </span>
                                </div>
                            </Field>
                            <Field rules="required" name="gender" >
                                <div class="country-dropdown">
                                    <div class="form-group form-select multiselect-grp" :class="{'errors': errors && errors.gender,'success':!errors.gender || accountHolder.gender}">
                                        <span class="float-label">
                                        <label for="gender">Gender*</label>
                                            <multiselect
                                                :disabled="accountHolder.readonlyMode"
                                                placeholder=""
                                                v-model="accountHolder.gender"
                                                :show-labels="false" 
                                                :options="gender_options" 
                                                :searchable="false">
                                                <template v-slot:singleLabel="{ option }">{{ option }}</template>
                                                <template v-slot:option="{ option }">
                                                    <div class="option__desc">
                                                        <span class="option__title">{{ option }}</span>
                                                    </div>
                                                </template>
                                            </multiselect>  
                                            <i class="fa fa-times" v-if="errors && errors.gender && !accountHolder.readonlyMode"></i>
                                            <i class="fa fa-check" v-if="accountHolder.gender && !accountHolder.readonlyMode"></i>
                                        </span>
                                    </div>
                                </div>
                            </Field>
                            <Field rules="required" name="birthdate" >
                                <div class="form-group datepicker" :class="{'errors': errors && errors.birthdate,'success':!errors.birthdate && !errors}">
                                    <span class="float-label">
                                        <b-input-group :class="{'datepicker-disable':accountHolder.readonlyMode}">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">Birthdate</span>
                                                <span class="input-group-text" @click="$refs.birthdate.showCalendar()"><i class="icon-calendar"></i></span>
                                            </div>
                                            <datepicker class="form-control" ref="birthdate" format="YYYY-MM-DD" placeholder="" v-model="accountHolder.birthdate" :disabled="accountHolder.readonlyMode" :typeable="true"></datepicker>
                                        </b-input-group>
                                        <i class="fa fa-times" v-if="errors && errors.birthdate && !accountHolder.readonlyMode"></i>
                                        <i class="fa fa-check" v-if="!errors.birthdate && !accountHolder.readonlyMode"></i>
                                    </span>
                                </div>
                            </Field>
                            
                            <Field rules="required" name="cob" >
                                <div class="country-dropdown">
                                    <div class="form-group form-select multiselect-grp" :class="{'errors': errors && errors.cob,'success':!errors.cob || accountHolder.countryofBirth}">
                                        <span class="float-label" :class="{'multiselect--disabled':accountHolder.readonlyMode}">
                                        <label for="Country">Country of Birth*</label>
                                            <multiselect
                                                :disabled="accountHolder.readonlyMode"
                                                placeholder=""
                                                v-model="accountHolder.countryofBirth" 
                                                :show-labels="false" 
                                                :options="countryofBirth_options" 
                                                :searchable="false"
                                                label="name" 
                                                track-by="name">
                                                <template v-slot:singleLabel="{ option }">{{ option.name }}</template>
                                                <template v-slot:option="{ option }">
                                                    <span :class="option.flagClass"></span>
                                                    <div class="option__desc">
                                                        <span class="option__title">{{ option.name }}</span>
                                                    </div>
                                                </template>
                                            </multiselect>  
                                            <i class="fa fa-times" v-if="errors && errors.cob && !accountHolder.readonlyMode"></i>
                                            <i class="fa fa-check" v-if="accountHolder.countryofBirth && !accountHolder.readonlyMode"></i>
                                        </span>
                                    </div>
                                </div>
                            </Field>
                            <Field rules="required|email" name="email_address" v-slot="{ handleChange }">
                                <div class="form-group" :class="{'errors': errors && errors.email_address,'success':!errors.email_address && !errors}">
                                    <span class="float-label">
                                        <input type="email" v-model="accountHolder.email" @update:model-value="handleChange" class="form-control" name="email" id="email_address" placeholder="Email Address*" :disabled="accountHolder.readonlyMode">
                                        <label for="email_address" class="control-label">Email Address*</label>
                                        <div class="line"></div>
                                        <i class="fa fa-times" v-if="errors && errors.email_address && !accountHolder.readonlyMode"></i>
                                        <i class="fa fa-check" v-if="!errors.email_address && !accountHolder.readonlyMode"></i>
                                    </span>
                                </div>
                            </Field>
                            <Field rules="required" name="phoneNumber" v-slot="{ handleChange }" >
                                <div class="form-group" :class="{'errors': errors && errors.phoneNumber,'success':!errors.phoneNumber && !errors}">
                                    <span class="float-label">
                                        <input type="text" v-model="accountHolder.phoneNumber" @update:model-value="handleChange" class="form-control" id="phoneNumber" placeholder="Mobile Number*" :disabled="accountHolder.readonlyMode">
                                        <label for="phoneNumber" class="control-label">Mobile Number*</label>
                                        <div class="line"></div>
                                        <i class="fa fa-times" v-if="errors && errors.phoneNumber && !accountHolder.readonlyMode"></i>
                                        <i class="fa fa-check" v-if="!errors.phoneNumber && !accountHolder.readonlyMode"></i>
                                    </span>
                                </div>
                            </Field>
                            <Field rules="required" name="role" >
                                <div class="country-dropdown">
                                    <div class="form-group form-select multiselect-grp" :class="{'errors': errors && errors.role,'success':!errors.role || accountHolder.role}">
                                        <span class="float-label">
                                        <label for="Country">Your Role*</label>
                                            <multiselect
                                                :disabled="accountHolder.readonlyMode"
                                                placeholder=""
                                                v-model="accountHolder.role"
                                                :show-labels="false" 
                                                :options="role_options" 
                                                :searchable="false">
                                                <template v-slot:singleLabel="{ option }">{{ option }}</template>
                                                <template v-slot:option="{ option }">
                                                    <span :class="option.flagClass"></span>
                                                    <div class="option__desc">
                                                        <span class="option__title">{{ option }}</span>
                                                    </div>
                                                </template>
                                            </multiselect>  
                                            <i class="fa fa-times" v-if="errors && errors.role && !accountHolder.readonlyMode"></i>
                                            <i class="fa fa-check" v-if="accountHolder.role && !accountHolder.readonlyMode"></i>
                                        </span>
                                    </div>
                                </div>
                            </Field>
                            
                        </div>
                        <div class="col-sm-6 col-12">
                            <Field rules="required" name="firstName" v-slot="{ handleChange }">
                                <div class="form-group" :class="{'errors': errors && errors.firstName,'success':!errors.firstName && !errors}">
                                    <span class="float-label">
                                        <input type="text" v-model="accountHolder.firstName" @update:model-value="handleChange" class="form-control" id="firstName" placeholder="First Name*" :disabled="accountHolder.readonlyMode">
                                        <label for="firstName" class="control-label">First Name*</label>
                                        <div class="line"></div>
                                        <i class="fa fa-times" v-if="errors && errors.firstName && !accountHolder.readonlyMode"></i>
                                        <i class="fa fa-check" v-if="!errors.firstName && !accountHolder.readonlyMode"></i>
                                    </span>
                                </div>
                            </Field>
                            <Field rules="required" name="lastName" v-slot="{ handleChange }">
                                <div class="form-group" :class="{'errors': errors && errors.lastName,'success':!errors.lastName && !errors}">
                                    <span class="float-label">
                                        <input type="text" v-model="accountHolder.lastName" @update:model-value="handleChange" class="form-control" id="lastName" placeholder="Last Name*" :disabled="accountHolder.readonlyMode">
                                        <label for="lastName" class="control-label">Last Name*</label>
                                        <div class="line"></div>
                                        <i class="fa fa-times" v-if="errors && errors.lastName && !accountHolder.readonlyMode"></i>
                                        <i class="fa fa-check" v-if="!errors.lastName && !accountHolder.readonlyMode"></i>
                                    </span>
                                </div>
                            </Field>
                            <Field rules="required" name="cor">
                                <div class="country-dropdown">
                                    <div class="form-group form-select multiselect-grp" :class="{'errors': errors && errors.cor,'success':!errors.cor || accountHolder.countryofResidence}">
                                        <span class="float-label" :class="{'multiselect--disabled':accountHolder.readonlyMode}">
                                        <label for="Country">Country of Residence*</label>
                                            <multiselect
                                                :disabled="accountHolder.readonlyMode"
                                                placeholder=""
                                                v-model="accountHolder.countryofResidence"
                                                :show-labels="false" 
                                                :options="countryofResidence_options" 
                                                :searchable="false"
                                                label="name" 
                                                track-by="name">
                                                <template v-slot:singleLabel="{ option }">{{ option.name }}</template>
                                                <template v-slot:option="{ option }">
                                                    <span :class="option.flagClass"></span>
                                                    <div class="option__desc">
                                                        <span class="option__title">{{ option.name }}</span>
                                                    </div>
                                                </template>
                                            </multiselect>  
                                            <i class="fa fa-times" v-if="errors && errors.cor && !accountHolder.readonlyMode"></i>
                                            <i class="fa fa-check" v-if="accountHolder.countryofResidence && !accountHolder.readonlyMode"></i>
                                        </span>
                                    </div>
                                </div>
                            </Field>
                            <Field rules="required" name="address1" v-slot="{ handleChange }" >
                                <div class="form-group" :class="{'errors': errors && errors.address1,'success':!errors.address1 && !errors}">
                                    <span class="float-label">
                                        <input type="text" v-model="accountHolder.address1" @update:model-value="handleChange" class="form-control" id="address1" placeholder="Address Line 1*" :disabled="accountHolder.readonlyMode">
                                        <label for="address1" class="control-label">Address Line 1*</label>
                                        <div class="line"></div>
                                        <i class="fa fa-times" v-if="errors && errors.address1 && !accountHolder.readonlyMode"></i>
                                        <i class="fa fa-check" v-if="!errors.address1 && !accountHolder.readonlyMode"></i>
                                    </span>
                                </div>
                            </Field>
                            <div class="form-group" >
                                <span class="float-label">
                                    <input type="text" v-model="accountHolder.address2" class="form-control" id="address2" placeholder="Address Line 2" :disabled="accountHolder.readonlyMode">
                                    <label for="address2" class="control-label">Address Line 2</label>
                                    <div class="line"></div>
                                </span>
                            </div>
                            <Field rules="required" name="town" v-slot="{ handleChange }" >
                                <div class="form-group" :class="{'errors': errors && errors.town,'success':!errors.town && !errors}">
                                    <span class="float-label">
                                        <input type="text" class="form-control" id="town" @update:model-value="handleChange" v-model="accountHolder.town" placeholder="Town*" :disabled="accountHolder.readonlyMode">
                                        <label for="town" class="control-label">Town*</label>
                                        <div class="line"></div>
                                        <i class="fa fa-times" v-if="errors && errors.town && !accountHolder.readonlyMode"></i>
                                        <i class="fa fa-check" v-if="!errors.town && !accountHolder.readonlyMode"></i>
                                    </span>
                                </div>
                            </Field>
                            <Field rules="required" name="city" v-slot="{ handleChange }" >
                                <div class="form-group" :class="{'errors': errors && errors.city,'success':!errors.city && !errors}">
                                    <span class="float-label">
                                        <input type="text" @update:model-value="handleChange" v-model="accountHolder.city" class="form-control" id="city" placeholder="City*" :disabled="accountHolder.readonlyMode">
                                        <label for="city" class="control-label">City*</label>
                                        <div class="line"></div>
                                        <i class="fa fa-times" v-if="errors && errors.city && !accountHolder.readonlyMode"></i>
                                        <i class="fa fa-check" v-if="!errors.city && !accountHolder.readonlyMode"></i>
                                    </span>
                                </div>
                            </Field>
                            <Field rules="required" name="state" v-slot="{ handleChange }" >
                                <div class="form-group" :class="{'errors': errors && errors.state,'success':!errors.state && !errors}">
                                    <span class="float-label">
                                        <input type="text" @update:model-value="handleChange" v-model="accountHolder.state" class="form-control" id="state" placeholder="State/Province/Region/County*" :disabled="accountHolder.readonlyMode">
                                        <label for="state" class="control-label">State/Province/Region/County*</label>
                                        <div class="line"></div>
                                        <i class="fa fa-times" v-if="errors && errors.state && !accountHolder.readonlyMode"></i>
                                        <i class="fa fa-check" v-if="!errors.state && !accountHolder.readonlyMode"></i>
                                    </span>
                                </div>
                            </Field>
                            <Field rules="required" name="zipCode" v-slot="{ handleChange }" >
                                <div class="form-group" :class="{'errors': errors && errors.zipCode,'success':!errors.zipCode && !errors}">
                                    <span class="float-label">
                                        <input type="text" @update:model-value="handleChange" v-model="accountHolder.zipCode" class="form-control" id="zipCode" placeholder="Post/Zip Code*" :disabled="accountHolder.readonlyMode">
                                        <label for="zipCode" class="control-label">Post/Zip Code*</label>
                                        <div class="line"></div>
                                        <i class="fa fa-times" v-if="errors && errors.zipCode && !accountHolder.readonlyMode"></i>
                                        <i class="fa fa-check" v-if="!errors.zipCode && !accountHolder.readonlyMode"></i>
                                    </span>
                                </div>
                            </Field>
                        </div>
                    </div>
                    <div v-if="!accountHolder.readonlyMode" class="justify-content-center d-flex">
                        <b-button size="lg" @click="accountHolderSubmit()" class="btn-mw">Cancel</b-button>
                        <b-button type="submit" variant="primary ml-1 btn-mw" size="lg">Update</b-button>
                    </div>
                </Form>
            </div>
        </div>
    </div>
</template>
<script>
import VueMultiselect from 'vue-multiselect'
import { Form, Field } from 'vee-validate'
export default {
    name: 'AccountHolderInfo-Profile',
    components:{
        datepicker: () => import('vue-date-picker'),
        Form,
        Field,
        'Multiselect': VueMultiselect,
    },
    data(){
        return{
            //Account Holder Information
            accountHolder:{
                readonlyMode : true,
                title:'Mr.',
                firstName:'James',
                middleName:'Charlee',
                lastName:'Smith',
                birthdate:'20-Jan-1990',
                gender:['Male'],
                countryofResidence:[{ name: 'United Kingdom', flagClass: "flag-icon flag-icon-gb fi-xl fi-round"}],
                address1:'10 Downing Street,LONDON',
                address2:'1 Bishopthorpe Road',
                town:'Penllyn',
                city:'Manchester',
                state:'England',
                zipCode:'CF71 0HZ',
                role:['Director'],
                phoneNumber:'079 4510 8919',
                email:'user@gmail.com',
                countryofBirth:[{ name: 'United Kingdom', flagClass: "flag-icon flag-icon-gb fi-xl fi-round"}]
            },

            countryofBirth_options: [
                { name: 'India', flagClass:"flag-icon flag-icon-in fi-xl fi-round"},
                { name: 'United Kingdom', flagClass: "flag-icon flag-icon-gb fi-xl fi-round"},
                { name: 'France' , flagClass: "flag-icon flag-icon-fr fi-xl fi-round"},
                { name: 'United States', flagClass: "flag-icon flag-icon-us fi-xl fi-round"},
            ],

            countryofResidence_options: [
                { name: 'India', flagClass:"flag-icon flag-icon-in fi-xl fi-round"},
                { name: 'United Kingdom', flagClass: "flag-icon flag-icon-gb fi-xl fi-round"},
                { name: 'France' , flagClass: "flag-icon flag-icon-fr fi-xl fi-round"},
                { name: 'United States', flagClass: "flag-icon flag-icon-us fi-xl fi-round"},
            ],

            gender_options:['Male','Female'],

            role_options:[
                'Owner',
                'Founder and CEO',
                'CEO/MD',
                'CTO/Head of IT',
                'CMO/Sales/Marketing Director',
                'Business Development Manager',
                'Director',
                'Manager',
            ],
        }
    },methods:{
        editDetails(){
            this.accountHolder.readonlyMode = false
        },

        //submit Personal Details information
        accountHolderSubmit(){
            // Object.values(this.accountHolder).filter(user => {
            //     console.log(user);
            // });
            this.accountHolder.readonlyMode  = true
        },
    }
}
</script>