<template>
     <div class="auth-main">
        <div class="auth-left d-lg-block">
            <AuthLeftSideComponent/>
        </div>
        <div class="auth-right">
            <div class="right-main d-flex flex-column h-100">
                
                <RightHeaderComponent/>
                <slot></slot>
                <RightFooterComponent/>
            </div>
        </div> 
    </div> 
</template>
<script>
import AuthLeftSideComponent from "@/components/admin/layouts/auth_left/AuthLeftSideComponent.vue";
import RightHeaderComponent from "@/components/admin/layouts/auth_right_header/RightHeaderComponent.vue";
import RightFooterComponent from "@/components/admin/layouts/auth_right_footer/RightFooterComponent.vue";
export default {
    name:'AuthLayout',
    components: {
        AuthLeftSideComponent,
        RightHeaderComponent,
        RightFooterComponent
    }
}
</script>
