<template>
    <ul class="list-unstyled search-list no-search-list send-list">
        <li>
            <a href="#" v-b-modal.addMobileMoney class="btn btn-add-new-recipients d-flex align-items-center justify-content-start mt-0">
                <i class="fa fa-plus"></i> Add a Mobile Money Account
            </a>
            <b-modal id="addMobileMoney" title="Add Mobile Money Details" hide-footer>
                <div class="modal-area"> 
                    <Form class="form-auth-small" @submit="addMobileMoney" :validation-schema="schema" v-slot="{ errors }" id="addMobileMoney" ref="addMobileMoney">
                        <div class="row">
                            <!-- Country -->
                            <div class="col-12">
                                <Field name="currency" rules="required">
                                    <div class="country-dropdown">
                                        <div class="form-group form-select multiselect-grp" :class="{'errors': errors && errors.currency,'success':!errors && !errors.currency}">
                                            <span class="float-label">
                                            <label for="Country">Select Country of Residence*</label>
                                                <multiselect
                                                    placeholder=""
                                                    v-model="addMobileMoney.country"
                                                    :show-labels="false" 
                                                    :options="country_options"
                                                    :searchable="false"
                                                    label="name" 
                                                    track-by="name"
                                                    :custom-label="currencyFlag"
                                                    @select="fromCurrencySelected"
                                                    >
                                                    <template v-slot:singleLabel="{ option }">
                                                        <span class="mr-2" :class="option.flagClass"></span>
                                                        <span class="option-title">
                                                            <span>{{ option.name }}</span>
                                                        </span>
                                                    </template>
                                                    <template v-slot:option="{ option }">
                                                        <div class="option-title">
                                                            <span class="mr-2" :class="option.flagClass"></span>
                                                            <span>{{ option.name }}</span>
                                                        </div>
                                                    </template>
                                                </multiselect>
                                            </span>
                                        </div>
                                    </div>
                                </Field>
                            </div>
                            <div class="col-12">
                                <Field rules="required" name="mobile_number">
                                    <div class="form-group" :class="{'errors': mobileInputError == false,'success':addMobileMoney.mobile_number && mobileInputError}">
                                        <span class="float-label">
                                            <vue-tel-input
                                                v-model="addMobileMoney.mobile_number"
                                                v-bind:id="primaryMobileNumber"
                                                @validate ="mobileInputValidate"
                                                ref="telInput"
                                                >
                                                <template v-slot:arrow-icon>
                                                    <span class="vti__dropdown-arrow">{{ '▼' }}</span>
                                                </template>
                                            </vue-tel-input>
                                            <label for="primary_number" class="control-label">Contact Mobile Number*</label>
                                            <div class="line"></div>
                                        </span>
                                        <span class="text-danger error-msg" v-if="errors[0]">Please provide unique business name</span>
                                    </div>
                                </Field>
                            </div>
                            <div class="col-12">
                                <Field name="currency" rules="required">
                                    <div class="country-dropdown">
                                        <div class="form-group form-select multiselect-grp" :class="{'errors': errors && errors.currency,'success':!errors && !errors.currency}">
                                            <span class="float-label">
                                            <label for="Country">Mobile Money Network*</label>
                                                <multiselect
                                                    placeholder="Mobile Money Network"
                                                    v-model="addMobileMoney.mobile_network"
                                                    :show-labels="false" 
                                                    :options="mobile_network"
                                                    :searchable="false"
                                                    label="name" 
                                                    track-by="name"
                                                    :custom-label="currencyFlag"
                                                    >
                                                    <template v-slot:singleLabel="{ option }">
                                                        <span class="mr-2" :class="option.flagClass"></span>
                                                        <span class="option-title">
                                                            <span>{{ option.name }}</span>
                                                        </span>
                                                    </template>
                                                    <template v-slot:option="{ option }">
                                                        <div class="option-title">
                                                            <span class="mr-2" :class="option.flagClass"></span>
                                                            <span>{{ option.name }}</span>
                                                        </div>
                                                    </template>
                                                </multiselect>
                                            </span>
                                        </div>
                                    </div>
                                </Field>
                            </div>
                            <div class="col-12">
                                <div class="d-flex justify-content-between align-items-center m-b-20">
                                    <p class="mb-0">Save the recored for future use.</p>
                                    <div class="custom-switch success-error-bg">
                                        <input type="checkbox" class="custom-switch-input" id="customSwitch1" v-model="addMobileMoney.isSaveForFuture">
                                        <label class="custom-switch-label" for="customSwitch1">
                                            <span class="custom-switch-text1">Yes</span>
                                            <span class="custom-switch-text2">No</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="align-items-center d-flex flex-column justify-content-between mt-3 w-100">
                            <b-button type="submit" block class="btn btn-primary btn-mw btn-lg" size="lg">Add Account</b-button>
                            <b-button type="submit" block class="btn btn-lg btn-outline-secondary" size="lg" @click="$bvModal.hide('addMobileMoney')">Cancel</b-button>
                        </div>
                    </Form>
                </div>
            </b-modal>
        </li>
        <li v-for="(mobilemoney,index) in mobileMoneyListing" :key="index">
            <router-link :to="{ path: '/admin/send-money/receive-money-by',query:{ type: $route.query.type }}" class="send-list-card">
                <div class="d-flex align-items-center">
                    <div class="mobile-img">
                        <img :src="mobilemoney.image" alt="">
                    </div>
                    <div class="name ml-3">
                        <h6 class="mb-1">{{ mobilemoney.name }}</h6>
                        <p class="mb-0 d-flex align-items-center">
                            <span class="flag-icon flag-icon-gh fi-md fi-round"></span>
                            <strong class="ml-2">{{ mobilemoney.number }}</strong>
                        </p>
                    </div>
                </div>
                <div class="icon-right">
                    <i class="arrow-right" v-html="mobilemoney.arrowImage"></i>
                </div>
            </router-link>
        </li>
    </ul>
</template>
<script>
import { Form, Field } from 'vee-validate';
import VueMultiselect from 'vue-multiselect'
export default {
    name:'MobileMoneyList',
    components: {
        Form,
        Field,
        'Multiselect': VueMultiselect,
    },
    data(){
        return{
            mobileInputError:'',
            primaryMobileNumber:{
                mode: "auto",
                defaultCountry:"GB",
                autoFormat:false,
                dropdownOptions:{
                    showFlags: true,
                    disabled:true,
                    showDialCodeInSelection:true
                }, 
            },
            country_options:[
                { name: 'United Kingdom', flagClass: "flag-icon flag-icon-gb fi-md fi-round",primaryNumberCode:'GB'},
                { name: 'India', flagClass:"flag-icon flag-icon-in fi-md fi-round",primaryNumberCode:'IN'},
                { name: 'France' , flagClass: "flag-icon flag-icon-fr fi-md fi-round",primaryNumberCode:'FR'},
                { name: 'United States', flagClass: "flag-icon flag-icon-us fi-md fi-round",primaryNumberCode:'US'},
            ],
            mobile_network: [
                { name: 'MTN Mobile Money'},
                { name: 'Vodafone Cash'},
                { name: 'Airtel-Tigo Cash'}
            ],
            addMobileMoney: {
                country: { name: 'United Kingdom', flagClass: "flag-icon flag-icon-gb fi-md fi-round",primaryNumberCode:'GB'},
                mobile_number: null,
                mobile_network: null,
                isSaveForFuture: false,
            },
            mobileMoneyListing:[
            {
                name:'MTN Mobile Money',
                number: '02438193999',
                image: require(`@/assets/img/send-money/mtn.png`),
                arrowImage:`<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path class="fill" fill-rule="evenodd" clip-rule="evenodd" d="M1 8.00002C1 7.86741 1.05268 7.74024 1.14645 7.64647C1.24021 7.5527 1.36739 7.50002 1.5 7.50002H13.293L10.146 4.35402C10.0521 4.26013 9.99937 4.1328 9.99937 4.00002C9.99937 3.86725 10.0521 3.73991 10.146 3.64602C10.2399 3.55213 10.3672 3.49939 10.5 3.49939C10.6328 3.49939 10.7601 3.55213 10.854 3.64602L14.854 7.64602C14.9006 7.69247 14.9375 7.74764 14.9627 7.80839C14.9879 7.86913 15.0009 7.93425 15.0009 8.00002C15.0009 8.06579 14.9879 8.13091 14.9627 8.19165C14.9375 8.2524 14.9006 8.30758 14.854 8.35402L10.854 12.354C10.7601 12.4479 10.6328 12.5007 10.5 12.5007C10.3672 12.5007 10.2399 12.4479 10.146 12.354C10.0521 12.2601 9.99937 12.1328 9.99937 12C9.99937 11.8672 10.0521 11.7399 10.146 11.646L13.293 8.50002H1.5C1.36739 8.50002 1.24021 8.44734 1.14645 8.35357C1.05268 8.25981 1 8.13263 1 8.00002Z"/>
                </svg>`
            },
            {
                name:'Airtel Tigo Mobile Money',
                number: '02438193999',
                image: require(`@/assets/img/send-money/airtle-tigo.png`),
                arrowImage:`<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path class="fill" fill-rule="evenodd" clip-rule="evenodd" d="M1 8.00002C1 7.86741 1.05268 7.74024 1.14645 7.64647C1.24021 7.5527 1.36739 7.50002 1.5 7.50002H13.293L10.146 4.35402C10.0521 4.26013 9.99937 4.1328 9.99937 4.00002C9.99937 3.86725 10.0521 3.73991 10.146 3.64602C10.2399 3.55213 10.3672 3.49939 10.5 3.49939C10.6328 3.49939 10.7601 3.55213 10.854 3.64602L14.854 7.64602C14.9006 7.69247 14.9375 7.74764 14.9627 7.80839C14.9879 7.86913 15.0009 7.93425 15.0009 8.00002C15.0009 8.06579 14.9879 8.13091 14.9627 8.19165C14.9375 8.2524 14.9006 8.30758 14.854 8.35402L10.854 12.354C10.7601 12.4479 10.6328 12.5007 10.5 12.5007C10.3672 12.5007 10.2399 12.4479 10.146 12.354C10.0521 12.2601 9.99937 12.1328 9.99937 12C9.99937 11.8672 10.0521 11.7399 10.146 11.646L13.293 8.50002H1.5C1.36739 8.50002 1.24021 8.44734 1.14645 8.35357C1.05268 8.25981 1 8.13263 1 8.00002Z"/>
                </svg>`
            },
            {
                name:'Vodafone Mobile Money',
                number: '02438193999',
                image: require(`@/assets/img/send-money/vodafone.png`),
                arrowImage:`<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path class="fill" fill-rule="evenodd" clip-rule="evenodd" d="M1 8.00002C1 7.86741 1.05268 7.74024 1.14645 7.64647C1.24021 7.5527 1.36739 7.50002 1.5 7.50002H13.293L10.146 4.35402C10.0521 4.26013 9.99937 4.1328 9.99937 4.00002C9.99937 3.86725 10.0521 3.73991 10.146 3.64602C10.2399 3.55213 10.3672 3.49939 10.5 3.49939C10.6328 3.49939 10.7601 3.55213 10.854 3.64602L14.854 7.64602C14.9006 7.69247 14.9375 7.74764 14.9627 7.80839C14.9879 7.86913 15.0009 7.93425 15.0009 8.00002C15.0009 8.06579 14.9879 8.13091 14.9627 8.19165C14.9375 8.2524 14.9006 8.30758 14.854 8.35402L10.854 12.354C10.7601 12.4479 10.6328 12.5007 10.5 12.5007C10.3672 12.5007 10.2399 12.4479 10.146 12.354C10.0521 12.2601 9.99937 12.1328 9.99937 12C9.99937 11.8672 10.0521 11.7399 10.146 11.646L13.293 8.50002H1.5C1.36739 8.50002 1.24021 8.44734 1.14645 8.35357C1.05268 8.25981 1 8.13263 1 8.00002Z"/>
                </svg>`
            },
        ]
        }
    },method: {
        addMobileMoney(){
            console.log("submit mobile money alled");
        },
        mobileInputValidate(phoneObject){
            this.mobileInputError = phoneObject.valid
        },
    }
}
</script>