import { createApp } from "vue";
import Vue from "vue";
import App from "./App.vue";
import { createWebHistory, createRouter } from "vue-router";
import { BootstrapVue } from "bootstrap-vue";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import VueTelInput from "vue3-tel-input";
import "vue3-tel-input/dist/vue3-tel-input.css";

//import DashboardV1 from '@/components/admin/dashboard/DashboardV1';
import MyWallet from "@/components/admin/my-wallet";
import Transactions from "@/components/admin/Transactions.vue";
import RouterView from "@/components/admin/RouterView.vue";
//send-money
import sms_LandingPage from "@/components/admin/send_money/LandingPage.vue";
import sms_SendMoneyBy from "@/components/admin/send_money/send_myself/SendMoneyBy.vue";
import sms_ReceiveMoneyBy from "@/components/admin/send_money/send_myself/ReceiveMoneyBy.vue";
import sms_SendMoneyByCashPickup from "@/components/admin/send_money/send_myself/SendMoneyByCashPickup.vue";
import sms_RecipientList from "@/components/admin/send_money/another_person/RecipientList.vue";
import sms_RecipientDetails from "@/components/admin/send_money/another_person/RecipientDetails.vue";
import sms_BusinessList from "@/components/admin/send_money/business_organisation/BusinessList.vue";
import sms_BusinessDetails from "@/components/admin/send_money/business_organisation/BusinessDetails.vue";

//Settings
import UserListComponent from "@/components/admin/setting/UserListComponent.vue";
import AddUserComponent from "@/components/admin/setting/AddUserComponent.vue";
import ChannelsComponent from "@/components/admin/setting/ChannelsComponent.vue";
import BlankChannelsComponent from "@/components/admin/setting/BlankChannelsComponent.vue";
import PreferencesComponent from "@/components/admin/setting/PreferencesComponent.vue";
import LimitsComponent from "@/components/admin/setting/LimitsComponent.vue";
import PayoutComponent from "@/components/admin/setting/PayoutComponent.vue";
import MyProfileComponent from "@/components/admin/setting/MyProfileComponent.vue";
import SetupProfileComponent from "@/components/admin/setting/SetupProfileComponent.vue";
import ComplianceComponent from "@/components/admin/setting/ComplianceComponent.vue";
import ComRequirementsComponent from "@/components/admin/setting/ComRequirementsComponent.vue";

// fund
import FundComponent from "@/components/admin/fund/FundComponent.vue"; 

//report_bug
import ReportBugComponent from "@/components/admin/report_bug/ReportBugComponent.vue";

//reports
import ReportsComponent from "@/components/admin/reports/ReportsComponent.vue";
// recaptcha
// import { VueReCaptcha } from 'vue-recaptcha-v3';

//axios
import axios from "axios";
Vue.prototype.$http = axios;
import store from "./store";

import IdleVue from "idle-vue";
const eventsHub = new Vue();
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: 600000, //300000, // 5 min
  startAtIdle: false,
});

// Declare base url
Vue.prototype.$baseurl = process.env.VUE_APP_NODE_URL;
Vue.prototype.$apiV2 = process.env.VUE_APP_PYTHON_URL;
Vue.prototype.$transaction = process.env.VUE_APP_TRANSACTION_URL;
Vue.prototype.$standapp = process.env.VUE_APP_STANDAPP_URL;

// Declare payout username password
Vue.prototype.$payoutuser = process.env.VUE_APP_PAYOUT_USERNAME;
Vue.prototype.$payoutpwd = process.env.VUE_APP_PAYOUT_PWD;

const routes = [
  {
    path: "",
    redirect: "/home",
  },
  {
    path: "/home",
    name: "home",
    component: () => import("@/components/admin/home/HomeComponent.vue"),
    meta: { title: "Home Page", layout: "auth-v2" },
  },
  {
    path: "/terms-and-conditions",
    component:
      require("@/components/admin/cms_frontend/TermsConditionsFrontComponent.vue")
        .default,
    meta: { title: "Terms & Conditions", layout: "auth-v2" },
  },

  {
    path: "/authentication",
    redirect: "/page-*",
    component: () => import("@/components/admin/uikit/RouterView.vue"),
    children: [
      {
        path: "page-login",
        component: () =>
          import("@/components/admin/authentication/LoginComponent.vue"),
        name: "login",
        meta: { title: "Login", layout: "auth" },
      },
      {
        path: "page-register",
        component: () =>
          import("@/components/admin/authentication/RegisterComponent.vue"),
        name: "register",
        meta: { title: "Register", layout: "auth" },
      },
      {
        path: "page-personaldetail",
        component: () =>
          import(
            "@/components/admin/authentication/PersonaldetailComponent.vue"
          ),
        name: "pdetail",
        meta: { title: "Personal Detail", layout: "auth" },
      },
      {
        path: "page-password",
        component: () =>
          import("@/components/admin/authentication/PasswordComponent.vue"),
        name: "pwd",
        meta: { title: "Password", layout: "auth" },
      },
      {
        path: "page-recoverPassword",
        component: () =>
          import(
            "@/components/admin/authentication/PasswordRecoverComponent.vue"
          ),
        name: "recoverpwd",
        meta: { title: "Recover Password", layout: "auth" },
      },
      {
        path: "page-changeEmail",
        component: () =>
          import("@/components/admin/authentication/ChangeEmailComponent.vue"),
        name: "changeEmail",
        meta: { title: "Change Email", layout: "auth" },
      },

      {
        path: "page-setPassword",
        component: () =>
          import("@/components/admin/authentication/SetPasswordComponent.vue"),
        name: "setpwd",
        meta: { title: "Set Password", layout: "auth" },
      },

      {
        path: "page-link-business",
        component: () =>
          import("@/components/admin/authentication/LinkBusinessComponent.vue"),
        name: "linkBiz",
        meta: { title: "Link Business", layout: "auth" },
      },
      {
        path: "page-reset-password",
        component: () =>
          import(
            "@/components/admin/authentication/ResetPasswordComponent.vue"
          ),
        name: "resetpwd",
        meta: { title: "Reset Password", layout: "auth" },
      },
      {
        path: "page-otp",
        component: () =>
          import("@/components/admin/authentication/OtpComponent.vue"),
        name: "otp",
        meta: { title: "OTP", layout: "auth" },
      },
      {
        path: "page-passcode",
        component: () =>
          import("@/components/admin/authentication/PasscodeOtpComponent.vue"),
        name: "passcode",
        meta: { title: "Passcode OTP", layout: "auth" },
      },
      {
        path: "page-forgot-password",
        component: () =>
          import(
            "@/components/admin/authentication/ForgotPasswordComponent.vue"
          ),
        name: "fpwd",
        meta: { title: "Forgot Password", layout: "auth" },
      },

      {
        path: "page-login-v2",
        component: () =>
          import("@/components/admin/authentication/LoginV2Component.vue"),
        name: "login_v2",
        meta: { title: "Login", layout: "auth-v2" },
      },
      {
        path: "page-register-v2",
        component: () =>
          import("@/components/admin/authentication/RegisterV2Component.vue"),
        name: "register_v2",
        meta: { title: "Register", layout: "auth-v2" },
      },
      {
        path: "page-personaldetail-v2",
        component: () =>
          import(
            "@/components/admin/authentication/PersonaldetailV2Component.vue"
          ),
        name: "pdetail_v2",
        meta: { title: "Personal Detail", layout: "auth-v2" },
      },
      {
        path: "page-password-v2",
        component: () =>
          import("@/components/admin/authentication/PasswordV2Component.vue"),
        name: "pwd_v2",
        meta: { title: "Password", layout: "auth-v2" },
      },
      {
        path: "page-reset-password-v2",
        component: () =>
          import(
            "@/components/admin/authentication/ResetPasswordV2Component.vue"
          ),
        name: "resetpwd_v2",
        meta: { title: "Reset Password", layout: "auth-v2" },
      },
      {
        path: "page-otp-v2",
        component: () =>
          import("@/components/admin/authentication/OtpV2Component.vue"),
        name: "otp_v2",
        meta: { title: "OTP", layout: "auth-v2" },
      },
      {
        path: "page-passcode-v2",
        component: () =>
          import(
            "@/components/admin/authentication/PasscodeOtpV2Component.vue"
          ),
        name: "passcode_v2",
        meta: { title: "Passcode OTP", layout: "auth-v2" },
      },
      {
        path: "page-forgot-password-v2",
        component: () =>
          import(
            "@/components/admin/authentication/ForgotPasswordV2Component.vue"
          ),
        name: "fpwd_v2",
        meta: { title: "Forgot Password", layout: "auth-v2" },
      },
    ],
  },

  // {
  //     path: '',
  //     redirect: '/admin/dashboard',
  //     name: 'dashboard',
  // },
  {
    path: "/admin",
    redirect: "/admin/dashboard",
    name: "dashboard",
    //component: RouterView,
    meta: {
      page_title: "Dashboard",
      breadCrumb: {
        title: "Dashboard",
      },
    },
    children: [
      {
        path: "dashboard",
        component: MyWallet,
        name: "dashboard",
      },
      // {
      //     path: 'my-wallet',
      //     component: MyWallet,
      //     name:'my-wallets',
      //     meta: {
      //         page_title: "My Wallets",
      //         breadCrumb: {
      //             title: 'My Wallets',
      //         },
      //     }
      // },
      {
        path: "transactions/:selected_account?",
        component: Transactions,
        name: "transactions",
        meta: {
          page_title: "Transactions",
          breadCrumb: {
            title: "Transactions",
          },
        },
      },
    ],
  },
  {
    path: "/admin/pay-transfer",
    component: RouterView,
    redirect: "/admin/pay-transfer/pay-transfer-details",
    name: "pay-transfer",
    meta: {
      breadCrumb: { title: "Pay Or Transfer" },
      page_title: "Pay Or Transfer",
    },
    children: [
      {
        path: "single-payout",
        component: () =>
          import(
            "@/components/admin/pay_to_transfer/SinglePayoutComponent.vue"
          ),
        meta: {
          breadCrumb: { title: "Single Payout" },
          page_title: "Single Payout",
        },
      },
      {
        path: "bulk-payout",
        component: () =>
          import(
            "@/components/admin/pay_to_transfer/BulkPayoutComponent.vue"
          ),
        meta: {
          breadCrumb: { title: "Bulk Payout" },
          page_title: "Bulk Payout",
        },
      },
      {
        path: "beneficiaries",
        component: () =>
          import(
            "@/components/admin/pay_to_transfer/BeneficiariesComponent.vue"
          ),
        meta: {
          breadCrumb: { title: "Beneficiaries" },
          page_title: "Beneficiaries",
        },
      },
      {
        path: "pay-transfer-details",
        component: () =>
          import("@/components/admin/pay_to_transfer/TransferComponent.vue"),
        meta: {
          breadCrumb: { title: "Payments Details" },
          page_title: "Payments",
        },
      },
      {
        path: "account-details",
        component: () =>
          import(
            "@/components/admin/pay_to_transfer/AccountDetailsComponent.vue"
          ),
        meta: {
          breadCrumb: { title: "Account Details" },
          page_title: "Account Details",
        },
      },
      {
        path: "user-account-details",
        component: () =>
          import(
            "@/components/admin/pay_to_transfer/UserAccountDComponent.vue"
          ),
        meta: {
          breadCrumb: { title: "User Account Details" },
          page_title: "User Account Details",
        },
      },
      // { path: 'exchange', component: () => import('@/components/admin/pay_to_transfer/ExchangeComponent.vue'), meta: { breadCrumb: { title: "Exchange"}, page_title: "Exchange"}},
      // { path: 'add-money', component: () => import('@/components/admin/pay_to_transfer/AddMoneyComponent.vue'), meta: { breadCrumb: { title: "Add Money"}, page_title: "Add Money"}},
    ],
  },
  {
    path: "/admin/send-money",
    component: RouterView,
    redirect: "/admin/send-money/dashboard",
    name: "send-money",
    meta: {
      page_title: "Send Money",
      breadCrumb: {
        title: "Send Money",
      },
    },
    children: [
      {
        path: "dashboard",
        component: sms_LandingPage,
        name: "send-money",
      },
      //Send Myself
      {
        path: "send-money-by",
        component: sms_SendMoneyBy,
        meta: {
          page_title: "Send Money By",
          breadCrumb: {
            title: "Send Money By",
          },
        },
      },
      {
        path: "receive-money-by",
        component: sms_ReceiveMoneyBy,
        meta: { breadCrumb: "Send Money", title: "Send Money" },
      },
      {
        path: "send-money-by-cash-pickup",
        component: sms_SendMoneyByCashPickup,
        meta: { breadCrumb: "Send Money", title: "Send Money" },
      },

      //Another Person
      {
        path: "recipients-list",
        component: sms_RecipientList,
        meta: { breadCrumb: "Send Money", title: "Send Money" },
      },
      {
        path: "recipients-details",
        component: sms_RecipientDetails,
        meta: { breadCrumb: "Send Money", title: "Send Money" },
      },

      //A Business / Organisation
      {
        path: "business-organisation-list",
        component: sms_BusinessList,
        meta: { breadCrumb: "Send Money", title: "Send Money" },
      },
      {
        path: "business-details",
        component: sms_BusinessDetails,
        meta: { breadCrumb: "Send Money", title: "Send Money" },
      },
    ],
  },
  {
    path: "/admin/setting",
    redirect: "/admin/setting/user-list",
    component: RouterView,
    name: "setting",
    meta: { page_title: "Setting", breadCrumb: { title: "Setting" } },
    children: [
      {
        path: "user-list",
        component: UserListComponent,
        meta: { page_title: "User List", breadCrumb: { title: "User List" } },
      },
      {
        path: "user-add",
        component: AddUserComponent,
        meta: { page_title: "Add User", breadCrumb: { title: "Add User" } },
      },
      {
        path: "channels",
        component: ChannelsComponent,
        meta: { page_title: "Channels", breadCrumb: { title: "Channels" } },
      },
      {
        path: "blank-channels",
        component: BlankChannelsComponent,
        meta: { page_title: "Channels", breadCrumb: { title: "Channels" } },
      },
      {
        path: "preferences",
        component: PreferencesComponent,
        meta: {
          page_title: "Preferences",
          breadCrumb: { title: "Preferences" },
        },
      },
      {
        path: "limits",
        component: LimitsComponent,
        meta: { page_title: "Limits", breadCrumb: { title: "Limits" } },
      },
      {
        path: "payouts",
        component: PayoutComponent,
        meta: { page_title: "Payouts", breadCrumb: { title: "Payouts" } },
      },
      {
        path: "Profile",
        component: MyProfileComponent,
        meta: { page_title: "My Profile", breadCrumb: { title: "My Profile" } },
      },
      {
        path: "setup-profile",
        component: SetupProfileComponent,
        meta: {
          page_title: "Set Up Your Profile",
          breadCrumb: { title: "Set Up Your Profile" },
        },
      },
      {
        path: "compliance",
        component: ComplianceComponent,
        meta: { page_title: "Compliance", breadCrumb: { title: "Compliance" } },
      },
      {
        path: "compliance-requirements",
        component: ComRequirementsComponent,
        meta: {
          page_title: "Registered Sole Proprietorship",
          breadCrumb: { title: "Registered Sole Proprietorship" },
        },
      },
    ],
  },
  {
    path: "/admin/transaction",
    redirect: "/admin/transaction/list",
    meta: { page_title: "Transaction", breadCrumb: { title: "Transaction" } },
    children: [
      {
        path: "list",
        component:
          require("@/components/admin/transaction/TransactionListComponent.vue")
            .default,
        name: "transaction",
        meta: {
          page_title: "Transaction List",
          breadCrumb: { title: "Transaction List" },
        },
      },
      {
        path: "details",
        component:
          require("@/components/admin/transaction/TransactionDetailComponent.vue")
            .default,
        meta: {
          page_title: "Transaction Details",
          breadCrumb: { title: "Transaction Details" },
        },
      },
      {
        path: "edit",
        component:
          require("@/components/admin/transaction/TransactionEditComponent.vue")
            .default,
        meta: {
          page_title: "Transaction Edit",
          breadCrumb: { title: "Transaction Edit" },
        },
      },
    ],
  },
  {
    path: "/admin/fund",
    name: "fund",
    component: FundComponent,
    meta: { page_title: "Fund", breadCrumb: { title: "Fund" } },
  },
  {
    path: "/admin/report-bug",
    component: ReportBugComponent,
    meta: { page_title: "Report Bug", breadCrumb: { title: "Report Bug" } },
  },
  {
    path: "/admin/reports",
    component: ReportsComponent,
    name: "reports",
    meta: { page_title: "Reports", breadCrumb: { title: "Reports" } },
  },
  {
    path: "/admin/user",
    redirect: "/admin/user/list",
    meta: { page_title: "User", breadCrumb: { title: "User" } },
    children: [
      {
        path: "list",
        component: require("@/components/admin/user/ListComponent.vue").default,
        name: "user list",
        meta: { page_title: "User List", breadCrumb: { title: "User List" } },
      },
      // {
      //     path: 'add',
      //     component: require('@/components/admin/transaction/TransactionDetailComponent.vue').default,
      //     meta: { page_title: "Transaction Details", breadCrumb: { title: "Transaction Details"} },
      // },
    ],
  },
  {
    path: '/admin/payment-link',
    component: RouterView,
    redirect: "/admin/payment-link/list",
    name: "payment-link",
    meta: {
      breadCrumb: { title: 'Payment Links' },
      page_title: "Payment Links",
    },
    children: [
      { path: 'list', component: () => import('@/components/admin/payment/PaymentListComponent.vue'), meta: { breadCrumb: { title: "List" }, page_title: "Payment Links"}},
      { path: 'create-payment-link', component: () => import('@/components/admin/payment/CreatePaymentLink.vue'), meta: { breadCrumb: { title:"Create Payment Link" }, page_title: "Create Payment Link"}},
    ]
  },
];

const router = createRouter({
  history: createWebHistory(), //'/demo/payangel/v2'
  routes,
});

const options = {
  customClass: {
    confirmButton: "btn btn-primary btn-lg my-3",
    cancelButton: "btn btn-outline-secondary btn-lg",
  },
  buttonsStyling: false,
};

import VueVectorMap from "vuevectormap";
// require("jsvectormap/dist/maps/world");
// require("jsvectormap/dist/css/jsvectormap.min.css");
createApp(App)
  .use(router)
  .use(BootstrapVue)
  .use(store)
  .use(VueSweetalert2, options)
  .use(VueVectorMap, {
    map: "world",
  })
  .use(VueTelInput)
  // .use(VueReCaptcha, { siteKey: '6Lf-7PYpAAAAAHkACgNjBBtNjEi4Lex1WHuzMHJU' })
  .mount("#app");
