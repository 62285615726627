<template>
    <!-- <fragment> -->
        <div v-for="(categoryitem,index) in categoryItems" :key="'category_'+index">
            <!--All Main Category Iteams table -->
            <b-table 
                responsive
                hover 
                outlined
                table-class="js-basic-example table-custom mb-0"
                head-variant="light"
                :items="categoryitem.items" 
                :fields="fields"
                thead-class="d-none">
                <template #cell(action)="row">
                    <a class="btn" :class="[row.detailsShowing  ? 'btn-primary text-white' : 'btn-outline-primary']" href="javascript:void(0);" @click="row.toggleDetails">
                        <i class="fa fa-angle-down" v-if="!row.detailsShowing"></i>
                        <i class="fa fa-angle-up" v-if="row.detailsShowing"></i>
                    </a>
                </template>
                <template #cell(name)="row">
                    <span class="font-weight-bold">{{ row.value }}</span>
                </template>
                <!--toggle sub-details onclick main category -->
                <template #row-details="row">
                    <b-card >
                        <!-- category reqirment Modal -->
                        <div class="d-md-flex justify-content-end py-3">
                            <b-button variant="primary btn-mw" 
                            size="lg" v-b-modal="'categoryreq_' + row.item.id" @click="getCurrentModalId(row.item.id)">Add Field <i class="fa fa-plus-square ml-1"></i></b-button>
                            <b-modal :id="'categoryreq_' + row.item.id" title="Add Field" hide-footer>
                                <div class="modal-area">
                                    <ul class="list-unstyled mb-0 payment-single-transfer">
                                        <Form @submit="categoryRequirement" :validation-schema="schema" id="category_requirement" ref="form" v-slot="{ errors }">
                                            <li> 
                                                <Field name="currency" rules="required" v-slot="{ handleChange }">  
                                                    <div class="form-group" :class="{'errors': errors && errors.currency,'success':!errors && !errors.currency}">
                                                        <span class="float-label">
                                                            <input type="text" @update:model-value="handleChange" v-model="businessCategory.field_name " class="form-control" name="field_name " id="field_name " placeholder="Field Name*">
                                                            <label for="field_name " class="control-label">Field Name*</label>
                                                            <div class="line"></div>
                                                            <i class="fa fa-times" v-if="errors && errors.currency"></i>
                                                            <i class="fa fa-check" v-if="!errors.currency && businessCategory.field_name"></i>
                                                        </span>
                                                    </div>
                                                </Field>
                                            </li>
                                            <li>
                                                <div class="d-flex align-items-center m-b-20">
                                                    <p class="mb-0 mr-3">Type 1</p>
                                                    <div class="custom-switch success-error-bg">
                                                        <input type="checkbox" class="custom-switch-input" id="type_one" v-model="businessCategory.typeOne">
                                                        <label class="custom-switch-label" for="type_one">
                                                            <span class="custom-switch-text1">Yes</span>
                                                            <span class="custom-switch-text2">No</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="d-flex align-items-center m-b-20">
                                                    <p class="mb-0 mr-3">Type 2</p>
                                                    <div class="custom-switch success-error-bg">
                                                        <input type="checkbox" class="custom-switch-input" id="type_two" v-model="businessCategory.typeTwo">
                                                        <label class="custom-switch-label" for="type_two">
                                                            <span class="custom-switch-text1">Yes</span>
                                                            <span class="custom-switch-text2">No</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="d-flex align-items-center m-b-20">
                                                    <p class="mb-0 mr-3">Type 3</p>
                                                    <div class="custom-switch success-error-bg">
                                                        <input type="checkbox" class="custom-switch-input" id="type_three" v-model="businessCategory.typeThree">
                                                        <label class="custom-switch-label" for="type_three">
                                                            <span class="custom-switch-text1">Yes</span>
                                                            <span class="custom-switch-text2">No</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="d-flex align-items-center m-b-20">
                                                    <p class="mb-0 mr-3">Type 4</p>
                                                    <div class="custom-switch success-error-bg">
                                                        <input type="checkbox" class="custom-switch-input" id="type_four" v-model="businessCategory.typeFour">
                                                        <label class="custom-switch-label" for="type_four">
                                                            <span class="custom-switch-text1">Yes</span>
                                                            <span class="custom-switch-text2">No</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="align-items-center d-flex flex-column justify-content-between mt-3 w-100">
                                                    <b-button type="submit" variant="primary btn-mw" size="lg">Add Field</b-button>
                                                    <b-button @click="$bvModal.hide('categoryreq_'+ row.item.id)" size="lg" variant="outline-secondary mt-3 btn-mw">Cancel</b-button>
                                                </div>
                                            </li>
                                        </Form>
                                    </ul>
                                </div>
                            </b-modal>
                        </div>
                        <!-- All Sub Category Table -->
                        <div v-if="!categoryitem.subItems[row.item.id].isSubList && !categoryitem.subItems[row.item.id].isSubTab">
                            <b-table 
                                responsive
                                hover 
                                outlined
                                table-class="js-basic-example table-custom mb-0"
                                head-variant="light"
                                :items="categoryitem.subItems[row.item.id].subIteamDetais" 
                                :fields="subFields"
                                :sort-by="sortBy"
                                :sort-desc="sortDesc"
                                :sort-direction="sortDirection">
                                <div>
                                    {{ label }}
                                    <!-- Custom icons -->
                                    <div>
                                        <i v-if="sortBy !== key" class=""></i>
                                        <i v-else-if="sortDesc" class="fa fa-long-arrow-down ml-1"></i>
                                        <i v-else class="fa fa-long-arrow-up ml-1"></i>
                                    </div>  
                                </div>
                                <template #cell(fid)="data">
                                    <b-button variant="outline-primary" size="sm" v-b-tooltip.hover :title="data.item.fid">
                                        <i class="fa fa-2x fa-barcode"></i>
                                    </b-button>
                                </template>
                                <template #cell(type1)="data">
                                    <div class="d-flex justify-content-between align-items-center m-b-20">
                                        <div class="custom-switch success-error-bg">
                                            <input type="checkbox" class="custom-switch-input" :id="'typeOne_'+row.item.id+data.index"  v-model="data.value.ison">
                                            <label class="custom-switch-label" :for="'typeOne_'+row.item.id+data.index">
                                                <span class="custom-switch-text1">Yes+</span>
                                                <span class="custom-switch-text2">No</span>
                                            </label>
                                        </div>
                                    </div>
                                </template>
                                <template #cell(type2)="data">
                                    <div class="d-flex justify-content-between align-items-center m-b-20">
                                        <div class="custom-switch success-error-bg">
                                            <input type="checkbox" class="custom-switch-input" :id="'typeTwo_'+row.item.id+data.index"  v-model="data.value.ison">
                                            <label class="custom-switch-label" :for="'typeTwo_'+row.item.id+data.index">
                                                <span class="custom-switch-text1">Yes</span>
                                                <span class="custom-switch-text2">No</span>
                                            </label>
                                        </div>
                                    </div>
                                </template>
                                <template #cell(type3)="data">
                                    <div class="d-flex justify-content-between align-items-center m-b-20">
                                        <div class="custom-switch success-error-bg">
                                            <input type="checkbox" class="custom-switch-input" :id="'typeThree_'+row.item.id+data.index"  v-model="data.value.ison">
                                            <label class="custom-switch-label" :for="'typeThree_'+row.item.id+data.index">
                                                <span class="custom-switch-text1">Yes</span>
                                                <span class="custom-switch-text2">No</span>
                                            </label>
                                        </div>
                                    </div>
                                </template>
                                <template #cell(type4)="data">
                                    <div class="d-flex justify-content-between align-items-center m-b-20">
                                        <div class="custom-switch success-error-bg">
                                            <input type="checkbox" class="custom-switch-input" :id="'typeFour_'+row.item.id+data.index"  v-model="data.value.ison">
                                            <label class="custom-switch-label" :for="'typeFour_'+row.item.id+data.index">
                                                <span class="custom-switch-text1">Yes</span>
                                                <span class="custom-switch-text2">No</span>
                                            </label>
                                        </div>
                                    </div>
                                </template>
                                <template #cell(action)=data>
                                    <b-dropdown id="dropdown-1" text="Actions" right variant="outline-primary">
                                        <b-dropdown-item>Edit</b-dropdown-item>
                                        <b-dropdown-item @click="showAlert(row.item.id,data.index,'')">Delete</b-dropdown-item>
                                    </b-dropdown>
                                </template>
                            </b-table>
                        </div>
                        <!-- Directors/Shareholders/UBO Table -->
                        <div v-if="categoryitem.subItems[row.item.id].isSubList">
                            <!-- Directors Table -->
                            <h6 class="mb-2 mt-3 font-15"><i class="fa fa-level-up fa-rotate-90"></i> <strong>Directors</strong></h6>
                            <b-table
                                responsive
                                hover 
                                outlined
                                table-class="js-basic-example table-custom mb-0"
                                head-variant="light"
                                :items="categoryitem.subItems[row.item.id].directors" 
                                :fields="subFields"
                                :sort-by="direSortBy"
                                :sort-desc="direSortDesc"
                                :sort-direction="sortDirection">
                                <div>
                                    {{ label }}
                                    <!-- Custom icons -->
                                    <div>
                                        <i v-if="direSortBy !== key" class=""></i>
                                        <i v-else-if="direSortDesc" class="fa fa-long-arrow-down ml-1"></i>
                                        <i v-else class="fa fa-long-arrow-up ml-1"></i>
                                    </div>  
                                </div>
                                <template #cell(fid)="data">
                                    <b-button variant="outline-primary" size="sm" v-b-tooltip.hover :title="data.item.fid">
                                        <i class="fa fa-2x fa-barcode"></i>
                                    </b-button>
                                </template>
                                <template #cell(type1)="data">
                                    <div class="d-flex justify-content-between align-items-center m-b-20">
                                        <div class="custom-switch success-error-bg">
                                            <input type="checkbox" class="custom-switch-input" :id="'director_typeOne_'+row.item.id+data.index"  v-model="data.value.ison">
                                            <label class="custom-switch-label" :for="'director_typeOne_'+row.item.id+data.index">
                                                <span class="custom-switch-text1">Yes</span>
                                                <span class="custom-switch-text2">No</span>
                                            </label>
                                        </div>
                                    </div>
                                </template>
                                <template #cell(type2)="data">
                                    <div class="d-flex justify-content-between align-items-center m-b-20">
                                        <div class="custom-switch success-error-bg">
                                            <input type="checkbox" class="custom-switch-input" :id="'director_typeTwo_'+row.item.id+data.index"  v-model="data.value.ison">
                                            <label class="custom-switch-label" :for="'director_typeTwo_'+row.item.id+data.index">
                                                <span class="custom-switch-text1">Yes</span>
                                                <span class="custom-switch-text2">No</span>
                                            </label>
                                        </div>
                                    </div>
                                </template>
                                <template #cell(type3)="data">
                                    <div class="d-flex justify-content-between align-items-center m-b-20">
                                        <div class="custom-switch success-error-bg">
                                            <input type="checkbox" class="custom-switch-input" :id="'director_typeThree_'+row.item.id+data.index"  v-model="data.value.ison">
                                            <label class="custom-switch-label" :for="'director_typeThree_'+row.item.id+data.index">
                                                <span class="custom-switch-text1">Yes</span>
                                                <span class="custom-switch-text2">No</span>
                                            </label>
                                        </div>
                                    </div>
                                </template>
                                <template #cell(type4)="data">
                                    <div class="d-flex justify-content-between align-items-center m-b-20">
                                        <div class="custom-switch success-error-bg">
                                            <input type="checkbox" class="custom-switch-input" :id="'director_typeFour_'+row.item.id+data.index"  v-model="data.value.ison">
                                            <label class="custom-switch-label" :for="'director_typeFour_'+row.item.id+data.index">
                                                <span class="custom-switch-text1">Yes</span>
                                                <span class="custom-switch-text2">No</span>
                                            </label>
                                        </div>
                                    </div>
                                </template>
                                <template #cell(action)="data">
                                    <b-dropdown id="dropdown-1" text="Actions" right variant="outline-primary">
                                        <b-dropdown-item>Edit</b-dropdown-item>
                                        <b-dropdown-item @click="showAlert(row.item.id,data.index,'directors')">Delete</b-dropdown-item>
                                    </b-dropdown>
                                </template>
                            </b-table>

                            <!--Shareholders - Individual Table -->
                            <h6 class="mb-2 mt-3 font-15"><i class="fa fa-level-up fa-rotate-90"></i> <strong>Shareholders - Individual</strong></h6>
                            <b-table
                                responsive
                                hover 
                                outlined
                                table-class="js-basic-example table-custom mb-0"
                                head-variant="light"
                                :items="categoryitem.subItems[row.item.id].shareholdersIndividual" 
                                :fields="subFields"
                                :sort-by="sharIndividualSortBy"
                                :sort-desc="sharIndividualSortDesc"
                                :sort-direction="sortDirection">
                                <div >
                                    {{ label }}
                                    <!-- Custom icons -->
                                    <div>
                                        <i v-if="sharIndividualSortBy !== key" class=""></i>
                                        <i v-else-if="sharIndividualSortDesc" class="fa fa-long-arrow-down ml-1"></i>
                                        <i v-else class="fa fa-long-arrow-up ml-1"></i>
                                    </div>  
                                </div>
                                <template #cell(fid)="data">
                                    <b-button variant="outline-primary" size="sm" v-b-tooltip.hover :title="data.item.fid">
                                        <i class="fa fa-2x fa-barcode"></i>
                                    </b-button>
                                </template>
                                <template #cell(type1)="data">
                                    <div class="d-flex justify-content-between align-items-center m-b-20">
                                        <div class="custom-switch success-error-bg">
                                            <input type="checkbox" class="custom-switch-input" :id="'shareholder_typeOne_'+data.index"  v-model="data.value.ison">
                                            <label class="custom-switch-label" :for="'shareholder_typeOne_'+data.index">
                                                <span class="custom-switch-text1">Yes</span>
                                                <span class="custom-switch-text2">No</span>
                                            </label>
                                        </div>
                                    </div>
                                </template>
                                <template #cell(type2)="data">
                                    <div class="d-flex justify-content-between align-items-center m-b-20">
                                        <div class="custom-switch success-error-bg">
                                            <input type="checkbox" class="custom-switch-input" :id="'shareholder_typeTwo_'+data.index"  v-model="data.value.ison">
                                            <label class="custom-switch-label" :for="'shareholder_typeTwo_'+data.index">
                                                <span class="custom-switch-text1">Yes</span>
                                                <span class="custom-switch-text2">No</span>
                                            </label>
                                        </div>
                                    </div>
                                </template>
                                <template #cell(type3)="data">
                                    <div class="d-flex justify-content-between align-items-center m-b-20">
                                        <div class="custom-switch success-error-bg">
                                            <input type="checkbox" class="custom-switch-input" :id="'shareholder_typeThree_'+data.index"  v-model="data.value.ison">
                                            <label class="custom-switch-label" :for="'shareholder_typeThree_'+data.index">
                                                <span class="custom-switch-text1">Yes</span>
                                                <span class="custom-switch-text2">No</span>
                                            </label>
                                        </div>
                                    </div>
                                </template>
                                <template #cell(type4)="data">
                                    <div class="d-flex justify-content-between align-items-center m-b-20">
                                        <div class="custom-switch success-error-bg">
                                            <input type="checkbox" class="custom-switch-input" :id="'shareholder_typeFour_'+data.index"  v-model="data.value.ison">
                                            <label class="custom-switch-label" :for="'shareholder_typeFour_'+data.index">
                                                <span class="custom-switch-text1">Yes</span>
                                                <span class="custom-switch-text2">No</span>
                                            </label>
                                        </div>
                                    </div>
                                </template>
                                <template #cell(action)="data">
                                    <b-dropdown id="dropdown-1" text="Actions" right variant="outline-primary">
                                        <b-dropdown-item>Edit</b-dropdown-item>
                                        <b-dropdown-item @click="showAlert(row.item.id,data.index,'individual')">Delete</b-dropdown-item>
                                    </b-dropdown>
                                </template>
                            </b-table>

                            <!--Shareholders - Corporate Table -->
                            <h6 class="mb-2 mt-3 font-15"><i class="fa fa-level-up fa-rotate-90"></i> <strong>Shareholders - Corporate</strong></h6>
                            <b-table
                                responsive
                                hover 
                                outlined
                                table-class="js-basic-example table-custom mb-0"
                                head-variant="light"
                                :items="categoryitem.subItems[row.item.id].shareholdersCorporate" 
                                :fields="subFields"
                                :sort-by="sharCorporateSortBy"
                                :sort-desc="sharCorporateSortDesc"
                                :sort-direction="sortDirection">
                                <div >
                                    {{ label }}
                                    <!-- Custom icons -->
                                    <div>
                                        <i v-if="sharCorporateSortBy !== key" class=""></i>
                                        <i v-else-if="sharCorporateSortDesc" class="fa fa-long-arrow-down ml-1"></i>
                                        <i v-else class="fa fa-long-arrow-up ml-1"></i>
                                    </div>  
                                </div>
                                <template #cell(fid)="data">
                                    <b-button variant="outline-primary" size="sm" v-b-tooltip.hover :title="data.item.fid">
                                        <i class="fa fa-2x fa-barcode"></i>
                                    </b-button>
                                </template>
                                <template #cell(type1)="data">
                                    <div class="d-flex justify-content-between align-items-center m-b-20">
                                        <div class="custom-switch success-error-bg">
                                            <input type="checkbox" class="custom-switch-input" :id="'corporate_typeOne_'+data.index"  v-model="data.value.ison">
                                            <label class="custom-switch-label" :for="'corporate_typeOne_'+data.index">
                                                <span class="custom-switch-text1">Yes</span>
                                                <span class="custom-switch-text2">No</span>
                                            </label>
                                        </div>
                                    </div>
                                </template>
                                <template #cell(type2)="data">
                                    <div class="d-flex justify-content-between align-items-center m-b-20">
                                        <div class="custom-switch success-error-bg">
                                            <input type="checkbox" class="custom-switch-input" :id="'corporate_typeTwo_'+data.index"  v-model="data.value.ison">
                                            <label class="custom-switch-label" :for="'corporate_typeTwo_'+data.index">
                                                <span class="custom-switch-text1">Yes</span>
                                                <span class="custom-switch-text2">No</span>
                                            </label>
                                        </div>
                                    </div>
                                </template>
                                <template #cell(type3)="data">
                                    <div class="d-flex justify-content-between align-items-center m-b-20">
                                        <div class="custom-switch success-error-bg">
                                            <input type="checkbox" class="custom-switch-input" :id="'corporate_typeThree_'+data.index"  v-model="data.value.ison">
                                            <label class="custom-switch-label" :for="'corporate_typeThree_'+data.index">
                                                <span class="custom-switch-text1">Yes</span>
                                                <span class="custom-switch-text2">No</span>
                                            </label>
                                        </div>
                                    </div>
                                </template>
                                <template #cell(type4)="data">
                                    <div class="d-flex justify-content-between align-items-center m-b-20">
                                        <div class="custom-switch success-error-bg">
                                            <input type="checkbox" class="custom-switch-input" :id="'corporate_typeFour_'+data.index"  v-model="data.value.ison">
                                            <label class="custom-switch-label" :for="'corporate_typeFour_'+data.index">
                                                <span class="custom-switch-text1">Yes</span>
                                                <span class="custom-switch-text2">No</span>
                                            </label>
                                        </div>
                                    </div>
                                </template>
                                <template #cell(action)="data">
                                    <b-dropdown id="dropdown-1" text="Actions" right variant="outline-primary">
                                        <b-dropdown-item>Edit</b-dropdown-item>
                                        <b-dropdown-item @click="showAlert(row.item.id,data.index,'corporate')">Delete</b-dropdown-item>
                                    </b-dropdown>
                                </template>
                            </b-table>

                            <!--UBO  Table -->
                            <h6 class="mb-2 mt-3 font-15"><i class="fa fa-level-up fa-rotate-90"></i> <strong>Ultimate Beneficial Owner (UBO)</strong></h6>
                            <b-table
                                responsive
                                hover 
                                outlined
                                table-class="js-basic-example table-custom mb-0"
                                head-variant="light"
                                :items="categoryitem.subItems[row.item.id].ubo" 
                                :fields="subFields"
                                :sort-by="uboSortBy"
                                :sort-desc="uboSortDesc"
                                :sort-direction="sortDirection">
                                <div >
                                    {{ label }}
                                    <!-- Custom icons -->
                                    <div>
                                        <i v-if="uboSortBy !== key" class=""></i>
                                        <i v-else-if="uboSortDesc" class="fa fa-long-arrow-down ml-1"></i>
                                        <i v-else class="fa fa-long-arrow-up ml-1"></i>
                                    </div>  
                                </div>
                                <template #cell(fid)="data">
                                    <b-button variant="outline-primary" size="sm" v-b-tooltip.hover :title="data.item.fid">
                                        <i class="fa fa-2x fa-barcode"></i>
                                    </b-button>
                                </template>
                                <template #cell(type1)="data">
                                    <div class="d-flex justify-content-between align-items-center m-b-20">
                                        <div class="custom-switch success-error-bg">
                                            <input type="checkbox" class="custom-switch-input" :id="'ubo_typeOne_'+data.index"  v-model="data.value.ison">
                                            <label class="custom-switch-label" :for="'ubo_typeOne_'+data.index">
                                                <span class="custom-switch-text1">Yes</span>
                                                <span class="custom-switch-text2">No</span>
                                            </label>
                                        </div>
                                    </div>
                                </template>
                                <template #cell(type2)="data">
                                    <div class="d-flex justify-content-between align-items-center m-b-20">
                                        <div class="custom-switch success-error-bg">
                                            <input type="checkbox" class="custom-switch-input" :id="'ubo_typeTwo_'+data.index"  v-model="data.value.ison">
                                            <label class="custom-switch-label" :for="'ubo_typeTwo_'+data.index">
                                                <span class="custom-switch-text1">Yes</span>
                                                <span class="custom-switch-text2">No</span>
                                            </label>
                                        </div>
                                    </div>
                                </template>
                                <template #cell(type3)="data">
                                    <div class="d-flex justify-content-between align-items-center m-b-20">
                                        <div class="custom-switch success-error-bg">
                                            <input type="checkbox" class="custom-switch-input" :id="'ubo_typeThree_'+data.index"  v-model="data.value.ison">
                                            <label class="custom-switch-label" :for="'ubo_typeThree_'+data.index">
                                                <span class="custom-switch-text1">Yes</span>
                                                <span class="custom-switch-text2">No</span>
                                            </label>
                                        </div>
                                    </div>
                                </template>
                                <template #cell(type4)="data">
                                    <div class="d-flex justify-content-between align-items-center m-b-20">
                                        <div class="custom-switch success-error-bg">
                                            <input type="checkbox" class="custom-switch-input" :id="'ubo_typeFour_'+data.index"  v-model="data.value.ison">
                                            <label class="custom-switch-label" :for="'ubo_typeFour_'+data.index">
                                                <span class="custom-switch-text1">Yes</span>
                                                <span class="custom-switch-text2">No</span>
                                            </label>
                                        </div>
                                    </div>
                                </template>
                                <template #cell(action)="data">
                                    <b-dropdown id="dropdown-1" text="Actions" right variant="outline-primary">
                                        <b-dropdown-item>Edit</b-dropdown-item>
                                        <b-dropdown-item @click="showAlert(row.item.id,data.index,'ubo')">Delete</b-dropdown-item>
                                    </b-dropdown>
                                </template>
                            </b-table>
                        </div>
                        <!-- Account/Finance/Customer/Technical Table -->
                        <div v-if="categoryitem.subItems[row.item.id].isSubTab">
                            <!-- Account Administration -->
                            <h6 class="pl-3 mb-2"><i class="fa fa-level-up fa-rotate-90 mr-2"></i> Account Administration</h6>
                            <b-table 
                                responsive
                                hover 
                                outlined
                                table-class="js-basic-example table-custom mb-0"
                                head-variant="light"
                                :items="categoryitem.subItems[row.item.id].accountAdministration" 
                                :fields="subFields"
                                :sort-by="accountSortBy"
                                :sort-desc="accountSortDesc"
                                :sort-direction="sortDirection">
                                <div >
                                    {{ label }}
                                    <!-- Custom icons -->
                                    <div>
                                        <i v-if="accountSortBy !== key" class=""></i>
                                        <i v-else-if="accountSortDesc" class="fa fa-long-arrow-down ml-1"></i>
                                        <i v-else class="fa fa-long-arrow-up ml-1"></i>
                                    </div>  
                                </div>
                                <template #cell(fid)="data">
                                    <b-button variant="outline-primary" size="sm" v-b-tooltip.hover :title="data.item.fid">
                                        <i class="fa fa-2x fa-barcode"></i>
                                    </b-button>
                                </template>
                                <template #cell(type1)="data">
                                    <div class="d-flex justify-content-between align-items-center m-b-20">
                                        <div class="custom-switch success-error-bg">
                                            <input type="checkbox" class="custom-switch-input" :id="'admin_typeOne_'+data.index"  v-model="data.value.ison">
                                            <label class="custom-switch-label" :for="'admin_typeOne_'+data.index">
                                                <span class="custom-switch-text1">Yes</span>
                                                <span class="custom-switch-text2">No</span>
                                            </label>
                                        </div>
                                    </div>
                                </template>
                                <template #cell(type2)="data">
                                    <div class="d-flex justify-content-between align-items-center m-b-20">
                                        <div class="custom-switch success-error-bg">
                                            <input type="checkbox" class="custom-switch-input" :id="'admin_typeTwo_'+data.index"  v-model="data.value.ison">
                                            <label class="custom-switch-label" :for="'admin_typeTwo_'+data.index">
                                                <span class="custom-switch-text1">Yes</span>
                                                <span class="custom-switch-text2">No</span>
                                            </label>
                                        </div>
                                    </div>
                                </template>
                                <template #cell(type3)="data">
                                    <div class="d-flex justify-content-between align-items-center m-b-20">
                                        <div class="custom-switch success-error-bg">
                                            <input type="checkbox" class="custom-switch-input" :id="'admin_typeThree_'+data.index"  v-model="data.value.ison">
                                            <label class="custom-switch-label" :for="'admin_typeThree_'+data.index">
                                                <span class="custom-switch-text1">Yes</span>
                                                <span class="custom-switch-text2">No</span>
                                            </label>
                                        </div>
                                    </div>
                                </template>
                                <template #cell(type4)="data">
                                    <div class="d-flex justify-content-between align-items-center m-b-20">
                                        <div class="custom-switch success-error-bg">
                                            <input type="checkbox" class="custom-switch-input" :id="'admin_typeFour_'+data.index"  v-model="data.value.ison">
                                            <label class="custom-switch-label" :for="'admin_typeFour_'+data.index">
                                                <span class="custom-switch-text1">Yes</span>
                                                <span class="custom-switch-text2">No</span>
                                            </label>
                                        </div>
                                    </div>
                                </template>
                                <template #cell(action)="data">
                                    <b-dropdown id="dropdown-1" text="Actions" right variant="outline-primary">
                                        <b-dropdown-item>Edit</b-dropdown-item>
                                        <b-dropdown-item @click="showAlert(row.item.id,data.index,'accountAdministration')">Delete</b-dropdown-item>
                                    </b-dropdown>
                                </template>
                            </b-table>

                            <!-- Finance Related Issues -->
                            <h6 class="pl-3 mb-2 mt-3"><i class="fa fa-level-up fa-rotate-90 mr-2"></i> Finance Related Issues</h6>
                            <b-table 
                                responsive
                                hover 
                                outlined
                                table-class="js-basic-example table-custom mb-0"
                                head-variant="light"
                                :items="categoryitem.subItems[row.item.id].financeIssues"
                                :fields="subFields"
                                :sort-by="financeSortBy"
                                :sort-desc="financeSortDesc"
                                :sort-direction="sortDirection">
                                <div >
                                    {{ label }}
                                    <!-- Custom icons -->
                                    <div>
                                        <i v-if="financeSortBy !== key" class=""></i>
                                        <i v-else-if="financeSortDesc" class="fa fa-long-arrow-down ml-1"></i>
                                        <i v-else class="fa fa-long-arrow-up ml-1"></i>
                                    </div>  
                                </div>
                                <template #cell(fid)="data">
                                    <b-button variant="outline-primary" size="sm" v-b-tooltip.hover :title="data.item.fid">
                                        <i class="fa fa-2x fa-barcode"></i>
                                    </b-button>
                                </template>
                                <template #cell(type1)="data">
                                    <div class="d-flex justify-content-between align-items-center m-b-20">
                                        <div class="custom-switch success-error-bg">
                                            <input type="checkbox" class="custom-switch-input" :id="'finance_typeOne_'+data.index"  v-model="data.value.ison">
                                            <label class="custom-switch-label" :for="'finance_typeOne_'+data.index">
                                                <span class="custom-switch-text1">Yes</span>
                                                <span class="custom-switch-text2">No</span>
                                            </label>
                                        </div>
                                    </div>
                                </template>
                                <template #cell(type2)="data">
                                    <div class="d-flex justify-content-between align-items-center m-b-20">
                                        <div class="custom-switch success-error-bg">
                                            <input type="checkbox" class="custom-switch-input" :id="'finance_typeTwo_'+data.index"  v-model="data.value.ison">
                                            <label class="custom-switch-label" :for="'finance_typeTwo_'+data.index">
                                                <span class="custom-switch-text1">Yes</span>
                                                <span class="custom-switch-text2">No</span>
                                            </label>
                                        </div>
                                    </div>
                                </template>
                                <template #cell(type3)="data">
                                    <div class="d-flex justify-content-between align-items-center m-b-20">
                                        <div class="custom-switch success-error-bg">
                                            <input type="checkbox" class="custom-switch-input" :id="'finance_typeThree_'+data.index"  v-model="data.value.ison">
                                            <label class="custom-switch-label" :for="'finance_typeThree_'+data.index">
                                                <span class="custom-switch-text1">Yes</span>
                                                <span class="custom-switch-text2">No</span>
                                            </label>
                                        </div>
                                    </div>
                                </template>
                                <template #cell(type4)="data">
                                    <div class="d-flex justify-content-between align-items-center m-b-20">
                                        <div class="custom-switch success-error-bg">
                                            <input type="checkbox" class="custom-switch-input" :id="'finance_typeFour_'+data.index"  v-model="data.value.ison">
                                            <label class="custom-switch-label" :for="'finance_typeFour_'+data.index">
                                                <span class="custom-switch-text1">Yes</span>
                                                <span class="custom-switch-text2">No</span>
                                            </label>
                                        </div>
                                    </div>
                                </template>
                                <template #cell(action)="data">
                                    <b-dropdown id="dropdown-1" text="Actions" right variant="outline-primary">
                                        <b-dropdown-item>Edit</b-dropdown-item>
                                        <b-dropdown-item @click="showAlert(row.item.id,data.index,'financeIssues')">Delete</b-dropdown-item>
                                    </b-dropdown>
                                </template>
                            </b-table>

                            <!-- Customer Service Issues -->
                            <h6 class="pl-3 mb-2 mt-3"><i class="fa fa-level-up fa-rotate-90 mr-2"></i> Customer Service Issues</h6>
                            <b-table 
                                responsive
                                hover 
                                outlined
                                table-class="js-basic-example table-custom mb-0"
                                head-variant="light"
                                :items="categoryitem.subItems[row.item.id].customerIssues"
                                :fields="subFields"
                                :sort-by="customerSortBy"
                                :sort-desc="customerSortDesc"
                                :sort-direction="sortDirection">
                                <div >
                                    {{ label }}
                                    <!-- Custom icons -->
                                    <div>
                                        <i v-if="customerSortBy !== key" class=""></i>
                                        <i v-else-if="customerSortDesc" class="fa fa-long-arrow-down ml-1"></i>
                                        <i v-else class="fa fa-long-arrow-up ml-1"></i>
                                    </div>  
                                </div>
                                <template #cell(fid)="data">
                                    <b-button variant="outline-primary" size="sm" v-b-tooltip.hover :title="data.item.fid">
                                        <i class="fa fa-2x fa-barcode"></i>
                                    </b-button>
                                </template>
                                <template #cell(type1)="data">
                                    <div class="d-flex justify-content-between align-items-center m-b-20">
                                        <div class="custom-switch success-error-bg">
                                            <input type="checkbox" class="custom-switch-input" :id="'service_typeOne_'+data.index"  v-model="data.value.ison">
                                            <label class="custom-switch-label" :for="'service_typeOne_'+data.index">
                                                <span class="custom-switch-text1">Yes</span>
                                                <span class="custom-switch-text2">No</span>
                                            </label>
                                        </div>
                                    </div>
                                </template>
                                <template #cell(type2)="data">
                                    <div class="d-flex justify-content-between align-items-center m-b-20">
                                        <div class="custom-switch success-error-bg">
                                            <input type="checkbox" class="custom-switch-input" :id="'service_typeTwo_'+data.index"  v-model="data.value.ison">
                                            <label class="custom-switch-label" :for="'service_typeTwo_'+data.index">
                                                <span class="custom-switch-text1">Yes</span>
                                                <span class="custom-switch-text2">No</span>
                                            </label>
                                        </div>
                                    </div>
                                </template>
                                <template #cell(type3)="data">
                                    <div class="d-flex justify-content-between align-items-center m-b-20">
                                        <div class="custom-switch success-error-bg">
                                            <input type="checkbox" class="custom-switch-input" :id="'service_typeThree_'+data.index"  v-model="data.value.ison">
                                            <label class="custom-switch-label" :for="'service_typeThree_'+data.index">
                                                <span class="custom-switch-text1">Yes</span>
                                                <span class="custom-switch-text2">No</span>
                                            </label>
                                        </div>
                                    </div>
                                </template>
                                <template #cell(type4)="data">
                                    <div class="d-flex justify-content-between align-items-center m-b-20">
                                        <div class="custom-switch success-error-bg">
                                            <input type="checkbox" class="custom-switch-input" :id="'service_typeFour_'+data.index"  v-model="data.value.ison">
                                            <label class="custom-switch-label" :for="'service_typeFour_'+data.index">
                                                <span class="custom-switch-text1">Yes</span>
                                                <span class="custom-switch-text2">No</span>
                                            </label>
                                        </div>
                                    </div>
                                </template>
                                <template #cell(action)="data">
                                    <b-dropdown id="dropdown-1" text="Actions" right variant="outline-primary">
                                        <b-dropdown-item>Edit</b-dropdown-item>
                                        <b-dropdown-item @click="showAlert(row.item.id,data.index,'customerIssues')">Delete</b-dropdown-item>
                                    </b-dropdown>
                                </template>
                            </b-table>

                            <!-- Technical Issues -->
                            <h6 class="pl-3 mb-2 mt-3"><i class="fa fa-level-up fa-rotate-90 mr-2"></i> Technical Issues</h6>
                            <b-table 
                                responsive
                                hover 
                                outlined
                                table-class="js-basic-example table-custom mb-0"
                                head-variant="light"
                                :items="categoryitem.subItems[row.item.id].technicalIssues"
                                :fields="subFields"
                                :sort-by="technicalSortBy"
                                :sort-desc="technicalSortDesc"
                                :sort-direction="sortDirection">
                                <div >
                                    {{ label }}
                                    <!-- Custom icons -->
                                    <div>
                                        <i v-if="technicalSortBy !== key" class=""></i>
                                        <i v-else-if="technicalSortDesc" class="fa fa-long-arrow-down ml-1"></i>
                                        <i v-else class="fa fa-long-arrow-up ml-1"></i>
                                    </div>  
                                </div>
                                <template #cell(fid)="data">
                                    <b-button variant="outline-primary" size="sm" v-b-tooltip.hover :title="data.item.fid">
                                        <i class="fa fa-2x fa-barcode"></i>
                                    </b-button>
                                </template>
                                <template #cell(type1)="data">
                                    <div class="d-flex justify-content-between align-items-center m-b-20">
                                        <div class="custom-switch success-error-bg">
                                            <input type="checkbox" class="custom-switch-input" :id="'technical_typeOne_'+data.index"  v-model="data.value.ison">
                                            <label class="custom-switch-label" :for="'technical_typeOne_'+data.index">
                                                <span class="custom-switch-text1">Yes</span>
                                                <span class="custom-switch-text2">No</span>
                                            </label>
                                        </div>
                                    </div>
                                </template>
                                <template #cell(type2)="data">
                                    <div class="d-flex justify-content-between align-items-center m-b-20">
                                        <div class="custom-switch success-error-bg">
                                            <input type="checkbox" class="custom-switch-input" :id="'technical_typeTwo_'+data.index"  v-model="data.value.ison">
                                            <label class="custom-switch-label" :for="'technical_typeTwo_'+data.index">
                                                <span class="custom-switch-text1">Yes</span>
                                                <span class="custom-switch-text2">No</span>
                                            </label>
                                        </div>
                                    </div>
                                </template>
                                <template #cell(type3)="data">
                                    <div class="d-flex justify-content-between align-items-center m-b-20">
                                        <div class="custom-switch success-error-bg">
                                            <input type="checkbox" class="custom-switch-input" :id="'technical_typeThree_'+data.index"  v-model="data.value.ison">
                                            <label class="custom-switch-label" :for="'technical_typeThree_'+data.index">
                                                <span class="custom-switch-text1">Yes</span>
                                                <span class="custom-switch-text2">No</span>
                                            </label>
                                        </div>
                                    </div>
                                </template>
                                <template #cell(type4)="data">
                                    <div class="d-flex justify-content-between align-items-center m-b-20">
                                        <div class="custom-switch success-error-bg">
                                            <input type="checkbox" class="custom-switch-input" :id="'technical_typeFour_'+data.index"  v-model="data.value.ison">
                                            <label class="custom-switch-label" :for="'technical_typeFour_'+data.index">
                                                <span class="custom-switch-text1">Yes</span>
                                                <span class="custom-switch-text2">No</span>
                                            </label>
                                        </div>
                                    </div>
                                </template>
                                <template #cell(action)="data">
                                    <b-dropdown id="dropdown-1" text="Actions" right variant="outline-primary">
                                        <b-dropdown-item>Edit</b-dropdown-item>
                                        <b-dropdown-item @click="showAlert(row.item.id,data.index,'technicalIssues')">Delete</b-dropdown-item>
                                    </b-dropdown>
                                </template>
                            </b-table>
                        </div>
                    </b-card>
                </template>
            </b-table>
        </div>
    <!-- </fragment> -->
</template>
<script>
import { Form, Field } from 'vee-validate';
import "@/plugins/vee-validate";
// import { Fragment } from 'fragment-for-vue/vue3'
export default {
    name:'ComRequirementsComponent',
    components: {
        Form, 
        Field,
        // Fragment
    },data(){
        return{
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            direSortBy:'',
            direSortDesc:false,
            sharIndividualSortBy:'',
            sharIndividualSortDesc:false,
            sharCorporateSortBy:'',
            sharCorporateSortDesc:false,
            uboSortBy:'',
            uboSortDesc:false,
            accountSortBy:'',
            accountSortDesc:false,
            financeSortBy:'',
            financeSortDesc:false,
            customerSortBy:'',
            customerSortDesc:false,
            technicalSortBy:'',
            technicalSortDesc:false,
            currentModalId:0,
            
            categoryItems:[
                {
                    items:[
                        {id:0,name:'Account Holder Information',action:''},
                        {id:1,name:'Basic Business Information',action:''},
                        {id:2,name:'Business Contact Details',action:''},
                        {id:3,name:'Tell us about your needs',action:''},
                        {id:4,name:'Controllers of your Business',action:''},
                        {id:5,name:'Business Structure, Condition and Size',action:''},
                        {id:6,name:'Your Business and Regulations',action:''},
                        {id:7,name:'Compliance Declarations',action:''},
                        {id:8,name:'Supporting Documents',action:''},
                        {id:9,name:'Finally, in case we need to reach you who should we contact?',action:''},
                    ],
                    subItems:[
                        {
                            isSubList: false,
                            isSubTab: false,
                            subIteamDetais:[
                                {fid:'2598',name:'Title',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'2356',name:'First Name',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'1598',name:'Middle Name',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'4892',name:'Last Name',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'1289',name:'Gender',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'0325',name:'Date of Birth',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9874',name:'Country of Birth',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'1478',name:'Email Address',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'3259',name:'Mobile Number',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'7895',name:'Your Role',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'2598',name:'Country of Residence',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'5896',name:'Address Line 1',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'3260',name:'Address Line 2',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'0325',name:'Town',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9840',name:'City',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'6702',name:'State/Province/Region/County',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name:'Post/Zip Code',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},  
                            ]
                        },
                        {
                            isSubList: false,
                            isSubTab: false,
                            subIteamDetais:[
                                {fid:'9530',name:'Country of Residence',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name:'Business Type',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name:'Business Name',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name:'Has your Business ever changed its name?',type1:{ison:false},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name:'When did you start or register your Business?',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name:'Approximately when did your Business start trading?',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name:'Does your Business trade under a name different from the one above?',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                            ]
                        },
                        {
                            isSubList: false,
                            isSubTab: false,
                            subIteamDetais:[
                                {fid:'9530',name:'Address Line 1',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name:'Address Line 2',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name:'Town',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name:'City',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name:'State/Province/Region/County',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name:'Post/Zip Code',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name:'Is the registered Business address the same as where you normally operate your Business? ',type1:{ison:false},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name:'What’s the best mobile number for contacting the Business?',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name:'What’s the best email for communicating with the Business?',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name:'Do you have a website?',type1:{ison:false},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''}, 
                            ]
                        },
                        {
                            isSubList: false,
                            isSubTab: false,
                            subIteamDetais:[
                                {fid:'9530',name:'What do you intend to use the account for?',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name:'Which services are you interested in?',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                            ]
                        },
                        {
                            isSubList: true,
                            isSubTab: false,
                            directors:[
                                {fid:'9530',name:'First Name',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'5698',name:'Middle Name',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'2589',name:'Last Name',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name:'Date of Appointment',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'5896',name:'Occupation',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'7820',name:'Nationality',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'1010',name:'Country of Residence',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'1020',name:'Address Line 1',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'1030',name:'Address Line 2',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'8099',name:'Town',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'3560',name:'City',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'2896',name:'State/Province/Region/County',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'8954',name:'Post/Zip Code',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'2569',name:'Date of Birth',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''}, 
                                {fid:'1035',name:'Country of Birth',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''}, 
                                {fid:'8950',name:'Is director serving as Shareholder?',type1:{ison:false},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''} 
                            ],

                            shareholdersIndividual:[
                                {fid:'9530',name:'First Name',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name:'Middle Name',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name:'Last Name',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name:'Nationality',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name:'Country of Residence',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name:'Address Line 1',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name:'Address Line 2',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name:'Town',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name:'City',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name:'State/Province/Region/County',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name:'Post/Zip Code',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name:'Occupation',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name:'Country of Birth',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''}, 
                            ],

                            shareholdersCorporate:[
                                {fid:'9530',name:'Company Name',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name:'Country of Residence',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name:'Address Line 1',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name:'Address Line 2',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name:'Town',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name:'City',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name:'State/Province/Region/County',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name:'Post/Zip Code',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                            ],

                            ubo:[
                                {fid:'9530',name:'First Name',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name:'Middle Name',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name:'Last Name',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name:'Ownership Percentage',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name:'Occupation',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name:'Nationality',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name:'Country of Residence',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name:'Address Line 1',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name:'Address Line 2',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name:'Town',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name:'City',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name:'State/Province/Region/County',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name:'Post/Zip Code',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name:'Date of Birth',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name:'Country of Birth',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''}, 
                            ],
                        },
                        {
                            isSubList: false,
                            isSubTab: false,
                            subIteamDetais:[
                                {fid:'9530',name:'Is your business a parent of another?',type1:{ison:false},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name:'Is your business a subsidiary of another business?',type1:{ison:false},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name:'Company Status',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name:'Number of Employees',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name:'Current Business Turnover',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name:'Expected Business Turnover',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name:'Size',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                            ]
                        },
                        {
                            isSubList: false,
                            isSubTab: false,
                            subIteamDetais:[
                                {fid:'9530',name:'Industry Classification',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name:'Confirm your Businesses ISIC Code',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name:'Registration Number',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name:'Business Tax ID',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name:'Is your business regulated?',type1:{ison:false},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name:'Is your business registered for VAT/Sales Tax?',type1:{ison:false},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name:'Do you have an AML Policy?',type1:{ison:false},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name:'Is your business registered for Data Protection?',type1:{ison:false},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name:'Is your business PCI Certified?',type1:{ison:false},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name:'Is your business ISO27001 Certified?',type1:{ison:false},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                            ]
                        },
                        {
                            isSubList: false,
                            isSubTab: false,
                            subIteamDetais:[
                                {fid:'9530',name:'Have you or anyone involved in the Business been convicted or facing the possibility of conviction for a crime?',type1:{ison:false},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name:'Are you or anyone involved in the Business considered a politically exposed person?',type1:{ison:false},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name:'Have you or anyone involved in the Business been declared insolvent or bankrupt?',type1:{ison:false},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name:'Are you or anyone involved in the Business involved in terrorism or terrorism financing?',type1:{ison:false},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name:'Are you or anyone involved in the Business involved in money laundering?',type1:{ison:false},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name:'Are you or anyone involved in the Business involved in dealing in arms or weapons?',type1:{ison:false},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                            ]
                        },
                        {
                            isSubList: false,
                            isSubTab: false,
                            subIteamDetais:[
                                {fid:'9530',name:'Article of Association',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name:'Memorandum of Association',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name:'Director - ID',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name:'Director - Proof of Address',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name:'Shareholder - ID',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name:'Shareholder - Proof of Address',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name:'Shareholder - Source of Wealth - Document 1',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name:'Shareholder - Source of Wealth - Document 2',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name:'Shareholder - Source of Wealth - Document 3',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name:'UBO - ID',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name:'UBO - Proof of Address',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name:'UBO - Source of Wealth - Document 1',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name:'UBO - Source of Wealth - Document 2',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name:'UBO - Source of Wealth - Document 3',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name:'Proof of Licences held, if any',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name:'Financial Accounts',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                            ]
                        },
                        {
                            isSubList: false,
                            isSubTab: true,
                            accountAdministration:[ 
                                {fid:'9530',name: 'Country',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name: 'Name',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name: 'Email',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name: 'Phone Number',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                            ],

                            financeIssues:[
                                {fid:'9530',name: 'Same as Admin',type1:{ison:false},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name: 'Country',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name: 'Name',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name: 'Email',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name: 'Phone Number',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                            ],

                            customerIssues:[ 
                                {fid:'9530',name: 'Same as Admin',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name: 'Country',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name: 'Name',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name: 'Email',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name: 'Phone Number',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                            ],

                            technicalIssues:[ 
                                {fid:'9530',name: 'Same as Admin',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name: 'Country',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name: 'Name',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name: 'Email',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                                {fid:'9530',name: 'Phone Number',type1:{ison:true},type2:{ison:true},type3:{ison:true},type4:{ison:true},action:''},
                            ],
                        },
                    ],
                    
                }
            ],

            //All Category list table header fields
            fields: [
                { key: 'name', label: 'Name',sortable: true,thClass:'th_sortfix'},
                { key: 'action', label: '',thClass:'text-center', tdClass:'text-center'},
                { sortable: true, sortByFormatted: true, filterByFormatted: true},
            ],

            //All sub Category list table header fields
            subFields: [
                { key: 'fid', label: 'Field Id',sortable: true,thClass:'th_sortfix'},
                { key: 'name', label: 'Name',sortable: true,thClass:'th_sortfix',tdClass:'mw-400-px '},
                { key: 'type1', label: 'Type 1',thClass:'w-130-px',tdClass:'align-middle'},
                { key: 'type2', label: 'Type 2',thClass:'w-130-px',tdClass:'align-middle'},
                { key: 'type3', label: 'Type 3',thClass:'w-130-px',tdClass:'align-middle'},
                { key: 'type4', label: 'Type 4',thClass:'w-130-px',tdClass:'align-middle'},
                { key: 'action', label: 'Action',thClass:'w-130-px',tdClass:'align-middle'},
                { sortable: true, sortByFormatted: true, filterByFormatted: true},
            ],

            businessCategory:{
                field_name:'',
                typeOne:true,
                typeTwo:true,
                typeThree:true,
                typeFour:true
            }
        }
    },methods:{
        getCurrentModalId(activeModalId){
            this.currentModalId = activeModalId
        },

        categoryRequirement(){
            this.$bvModal.hide('categoryreq_'+this.currentModalId);
        },

        showAlert(rowIndex,dataIndex,type) {
            const swalWithBootstrapButtons = this.$swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-primary btn-lg my-3',
                    cancelButton: 'btn btn-outline-secondary btn-lg'
                },
                buttonsStyling: false
            })
            swalWithBootstrapButtons.fire({
                title: 'Are you sure?',
                text: "You won't delete this!",
                icon: 'warning',
                showCancelButton: true,              
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    if(type == 'directors'){
                        this.categoryItems[0].subItems[rowIndex].directors.splice(dataIndex, 1)
                    }else if(type == 'individual'){
                        this.categoryItems[0].subItems[rowIndex].shareholdersIndividual.splice(dataIndex, 1)
                    }else if(type == 'corporate'){
                        this.categoryItems[0].subItems[rowIndex].shareholdersCorporate.splice(dataIndex, 1)
                    }else if(type == 'ubo'){
                        this.categoryItems[0].subItems[rowIndex].ubo.splice(dataIndex, 1)
                    }else if(type == 'accountAdministration'){
                       this.categoryItems[0].subItems[rowIndex].accountAdministration.splice(dataIndex, 1)
                    }else if(type == 'financeIssues'){
                        this.categoryItems[0].subItems[rowIndex].financeIssues.splice(dataIndex, 1)
                    }else if(type == 'customerIssues'){
                        this.categoryItems[0].subItems[rowIndex].customerIssues.splice(dataIndex, 1)
                    }else if(type == 'technicalIssues'){
                        this.categoryItems[0].subItems[rowIndex].technicalIssues.splice(dataIndex, 1)
                    }else{
                        this.categoryItems[0].subItems[rowIndex].subIteamDetais.splice(dataIndex, 1)
                    }
                }
            })
        },
    }
}
</script>
