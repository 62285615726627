<template>
    <div class="card card-top-line">
        <div class="header d-flex align-items-center justify-content-between">
            <h2>Controllers of your Business</h2>
            <i class="fa fa-edit font-20" @click="editDetails()"></i>
        </div>
        <div class="body">
            <div class="account-form">
                <Form @submit="shareholdersDataSubmit" id="basic-info" ref="form" :validation-schema="schema" v-slot="{ errors }">
                    <h6 class="mb-3 font-15"><i class="fa fa-level-up fa-rotate-90"></i> <strong>Shareholders</strong></h6>
                    <b-tabs content-class="px-0" class="shareholders-tab" nav-class="nav-tabs-new2" :no-nav-style="true" v-model="currentTabIndex">
                        <!-- Individual Tab -->
                        <b-tab title="Individual">
                            <div class="form-repeat" v-for="(individual,index) in individuals" :key="index">
                                <Field  :name="'subFirstName_'+ index" :rules="currentTabIndex == 0 ? 'required' : ''"  v-slot="{ handleChange }">
                                    <div class="form-group" :class="{'errors': errors && !individual.first_name,'success':individual.first_name}">
                                        <span class="float-label">
                                            <input type="text" @update:model-value="handleChange" v-model="individual.first_name" class="form-control" :id="'subFirstName_'+ index" placeholder="First Name*" :disabled="readonlyMode">
                                            <label :for="'subFirstName_'+ index" class="control-label">First Name*</label>
                                            <div class="line"></div>
                                            <i class="fa fa-times" v-if="errors && errors.first_name && !readonlyMode"></i>
                                            <i class="fa fa-check" v-if="!errors && !errors.first_name && !readonlyMode"></i>
                                        </span>
                                    </div>
                                </Field>
                                <div class="form-group">
                                    <span class="float-label">
                                        <input type="text" @update:model-value="handleChange" v-model="individual.middle_name" class="form-control" :id="'subMiddleName_'+index" placeholder="Middle Name" :disabled="readonlyMode">
                                        <label :for="'subMiddleName_'+index" class="control-label">Middle Name</label>
                                        <div class="line"></div>
                                    </span>
                                </div>
                                <Field  :name="'subLastName_'+index" :rules="currentTabIndex == 0 ? 'required' : ''"  v-slot="{ handleChange }">
                                    <div class="form-group" :class="{'errors': errors && !individual.last_name,'success':individual.last_name}">
                                        <span class="float-label">
                                            <input type="text" @update:model-value="handleChange" v-model="individual.last_name" class="form-control" :id="'subLastName_'+index" placeholder="Last Name*" :disabled="readonlyMode">
                                            <label :for="'subLastName_'+index" class="control-label">Last Name*</label>
                                            <div class="line"></div>
                                            <i class="fa fa-times" v-if="errors && errors.last_name && !readonlyMode"></i>
                                            <i class="fa fa-check" v-if="!errors && !errors.last_name && !readonlyMode"></i>
                                        </span>
                                    </div>
                                </Field>
                                <Field  :name="'subOwnership_'+index" :rules="currentTabIndex == 0 ? 'required' : ''"  v-slot="{ handleChange }">
                                    <div class="form-group" :class="{'errors': errors && !individual.ownership,'success':individual.ownership}">
                                        <span class="float-label">
                                            <b-input-group append="%" :class="{'ownership-disable':readonlyMode}">
                                                <b-form-input type="text" @update:model-value="handleChange" v-model="individual.ownership" :id="'ownership_'+index" placeholder="Ownership Percentage*" :disabled="readonlyMode"></b-form-input>
                                                <i class="fa fa-times" v-if="errors && errors.ownership && !readonlyMode"></i>
                                                <i class="fa fa-check" v-if="!errors && !errors.ownership && !readonlyMode"></i>
                                            </b-input-group>
                                            <label :for="'subOwnership_'+index" class="control-label">Ownership Percentage*</label>
                                            <div class="line"></div>
                                        </span>
                                    </div>
                                </Field>
                                <Field :name="'subOccupation_'+index" :rules="currentTabIndex == 0 ? 'required' : ''"  v-slot="{ handleChange }">
                                    <div class="form-group" :class="{'errors': errors && !individual.occupation,'success':individual.occupation}">
                                        <span class="float-label">
                                            <input type="text" @update:model-value="handleChange" v-model="individual.occupation" class="form-control" :id="'subOccupation_'+index" placeholder="Occupation*" :disabled="readonlyMode">
                                            <label :for="'subOccupation_'+index" class="control-label">Occupation*</label>
                                            <div class="line"></div>
                                            <i class="fa fa-times" v-if="errors && errors.logo && !readonlyMode"></i>
                                            <i class="fa fa-check" v-if="!errors && !errors.logo && !readonlyMode"></i>
                                        </span>
                                    </div>
                                </Field>
                                <Field :name="'cob_'+index" rules="required"  >
                                    <div class="country-dropdown">
                                        <div class="form-group form-select multiselect-grp" :class="{'errors': errors,'success':individual.countryofBirth && !errors}">
                                            <span class="float-label" :class="{'multiselect--disabled':readonlyMode}">
                                            <label for="Country">Country of Birth*</label>
                                                <multiselect
                                                    :disabled="readonlyMode"
                                                    placeholder=""
                                                    v-model="individual.countryofBirth" 
                                                    :show-labels="false" 
                                                    :options="countryofBirth_options" 
                                                    :searchable="false"
                                                    label="name" 
                                                    track-by="name">
                                                    <template v-slot:singleLabel="{ option }">{{ option.name }}</template>
                                                    <template v-slot:option="{ option }">
                                                        <span :class="option.flagClass"></span>
                                                        <div class="option__desc">
                                                            <span class="option__title">{{ option.name }}</span>
                                                        </div>
                                                    </template>
                                                </multiselect>  
                                                <i class="fa fa-times" v-if="errors && errors.logo && !readonlyMode"></i>
                                                <i class="fa fa-check" v-if="!errors && !errors.logo && !readonlyMode"></i>
                                            </span>
                                        </div>
                                    </div>
                                </Field>
                                <Field :name="'subNationality_'+index" :rules="currentTabIndex == 0 ? 'required' : ''"   >
                                    <div class="country-dropdown">
                                        <div class="form-group form-select multiselect-grp" :class="{'errors': errors,'success':individual.nationality && !errors}">
                                            <span class="float-label" :class="{'multiselect--disabled':readonlyMode}">
                                            <label for="Country">Nationality*</label>
                                                <multiselect
                                                    :disabled="readonlyMode"
                                                    placeholder=""
                                                    v-model="individual.nationality" 
                                                    :show-labels="false" 
                                                    :options="nationality_countrys" 
                                                    :searchable="false"
                                                    label="name" 
                                                    track-by="name">
                                                    <template v-slot:singleLabel="{ option }">{{ option.name }}</template>
                                                    <template v-slot:option="{ option }">
                                                        <span :class="option.flagClass"></span>
                                                        <div class="option__desc">
                                                            <span class="option__title">{{ option.name }}</span>
                                                        </div>
                                                    </template>
                                                </multiselect>  
                                                <i class="fa fa-times" v-if="errors && errors.logo && !readonlyMode"></i>
                                                <i class="fa fa-check" v-if="!errors && !errors.logo && !readonlyMode"></i>
                                            </span>
                                        </div>
                                    </div>
                                </Field>
                                <Field :name="'subResidenceCountry_'+index" :rules="currentTabIndex == 0 ? 'required' : ''" >
                                    <div class="country-dropdown">
                                        <div class="form-group form-select multiselect-grp" :class="{'errors': errors,'success':individual.country_of_residence && !errors}">
                                            <span class="float-label" :class="{'multiselect--disabled':readonlyMode}">
                                            <label for="Country">Country of Residence*</label>
                                                <multiselect
                                                    :disabled="readonlyMode"
                                                    placeholder=""
                                                    v-model="individual.country_of_residence" 
                                                    :show-labels="false" 
                                                    :options="residence_countrys" 
                                                    :searchable="false"
                                                    label="name" 
                                                    track-by="name">
                                                    <template v-slot:singleLabel="{ option }">{{ option.name }}</template>
                                                    <template v-slot:option="{ option }">
                                                        <span :class="option.flagClass"></span>
                                                        <div class="option__desc">
                                                            <span class="option__title">{{ option.name }}</span>
                                                        </div>
                                                    </template>
                                                </multiselect>  
                                                <i class="fa fa-times" v-if="errors && errors.logo && !readonlyMode"></i>
                                                <i class="fa fa-check" v-if="!errors && !errors.logo && !readonlyMode"></i>
                                            </span>
                                        </div>
                                    </div>
                                </Field>
                                <Field :name="'individualAddress_'+index" :rules="currentTabIndex == 0 ? 'required' : ''"  v-slot="{ handleChange }">
                                    <div class="form-group" :class="{'errors': errors && !individual.address1,'success':individual.address1}">
                                        <span class="float-label">
                                            <input type="text" @update:model-value="handleChange" v-model="individual.address1" class="form-control" :id="'individualAddress_'+index" placeholder="Address Line 1*" :disabled="readonlyMode"/>
                                            <label :for="'individualAddress_'+index" class="control-label">Address Line 1*</label>
                                            <div class="line"></div>
                                            <i class="fa fa-times" v-if="errors && errors.address1 && !readonlyMode"></i>
                                            <i class="fa fa-check" v-if="!errors && !errors.address1 && !readonlyMode"></i>
                                        </span>
                                    </div>
                                </Field>
                                <div class="form-group" >
                                    <span class="float-label">
                                        <input type="text" @update:model-value="handleChange" v-model="individual.address2" class="form-control" :id="'individualAddresstwo_'+index" placeholder="Address Line 2" :disabled="readonlyMode"/>
                                        <label :for="'individualAddresstwo_'+index" class="control-label">Address Line 2</label>
                                        <div class="line"></div>
                                    </span>
                                </div>
                                    
                                <Field :name="'individualTown_'+index" :rules="currentTabIndex == 0 ? 'required' : ''"  v-slot="{ handleChange }">
                                    <div class="form-group" :class="{'errors': errors && !individual.town,'success':individual.town}">
                                        <span class="float-label">
                                            <input type="text" @update:model-value="handleChange" v-model="individual.town" class="form-control" :id="'individualTown_'+index" placeholder="Town*" :disabled="readonlyMode"/>
                                            <label :for="'individualTown_'+index" class="control-label">Town*</label>
                                            <div class="line"></div>
                                            <i class="fa fa-times" v-if="errors && errors.town && !readonlyMode"></i>
                                            <i class="fa fa-check" v-if="!errors && !errors.town && !readonlyMode"></i>
                                        </span>
                                    </div>
                                </Field>
                            
                                <Field :name="'individualCity_'+index" :rules="currentTabIndex == 0 ? 'required' : ''"  v-slot="{ handleChange }">
                                    <div class="form-group" :class="{'errors': errors && !individual.city,'success':individual.city}">
                                        <span class="float-label">
                                            <input type="text" @update:model-value="handleChange" v-model="individual.city" class="form-control" :id="'individualCity_'+index" placeholder="City*" :disabled="readonlyMode"/>
                                            <label :for="'individualCity_'+index" class="control-label">City*</label>
                                            <div class="line"></div>
                                            <i class="fa fa-times" v-if="errors && errors.city && !readonlyMode"></i>
                                            <i class="fa fa-check" v-if="!errors && !errors.city && !readonlyMode"></i>
                                        </span>
                                    </div>
                                </Field>
                            
                                <Field :name="'individualstate_'+index" :rules="currentTabIndex == 0 ? 'required' : ''"  v-slot="{ handleChange }">
                                    <div class="form-group" :class="{'errors': errors && !individual.state,'success':individual.state}">
                                        <span class="float-label">
                                            <input type="text" @update:model-value="handleChange" v-model="individual.state" class="form-control" :id="'individualstate_'+index" placeholder="State/Province/Region/County*" :disabled="readonlyMode"/>
                                            <label :for="'individualstate_'+index" class="control-label">State/Province/Region/County*</label>
                                            <div class="line"></div>
                                            <i class="fa fa-times" v-if="errors && errors.state && !readonlyMode"></i>
                                            <i class="fa fa-check" v-if="!errors && !errors.state && !readonlyMode"></i>
                                        </span>
                                    </div>
                                </Field>
                            
                                <Field :name="'individualzipCode_'+index" :rules="currentTabIndex == 0 ? 'required' : ''"  v-slot="{ handleChange }">
                                    <div class="form-group" :class="{'errors': errors && !individual.zipCode,'success':individual.zipCode}">
                                        <span class="float-label">
                                            <input type="text" @update:model-value="handleChange" v-model="individual.zipCode" class="form-control" :id="'individualzipCode_'+index" placeholder="Post/Zip Code*" :disabled="readonlyMode"/>
                                            <label :for="'individualzipCode_'+index" class="control-label">Post/Zip Code*</label>
                                            <div class="line"></div>
                                            <i class="fa fa-times" v-if="errors && errors.zipCode && !readonlyMode"></i>
                                            <i class="fa fa-check" v-if="!errors && !errors.zipCode && !readonlyMode"></i>
                                        </span>
                                    </div>
                                </Field>
                                <Field :name="'dob_'+index" rules="required" v-slot="{ errors }" >
                                    <div class="form-group datepicker" :class="{'errors': errors,'success':individual.date_of_birth && !errors}">
                                        <span class="float-label">
                                            <b-input-group :class="{'datepicker-disable':readonlyMode}">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text">Date of Birth</span>
                                                    <span class="input-group-text"><i class="icon-calendar"></i></span>
                                                </div>
                                                <datepicker class="form-control" format="YYYY-MM-DD" placeholder="" @update:model-value="handleChange" v-model="individual.date_of_birth" :disabled="readonlyMode" :typeable="true"></datepicker>
                                            </b-input-group>
                                            <i class="fa fa-times" v-if="errors && errors.date_of_birth && !readonlyMode"></i>
                                            <i class="fa fa-check" v-if="!errors && !errors.date_of_birth && !readonlyMode"></i>
                                        </span>
                                    </div>
                                </Field>
                            </div>
                        </b-tab>

                        <!-- Corporate Tab -->
                        <b-tab title="Corporate">
                            <div class="form-repeat" v-for="(corporate,index) in corporates" :key="index">
                                
                                <Field :name="'companyName_'+ index" :rules="currentTabIndex == 1 ? 'required' : ''" v-slot="{ handleChange }">
                                    <div class="form-group" :class="{'errors': errors && !corporate.companyName,'success':corporate.companyName}">
                                        <span class="float-label">
                                            <input type="text" @update:model-value="handleChange" v-model="corporate.companyName" class="form-control" :id="'companyName_'+ index" placeholder="Company Name*" :disabled="readonlyMode">
                                            <label :for="'companyName_'+ index" class="control-label">Company Name*</label>
                                            <div class="line"></div>
                                            <i class="fa fa-times" v-if="errors && errors.companyName && !readonlyMode"></i>
                                            <i class="fa fa-check" v-if="!errors && !errors.companyName && !readonlyMode"></i>
                                        </span>
                                    </div>
                                </Field>
                            
                                <Field :name="'companyCountry_'+index" :rules="currentTabIndex == 1 ? 'required' : ''">
                                    <div class="country-dropdown">
                                        <div class="form-group form-select multiselect-grp" :class="{'errors': errors,'success':corporate.country_of_residence && !errors}">
                                            <span class="float-label" :class="{'multiselect--disabled':readonlyMode}">
                                            <label for="Country">Country of Residence*</label>
                                                <multiselect
                                                    :disabled="readonlyMode"
                                                    placeholder=""
                                                    v-model="corporate.country_of_residence" 
                                                    :show-labels="false" 
                                                    :options="residence_countrys" 
                                                    :searchable="false"
                                                    label="name" 
                                                    track-by="name">
                                                    <template v-slot:singleLabel="{ option }">{{ option.name }}</template>
                                                    <template v-slot:option="{ option }">
                                                        <span :class="option.flagClass"></span>
                                                        <div class="option__desc">
                                                            <span class="option__title">{{ option.name }}</span>
                                                        </div>
                                                    </template>
                                                </multiselect>  
                                                <i class="fa fa-times" v-if="errors && errors.logo && !readonlyMode"></i>
                                                <i class="fa fa-check" v-if="!errors && !errors.logo && !readonlyMode"></i>
                                            </span>
                                        </div>
                                    </div>
                                </Field>
                            
                                <Field :name="'corporateAddress_'+index" :rules="currentTabIndex == 1 ? 'required' : ''"  v-slot="{ handleChange }">
                                    <div class="form-group" :class="{'errors': errors && !corporate.address1,'success':corporate.address1}">
                                        <span class="float-label">
                                            <input type="text" @update:model-value="handleChange" v-model="corporate.address1" class="form-control" :id="'corporateAddress_'+index" placeholder="Address Line 1*" :disabled="readonlyMode"/>
                                            <label :for="'corporateAddress_'+index" class="control-label">Address Line 1*</label>
                                            <div class="line"></div>
                                            <i class="fa fa-times" v-if="errors && errors.address1 && !readonlyMode"></i>
                                            <i class="fa fa-check" v-if="!errors && !errors.address1 && !readonlyMode"></i>
                                        </span>
                                    </div>
                                </Field>
                            
                                <div class="form-group" >
                                    <span class="float-label">
                                        <input type="text" @update:model-value="handleChange" v-model="corporate.address2" class="form-control" :id="'corporateAddressTwo_'+index" placeholder="Address Line 2" :disabled="readonlyMode"/>
                                        <label :for="'corporateAddressTwo_'+index" class="control-label">Address Line 2</label>
                                        <div class="line"></div>
                                    </span>
                                </div>
                            
                                <Field :name="'corporateTown_'+index" :rules="currentTabIndex == 1 ? 'required' : ''"  v-slot="{ handleChange }">
                                    <div class="form-group" :class="{'errors': errors && !corporate.town,'success':corporate.town}">
                                        <span class="float-label">
                                            <input type="text" @update:model-value="handleChange" v-model="corporate.town" class="form-control" :id="'corporateTown_'+index" placeholder="Town*" :disabled="readonlyMode"/>
                                            <label :for="'corporateTown_'+index" class="control-label">Town*</label>
                                            <div class="line"></div>
                                            <i class="fa fa-times" v-if="errors && errors.town && !readonlyMode"></i>
                                            <i class="fa fa-check" v-if="!errors && !errors.town && !readonlyMode"></i>
                                        </span>
                                    </div>
                                </Field>
                            
                                <Field :name="'corporateCity_'+index" :rules="currentTabIndex == 1 ? 'required' : ''"  v-slot="{ handleChange }">
                                    <div class="form-group" :class="{'errors': errors && !corporate.city,'success':corporate.city}">
                                        <span class="float-label">
                                            <input type="text" @update:model-value="handleChange" v-model="corporate.city" class="form-control" :id="'corporateCity_'+index" placeholder="City*" :disabled="readonlyMode"/>
                                            <label :for="'corporateCity_'+index" class="control-label">City*</label>
                                            <div class="line"></div>
                                            <i class="fa fa-times" v-if="errors && errors.city && !readonlyMode"></i>
                                            <i class="fa fa-check" v-if="!errors && !errors.city && !readonlyMode"></i>
                                        </span>
                                    </div>
                                </Field>
                                            
                                <Field :name="'corporatestate_'+index" :rules="currentTabIndex == 1 ? 'required' : ''"  v-slot="{ handleChange }">
                                    <div class="form-group" :class="{'errors': errors && !corporate.state,'success':corporate.state}">
                                        <span class="float-label">
                                            <input type="text" @update:model-value="handleChange" v-model="corporate.state" class="form-control" :id="'corporatestate_'+index" placeholder="State/Province/Region/County*" :disabled="readonlyMode"/>
                                            <label :for="'corporatestate_'+index" class="control-label">State/Province/Region/County*</label>
                                            <div class="line"></div>
                                            <i class="fa fa-times" v-if="errors && errors.state && !readonlyMode"></i>
                                            <i class="fa fa-check" v-if="!errors && !errors.state && !readonlyMode"></i>
                                        </span>
                                    </div>
                                </Field>
                            
                                <Field :name="'corporatezipCode_'+index" :rules="currentTabIndex == 1 ? 'required' : ''"  v-slot="{ handleChange }">
                                    <div class="form-group" :class="{'errors': errors && !corporate.zipCode,'success':corporate.zipCode}">
                                        <span class="float-label">
                                            <input type="text" @update:model-value="handleChange" v-model="corporate.zipCode" class="form-control" :id="'corporatezipCode_'+index" placeholder="Post/Zip Code*" :disabled="readonlyMode"/>
                                            <label :for="'corporatezipCode_'+index" class="control-label">Post/Zip Code*</label>
                                            <div class="line"></div>
                                            <i class="fa fa-times" v-if="errors && errors.zipCode && !readonlyMode"></i>
                                            <i class="fa fa-check" v-if="!errors && !errors.zipCode && !readonlyMode"></i>
                                        </span>
                                    </div>
                                </Field>
                            </div>
                        </b-tab>
                    </b-tabs>
                    <div v-if="!readonlyMode" class="justify-content-center d-flex">
                        <b-button size="lg" @click="shareholdersDataSubmit()" class="btn-mw">Cancel</b-button>
                        <b-button type="submit" variant="primary ml-1 btn-mw" size="lg">Update</b-button>
                    </div>
                </Form>
            </div>
        </div>
    </div>
</template>
<script>
import VueMultiselect from 'vue-multiselect'
import { Form, Field } from 'vee-validate';
export default {
    name: 'Shareholders-Profile',
    components:{
        Form,
        Field,
        'Multiselect': VueMultiselect,
        datepicker: () => import('vue-date-picker'),
    },
    data(){
        return{
            //Individuals
            currentTabIndex: 0,
            readonlyMode:true,
            individuals:[
                {
                    first_name:'Individuals - John',
                    middle_name:'Individuals - Charlee',
                    last_name:'Individuals - Smith',
                    occupation:'Accountant',
                    nationality:[{ name: 'United Kingdom', flagClass: "flag-icon flag-icon-gb fi-xl fi-round"}],
                    country_of_residence: [{ name: 'France' , flagClass: "flag-icon flag-icon-fr fi-xl fi-round"}],
                    countryofBirth:[{ name: 'United Kingdom', flagClass: "flag-icon flag-icon-gb fi-xl fi-round"}],
                    address1:'10 Downing Street,LONDON',
                    address2:'1 Bishopthorpe Road',
                    town:'Penllyn',
                    city:'Manchester',
                    state:'England',
                    zipCode:'CF71 0HZ',
                    ownership:12,
                    date_of_birth:'10-Jan-1987',
                }
            ],

            nationality_countrys: [
                { name: 'India', flagClass:"flag-icon flag-icon-in fi-xl fi-round"},
                { name: 'United Kingdom', flagClass: "flag-icon flag-icon-gb fi-xl fi-round"},
                { name: 'France' , flagClass: "flag-icon flag-icon-fr fi-xl fi-round"},
                { name: 'United States', flagClass: "flag-icon flag-icon-us fi-xl fi-round"},
            ],

            residence_countrys: [
                { name: 'India', flagClass:"flag-icon flag-icon-in fi-xl fi-round"},
                { name: 'United Kingdom', flagClass: "flag-icon flag-icon-gb fi-xl fi-round"},
                { name: 'France' , flagClass: "flag-icon flag-icon-fr fi-xl fi-round"},
                { name: 'United States', flagClass: "flag-icon flag-icon-us fi-xl fi-round"},
            ],

            countryofBirth_options: [
                { name: 'India', flagClass:"flag-icon flag-icon-in fi-xl fi-round"},
                { name: 'United Kingdom', flagClass: "flag-icon flag-icon-gb fi-xl fi-round"},
                { name: 'France' , flagClass: "flag-icon flag-icon-fr fi-xl fi-round"},
                { name: 'United States', flagClass: "flag-icon flag-icon-us fi-xl fi-round"},
            ],
            
            //Corporates
            corporates:[
                {
                    companyName:'Thememakker',
                    country_of_residence: [{ name: 'France' , flagClass: "flag-icon flag-icon-fr fi-xl fi-round"}],
                    address1:'10 Downing Street,LONDON',
                    address2:'1 Bishopthorpe Road',
                    town:'Penllyn',
                    city:'Manchester',
                    state:'England',
                    zipCode:'CF71 0HZ',
                }
            ],

        }
    },methods:{
        editDetails(){
           this.readonlyMode = false
        },

        shareholdersDataSubmit(){
            this.readonlyMode = true
        },
    }
}
</script>
