<template>
    <div class="container-fluid">
        <div class="block-header">
            <BreadCrumb></BreadCrumb>
        </div>
        <div class="row clearfix">
            
            <div class="col-md-12">
                <div class="chart-top-action d-md-flex justify-content-end">
                    <div class="p-2 mr-auto">
                        <VMdDateRangePicker class="pull-right" opens='left' v-model="dateRange" @change="getSelectedDateRange($event)" :presets="presets" :autoApply="false"></VMdDateRangePicker>
                    </div>
                    <div class="btn-group mb-2 p-2" role="group" aria-label="Basic example">
                        <button class="btn btn-outline-primary" id="hourly" @click="xcategories('hourly')" :class="{active: selection =='hourly'}">Hourly</button>
                        <button class="btn btn-outline-primary" id="daily"  @click="xcategories('daily')" :class="{active: selection =='daily'}">Daily</button>
                        <button class="btn btn-outline-primary" id="weekly" @click="xcategories('weekly')" :class="{active: selection =='weekly'}">Weekly</button>
                        <button class="btn btn-outline-primary" id="monthly" @click="xcategories('monthly')" :class="{active: selection =='monthly'}">Monthly</button>
                        <button class="btn btn-outline-primary" id="yearly" @click="xcategories('yearly')" :class="{active: selection =='yearly'}">Yearly</button>
                    </div>
                </div>
                <div class="card card-top-line">
                    <div class="body">
                        <!-- <h4 class="pt-3 pb-3 text-center">Transactions Summary by Country</h4> -->
                        <div class="row">
                            <div class="col-md-12">
                                <h4 class="pt-3 pb-3 text-center">Transaction Summery</h4>
                                <VueApexCharts height="350" ref="chart" type="line" class="payangel-rewards" :options="conversionsLineChart.options" :series="conversionsLineChart.series"></VueApexCharts>    
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="card card-top-line">
                            <div class="body">
                                <h4 class="pt-3 pb-3 text-center">Transactions Summary by Country</h4>                                    
                                <VueApexCharts height="350" ref="barChart" type="bar" class="payangel-rewards" :options="areaDatetimeChart.options" :series="areaDatetimeChart.series"></VueApexCharts>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="card card-top-line">
                            <div class="body">
                                <h4 class="pt-3 pb-3 text-center">Transactions Summary by Payment Method</h4>
                                <VueApexCharts height="350" ref="barChartPeyment" type="bar" class="payangel-rewards" :options="paymentMethodChart.options" :series="paymentMethodChart.series"></VueApexCharts>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
//import { toRaw } from 'vue';
import VueApexCharts from "vue3-apexcharts";
import BreadCrumb from'@/components/BreadCrumb.vue'
const { VMdDateRangePicker } = require("v-md-date-range-picker")
const moment = require('moment')
export default {
    name:'ReportsComponent',
    components: {
        BreadCrumb,
        VMdDateRangePicker,
        VueApexCharts
    },data (){
        return{
            dateRange: null,
            valueAsperScale: {},
            //dateRange setup
            presets: [
                {
                    label: 'Today',
                    range: [
                        moment().startOf('day'),
                        moment().endOf('day')
                    ]
                },
                {
                    label: 'Yesterday',
                    range: [
                        moment().subtract(1, 'day').startOf('day'),
                        moment().subtract(1, 'day').endOf('day')
                    ]
                },
                {
                    label: 'Last 7 Days',
                    range: [
                        moment().subtract(7, 'day'),
                        moment()
                    ]
                },
                {
                    label: 'This Week',
                    range: [
                        moment().startOf('week'),
                        moment().endOf('week')
                    ]
                },
                {
                    label: 'Last Week',
                    range: [
                        moment().subtract(1, 'week').startOf('week'),
                        moment().subtract(1, 'week').endOf('week'),
                    ]
                },
                {
                    label: 'Last 30 Days',
                    range: [
                        moment().subtract(30, 'day'),
                        moment()
                    ]
                },
                {
                    label: 'This Month',
                    range: [
                        moment().startOf('month'),
                        moment().endOf('month')
                    ]
                },
                {
                    label: 'Last Month',
                    range: [
                        moment().subtract(1,'months').startOf('month'),
                        moment().subtract(1,'months').endOf('month')
                    ]
                },
                {
                    label: 'This Year',
                    range: [
                        moment().startOf('year'),
                        moment().endOf('year')
                    ]
                },
                {
                    label: 'Last Year',
                    range: [
                        moment().subtract(1,'year').startOf('year'),
                        moment().subtract(1,'year').endOf('year'),
                    ]
                }
            ],
            //end dateRange setup
            map: null,
            // markers: [
            // { name: "Palestine", coords: [31.5, 34.8] },
            // { name: "Russia", coords: [61, 105] },
            // ],
            markerStyle: {},
            markerLabelStyle: {},
            selectedMarkers: [0],

            // Regions
            series: {},

            fields: [
                { key: 'ranking', label: 'Ranking', sortable: false, thClass: 'th_sortfix' },
                { key: 'country_region', label: 'Country / Region', sortable: false, thClass: 'th_sortfix' },
                { key: 'transaction', label: 'Transactions', sortable: false, thClass: 'th_sortfix' },
                { key: 'amount', label: 'Amount', sortable: false, thClass: 'th_sortfix' },
            ],
            chartData: [
                {
                    country: 'Russia',
                    country_region: 'RU',
                    transaction: 4,
                    amount: 3500000,
                    date: '01-04-2023',
                    name:'Russia',
                    // data: [30, 40, 19, 30, 20, 20],
                    smooth: true,
                    type: 'line',
                },
                {
                    country: 'Afghanistan',
                    country_region: 'AF',
                    transaction: 45,
                    amount: 52560,
                    date: '01-04-2023',
                    name:'Afghanistan',
                    // data: [30, 40, 19, 30, 20, 20],
                    smooth: true,
                    type: 'line',
                },
                {
                    country: 'Albania',
                    country_region: 'AL',
                    transaction: 45,
                    amount: 42360,
                    date: '02-04-2023',
                    name:'Albania',
                    // data: [30, 40, 19, 30, 20, 20],
                    smooth: true,
                    type: 'line',
                },
                {
                    country: 'Algeria',
                    country_region: 'DZ',
                    transaction: 567,
                    amount: 57360,
                    date: '01-04-2023',
                    name:'Algeria',
                    // data: [30, 40, 19, 30, 20, 20],
                    smooth: true,
                    type: 'line',
                },
                {
                    country: 'American',
                    country_region: 'AS',
                    transaction: 8768,
                    amount: 525670,
                    date: '10-03-2023',
                    name:'American',
                    // data: [30, 40, 19, 30, 20, 20],
                    smooth: true,
                    type: 'line',
                },
                {
                    country: 'Andorra',
                    country_region: 'AD',
                    transaction: 679,
                    amount: 5236568,
                    date: '10-02-2023',
                    name:'Andorra',
                    // data: [30, 40, 19, 30, 20, 20],
                    smooth: true,
                    type: 'line',
                },
                {
                    country: 'Angola',
                    country_region: 'AO',
                    transaction: 56,
                    amount: 5236678,
                    date: '10-10-2022',
                    name:'Angola',
                    // data: [30, 40, 19, 30, 20, 20],
                    smooth: true,
                    type: 'line',
                },
                {
                    country: 'Anguilla',
                    country_region: 'AI',
                    transaction: 456,
                    amount: 77360,
                    date: '06-06-2022',
                    name:'Anguilla',
                    // data: [30, 40, 19, 30, 20, 20],
                    smooth: true,
                    type: 'line',
                },
                {
                    country: 'Antarctica',
                    country_region: 'AQ',
                    transaction: 324,
                    amount: 5456360,
                    date: '01-02-2023',
                    name:'Antarctica',
                    // data: [30, 40, 19, 30, 20, 20],
                    smooth: true,
                    type: 'line',
                },
                {
                    country: 'Antigua',
                    country_region: 'AG',
                    transaction: 456,
                    amount: 882360,
                    date: '01-01-2023',
                    name:'Antigua',
                    // data: [30, 40, 19, 30, 20, 20],
                    smooth: true,
                    type: 'line',
                },
                {
                    country: 'Argentina',
                    country_region: 'AR',
                    transaction: 658,
                    amount: 59360,
                    date: '01-01-2023',
                    name:'Argentina',
                    // data: [30, 40, 19, 30, 20, 20],
                    smooth: true,
                    type: 'line',
                },
                {
                    country: 'Armenia',
                    country_region: 'AM',
                    transaction: 568,
                    amount: 523260,
                    date: '01-01-2023',
                    name:'Armenia',
                    // data: [30, 40, 19, 30, 20, 20],
                    smooth: true,
                    type: 'line',
                },
                {
                    country: 'Aruba',
                    country_region: 'AW',
                    transaction: 12,
                    amount: 12460,
                    date: '10-01-2023',
                    name:'Aruba',
                    // data: [30, 40, 19, 30, 20, 20],
                    smooth: true,
                    type: 'line',
                },
                {
                    country: 'Australia',
                    country_region: 'AU',
                    transaction: 4,
                    amount: 52360,
                    date: '01-01-2023',
                    name:'Australia',
                    // data: [30, 40, 19, 30, 20, 20],
                    smooth: true,
                    type: 'line',
                },
                {
                    country: 'Austria',
                    country_region: 'AT',
                    transaction: 353,
                    amount: 360,
                    date: '01-01-2023',
                    name:'Austria',
                    // data: [30, 40, 19, 30, 20, 20],
                    smooth: true,
                    type: 'line',
                },
                {
                    country: 'Azerbaijan',
                    country_region: 'AZ',
                    transaction: 65,
                    amount: 5360,
                    date: '01-01-2023',
                    name:'Azerbaijan',
                    // data: [30, 40, 19, 30, 20, 20],
                    smooth: true,
                    type: 'line',
                },
                {
                    country: 'Bahamas',
                    country_region: 'BS',
                    transaction: 67,
                    amount: 52360,
                    date: '01-01-2023',
                    name:'Bahamas',
                    // data: [30, 40, 19, 30, 20, 20],
                    smooth: true,
                    type: 'line',
                },
                {
                    country: 'Bahrain',
                    country_region: 'BH',
                    transaction: 456,
                    amount: 52360,
                    date: '01-01-2023',
                    name:'Bahrain',
                    // data: [30, 40, 19, 30, 20, 20],
                    smooth: true,
                    type: 'line',
                },
                {
                    country: 'Bangladesh',
                    country_region: 'BD',
                    transaction: 376,
                    amount: 52360,
                    date: '01-01-2023',
                    name:'Bangladesh',
                    // data: [30, 40, 19, 30, 20, 20],
                    smooth: true,
                    type: 'line',
                },
                {
                    country: 'Barbados',
                    country_region: 'BB',
                    transaction: 743,
                    amount: 52360,
                    date: '01-01-2023',
                    name:'Barbados',
                    // data: [30, 40, 19, 30, 20, 20],
                    smooth: true,
                    type: 'line',
                },
                {
                    country: 'Belarus',
                    country_region: 'BY',
                    transaction: 345,
                    amount: 52360,
                    date: '01-01-2023',
                    name:'Belarus',
                    // data: [30, 40, 19, 30, 20, 20],
                    smooth: true,
                    type: 'line',
                },
                {
                    country: 'Belgium',
                    country_region: 'BE',
                    transaction: 652,
                    amount: 52360,
                    date: '01-01-2023',
                    name:'Belgium',
                    // data: [30, 40, 19, 30, 20, 20],
                    smooth: true,
                    type: 'line',
                },
                {
                    country: 'Belize',
                    country_region: 'BZ',
                    transaction: 289,
                    amount: 52360,
                    date: '01-01-2023',
                    name:'Belize',
                    // data: [30, 40, 19, 30, 20, 20],
                    smooth: true,
                    type: 'line',
                },
                {
                    country: 'Benin',
                    country_region: 'BJ',
                    transaction: 567,
                    amount: 52360,
                    date: '01-01-2023',
                    name:'Benin',
                    // data: [30, 40, 19, 30, 20, 20],
                    smooth: true,
                    type: 'line',
                },
                {
                    country: 'Bermuda',
                    country_region: 'BM',
                    transaction: 45,
                    amount: 52360,
                    date: '01-01-2023',
                    name:'Bermuda',
                    // data: [30, 40, 19, 30, 20, 20],
                    smooth: true,
                    type: 'line',
                },
                {
                    country: 'Bhutan',
                    country_region: 'BT',
                    transaction: 45,
                    amount: 52360,
                    date: '01-01-2023',
                    name:'Bhutan',
                    // data: [30, 40, 19, 30, 20, 20],
                    smooth: true,
                    type: 'line',
                },
                {
                    country: 'Bolivia',
                    country_region: 'BO',
                    transaction: 567,
                    amount: 52360,
                    date: '01-01-2023',
                    name:'Bolivia',
                    // data: [30, 40, 19, 30, 20, 20],
                    smooth: true,
                    type: 'line',
                },
                {
                    country: 'Bonaire',
                    country_region: 'BQ',
                    transaction: 8768,
                    amount: 52360,
                    date: '01-03-2023',
                    name:'Bonaire',
                    // data: [30, 40, 19, 30, 20, 20],
                    smooth: true,
                    type: 'line',
                },
                {
                    country: 'Bosnia',
                    country_region: 'BA',
                    transaction: 679,
                    amount: 52360,
                    date: '01-03-2023',
                    name:'Bosnia',
                    // data: [30, 40, 19, 30, 20, 20],
                    smooth: true,
                    type: 'line',
                },
                {
                    country: 'Botswana',
                    country_region: 'BW',
                    transaction: 56,
                    amount: 52360,
                    date: '01-02-2023',
                    name:'Botswana',
                    // data: [30, 40, 19, 30, 20, 20],
                    smooth: true,
                    type: 'line',
                },
                {
                    country: 'Bouvet',
                    country_region: 'BV',
                    transaction: 456,
                    amount: 52360,
                    date: '01-01-2023',
                    name:'Bouvet',
                    // data: [30, 40, 19, 30, 20, 20],
                    smooth: true,
                    type: 'line',
                },
                {
                    country: 'Brazil',
                    country_region: 'BR',
                    transaction: 324,
                    amount: 52360,
                    date: '01-01-2023',
                    name:'Brazil',
                    // data: [30, 40, 19, 30, 20, 20],
                    smooth: true,
                    type: 'line',
                },
                {
                    country: 'British',
                    country_region: 'IO',
                    transaction: 456,
                    amount: 52360,
                    date: '01-02-2023',
                    name:'British',
                    // data: [30, 40, 19, 30, 20, 20],
                    smooth: true,
                    type: 'line',
                },
                {
                    country: 'Brunei',
                    country_region: 'BN',
                    transaction: 658,
                    amount: 52360,
                    date: '05-12-2022',
                    name:'Brunei',
                    // data: [30, 40, 19, 30, 20, 20],
                    smooth: true,
                    type: 'line',
                },
                {
                    country: 'Bulgaria',
                    country_region: 'BG',
                    transaction: 568,
                    amount: 52360,
                    date: '14-12-2022',
                    name:'Bulgaria',
                    // data: [30, 40, 19, 30, 20, 20],
                    smooth: true,
                    type: 'line',
                },
                {
                    country: 'Burkina',
                    country_region: 'BF',
                    transaction: 12,
                    amount: 52360,
                    date: '12-12-2022',
                    name:'Burkina',
                    // data: [30, 40, 19, 30, 20, 20],
                    smooth: true,
                    type: 'line',
                },
                {
                    country: 'Burundi',
                    country_region: 'BI',
                    transaction: 4,
                    amount: 12345,
                    date: '03-04-2023',
                    name:'Burundi',
                    // data: [30, 40, 19, 30, 20, 20],
                    smooth: true,
                    type: 'line',
                },
                {
                    country: 'Cabo',
                    country_region: 'CV',
                    transaction: 353,
                    amount: 4500,
                    date: '01-04-2023',
                    name:'Cabo',
                    // data: [30, 40, 19, 30, 20, 20],
                    smooth: true,
                    type: 'line',
                },
                {
                    country: 'Cambodia',
                    country_region: 'KH',
                    transaction: 65,
                    amount: 52360,
                    date: '10-10-2022',
                    name:'Cambodia',
                    // data: [30, 40, 19, 30, 20, 20],
                    smooth: true,
                    type: 'line',
                },
                {
                    country: 'Cameroon',
                    country_region: 'CM',
                    transaction: 67,
                    amount: 2500,
                    date: '12-11-2022',
                    name:'Cameroon',
                    // data: [30, 40, 19, 30, 20, 20],
                    smooth: true,
                    type: 'line',
                },
                {
                    country: 'Canada',
                    country_region: 'CA',
                    transaction: 456,
                    amount: 500,
                    date: '09-10-2022',
                    name:'Canada',
                    // data: [10, 20, 30, 30, 40, 5],
                    smooth: true,
                    type: 'line',
                }
            ],
            conversionsLineChart:{
                options :{
                    chart: {
                        toolbar: {
                            show: false,
                        },
                    },
                    stroke: {
                        width:2,
                        colors: ["#32a8a4", "#3271a8", "#4832a8", "#9032a8", "#a83832"],
                        curve: 'smooth'
                    },
                    markers: {
                        size: 2,  
                    },
                    dataLabels: {
                        enabled: false
                    },
                    yaxis: {
                        tickAmount: 6,
                        labels: {
                            formatter: function (value) {
                                return "USD "+ value.toString().replace(/[^-\d.]/g, "")
                                .replace(/^(\d*\.)(.*)\.(.*)$/, '$1$2$3')
                                .replace(/\.(\d{2})\d+/, '.$1')
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                            }
                        },
                    },
                    xaxis: {
                        categories: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'], 
                    },
                    colors: ["#32a8a4", "#3271a8", "#4832a8", "#9032a8", "#a83832"]
                },
                series: [],
                //[
                //]
            },
            areaDatetimeChart:{
                options :{
                    chart: {
                        type: 'bar',
                        toolbar: {
                            show: false,
                        },
                    },
                    tooltip: {
                        intersect: false
                    },
                    stroke: {
                        colors: ['transparent'],
                    },
                    plotOptions: {
                        bar: {
                            dataLabels: {
                                position: 'top',
                            },
                        }
                    },
                    dataLabels: {
                        enabled: true,
                        formatter: function (value) {
                            return "USD "+ value.toString().replace(/[^-\d.]/g, "")
                            .replace(/^(\d*\.)(.*)\.(.*)$/, '$1$2$3')
                            .replace(/\.(\d{2})\d+/, '.$1')
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                        },
                        offsetY: -20,
                        style: {
                            fontSize: '12px',
                            colors: ["#304758"]
                        }
                    },
                    yaxis: {
                        labels: {
                            formatter: function (value) {
                                return "USD "+ value.toString().replace(/[^-\d.]/g, "")
                                .replace(/^(\d*\.)(.*)\.(.*)$/, '$1$2$3')
                                .replace(/\.(\d{2})\d+/, '.$1')
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                            }
                        },
                    },
                    xaxis: {
                        // labels: {
                        //     formatter: function (value) {
                        //     return value;
                        //     }
                        // },
                        categories: [], 
                    },
                    colors: ["#32a8a4", "#3271a8", "#4832a8", "#9032a8", "#a83832"],
                },
                series: []
            },
            paymentMethodChart:{
                options :{
                    chart: {
                        type: 'bar',
                        toolbar: {
                            show: false,
                        },
                    },
                    tooltip: {
                        intersect: false
                    },
                    stroke: {
                        colors: ['transparent'],
                    },
                    plotOptions: {
                        bar: {
                            dataLabels: {
                                position: 'top',
                            },
                        }
                    },
                    dataLabels: {
                        enabled: true,
                        formatter: function (value) {
                            return "USD "+ value.toString().replace(/[^-\d.]/g, "")
                            .replace(/^(\d*\.)(.*)\.(.*)$/, '$1$2$3')
                            .replace(/\.(\d{2})\d+/, '.$1')
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                        },
                        offsetY: -20,
                        style: {
                            fontSize: '12px',
                            colors: ["#304758"]
                        }
                    },
                    yaxis: {
                        labels: {
                            formatter: function (value) {
                                return "USD "+ value.toString().replace(/[^-\d.]/g, "")
                                .replace(/^(\d*\.)(.*)\.(.*)$/, '$1$2$3')
                                .replace(/\.(\d{2})\d+/, '.$1')
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                            }
                        },
                    },
                    xaxis: {
                        categories:  ['PayAngel Wallet', 'Mobile Money', 'Card','Bank Transfer']
                    },
                    colors: ["#32a8a4", "#3271a8", "#4832a8", "#9032a8", "#a83832"]
                },
                series: []
            },
        }
    },methods: {
        convertCurrencyFormat: function(currencyAmount) {
            return this.formatNumbers((Math.round(currencyAmount * 100) / 100).toFixed(2));
        },
        formatNumbers(origionalNumber) {
            return origionalNumber.toString().replace(/[^-\d.]/g, "")
            // Remove duplicated decimal point, if one exists:
            .replace(/^(\d*\.)(.*)\.(.*)$/, '$1$2$3')
            // Keep only two digits past the decimal point:
            .replace(/\.(\d{2})\d+/, '.$1')
            // Add thousands separators:
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        xcategories(timeline) {
            this.selection = timeline
            let chartData = {};
            switch (timeline) {
                case 'hourly':
                    chartData = {
                        xaxis: {
                            categories:  ['01:00','02:00','03:00','04:00','05:00','06:00','07:00','08:00','09:00','10:00'
                            ,'11:00','12:00','13:00','14:00','15:00','16:00','17:00','18:00','19:00','20:00','21:00','22:00'
                            ,'23:00','24:00']
                        }
                    }
                    this.$refs.chart.updateOptions(chartData)
                    this.$refs.chart.updateSeries([{ data: this.updateData(24) }]);
                    
                    break
                case 'daily':
                    chartData = {
                        xaxis: {
                            categories:  ['1 Aug','2 Aug','3 Aug','4 Aug','5 Aug','6 Aug','7 Aug','8 Aug','9Aug','10 Aug','11 Aug','12 Aug','13 Aug','14 Aug',
                            '15 Aug','16 Aug','17 Aug','18 Aug','19 Aug','20 Aug','21 Aug','22 Aug',
                            '23 Aug','24 Aug','25Aug','26 Aug','27 Aug','28 Aug','29 Aug','30 Aug','31 Aug']
                        }
                    }
                    this.$refs.chart.updateOptions(chartData)
                    this.$refs.chart.updateSeries([{ data: this.updateData(31) }]);

                    break
                case 'yearly':
                    chartData = {
                        xaxis: {
                            categories:  ['2017', '2018','2019', '2020','2021', '2022','2023'],
                        }
                    }
                    this.$refs.chart.updateOptions(chartData)
                    this.$refs.chart.updateSeries([{ data: this.updateData(7) }]);

                    break
                case 'monthly':
                    chartData = {
                        xaxis: {
                            categories:  ['Jan','Feb','Mar','Apr','May','June','July','Aug','Sept','Oct','Nov','Dec']
                        }
                    }
                    this.$refs.chart.updateOptions(chartData)
                    this.$refs.chart.updateSeries([{ data: this.updateData(12) }]);

                    break
                case 'weekly':
                    chartData = {
                        xaxis: {
                            categories:  ['week 1', 'week 2', 'week 3', 'week 4', 'week 5']
                        }
                    }
                    
                    this.$refs.chart.updateOptions(chartData)
                    this.$refs.chart.updateSeries([{ data: this.updateData(5) }]);
                    break
                default:
            }
        },
        getSelectedDateRange(e){
            let startDate = moment(e[0]).format('D-MM-YYYY');
            let endDate = moment(e[1]).format('D-MM-YYYY');
            
            let tempArr = [];
            this.chartData.map((data) => { 
                var d1 = startDate.split("-");
                var d2 = endDate.split("-");
                var c = data.date.split("-");

                var from = new Date(d1[2], parseInt(d1[1])-1, d1[0]);  // -1 because months are from 0 to 11
                var to   = new Date(d2[2], parseInt(d2[1])-1, d2[0]);
                var check = new Date(c[2], parseInt(c[1])-1, c[0]);

                if(check >= from && check <= to){
                    tempArr.push(data);
                }
            });
            tempArr.sort((a, b) => {
                if (a.amount > b.amount) {
                    return -1;
                }
            });
            this.conversionsLineChart.series = tempArr.slice(0, 5);
            this.areaDatetimeChart.series = tempArr.slice(0, 5);
            this.updateData();
        },
        updateData(valueLength=null){
            if(valueLength){
                this.chartData.map((data) => {
                    data.data = Array.from({length: valueLength}, () => Math.floor(Math.random() * 1200));
                    this.conversionsLineChart.series.push(data);
                });
                this.chartData.sort((a, b) => {
                    if (a.amount > b.amount) {
                        return -1;
                    }
                });
                
                this.conversionsLineChart.series = this.chartData.slice(0, 5);
                let transaction = [];
                if(this.conversionsLineChart){
                    this.conversionsLineChart.series.map((data) => {
                        transaction.push(data.transaction)
                    })
                    this.areaDatetimeChart.series = [{
                        data: transaction
                    }];
                }
            }
            this.areaDatetimeChart.series = [{
                data: Array.from({length: 5}, () => Math.floor(Math.random() * 1200))
            }];
            this.paymentMethodChart.series = [{
                data: Array.from({length: 4}, () => Math.floor(Math.random() * 1200))
            }];
        }
        // setScale(data){
        //     if(0 >= data.amount){
        //         return 'No Transactions';
        //     }else if(  0 < data.amount &&  500 >= data.amount){
        //         return '$1 - $500';
        //     }else if(  500 < data.amount &&  2000 >= data.amount){
        //         return  '$500 - $2000';
        //     }else if(  2000 < data.amount &&  7000 >= data.amount){
        //         return '$2000 - $7000';
        //     }else if(  7000 < data.amount &&  12000 >= data.amount){
        //         return '$7000 - $12000';
        //     } else {
        //         return '$12000+';
        //     }
        // },
        //getScale(){
            // this.items.map(data => {
            //     this.valueAsperScale[data.country_region] = this.setScale(data);
            // })
            
            // this.valueAsperScale = { ...this.valueAsperScale }
            // this.series = {
            //     regions: [
            //         {
            //             attribute: "fill",
            //             legend: {
            //                 title: "Transactions",
            //             },
            //             scale: {
            //                 'No Transactions': "#ebf1ff",
            //                 '$1 - $500': "#98a2ed",
            //                 '$500 - $2000': "#7a86de",
            //                 '$2000 - $7000': "#515fc4",
            //                 '$7000 - $12000': "#243296",
            //                 '$12000+': "#011080",
            //             },
            //             values: { ...this.valueAsperScale },
            //         },
            //     ],
            // }
        //}
    },
    created() {
        //let tempArray = [];
        this.chartData.map((data) => {
            data.data = Array.from({length: 12}, () => Math.floor(Math.random() * 1200));
            //tempArray.push(data);
            // 
            data.amount = this.convertCurrencyFormat(data.amount);
            this.conversionsLineChart.series.push(data);
        });
        this.conversionsLineChart.series.sort((a, b) => {
            if (a.amount > b.amount) {
                return -1;
            }
        });
        
        this.conversionsLineChart.series = this.conversionsLineChart.series.slice(0, 5);
        let barArr = [];
        let country = [];
        this.conversionsLineChart.series.map((data) => {
            barArr.push(data.transaction);
            country.push(data.country);
        });
        this.areaDatetimeChart.series = [{
            name: "Transactions",
            data: barArr
        }];
        this.areaDatetimeChart.options.xaxis.categories = country;
        this.paymentMethodChart.series = [{
            name: "Transactions",
            data: Array.from({length: 4}, () => Math.floor(Math.random() * 1200))
        }];
    },
    mounted() {
        //this.conversionsLineChart.series = 
        //this.conversionsLineChart.series = tempArray;
        // this.series = {
        //     regions: [
        //         {
        //             attribute: "fill",
        //             legend: {
        //                 title: "Transactions",
        //             },
        //             scale: {
        //                 'No Transactions': "#ebf1ff",
        //                 '$1 - $500': "#98a2ed",
        //                 '$500 - $2000': "#7a86de",
        //                 '$2000 - $7000': "#515fc4",
        //                 '$7000 - $12000': "#243296",
        //                 '$12000+': "#011080",
        //             },
        //             values: this.valueAsperScale,
        //         },
        //     ],
        // }
    },
    // watch: {
    //     items: function(){
    //         this.getScale();
    //     }
    // },
    // computed: {
    //     labels() {
    //         // Labels for markers and regions
    //         return {
    //             markers: {
    //                 render(marker) {
    //                     return marker.name;
    //                 },
    //             },
    //         };
    //     }
    // },
}
</script>
