<template>
    <div class="container-fluid">
        <!-- Bread-crumb-->
        <div class="block-header">
            <bread-crumb></bread-crumb>
        </div>
        <div class="row clearfix">
            <div class="col-lg-12">
                <div class="card card-top-line">
                    <!-- Header -->
                    <div class="header d-flex justify-content-between align-items-center">
                        <h2>Transaction List</h2>
                    </div>
                    <!-- Transaction Listing-->
                    <div class="body pt-0">
                        <!-- Filters/Buttons -->
                        <div class="chart-top-action d-lg-flex justify-content-end py-3">
                            <!-- <div class="p-2 mr-auto">
                                <VMdDateRangePicker class="pull-right" opens='left' v-model="dateRange" @change="getSelectedDateRange($event)" :presets="presets" :autoApply="false"></VMdDateRangePicker>
                            </div> -->
                            <!-- SearchBox Filters -->
                            <!-- <div class="d-flex align-items-center flex-column flex-md-row-reverse justify-content-center justify-content-md-start mb-3 payment-dropdown">
                                <div class="input-group ml-md-3">
                                    <input type="text" class="form-control" v-model="searchText"
                                        placeholder="Enter Keywords" aria-label="Enter Keywords"
                                        aria-describedby="search-transactions"  v-on:keyup.enter="refreshTransactionTable">
                                    <div class="input-group-append" @click="refreshTransactionTable()">
                                        <span class="input-group-text" id="search-mail"><i
                                                class="icon-magnifier"></i></span>
                                    </div>
                                    <div class="input-group-append" @click="clearSearch()" v-if="this.searchText">
                                            <span class="input-group-text" id="search-mail"><i class="icon-close"></i></span>
                                    </div>
                                </div>
                                <b-button variant="primary btn-lg btn-mw" @click="excelExport()">Export as Excel<i class="fa fa-download ml-1"></i></b-button>
                                
                            </div> -->
                            <div class="payment-dropdown d-flex flex-lg-row-reverse flex-column ml-lg-3 ml-0 mt-3 mt-lg-0">
                                <div class="country-dropdown">
                                    <div class="form-group form-select multiselect-grp">
                                        <span class="float-label">
                                            <label for="paymentOption">Select Transaction Type</label>
                                            <multiselect
                                                placeholder=""
                                                :show-labels="false" 
                                                :options="type_options" 
                                                label="name"
                                                track-by="name"
                                                @select="setType"
                                                :searchable="false">
                                                <template v-slot:singleLabel="{ option }">
                                                    <span class="option-title">
                                                        <span>{{ option.name }} Payout</span>
                                                    </span>
                                                </template>
                                                <template v-slot:option="{ option }">
                                                    <div class="option-title">
                                                        <span>{{ option.name }} Payout</span>
                                                    </div>
                                                </template>
                                            </multiselect> 
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- All Transaction List -->
                        <b-table responsive ref="transactionTable" table-class="mb-0 table-custom border transaction-list-table"
                            head-variant="light" :items="getTransactionList" :fields="fields" :current-page="currentPage"
                            :per-page="perPage" :sort-by="sortBy" :sort-desc="sortDesc"
                            :sort-direction="sortDirection" :emptyText="'No data found.'" show-empty>
                            <template #head()="{ label, field: { key } }">
                                {{ label }}
                                <!-- Custom icons -->
                                <div>
                                    <i v-if="sortBy !== key" class=""></i>
                                    <i v-else-if="sortDesc" class="fa fa-long-arrow-down ml-1"></i>
                                    <i v-else class="fa fa-long-arrow-up ml-1"></i>
                                </div>
                            </template>
                            <template #cell(srccountry)="data">
                                <div class="align-items-center d-flex justify-content-center">
                                    <span :class="data.value.flagClass"></span>
                                </div>
                            </template>
                            <template #cell(sender)="data">
                                <strong>
                                    <span v-b-tooltip.hover :data-original-title="data.value.name" :title="data.value.name">
                                        {{ data.value.name?.substring(0, 15)}}<span v-if="data.value.name?.length > 15">...</span>
                                    </span>
                                </strong>
                            </template>
                            <template #cell(benificiary)="data">
                                <strong>
                                    <span v-b-tooltip.hover :data-original-title="data.value.name" :title="data.value.name">
                                        {{ data.value.name?.substring(0, 15)}}<span v-if="data.value.name?.length > 15">...</span>
                                    </span>
                                </strong>
                            </template>
                            
                            <template #cell(custRefno)="data">
                                <div class="align-items-center d-flex">
                                    <span>
                                        <i :class="data.value.type" class="mr-1"></i>
                                        <strong>
                                            {{data.value.refno}}
                                        </strong>
                                        <br />
                                        <span class="text-grey d-block" v-b-tooltip.hover :data-original-title="data.value.transactionid" :title="data.value.transactionid">
                                            {{ data.value.transactionid?.substring(0, 15)}}<span v-if="data.value.transactionid?.length > 15">...</span>
                                        </span>
                                    </span>
                                </div>
                            </template>
                            <template #cell(datetime)="data">
                                <strong>{{ data.value.date }}</strong>
                                <small class="text-grey d-block">{{ data.value.time }}</small>
                            </template>
                            <template #cell(accno)="data">
                                <div class="align-items-center d-flex">
                                    <span>
                                        <i :class="data.value.type" class="mr-1"></i>
                                        <strong>
                                            <span v-b-tooltip.hover :data-original-title="data.value.acc" :title="data.value.acc">
                                                {{ data.value.acc?.substring(0, 15)}}<span v-if="data.value.acc?.length > 15">...</span>
                                            </span>
                                        </strong>
                                        <!-- <strong>{{ data.value.acc}}</strong> -->
                                        <br />
                                        <span class="text-grey d-block">{{ data.value.no }}</span>
                                    </span>
                                </div>
                            </template>

                            <template #cell(destamount)="data">
                                <div class="d-flex align-items-start justify-content-center">
                                    <p class="mb-0 text-right">
                                        <span class="d-block">{{ data.value.destamount }}</span>
                                    </p>
                                </div>
                            </template>
                            <template #cell(status)="data">
                                <p class="function-badge badge-group-table mb-0 text-center">
                                    <span class="badge ml-0 d-block" :class="data.value.class">{{ data.value.name
                                    }}</span>
                                </p>
                            </template>
                            <template #cell(action)="data">
                                <b-button class="btn btn-primary ml-2" v-if="data.item.status.name === 'Failed'" @click="showerrorDetail(data.item)"><i class="fa fa-eye"></i> View </b-button>
                            </template>
                        </b-table>
                        <!-- Table Pagination -->
                        <div class="py-3 align-items-center justify-content-between table-pagination" :class="totalRows > 0?' d-flex':'d-none'">
                            <div class="per-page">
                                <b-form-group label="Per page" label-for="per-page-select" class="mb-0">
                                    <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions"
                                        size="sm"></b-form-select>
                                </b-form-group>
                            </div>
                            <div class="number-page">
                                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"
                                    hide-goto-end-buttons hide-ellipsis prev-text="Previous page" next-text="Next page"
                                    align="right">
                                </b-pagination>
                            </div>
                            <div class="go-page">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Go to Page</span>
                                    </div>
                                    <input type="text" class="form-control" v-model="currentPageJump" value="" @keyup.enter="onEnter"
                                        onkeypress='return event.charCode >= 48 && event.charCode <= 57'>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <b-modal id="show-details" size="lg" class="transaction-lg-modal" title="Transaction Details" hide-footer no-stacking no-close-on-backdrop>
           <div class="modal-area">
                <div class="transaction-raw-details-body max-width">
                    <ul class="list-unstyled mb-0">
                        <li>
                            <p class="mb-0 d-flex align-items-center">
                                <span class="font-weight-bold w-40">Trans. ID:</span>
                                <span class="ml-2">{{ tranid }}</span>
                            </p>
                        </li>
                        <li>
                            <p class="mb-0 d-flex align-items-center">
                                <span class="font-weight-bold w-30">Cust. Ref.:</span>
                                <span class="ml-2">{{ custref }}</span>
                            </p>
                        </li>
                        <li>
                            <p class="mb-0 d-flex align-items-center">
                                <span class="font-weight-bold w-30">Trans. Type:</span>
                                <span class="ml-2">{{ trantype }}</span>
                            </p>
                        </li>
                        <li>  <!-- class="py-3" -->
                            <p class="mb-0 d-flex align-items-center">
                                <span class="font-weight-bold w-30">Description:</span>
                                <span class="ml-2 badge-danger">{{ errdescription }}</span>
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
        </b-modal>
        <main-loader ref="loader"></main-loader>
    </div>
</template>
<script>
import '@/plugins/sweetalert2'
import "@/plugins/vee-validate";
import Multiselect from 'vue-multiselect';
const LoaderComponent = require("@/components/admin/loader/LoaderComponent.vue").default;
// const { VMdDateRangePicker } = require("v-md-date-range-picker")
const moment = require('moment')

// import {useToast} from 'vue-toast-notification';
// import 'vue-toast-notification/dist/theme-bootstrap.css';
// const $toast = useToast();

export default {
    name: 'TransactionListComponent',
    components: {
        BreadCrumb: () => import('@/components/BreadCrumb.vue'),
        // Datepicker: () => import('vuejs-datepicker'),
        Multiselect,
        // VMdDateRangePicker
        "main-loader": LoaderComponent,
    },
    methods: {
        getSelectedDateRange(e){
            let startDate = moment(e[0]).format('D-MM-YYYY');
            let endDate = moment(e[1]).format('D-MM-YYYY');
            
            let tempArr = [];
            this.items.map((data) => { 
                let formatResponseData = moment(data.datetime.date).format('D-MM-YYYY');
                
                var d1 = startDate.split("-");
                var d2 = endDate.split("-");
                var c = formatResponseData.split("-");

                var from = new Date(d1[2], parseInt(d1[1])-1, d1[0]);  // -1 because months are from 0 to 11
                var to   = new Date(d2[2], parseInt(d2[1])-1, d2[0]);
                var check = new Date(c[2], parseInt(c[1])-1, c[0]);

                if(check >= from && check <= to){
                    tempArr.push(data);
                }
            });
            this.items = tempArr;
            this.totalRows = this.items.length
        },
        onEnter(e) {
            var paginationMenu = Math.ceil(this.totalRows / this.perPage);
            console.log(e.target.value);
            if(e.target.value > 0){
                if(paginationMenu >= e.target.value){
                    this.currentPage = this.currentPageJump = e.target.value
                } else {
                   this.currentPage = this.currentPageJump = paginationMenu;
                }
            } else {
                this.currentPage = this.currentPageJump = 1;
            }
        },

        refreshTransactionTable() {
            this.$refs.transactionTable.refresh();
        },

        clearSearch(){
            this.searchText = '';
            this.$refs.transactionTable.refresh();
        },
        
        setType(data){
            console.log(data.name);
            this.transtype = {name: data.name};
            this.type = data.name;
            this.refreshTransactionTable();
        },

        setTradename(data){
            this.tradename = {id:data.id, name: data.name};
            this.tradenameId = data.id;
            this.refreshTransactionTable();
        },

        async getTransactionList(ctx) {
            this.$refs.loader.show();
            try {
                let page = "";
                let size = "";
                let sortby = "";
                let sort = "";

                if (ctx.currentPage != undefined) {
                    page = this.excelpagenumber = ctx.currentPage;
                } else {
                    page = this.excelpagenumber = 1;
                }
                if (ctx.perPage != undefined) {
                    size = this.excelpagesize = ctx.perPage;
                } else {
                    size = this.excelpagesize = 50;
                }
                if (ctx.sortBy == "") {
                    sortby = this.excelsortby = "createdAt";
                } else {
                    sortby = this.excelsortby = ctx.sortBy;
                }
                console.log(ctx.sortDesc);
                if (ctx.sortDesc == false) {
                    sort = this.excelsorting = "DESC"; //"DESC";
                } else {
                    sort = this.excelsorting = "ASC";//"ASC";
                }

                this.currentPageJump = page;
                this.items = [];
                this.totalRows = 0;
                // // TODO
                let paramData = {
                    sorting: sort,
                    sortby: sortby,
                    limit: size,
                    page: page,
                };
                if(this.type){
                    paramData.type = this.type
                }

                const response = await this.$http.get(this.$baseurl+"payout/options/transactions",
                    {
                        params: paramData,
                        headers: {
                            'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken")
                        }
                    }
                );
                // console.log(response);
                // JSON responses are automatically parsed.
                response.data.data.map((item) => {
                    const destamt = item.destinationAmount ? item.receivingCurrency + " " + parseFloat(item.destinationAmount).toFixed(2) : 'N/A';
                    // const srcamt = item.destination_amount ? item.sendingCurrency + " " + parseFloat(item.source_transfer_amount).toFixed(2) : 'N/A'; 

                    let status = { name: 'N/A', class: 'badge-grey' };
                    if(item.status == 'Completed' || item.status == 'COMPLETED'){
                        status = { name: 'Completed', class: 'badge-success' }
                    }else if(item.status == 'Failed' || item.status == 'FAILED'){
                        status = { name: 'Failed', class: 'badge-danger' }
                    }else {
                        status = { name: item.status, class: 'badge-grey' }
                    }

                    let transactionLists = {
                        srccountry: { flagClass: "flag-icon flag-icon-" + item.countryFrom.toLowerCase() + " fi-lg fi-round" },
                        datetime: { date: moment(item.createdAt).format('DD-MM-YYYY'), time: moment(item.createdAt).format('h:mm A')+' GMT' },
                        sender: { name: item.senderFirstName + " " + item.senderLastName },
                        benificiary: { name: item.beneficiaryFirstName + " " + item.beneficiaryLastName },
                        destamount: { destamount: destamt },
                        custRefno: {refno:item.customer_ref, transactionid: item.transaction_id},
                        errordescription: item.error_description,
                        type: item.type,
                        status
                    };
                    this.items.push(transactionLists);
                });
                this.totalRows = response.data.totalResults;
                this.$refs.loader.hide();
                return this.items;
            } catch (error) {
                console.log(error);
                this.$refs.loader.hide();
            }
        },

        showerrorDetail(data){
            console.log(data);
            this.tranid=data.custRefno.transactionid;
            this.custref=data.custRefno.refno;
            this.trantype = data.type;
            this.errdescription=data.errordescription;
            this.$bvModal.show('show-details');
        },

        // async excelExport(){
        //     let search = '';
        //     let url = '';
        //     if(this.tradenameId && this.totalRows > 0){
        //         if(this.searchText && this.statusid){
        //             search = 'senderFirstName:'+ this.searchText+',senderLastName:'+ this.searchText+',beneficiaryFirstName:'+ this.searchText+',beneficiaryLastName:'+ this.searchText+',destinationAmount:'+ this.searchText+',customer_ref:'+ this.searchText+',bankAccountNumber:'+ this.searchText

        //             url = 'https://standapp.net/api/v2/payment/transactions-excel/?sorting='+this.excelsorting+'&sortby='+this.excelsortby+'&pagesize='+this.totalRows+'&pagenumber='+this.excelpagenumber+'&tradenameid='+this.tradenameId+'&search='+search+'&status='+this.statusid;
        //         }else if(this.searchText){
        //             search = 'senderFirstName:'+ this.searchText+',senderLastName:'+ this.searchText+',beneficiaryFirstName:'+ this.searchText+',beneficiaryLastName:'+ this.searchText+',destinationAmount:'+ this.searchText+',customer_ref:'+ this.searchText+',bankAccountNumber:'+ this.searchText

        //             url = 'https://standapp.net/api/v2/payment/transactions-excel/?sorting='+this.excelsorting+'&sortby='+this.excelsortby+'&pagesize='+this.totalRows+'&pagenumber='+this.excelpagenumber+'&tradenameid='+this.tradenameId+'&search='+search;
        //         }else if(this.statusid){
        //             url = 'https://standapp.net/api/v2/payment/transactions-excel/?sorting='+this.excelsorting+'&sortby='+this.excelsortby+'&pagesize='+this.totalRows+'&pagenumber='+this.excelpagenumber+'&tradenameid='+this.tradenameId+'&status='+this.statusid;
        //         }else{
        //             url = 'https://standapp.net/api/v2/payment/transactions-excel/?sorting='+this.excelsorting+'&sortby='+this.excelsortby+'&pagesize='+this.totalRows+'&pagenumber='+this.excelpagenumber+'&tradenameid='+this.tradenameId;
        //         }

        //         const link = document.createElement('a');
        //         link.href = url;
        //         link.setAttribute('download', 'transaction.xlsx'); //or any other extension
        //         link.setAttribute('target', '_blank'); //or any other extension
        //         document.body.appendChild(link);
        //         link.click();
        //     }else{
        //         $toast.error('No Data Found',  {
        //                         position:'top-right'
        //                     });
        //     }
        // }
    }, data() {
        return {
            dateRange: null,
            presets: [
                {
                    label: 'Today',
                    range: [
                        moment().startOf('day'),
                        moment().endOf('day')
                    ]
                },
                {
                    label: 'Yesterday',
                    range: [
                        moment().subtract(1, 'day').startOf('day'),
                        moment().subtract(1, 'day').endOf('day')
                    ]
                },
                {
                    label: 'Last 7 Days',
                    range: [
                        moment().subtract(7, 'day'),
                        moment()
                    ]
                },
                {
                    label: 'This Week',
                    range: [
                        moment().startOf('week'),
                        moment().endOf('week')
                    ]
                },
                {
                    label: 'Last Week',
                    range: [
                        moment().subtract(1, 'week').startOf('week'),
                        moment().subtract(1, 'week').endOf('week'),
                    ]
                },
                {
                    label: 'Last 30 Days',
                    range: [
                        moment().subtract(30, 'day'),
                        moment()
                    ]
                },
                {
                    label: 'This Month',
                    range: [
                        moment().startOf('month'),
                        moment().endOf('month')
                    ]
                },
                {
                    label: 'Last Month',
                    range: [
                        moment().subtract(1,'months').startOf('month'),
                        moment().subtract(1,'months').endOf('month')
                    ]
                },
                {
                    label: 'This Year',
                    range: [
                        moment().startOf('year'),
                        moment().endOf('year')
                    ]
                },
                {
                    label: 'Last Year',
                    range: [
                        moment().subtract(1,'year').startOf('year'),
                        moment().subtract(1,'year').endOf('year'),
                    ]
                }
            ],
            transactionDetail: null,
            items: [],

            fields: [
                { key: 'srccountry', label: 'S.C.', sortable: true, thClass: 'th_sortfix' },
                { key: 'datetime', label: 'Date', sortable: true, thClass: 'th_sortfix' },
                { key: 'sender', label: 'Sender', sortable: true, thClass: 'th_sortfix' },
                { key: 'benificiary', label: 'Receiver', sortable: true, thClass: 'th_sortfix' },
                { key: 'custRefno', label: 'Trans. Ref', sortable: true, thClass: 'th_sortfix' },
                { key: 'destamount', label: 'Dest Amount', sortable: true, thClass: 'th_sortfix' },
                { key: 'status', label: 'Trans. Status', sortable: true, thClass: 'th_sortfix' },
                { key: 'action', label: 'Show Error', thClass: 'th_sortfix' },
                { sortable: true, sortByFormatted: true, filterByFormatted: true },
            ],

            totalRows: 0,
            currentPage: 1,
            perPage: 50,
            pageOptions: [50, 100, 150, 200, { value: 1000, text: 1000 }],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            // newMemberUrl: window.location.origin+'/members-form/members-form.html',
            uploadMember: {
                csvExcelFile: null
            },

            isVisibleFilter: false,

            currentPageJump:'',
            searchText:'',
            tradenameId:'',

            excelpagesize: '',
            excelpagenumber : '',
            excelsortby : '',
            excelsorting : '',

            type_options:[
                {name : 'Single'},
                {name : 'Bulk'}
            ],
            transtype:{},
            type:'',

            tranid:'',
            custref:'',
            trantype:'',
            errdescription:''
        }
    }, mounted() {
        // this.totalRows = this.items.length
        if(sessionStorage.getItem("jwtToken")){
            // this.getChannelData();
        } else {
            this.$swal('Error!', 'Please login to continue', 'error', 'Close');
            this.$router.push({path:'/authentication/page-login'});
        }
    }
}
</script>
<style>
text-grey {
    color: #8a8a8a !important;
}
</style>