<template>
    <!-- <fragment> -->
        <div class="d-md-flex justify-content-end py-3">
            <b-button variant="primary btn-mw" size="lg" v-b-modal.business_category>Add Business Category <i class="fa fa-plus-square ml-1"></i></b-button>
            <b-modal id="business_category" title="Add Business Category" hide-footer>
                <div class="modal-area">
                    <ul class="list-unstyled mb-0 payment-single-transfer">
                        <Form @submit="categoryList" :validation-schema="schema" id="business_category" ref="form" v-slot="{ errors }">
                            <li>  
                                <Field name="category_name" rules="required" v-slot="{ handleChange }">   
                                    <div class="form-group" :class="{'errors': errors && errors.category_name,'success':!errors && !errors.category_name}">
                                        <span class="float-label">
                                            <input type="text" @update:model-value="handleChange" v-model="businessCategory.category_name " class="form-control" name="category_name " id="category_name " placeholder="Category Name*">
                                            <label for="category_name " class="control-label">Category Name*</label>
                                            <div class="line"></div>
                                            <i class="fa fa-times" v-if="errors && errors.category_name"></i>
                                            <i class="fa fa-check" v-if="!errors.category_name && businessCategory.category_name"></i>
                                        </span>
                                    </div>
                                </Field>
                            </li>
                            <li>
                                <Field name="business_type" rules="required">
                                    <div class="form-group form-select single-multiselect" :class="{'errors': errors && errors.business_type,'success':!errors || businessCategory.type}">
                                        <span class="float-label">
                                            <label for="functions">Business Type*</label>
                                            <multiselect
                                                placeholder=""
                                                v-model="businessCategory.type"
                                                :show-labels="false" 
                                                :options="typeOptions" 
                                                :searchable="false">
                                                <template v-slot:singleLabel="{ option }">{{ option }}</template>
                                                <template v-slot:option="{ option }">
                                                    <div class="option__desc">
                                                        <span class="option__title">{{ option }}</span>
                                                    </div>
                                                </template>
                                            </multiselect>
                                            <i class="fa fa-times" v-if="errors && errors.business_type"></i>
                                            <i class="fa fa-check" v-if="!errors.business_type && businessCategory.type"></i>
                                        </span>
                                    </div>
                                </Field>
                            </li>
                            <li>
                                <div class="align-items-center d-flex flex-column justify-content-between mt-3 w-100">
                                    <b-button type="submit" variant="primary btn-mw" size="lg">Add Business Category</b-button>
                                    <b-button @click="$bvModal.hide('business_category')" size="lg" variant="outline-secondary mt-3 btn-mw">Cancel</b-button>
                                </div>
                            </li>
                        </Form>
                    </ul>
                </div>
            </b-modal>
        </div>
        <b-table 
            responsive
            outlined
            table-class="mb-0 table-custom"
            head-variant="light"
            :items="items" 
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :sort-by="sortBy"
            :sort-desc="sortDesc"
            :sort-direction="sortDirection">
            <div>
                {{ label }}
                <!-- Custom icons -->
                <div>
                    <i v-if="sortBy !== key" class=""></i>
                    <i v-else-if="sortDesc" class="fa fa-long-arrow-down ml-1"></i>
                    <i v-else class="fa fa-long-arrow-up ml-1"></i>
                </div>  
            </div>
            <template #cell(types)="row">
                <b-dropdown id="type" :text="row.item.types.selectedType" right variant="outline-primary">
                    <b-dropdown-item v-for="(type,index) in row.item.types.typesOption" :key="index" :active="row.item.types.selectedType == type" @click="currentType((currentPage-1) * perPage + row.index,type)">{{ type }}</b-dropdown-item>
                </b-dropdown>
            </template>
            <template #cell(action)>
                <b-dropdown id="action" text="Actions" right variant="outline-primary">
                    <b-dropdown-item>Edit</b-dropdown-item>
                    <b-dropdown-item @click="showAlert">Delete</b-dropdown-item>
                </b-dropdown>
            </template>
        </b-table>

        <!-- Table Pagination -->
        <div class="py-3 d-flex align-items-center justify-content-between table-pagination">
            <div class="per-page">
                <b-form-group label="Per page" label-for="per-page-select" class="mb-0">
                    <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
                </b-form-group>
            </div>
            <div class="number-page">
                <b-pagination 
                    v-model="currentPage" 
                    :total-rows="totalRows" 
                    :per-page="perPage"
                    hide-goto-end-buttons 
                    hide-ellipsis 
                    prev-text="Previous page" 
                    next-text="Next page" 
                    align="right">
                </b-pagination>
            </div>
            <div class="go-page">
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text">Go To Page</span>
                    </div>
                    <input type="text" class="form-control" value="" @keyup.enter="onEnter" onkeypress='return event.charCode >= 48 && event.charCode <= 57'>
                </div>
            </div>
        </div>
    <!-- </fragment> -->
</template>
<script>
import { Form, Field } from 'vee-validate';
import VueMultiselect from 'vue-multiselect'
// import { Fragment } from 'fragment-for-vue/vue3'
export default {
    name:'ComplianceListComponent',
    components: {
        'Multiselect': VueMultiselect,
        Form, 
        Field,
        // Fragment
    },methods: {
        showAlert() {
            const swalWithBootstrapButtons = this.$swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-primary btn-lg my-3',
                    cancelButton: 'btn btn-outline-secondary btn-lg'
                },
                buttonsStyling: false
            })
            swalWithBootstrapButtons.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    swalWithBootstrapButtons.fire(
                    'Deleted!',
                    'Your file has been deleted.',
                    'success'
                    )
                }
            })
        },

        currentType(index,type){
            this.items.map((item,i) => {
                if(index == i){
                    item.types.selectedType = type;
                }
            });
        },

        categoryList(){
            this.$bvModal.hide('business_category');
        }
    },data(){
        return{
           items:[
                {
                   categoryName: 'Registered Sole Proprietorship',
                   types:{selectedType:'Type1',typesOption:['Type1','Type 2','Type 3','Type 4']},
                   action:''
                },
                {
                   categoryName: 'Unregistered Solver Proprietorship',
                   types:{selectedType:'Type 3',typesOption:['Type1','Type 2','Type 3','Type 4']},
                   action:''
                },
                {
                   categoryName: 'Private Limited Liability Company',
                   types:{selectedType:'Type 2',typesOption:['Type1','Type 2','Type 3','Type 4']},
                   action:''
                },
                {
                   categoryName: 'Public Limited Company', 
                   types:{selectedType:'Type 4',typesOption:['Type1','Type 2','Type 3','Type 4']},
                   action:''
                },
                {
                   categoryName: 'Limited Liability Partnership',
                   types:{selectedType:'Type1',typesOption:['Type1','Type 2','Type 3','Type 4']},
                   action:''
                },
                {
                   categoryName: 'Unlimited Liability Company',
                   types:{selectedType:'Type 2',typesOption:['Type1','Type 2','Type 3','Type 4']},
                   action:''
                },
                {
                   categoryName: 'Alumni Association',
                   types:{selectedType:'Type 4',typesOption:['Type1','Type 2','Type 3','Type 4']},
                   action:''
                },
                {
                   categoryName: 'Club or Association',                   
                   types:{selectedType:'Type 1',typesOption:['Type1','Type 2','Type 3','Type 4']},
                   action:''
                },
                {
                   categoryName: 'Registered Charity',
                   types:{selectedType:'Type 3',typesOption:['Type1','Type 2','Type 3','Type 4']},
                   action:''
                },
                {
                   categoryName: 'Unregistered Charity',
                   types:{selectedType:'Type 2',typesOption:['Type1','Type 2','Type 3','Type 4']},
                   action:''
                },
                {
                   categoryName: 'Religious Organisation',
                   types:{selectedType:'Type 1',typesOption:['Type1','Type 2','Type 3','Type 4']},
                   action:''
                },
                {
                   categoryName: 'Membership Organisation',
                   types:{selectedType:'Type 3',typesOption:['Type1','Type 2','Type 3','Type 4']},
                   action:''
                },
                {
                   categoryName: 'Branch or External Company',
                   types:{selectedType:'Type 1',typesOption:['Type1','Type 2','Type 3','Type 4']},
                   action:''
                },
                {
                   categoryName: 'Government Institutions',
                   types:{selectedType:'Type 4',typesOption:['Type1','Type 2','Type 3','Type 4']},
                   action:''
                },
                {
                   categoryName: 'Trust Funds',
                   types:{selectedType:'Type 1',typesOption:['Type1','Type 2','Type 3','Type 4']},
                   action:''
                },
                {
                   categoryName: 'Other – Please Specify',
                   types:{selectedType:'Type 3',typesOption:['Type1','Type 2','Type 3','Type 4']},
                   action:''
                },
            ],
           
            fields: [
                { key: 'categoryName', label: 'Business Categories',sortable: true,thClass:'th_sortfix'},
                { key: 'types', label: 'Type',thClass:'w-130-px'},
                { key: 'action', label: 'Action',thClass:'w-130-px'},
                { sortable: true, sortByFormatted: true, filterByFormatted: true},
            ],

            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10,25, 50, { value: 1000, text: 1000 }],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',

            breadCrumbIteams:[
                {
                    title:'Category List',
                    links:''
                },
            ],

            businessCategory:{
                category_name:'',
                type:''
            },

            typeOptions:['Type 1','Type 2','Type 3','Type 4']
        }
    },mounted() {
        this.totalRows = this.items.length
    }
}
</script>