<template>
    <ul class="list-unstyled mb-4 send-money-card">
        <li>
            <div class="phone-with-country d-flex justify-content-between align-items-center">
                <div class="select-country">
                    <div class="form-group form-select single-multiselect mb-0">
                        <span class="float-label">
                            <multiselect
                                placeholder=""
                                v-model="moneyDetails.currencyFrom"   
                                :show-labels="false" 
                                :options="currencyOptions"
                                :searchable="false"
                                label="name" 
                                track-by="name"
                                :custom-label="currencyFlag"
                                @select="fromCurrencySelected">
                                <template v-slot:singleLabel="{ option }">
                                    <span class="mr-2" :class="option.flagClass"></span>
                                    <span class="option-title">
                                        <span>{{ option.name }}</span>
                                    </span>
                                </template>
                                <template v-slot:option="{ option }">
                                    <div class="option-title">
                                        <span class="mr-2" :class="option.flagClass"></span>
                                        <span>{{ option.name }}</span>
                                    </div>
                                </template>
                            </multiselect>
                        </span>
                    </div>
                </div>
                <div class="add-number">
                    <div class="form-group mb-0">
                        <span class="float-label">
                            <input type="text" v-model="moneyDetails.amountFrom" class="form-control" id="amountFrom" placeholder="0.00" @keyup="handleInput($event)" @keydown="checkDecimals($event)" @input="setcurrencyOnTo()">
                            <div class="line"></div>
                        </span>
                    </div>
                </div>
            </div>
        </li>
        <li class="text-center exchange-icon">
            <a href="javascript:void(0);" @click="swipeTextbox()"><i class="fa fa-exchange"></i></a>
        </li>
        <li>
            <div class="phone-with-country d-flex justify-content-between align-items-center">
                <div class="select-country">
                    <div class="form-group mb-0 form-select single-multiselect">
                        <span class="float-label">
                            <multiselect
                                placeholder=""
                                v-model="moneyDetails.currencyTo"   
                                :show-labels="false" 
                                :options="currencyOptions"
                                :searchable="false"
                                label="name" 
                                track-by="name"
                                :custom-label="currencyFlag"
                                @select="toCurrencySelected">
                                <template v-slot:singleLabel="{ option }">
                                    <span class="mr-2" :class="option.flagClass"></span>
                                    <span class="option-title">
                                        <span>{{ option.name }}</span>
                                    </span>
                                </template>
                                <template v-slot:option="{ option }">
                                    <div class="option-title">
                                        <span class="mr-2" :class="option.flagClass"></span>
                                        <span>{{ option.name }}</span>
                                    </div>
                                </template>
                            </multiselect>
                        </span>
                    </div>
                </div>
                <div class="add-number">
                    <div class="form-group mb-0">
                        <span class="float-label">
                            <input type="text" v-model="moneyDetails.amountTo" class="form-control" id="amountTo" placeholder="0.00" @keyup="handleInput($event)" @keydown="checkDecimals($event)"  @input="setcurrencyOnFrom()">
                            <div class="line"></div>
                        </span>
                    </div>
                </div>
            </div>
        </li>
        <li class="mt-4">
            <div class="country-dropdown">
                <div class="form-group form-select multiselect-grp">
                    <span class="float-label">
                    <label for="Country">How they Receive it</label>
                        <multiselect
                            placeholder=""
                            v-model="moneyDetails.receiveMoneyBy" 
                            :show-labels="false" 
                            :options="receiveMoneyByOptions" 
                            :searchable="false" 
                            label="name" 
                            track-by="value">
                            <template v-slot:singleLabel="{ option }">
                                <span>{{ option.name }}</span>
                            </template>
                            <template v-slot:option="{ option }">
                                <span>{{ option.name }}</span>
                            </template>
                        </multiselect>
                    </span>
                </div>
            </div>
        </li>
        <li class="mt-4">
            <div class="country-dropdown">
                <div class="form-group form-select multiselect-grp">
                    <span class="float-label">
                    <label for="Country">Reason for Transfer</label>
                        <multiselect
                            placeholder=""
                            v-model="moneyDetails.reasonforTransfer" 
                            :show-labels="false" 
                            :options="reasonforTransferOption" 
                            :searchable="false">
                            <template v-slot:singleLabel="{ option }">
                                <span>{{ option }}</span>
                            </template>
                            <template v-slot:option="{ option }">
                                <span>{{ option }}</span>
                            </template>
                        </multiselect>
                    </span>
                </div>
            </div>
        </li>
    </ul>
</template>
<script>
import VueMultiselect from 'vue-multiselect'
export default {
    name:"MoneyExchangeCalculation",
    components: {
        'Multiselect': VueMultiselect,
    },data(){
        return{
            moneyDetails:{
                amountFrom:'',
                currencyFrom:{ name: 'USD', flagClass: "flag-icon flag-icon-us fi-md fi-round"},
                amountTo:'',
                currencyTo:{ name: 'GHS', flagClass: "flag-icon flag-icon-gh fi-md fi-round"},
                receiveMoneyBy: { name:'',value: ''},
                reasonforTransfer:''
            },
            receiveMoneyByOptions:[
                {
                    name:'Mobile Money',
                    value:'mobile-money'
                },
                {
                    name:'Bank Deposit',
                    value:'bank-deposits'
                },
                {
                    name:'Cash Pickup',
                    value:'cash-pickup'
                },
                {
                    name:'Airtime',
                    value:'airtime'
                },
                {
                    name:'PayAngel Wallet',
                    value:'payangel-wallet'
                }
            ],

            reasonforTransferOption:[
                'Financial Support',
                'Funeral Support',
                'Pay / Salary',
                'Goods / Serviced Purchased',
                'School Fees / Education',
                'Mortgage Payment',
                'Property Purchase / Management',
                'Loan',
                'Charity / Donation / Dues',
                'Membership Dues'
            ],

            currencyOptions:[
                { name: 'GBP', flagClass: "flag-icon flag-icon-gb fi-md fi-round"},
                { name: 'GHS', flagClass: "flag-icon flag-icon-gh fi-md fi-round"},
                { name: 'USD', flagClass: "flag-icon flag-icon-us fi-md fi-round"},
                { name: 'RUB', flagClass:"flag-icon flag-icon-ru fi-md fi-round"},
            ],

            currencyRate:{
                GBP:{
                    GHS:9.97,
                    USD:1.31,
                    RUB:108.69,
                    GBP:0.00
                },

                GHS:{
                    GBP:0.10,
                    USD:0.13,
                    RUB:10.88,
                    GHS:0.00
                },

                USD:{
                    GHS:7.58,
                    GBP:0.76,
                    RUB:82.53,
                    USD:0.00
                },

                RUB:{
                    GHS:0.092,
                    GBP:0.0092,
                    USD:0.012,
                    RUB:0.00
                }
            }
        }
    },methods:{
        currencyFlag ({ name, flagClass }) {
            return `${flagClass} - ${name}`
        },

        handleInput (e) {
            let stringValue = e.target.value.toString()
            let regex = /^(?!\.)^[0-9]*(\.[0-9]{0,2})?$/
            if(!stringValue.match(regex) && this.moneyDetails.amountFrom !== '' && this.moneyDetails.amountTo !== '') {
                this.moneyDetails.amountFrom = null
                this.moneyDetails.amountTo = null
            }
            
        },

        checkDecimals (e) {
            let stringValue = e.target.value.toString();
            let regex = /^(?!\.)^[0-9]*(\.[0-9]{0,1})?$/
            if (!stringValue.match(regex)
                && e.which != 8   // backspace
                && e.which != 46  // delete
                && (e.which < 48 || e.which > 57)
                && (e.which < 96 || e.which > 105)){
                    e.preventDefault();
                    return false;
                }
        },

        fromCurrencySelected(currency){
            this.moneyDetails.currencyFrom = currency
           
            if(this.moneyDetails.amountTo){
               this.moneyDetails.amountFrom = parseFloat(this.moneyDetails.amountTo * this.currencyRate[this.moneyDetails.currencyTo.name][this.moneyDetails.currencyFrom.name]).toFixed(2);            
            }

            if(currency.name ==  this.moneyDetails.currencyTo.name && this.moneyDetails.amountTo){
                this.moneyDetails.amountFrom = parseFloat(this.moneyDetails.amountTo).toFixed(2);
            }
        },

        toCurrencySelected(currency){
            this.moneyDetails.currencyTo = currency
            
            if(this.moneyDetails.amountFrom){
                this.moneyDetails.amountTo = parseFloat(this.moneyDetails.amountFrom * this.currencyRate[this.moneyDetails.currencyFrom.name][this.moneyDetails.currencyTo.name]).toFixed(2);          
            }
            if(currency.name ==  this.moneyDetails.currencyFrom.name && this.moneyDetails.amountFrom){
                this.moneyDetails.amountTo = parseFloat(this.moneyDetails.amountFrom).toFixed(2);
            }
        },

        setcurrencyOnTo(){
            var from = this.moneyDetails.currencyFrom.name;
            var to = this.moneyDetails.currencyTo.name;
            this.moneyDetails.amountTo = parseFloat(this.moneyDetails.amountFrom * this.currencyRate[from][to]).toFixed(2);
        },

        setcurrencyOnFrom(){
            var from = this.moneyDetails.currencyFrom.name;
            var to = this.moneyDetails.currencyTo.name;
            this.moneyDetails.amountFrom = parseFloat(this.moneyDetails.amountTo * this.currencyRate[to][from]).toFixed(2);
        },

        swipeTextbox(){
            var currencyFrom = this.moneyDetails.currencyFrom
            var currencyTo = this.moneyDetails.currencyTo
            this.moneyDetails.currencyFrom = currencyTo
            this.moneyDetails.currencyTo = currencyFrom

            var amountFrom = this.moneyDetails.amountFrom
            var amountTo = this.moneyDetails.amountTo
            if(amountFrom){
                this.moneyDetails.amountFrom = parseFloat(amountTo).toFixed(2);
            }
            if(amountTo){
                this.moneyDetails.amountTo = parseFloat(amountTo * this.currencyRate[currencyTo.name][currencyFrom.name]).toFixed(2);
            }
        }
    },mounted(){
        this.receiveMoneyByOptions.map((item)=>{
            if(item.value == this.$route.query.type){ 
                this.moneyDetails.receiveMoneyBy = item
            }
        });
    }
}
</script>