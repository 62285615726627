<template>
    <div class="card card-top-line">
        <div class="header d-flex align-items-center justify-content-between">
            <h2>Finally, in case we need to reach you who should we contact?</h2>
            <i class="fa fa-edit font-20" @click="editDetails()"></i>
        </div>
        <div class="body">
            <div class="account-form">
                <Form @submit="contactDataSubmit" id="contact" ref="form" :validation-schema="schema" v-slot="{ errors }">
                    <b-tabs content-class="px-0" class="contact-tab" nav-class="nav-tabs-new2" :no-nav-style="true" v-model="currentContactsTabIndex">
                        <!-- Individual Tab -->
                        <b-tab title="Account Administration">
                            <Field :rules="currentContactsTabIndex == 0 ? 'required' : ''" name="administration_country">
                                <div class="country-dropdown">
                                    <div class="form-group form-select multiselect-grp" :class="{'errors': errors && errors.administration_country ,'success':contacts.administration.country || !errors}">
                                        <span class="float-label" :class="{'multiselect--disabled':contacts.readonlyMode}">
                                        <label for="c">Country*</label>
                                            <multiselect
                                                :disabled="contacts.readonlyMode"
                                                placeholder=""
                                                v-model="contacts.administration.country" 
                                                :show-labels="false" 
                                                :options="residence_countrys" 
                                                :searchable="false"
                                                label="name" 
                                                track-by="name">
                                                <template v-slot:singleLabel="{ option }">{{ option.name }}</template>
                                                <template v-slot:option="{ option }">
                                                    <span :class="option.flagClass"></span>
                                                    <div class="option__desc">
                                                        <span class="option__title">{{ option.name }}</span>
                                                    </div>
                                                </template>
                                            </multiselect>  
                                            <i class="fa fa-times" v-if="errors && errors.logo && !contacts.readonlyMode"></i>
                                            <i class="fa fa-check" v-if=" !errors.logo && !contacts.readonlyMode"></i>
                                        </span>
                                    </div>
                                </div>
                            </Field>
                            <Field :rules="currentContactsTabIndex == 0 ? 'required' : ''" name="administration_name" v-slot="{ handleChange }">
                                <div class="form-group" :class="{'errors': errors && errors.administration_name ,'success':!errors.administration_name && !errors}">
                                    <span class="float-label">
                                        <input type="text" @update:model-value="handleChange" v-model="contacts.administration.name" class="form-control" id="administration_name" placeholder="Name*"  :disabled="contacts.readonlyMode"/>
                                        <label for="administration_name" class="control-label">Name*</label>
                                        <div class="line"></div>
                                        <i class="fa fa-times" v-if="errors && errors.administration_name && !contacts.readonlyMode"></i>
                                        <i class="fa fa-check" v-if=" !errors.administration_name && !contacts.readonlyMode"></i>
                                    </span>
                                </div>
                            </Field>
                            <Field :rules="currentContactsTabIndex == 0 ? 'required|email' : ''" name="administration_email" v-slot="{ handleChange }">
                                <div class="form-group" :class="{'errors': errors && errors.administration_email ,'success':!errors.administration_email && !errors}">
                                    <span class="float-label">
                                        <input type="email" @update:model-value="handleChange" v-model="contacts.administration.Email" class="form-control" name="email1" id="administration_email" placeholder="Contact Email Address*" :disabled="contacts.readonlyMode">
                                        <label for="administration_email" class="control-label">Email*</label>
                                        <div class="line"></div>
                                        <i class="fa fa-times" v-if="errors && errors.administration_email && !contacts.readonlyMode"></i>
                                        <i class="fa fa-check" v-if=" !errors.administration_email && !contacts.readonlyMode"></i>
                                    </span>
                                </div>
                            </Field>
                            <Field :rules="currentContactsTabIndex == 0 ? 'required' : ''" name="administrationPhoneNumber" v-slot="{ handleChange }">
                                <div class="form-group" :class="{'errors': errors && errors.administrationPhoneNumber ,'success':!errors.administrationPhoneNumber && !errors}">
                                    <span class="float-label">
                                        <input type="text" @update:model-value="handleChange" v-model="contacts.administration.phoneNumber" class="form-control" id="administrationPhoneNumber" placeholder="Phone Number*" :disabled="contacts.readonlyMode"/>
                                        <label for="administrationPhoneNumber" class="control-label">Phone Number*</label>
                                        <div class="line"></div>
                                        <i class="fa fa-times" v-if="errors && errors.administrationPhoneNumber && !contacts.readonlyMode"></i>
                                        <i class="fa fa-check" v-if=" !errors.administrationPhoneNumber && !contacts.readonlyMode"></i>
                                    </span>
                                </div>
                            </Field>
                        </b-tab>

                        <!-- Corporate Tab -->
                        <b-tab title="Finance related issues">
                            <div class="d-flex align-items-center justify-content-between mb-3" :class="{'switch-disable':contacts.readonlyMode}">
                                <p class="mb-0 mr-3 custom-switch-text">Same as Admin</p>
                                <div class="custom-switch success-handle">
                                    <input type="checkbox" class="custom-switch-input" id="is_finance_same_as_admin" v-model="contacts.finance.isSameAsAdmin" :disabled="contacts.readonlyMode">
                                    <label class="custom-switch-label" for="is_finance_same_as_admin">
                                        <span class="custom-switch-text1">Yes</span>
                                        <span class="custom-switch-text2">No</span>
                                    </label>
                                </div>
                            </div>
                            <Field :rules="currentContactsTabIndex == 1 ? 'required' : ''" name="finance_country" v-if="!contacts.finance.isSameAsAdmin">
                                <div class="country-dropdown">
                                    <div class="form-group form-select multiselect-grp" :class="{'errors': errors && errors.finance_country ,'success':contacts.finance.country || !errors}">
                                        <span class="float-label" :class="{'multiselect--disabled':contacts.readonlyMode}">
                                        <label for="c">Country*</label>
                                            <multiselect
                                                :disabled="contacts.readonlyMode"
                                                placeholder=""
                                                v-model="contacts.finance.country" 
                                                :show-labels="false" 
                                                :options="residence_countrys" 
                                                :searchable="false"
                                                label="name" 
                                                track-by="name">
                                                <template v-slot:singleLabel="{ option }">{{ option.name }}</template>
                                                <template v-slot:option="{ option }">
                                                    <span :class="option.flagClass"></span>
                                                    <div class="option__desc">
                                                        <span class="option__title">{{ option.name }}</span>
                                                    </div>
                                                </template>
                                            </multiselect>  
                                            <i class="fa fa-times" v-if="errors && errors.logo && !contacts.readonlyMode"></i>
                                            <i class="fa fa-check" v-if=" !errors.logo && !contacts.readonlyMode"></i>
                                        </span>
                                    </div>
                                </div>
                            </Field>
                        
                            <Field :rules="currentContactsTabIndex == 1 ? 'required' : ''" name="finance_name" v-slot="{ handleChange }" v-if="!contacts.finance.isSameAsAdmin">
                                <div class="form-group" :class="{'errors': errors && errors.finance_name ,'success':!errors.finance_name && !errors}">
                                    <span class="float-label">
                                        <input type="text" @update:model-value="handleChange" v-model="contacts.finance.name" class="form-control" id="finance_name" placeholder="Name*" :disabled="contacts.readonlyMode"/>
                                        <label for="finance_name" class="control-label">Name*</label>
                                        <div class="line"></div>
                                        <i class="fa fa-times" v-if="errors && errors.finance_name && !contacts.readonlyMode"></i>
                                        <i class="fa fa-check" v-if=" !errors.finance_name && !contacts.readonlyMode"></i>
                                    </span>
                                </div>
                            </Field>
                        
                            <Field :rules="currentContactsTabIndex == 1 ? 'required|email' : ''" name="finance_email" v-slot="{ handleChange }" v-if="!contacts.finance.isSameAsAdmin">
                                <div class="form-group" :class="{'errors': errors && errors.finance_email ,'success':!errors.finance_email && !errors}">
                                    <span class="float-label">
                                        <input type="email" @update:model-value="handleChange" v-model="contacts.finance.Email" class="form-control" name="email1" id="finance_email" placeholder="Contact Email Address*" :disabled="contacts.readonlyMode">
                                        <label for="finance_email" class="control-label">Email*</label>
                                        <div class="line"></div>
                                        <i class="fa fa-times" v-if="errors && errors.finance_email && !contacts.readonlyMode"></i>
                                        <i class="fa fa-check" v-if=" !errors.finance_email && !contacts.readonlyMode"></i>
                                    </span>
                                </div>
                            </Field>
                        
                            <Field name="financePhoneNumber" :rules="currentContactsTabIndex == 1 ? 'required' : ''" v-slot="{ handleChange }" v-if="!contacts.finance.isSameAsAdmin">
                                <div class="form-group" :class="{'errors': errors && errors.financePhoneNumber ,'success':!errors.financePhoneNumber && !errors}">
                                    <span class="float-label">
                                        <input type="text" @update:model-value="handleChange" v-model="contacts.finance.phoneNumber" class="form-control" id="financePhoneNumber" placeholder="Phone Number*" :disabled="contacts.readonlyMode"/>
                                        <label for="financePhoneNumber" class="control-label">Phone Number*</label>
                                        <div class="line"></div>
                                        <i class="fa fa-times" v-if="errors && errors.financePhoneNumber && !contacts.readonlyMode"></i>
                                        <i class="fa fa-check" v-if=" !errors.financePhoneNumber && !contacts.readonlyMode"></i>
                                    </span>
                                </div>
                            </Field>
                        </b-tab>

                        <!-- Customer Service Issues  -->
                        <b-tab title="Customer Service Issues">
                            <div class="d-flex align-items-center justify-content-between mb-3" :class="{'switch-disable':contacts.readonlyMode}">
                                <p class="mb-0 mr-3 custom-switch-text">Same as Admin</p>
                                <div class="custom-switch success-handle">
                                    <input type="checkbox" class="custom-switch-input" id="is_customer_same_as_admin" @update:model-value="handleChange" v-model="contacts.customerService.isSameAsAdmin" :disabled="contacts.readonlyMode">
                                    <label class="custom-switch-label" for="is_customer_same_as_admin">
                                        <span class="custom-switch-text1">Yes</span>
                                        <span class="custom-switch-text2">No</span>
                                    </label>
                                </div>
                            </div>
                            <Field :rules="currentContactsTabIndex == 2 ? 'required' : ''" name="customerService_country" v-if="!contacts.customerService.isSameAsAdmin">
                                <div class="country-dropdown">
                                    <div class="form-group form-select multiselect-grp" :class="{'errors': errors && errors.customerService_country ,'success':contacts.customerService.country || !errors}">
                                        <span class="float-label" :class="{'multiselect--disabled':contacts.readonlyMode}">
                                        <label for="c">Country*</label>
                                            <multiselect
                                                :disabled="contacts.readonlyMode"
                                                placeholder=""
                                                v-model="contacts.customerService.country" 
                                                :show-labels="false" 
                                                :options="residence_countrys" 
                                                :searchable="false"
                                                label="name" 
                                                track-by="name">
                                                <template v-slot:singleLabel="{ option }">{{ option.name }}</template>
                                                <template v-slot:option="{ option }">
                                                    <span :class="option.flagClass"></span>
                                                    <div class="option__desc">
                                                        <span class="option__title">{{ option.name }}</span>
                                                    </div>
                                                </template>
                                            </multiselect>  
                                            <i class="fa fa-times" v-if="errors && errors.logo && !contacts.readonlyMode"></i>
                                            <i class="fa fa-check" v-if=" !errors.logo && !contacts.readonlyMode"></i>
                                        </span>
                                    </div>
                                </div>
                            </Field>
                        
                            <Field :rules="currentContactsTabIndex == 2 ? 'required' : ''" name="customerService_name" v-slot="{ handleChange }" v-if="!contacts.customerService.isSameAsAdmin">
                                <div class="form-group" :class="{'errors': errors && errors.customerService_name ,'success':!errors.customerService_name && !errors}">
                                    <span class="float-label">
                                        <input type="text" @update:model-value="handleChange" v-model="contacts.customerService.name" class="form-control" id="customerService_name" placeholder="Name*" :disabled="contacts.readonlyMode"/>
                                        <label for="customerService_name" class="control-label">Name*</label>
                                        <div class="line"></div>
                                        <i class="fa fa-times" v-if="errors && errors.customerService_name && !contacts.readonlyMode"></i>
                                        <i class="fa fa-check" v-if=" !errors.customerService_name && !contacts.readonlyMode"></i>
                                    </span>
                                </div>
                            </Field>
                        
                            <Field :rules="currentContactsTabIndex == 2 ? 'required|email' : ''" name="customerService_email" v-slot="{ handleChange }" v-if="!contacts.customerService.isSameAsAdmin">
                                <div class="form-group" :class="{'errors': errors && errors.customerService_email ,'success':!errors.customerService_email && !errors}">
                                    <span class="float-label">
                                        <input type="email" @update:model-value="handleChange" v-model="contacts.customerService.Email" class="form-control" name="email1" id="customerService_email" placeholder="Contact Email Address*" :disabled="contacts.readonlyMode">
                                        <label for="customerService_email" class="control-label">Email*</label>
                                        <div class="line"></div>
                                        <i class="fa fa-times" v-if="errors && errors.customerService_email && !contacts.readonlyMode"></i>
                                        <i class="fa fa-check" v-if=" !errors.customerService_email && !contacts.readonlyMode"></i>
                                    </span>
                                </div>
                            </Field>
                        
                            <Field :rules="currentContactsTabIndex == 2 ? 'required' : ''" name="customerServicePhoneNumber" v-slot="{ handleChange }" v-if="!contacts.customerService.isSameAsAdmin">
                                <div class="form-group" :class="{'errors': errors && errors.customerServicePhoneNumber ,'success':!errors.customerServicePhoneNumber && !errors}">
                                    <span class="float-label">
                                        <input type="text" @update:model-value="handleChange" v-model="contacts.customerService.phoneNumber" class="form-control" id="customerServicePhoneNumber" placeholder="Phone Number*" :disabled="contacts.readonlyMode"/>
                                        <label for="customerServicePhoneNumber" class="control-label">Phone Number*</label>
                                        <div class="line"></div>
                                        <i class="fa fa-times" v-if="errors && errors.customerServicePhoneNumber && !contacts.readonlyMode"></i>
                                        <i class="fa fa-check" v-if=" !errors.customerServicePhoneNumber && !contacts.readonlyMode"></i>
                                    </span>
                                </div>
                            </Field>  
                        </b-tab>

                        <!-- Technical Issues  -->
                        <b-tab title="Technical Issues">
                            <div class="d-flex align-items-center justify-content-between mb-3" :class="{'switch-disable':contacts.readonlyMode}">
                                <p class="mb-0 mr-3 custom-switch-text">Same as Admin</p>
                                <div class="custom-switch success-handle">
                                    <input type="checkbox" class="custom-switch-input" id="is_technical_same_as_admin" @update:model-value="handleChange" v-model="contacts.technical.isSameAsAdmin" :disabled="contacts.readonlyMode">
                                    <label class="custom-switch-label" for="is_technical_same_as_admin">
                                        <span class="custom-switch-text1">Yes</span>
                                        <span class="custom-switch-text2">No</span>
                                    </label>
                                </div>
                            </div>
                            <Field name="technical_country" :rules="currentContactsTabIndex == 3 ? 'required' : ''" >
                                <div class="country-dropdown">
                                    <div class="form-group form-select multiselect-grp" :class="{'errors': errors && errors.technical_country ,'success':contacts.technical.country || !errors}">
                                        <span class="float-label" :class="{'multiselect--disabled':contacts.readonlyMode}">
                                        <label for="c">Country*</label>
                                            <multiselect
                                                :disabled="contacts.readonlyMode"
                                                placeholder=""
                                                v-model="contacts.technical.country" 
                                                :show-labels="false" 
                                                :options="residence_countrys" 
                                                :searchable="false"
                                                label="name" 
                                                track-by="name">
                                                <template v-slot:singleLabel="{ option }">{{ option.name }}</template>
                                                <template v-slot:option="{ option }">
                                                    <span :class="option.flagClass"></span>
                                                    <div class="option__desc">
                                                        <span class="option__title">{{ option.name }}</span>
                                                    </div>
                                                </template>
                                            </multiselect>  
                                            <i class="fa fa-times" v-if="errors && errors.logo && !contacts.readonlyMode"></i>
                                            <i class="fa fa-check" v-if=" !errors.logo && !contacts.readonlyMode"></i>
                                        </span>
                                    </div>
                                </div>
                            </Field>
                        
                            <Field :rules="currentContactsTabIndex == 3 ? 'required' : ''" name="technical_name" v-slot="{ handleChange }">
                                <div class="form-group" :class="{'errors': errors && errors.technical_name ,'success':!errors.technical_name && !errors}">
                                    <span class="float-label">
                                        <input type="text" @update:model-value="handleChange" v-model="contacts.technical.name" class="form-control" id="technical_name" placeholder="Name*" :disabled="contacts.readonlyMode"/>
                                        <label for="technical_name" class="control-label">Name*</label>
                                        <div class="line"></div>
                                        <i class="fa fa-times" v-if="errors && errors.technical_name && !contacts.readonlyMode"></i>
                                        <i class="fa fa-check" v-if=" !errors.technical_name && !contacts.readonlyMode"></i>
                                    </span>
                                </div>
                            </Field>
                        
                            <Field :rules="currentContactsTabIndex == 3 ? 'required|email' : ''" name="technical_email" v-slot="{ handleChange }">
                                <div class="form-group" :class="{'errors': errors && errors.technical_email ,'success':!errors.technical_email && !errors}">
                                    <span class="float-label">
                                        <input type="email" @update:model-value="handleChange" v-model="contacts.technical.Email" class="form-control" name="email1" id="technical_email" placeholder="Contact Email Address*" :disabled="contacts.readonlyMode">
                                        <label for="technical_email" class="control-label">Email*</label>
                                        <div class="line"></div>
                                        <i class="fa fa-times" v-if="errors && errors.technical_email && !contacts.readonlyMode"></i>
                                        <i class="fa fa-check" v-if=" !errors.technical_email && !contacts.readonlyMode"></i>
                                    </span>
                                </div>
                            </Field>
                        
                            <Field :rules="currentContactsTabIndex == 3 ? 'required' : ''" name="technicalphoneNumber" v-slot="{ handleChange }">
                                <div class="form-group" :class="{'errors': errors && errors.technicalphoneNumber ,'success':!errors.technicalphoneNumber && !errors}">
                                    <span class="float-label">
                                        <input type="text" @update:model-value="handleChange" v-model="contacts.technical.phoneNumber" class="form-control" id="technicalphoneNumber" placeholder="Phone Number*" :disabled="contacts.readonlyMode"/>
                                        <label for="technicalphoneNumber" class="control-label">Phone Number*</label>
                                        <div class="line"></div>
                                        <i class="fa fa-times" v-if="errors && errors.technicalphoneNumber && !contacts.readonlyMode"></i>
                                        <i class="fa fa-check" v-if=" !errors.technicalphoneNumber && !contacts.readonlyMode"></i>
                                    </span>
                                </div>
                            </Field> 
                        </b-tab>
                    </b-tabs>
                    <div v-if="!contacts.readonlyMode" class="justify-content-center d-flex">
                        <b-button size="lg" @click="contactDataSubmit()" class="btn-mw">Cancel</b-button>
                        <b-button type="submit" variant="primary ml-1 btn-mw" size="lg">Update</b-button>
                    </div>
                </Form>
            </div>
        </div>
    </div>
</template>
<script>
import VueMultiselect from 'vue-multiselect'
import { Form, Field } from 'vee-validate';
export default {
    name: 'Contact-Profile',
    components:{
        Form,
        Field,
        'Multiselect': VueMultiselect,
    },
    data(){
        return{
            currentContactsTabIndex: 0, 
            contacts:{
                readonlyMode:true,
                administration:{
                    country:[{ name: 'France', flagClass: "flag-icon flag-icon-fr fi-xl fi-round"}],
                    name:'administration',
                    Email:'administration@gmail.com',
                    phoneNumber:'02359874563'
                },

                finance:{
                    country:[{ name: 'United Kingdom', flagClass: "flag-icon flag-icon-gb fi-xl fi-round"}],
                    name:'finance',
                    Email:'finance@gmail.com',
                    phoneNumber:'85624569852',
                    isSameAsAdmin: false,
                },

                customerService:{
                    country:[{ name: 'United States', flagClass: "flag-icon flag-icon-us fi-xl fi-round"}],
                    name:'customerService',
                    Email:'customerService@gmail.com',
                    phoneNumber:'120365894520',
                    isSameAsAdmin: true,
                },

                technical:{
                    country:[{ name: 'United Kingdom', flagClass: "flag-icon flag-icon-gb fi-xl fi-round"}],
                    name:'technical',
                    Email:'technical@gmail.com',
                    phoneNumber:'1023658974102',
                    isSameAsAdmin: true,
                },
            },
            residence_countrys: [
                { name: 'India', flagClass:"flag-icon flag-icon-in fi-xl fi-round"},
                { name: 'United Kingdom', flagClass: "flag-icon flag-icon-gb fi-xl fi-round"},
                { name: 'France' , flagClass: "flag-icon flag-icon-fr fi-xl fi-round"},
                { name: 'United States', flagClass: "flag-icon flag-icon-us fi-xl fi-round"},
            ],

        }
    },methods:{
        editDetails(){
           this.contacts.readonlyMode = false
        },

        contactDataSubmit(){
            this.contacts.readonlyMode = true
        },
    }
}
</script>
