<template>
    <div class="container-fluid">
        <div class="block-header">
            <bread-crumb></bread-crumb>
        </div>
        <div class="row clearfix">
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="card card-top-line">
                    <div class="header">
                        <h2>Manage Funds</h2>
                    </div>
                    <div class="body pt-0">
                        <div class="chart-top-action d-lg-flex justify-content-between py-3">
                            <div class="d-md-flex justify-content-between">
                                <div class="mr-0 mr-md-3 action-top-search">
                                    <div class="input-group">
                                        <input type="text" class="form-control" placeholder="Search fund" aria-label="Search fund" aria-describedby="search-fund" v-model="searchText" v-on:keyup.enter="refreshFundTable">
                                        <div class="input-group-append" @click="refreshFundTable()">
                                            <span class="input-group-text" id="search-mail"><i class="icon-magnifier"></i></span>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="fliter-main mt-3 mt-md-0">
                                    <b-button class="fliter-btn btn-lg btn-mw" :variant="isVisibleFilter ? 'primary' : 'outline-primary'" @click="isVisibleFilter = !isVisibleFilter">Filter <i class="fa fa-filter" aria-hidden="true"></i></b-button>
                                    <b-collapse id="fliter-collapse" class="fliter-collapse" v-model="isVisibleFilter">
                                        <div class="d-flex justify-content-between p-3">
                                            <div class="form-group form-select single-multiselect">
                                                <span class="float-label">
                                                    <multiselect
                                                        placeholder="Select Filter Type"
                                                        v-model="filterBy"  
                                                        :show-labels="false" 
                                                        :options="filterOption" 
                                                        label="name"
                                                        :searchable="true">
                                                    </multiselect> 
                                                </span>
                                                <div class="line"></div>
                                            </div>
                                            <div class="form-group form-select single-multiselect">
                                                <span class="float-label">
                                                    <multiselect
                                                        placeholder="Select Filter Type"
                                                        v-model="condition"  
                                                        :show-labels="false" 
                                                        :options="conditionList" 
                                                        label="name"
                                                        :searchable="true">
                                                    </multiselect> 
                                                </span>
                                                <div class="line"></div>
                                            </div>
                                            <div class="form-group">
                                                <input class="form-control" type="text" placeholder="Enter Value" v-model="filterValue" />
                                                <div class="line"></div>
                                            </div>
                                            <div class="form-group">
                                                <button class="btn btn-primary h-100" @click="isVisibleFilter = false; refreshFundTable()">Apply</button>
                                            </div>
                                            <div class="form-group">
                                                <button class="btn btn-outline-danger h-100" @click="isVisibleFilter = false; resetFilterValue()">Cancel</button>
                                            </div>
                                        </div>
                                    </b-collapse>
                                </div> -->
                            </div>
                            <div class="d-md-flex justify-content-between mt-lg-0 mt-md-3 mt-0">
                                <div class="payment-dropdown d-flex flex-row-reverse ml-lg-3 mt-3 mt-sm-0">
                                    <b-button size="lg" variant="primary btn-mw" @click="createFund()">Add Funds <i class="fa fa-plus-square"></i></b-button>
                                    <!-- <b-button class="mr-0 mr-md-3" variant="outline-primary btn-mw" size="lg"  @click="withdrawFund()">Withdraw Funds <i class="fa fa-external-link"></i></b-button> -->
                                </div>
                            </div>
                        </div>

                        <b-table 
                            ref="fundTable"
                            responsive
                            hover 
                            table-class="js-basic-example table-custom mb-0 border"
                            head-variant="light"
                            :items="getFundData" 
                            :fields="fields"
                            :current-page="currentPage"
                            :per-page="perPage"
                            :sort-by="sortBy"
                            :sort-desc="sortDesc"
                            :sort-direction="sortDirection" :emptyText="'No data found.'" show-empty>
                            <template #head()="{ label, field: { key } }">
                                {{ label }}
                                <!-- Custom icons -->
                                <div>
                                    <i v-if="sortBy !== key" class=""></i>
                                    <i v-else-if="sortDesc" class="fa fa-long-arrow-down ml-1"></i>
                                    <i v-else class="fa fa-long-arrow-up ml-1"></i>
                                </div>
                            </template>
                            <template #cell(dropdawn)="data">
                                <p class="function-badge badge-group-table mb-0">
                                    <span class="badge ml-0" :class="data.value.class">{{ data.value.name }}</span>
                                </p>
                            </template>
                            <template #cell(action)="data">
                                <span v-if="data.item.fileexist != ''">
                                    <span @click="downloadfile(data)"><i class="fa fa-download fa-lg" ></i></span>
                                    <span class="ml-4" @click="previewPdf(data.item.id)"><i class="fa fa-eye fa-lg" ></i></span>
                                </span>
                                <span v-if="data.item.fileexist == ''">N/A</span>
                            </template>
                        </b-table>
                        <!-- Table Pagination -->
                        <div class="py-3 align-items-center justify-content-between table-pagination" :class="totalRows > 0?' d-flex':'d-none'" >
                            <div class="per-page">
                                <b-form-group label="Per page" label-for="per-page-select" class="mb-0">
                                    <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
                                </b-form-group>
                            </div>
                            <div class="number-page">
                                <b-pagination 
                                    v-model="currentPage" 
                                    :total-rows="totalRows" 
                                    :per-page="perPage"
                                    hide-goto-end-buttons 
                                    hide-ellipsis 
                                    prev-text="Previous page" 
                                    next-text="Next page" 
                                    align="right">
                                </b-pagination>
                            </div>
                            <div class="go-page">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Go To Page</span>
                                    </div>
                                    <input type="text" v-model="currentPageJump" class="form-control" value="" @keyup.enter="onEnter" onkeypress='return event.charCode >= 48 && event.charCode <= 57'>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <add-modal></add-modal> -->
        <!-- Add Fund -->
        <b-modal id="add_fund" title="Add Fund" hide-footer no-stacking no-close-on-backdrop @shown="modalShow()">
            <!--  @shown="resetForm" -->
            <ul class="list-unstyled mb-0 payment-single-transfer">

                <Form @submit="addFundForm" :validation-schema="schema" autocomplete="off" id="basic-form" ref="form" v-slot="{ errors }">
                    <li class="mb-3">
                        <strong> Business Name : <span>{{selectedBusinessName}}</span></strong>
                    </li>
                    <li>
                        <Field name="associatedWallet" :rules="{'required':!addFundDatas.associatedWallet}" v-slot="{ handleChange }">
                            <div class="country-dropdown">
                                <div class="form-group form-select multiselect-grp" :class="{'errors':errors.associatedWallet && !addFundDatas.associatedWallet,'success':!errors.associatedWallet && addFundDatas.associatedWallet}">
                                    <span class="float-label">
                                    <label for="Country">Associated wallet*</label>
                                        <multiselect
                                            placeholder="Associated wallet"
                                            v-model="addFundDatas.associatedWallet"
                                            :show-labels="false" 
                                            :options="walletOptions" 
                                            :searchable="true" 
                                            label="name" 
                                            track-by="name"
                                            @input="handleChange"
                                            @select="setCurrency">
                                            <template v-slot:singleLabel="{ option }">{{ option.name }}</template>
                                            <template v-slot:option="{ option }">
                                                <div class="option__desc">
                                                    <span class="option__title">{{ option.name }}</span>
                                                </div>
                                            </template>
                                        </multiselect> 
                                        <i class="fa fa-times" v-if="errors.associatedWallet && !addFundDatas.associatedWallet"></i>
                                        <i class="fa fa-check" v-if="!errors.associatedWallet && addFundDatas.associatedWallet"></i>
                                    </span>
                                </div>
                            </div>
                        </Field>
                    </li>
                    <li class="mb-3">
                        <strong> Selected Currency : <span v-if="addFundDatas.associatedWallet">{{selectedCurrency}}</span></strong>
                    </li>
                    <li>
                        <Field name="paymentOption" :rules="{'required':!addFundDatas.paymentOption}" v-slot="{ handleChange }">
                            <div class="country-dropdown">
                                <div class="form-group form-select multiselect-grp" :class="{'errors':errors.paymentOption && !addFundDatas.paymentOption,'success':!errors.paymentOption && addFundDatas.paymentOption}">
                                    <span class="float-label">
                                        <label for="paymentOption">Payment Option*</label>
                                        <multiselect
                                            placeholder=""
                                            v-model="addFundDatas.paymentOption"  
                                            :show-labels="false" 
                                            :options="paymentOptions" 
                                            label="name"
                                            track-by="name"
                                            @input="handleChange"
                                            :searchable="true">
                                            <template v-slot:singleLabel="{ option }">
                                                <span class="option-title"><span>{{ option.name }}</span></span>
                                            </template>
                                            <template v-slot:option="{ option }">
                                                <div class="option-title"><span>{{ option.name }}</span></div>
                                            </template>
                                        </multiselect> 
                                        <i class="fa fa-times" v-if="errors && errors.paymentOption"></i>
                                        <i class="fa fa-check" v-if="addFundDatas.paymentOption && !errors.paymentOption"></i>
                                    </span>
                                </div>
                            </div>
                        </Field>
                    </li>
                    <!-- <li>
                        <ValidationProvider name="paymentReference" rules="required" v-slot="{ errors}">
                            <div class="form-group" :class="{'errors': errors.length && !addFundDatas.paymentReference || errorArr[1] && errorArr[1].fieldName!='','success':addFundDatas.paymentReference && errorArr[1] && errorArr[1].fieldName==''}">
                                <span class="float-label">
                                    <input type="text" v-model="addFundDatas.paymentReference" class="form-control" id="paymentReference" placeholder="Payment Reference*">
                                    <label for="paymentReference" class="control-label">Payment Reference*</label>
                                    <div class="line"></div>
                                    <i class="fa fa-times" v-if="errors.length && !addFundDatas.paymentReference || errorArr[1] && errorArr[1].fieldName!=''"></i>
                                    <i class="fa fa-check" v-if="addFundDatas.paymentReference && errorArr[1] && errorArr[1].fieldName==''"></i>
                                </span>
                            </div>
                        </ValidationProvider>
                    </li> -->
                    <li>
                        <Field rules="required" name="addAmount" v-slot="{ handleChange }">
                            <div class="form-group" :class="{'errors': errors && errors.addAmount,'success':!errors && !errors.addAmount}">
                                <span class="float-label">
                                    <input type="text" id="addAmount" v-model="addFundDatas.addAmount" @update:model-value="handleChange" class="form-control" placeholder="Add Fund Amount*">
                                    <label for="addAmount" class="control-label">Add Fund Amount*</label>
                                    <div class="line"></div>
                                    <i class="fa fa-times" v-if="errors && errors.addAmount"></i>
                                    <i class="fa fa-check" v-if="addFundDatas.addAmount && !errors.addAmount"></i>
                                </span>
                            </div>
                        </Field>
                    </li>
                    <li>
                        <div class="form-group">
                            <Field name="fund_proof_file">
                                <!--  rules="required" -->
                                <div class="form-group" :class="{'errors': errors && errors.fund_proof_file, 'success': addFundDatas.fundProofFile}">
                                    <div class="custom-file">
                                        <b-form-file
                                            v-model="addFundDatas.fundProofFile"
                                            placeholder="Select Pdf for upload Proof of funds"
                                            drop-placeholder="Drop file here..."
                                            accept=".pdf"
                                            ref="file"
                                            @change="uploadFile"
                                        ></b-form-file>
                                        <span v-if="errors && errors.fund_proof_file" class="text-danger">Please upload a file</span>
                                        <i class="fa fa-times" v-if="errors && errors.fund_proof_file"></i>
                                        <i class="fa fa-check" v-if="addFundDatas.fundProofFile"></i>
                                    </div>
                                </div>
                            </Field>
                            <span class="text-danger" v-if="fundErrorMsg != ''">{{fundErrorMsg}}</span>
                        </div>
                    </li>
                    <div class="align-items-center d-flex flex-column justify-content-between mt-3 w-100">
                        <b-button type="submit" variant="primary btn-mw" size="lg">Add Fund</b-button>
                        <b-button @click="$bvModal.hide('add_fund')" size="lg" variant="outline-secondary mt-3 btn-mw">Cancel</b-button>
                    </div>
                </Form>
            </ul>
        </b-modal>
        <main-loader ref="loader"></main-loader>
    </div>
</template>
<script>
const BreadCrumb = require('@/components/BreadCrumb.vue').default
const LoaderComponent = require('@/components/admin/loader/LoaderComponent.vue').default
import Multiselect from 'vue-multiselect'
import { Form, Field } from 'vee-validate';
import {useToast} from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-bootstrap.css';
const $toast = useToast();

export default {
    name:'FundComponent',
    components: {
        BreadCrumb,
        Form, Field,
        'main-loader':LoaderComponent,
        Multiselect
    },data(){
        return{
            isVisibleFilter: false,

            options: [
                { value: 'pending', text:'Pending' },
                { value: 'approved', text: 'Approved' },
                { value: 'rejected', text: 'Rejected' },
            ],

            items:[],
           
            fields: [
                { key: 'name', label: 'Name',sortable: true,thClass:'th_sortfix'},
                { key: 'paymentOption', label: 'Payment Option',sortable: true,thClass:'th_sortfix'},
                { key: 'reference', label: 'Payment Reference'},
                { key: 'currency', label:'Currency' },
                { key: 'amount', label:'Amount' },
                { key: 'dropdawn', label: 'Status'},
                { key:'action', label:'Proof of fund' },
                { sortable: true, sortByFormatted: true, filterByFormatted: true},
            ],

            filterOption: [
                { value: 'walletname', name:'Wallet Name' },
                { value: 'paymentoptionname', name:'Payment Option Name' },
                { value: 'paymentreference', name:'Payment Reference' },
                { value: 'amount', name:'Amount' },
                { value: 'status', name:'Status' },
                { value: 'createdat', name:'Created At'},
                { value: 'createdby', name:'Created By' },
            ],

            conditionList: [
                { value: '==', name:'equals to' },
                { value: '>=', name:'greater than or equals to' },
                { value: '>', name:'greater than' },
                { value: '<', name:'less than' },
                { value: '<=', name:'less than or equals to' },
                { value: 'like', name:'like' }
            ],

            totalRows: 0,
            currentPage: 1,
            currentPageJump: 1,
            perPage: 10,
            pageOptions: [10 , 25, 50, { value: 100, text: 100 }],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            searchText: '',
            filterBy: '',
            filterValue: '',
            condition: '',

            addFundDatas:{
                // businessName:'',
                associatedWallet:null,
                // currency:'GB',
                paymentOption:null,
                paymentReference:'',
                addAmount:'',
                fundProofFile:null
            },
            images: null,

            selectedBusinessName : '',
            selectedCurrency : '',
            
            businessNameOptions: [],
            walletOptions : [],
            paymentOptions: [
                // {'id':'1', 'name': 'Bank Deposit'},
                // {'id':'2', 'name': 'Debit Card'},
                // {'id':'3', 'name': 'Credit Card'}
            ],
        }
    },methods: {

        onEnter(e) {
            var paginationMenu = Math.ceil(this.totalRows / this.perPage);
            if(e.target.value > 0){
                if(paginationMenu >= e.target.value){
                    this.currentPage = e.target.value
                }
            }
        },

        async getFundData(ctx) {
            try {
                let page  = '';
                let size = '';
                let sortby = '';
                let sort = false;
                if(ctx.currentPage != undefined) {
                    page =  ctx.currentPage;
                } else {
                    page = 1;
                }
                if(ctx.perPage != undefined) {
                    size = ctx.perPage;
                } else {
                    size = 10;
                }
                if(ctx.sortBy == '') {
                    sortby = 'createdat'
                } else {
                    sortby = ctx.sortBy;
                }
                if(ctx.sortDesc == false) {
                    sort = 'desc'
                } else {
                    sort = 'asc';
                }

                
                this.currentPageJump = page;
                this.items = [];
                let paramData = {
                    sort : sort,
                    sortby : sortby,
                    size : size,
                    page : page,
                    search : this.searchText,
                    filterby : this.filterBy ? this.filterBy.value : '',
                    filtervalue : this.filterValue,
                    condition : this.condition ? this.condition.value : '=='
                }
                // console.log(paramData);
                const response = await this.$http.get(this.$baseurl+"transactions/funds/"+sessionStorage.getItem("businessId"), { 
                    params: paramData,
                    headers: {
                        'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken")
                    }
                });
                // JSON responses are automatically parsed.
                // console.log(response);
                response.data.transactions.map((item) => {
                    let dropdownMenu = '';
                    if(item.status == 'pending'){
                        dropdownMenu = {selected:'pending',class:'btn-outline-warning',id:item.walletid,name:'Pending'}
                    } else if(item.status == 'approved') {
                        dropdownMenu = {selected:'approved',class:'btn-outline-success',id:item.walletid,name:'Approved'}
                    } else {
                        dropdownMenu = {selected:'rejected',class:'btn-outline-danger',id:item.walletid,name:'Rejected'}
                    }
                    let fundLists = {
                        id:item.id,
                        fileexist: item.downloadfile,
                        walletid:item.walletid,
                        name:item.walletname,
                        paymentOption:item.paymentoptionname,
                        reference:item.paymentreference,
                        currency:item.currencycode,
                        amount:this.formateNumbers(parseFloat(item.amount).toFixed(2)),
                        dropdawn:dropdownMenu,
                        action:''
                    };
                    this.items.push(fundLists);
                }); 
                this.totalRows = response.data.query.total;
                return this.items;               
            } catch (error) {
                console.log(error);
            }
        },

        refreshFundTable() {
            this.$refs.fundTable.refresh();
        },

        resetFilterValue(){
            this.filterBy= '',
            this.filterValue= '',
            this.condition= '',
            this.refreshFundTable();
        },

        createFund(){
            this.$bvModal.show('add_fund');
        },
        uploadFile(e) {
            let file = e.target.files[0];
            let fileType = file.type;
            if(fileType == 'application/pdf' || fileType == 'application/PDF'){
                this.Images = file;//e.target.files[0];
            } else {
                this.$swal('Error!', 'Please provide pdf file', 'error', 'Close');
            }
        },

        //add fund submit form data
        addFundForm(){
            // console.log(!this.addFundDatas.addAmount)
            if(!this.addFundDatas.addAmount){
                this.apiErrorMsg = 'Please provide valid amount';
            } else if (!this.addFundDatas.fundProofFile) {
                this.fundErrorMsg = 'Please provide proof of funds';
            }else{
                this.apiErrorMsg = '';
                this.fundErrorMsg = '';
                this.$refs.loader.show();
                
                const randnumber = Math.ceil(Math.random()*10000);
                const paymentRef = this.addFundDatas.associatedWallet.name+'_'+this.selectedCurrency+'_'+randnumber;

                const formData = new FormData();
                formData.append('businessid', sessionStorage.getItem("businessId"));
                formData.append('walletid', this.addFundDatas.associatedWallet.id);
                formData.append('amount', parseInt(this.addFundDatas.addAmount));
                formData.append('paymentreference', paymentRef);//this.addFundDatas.paymentReference);
                formData.append('paymentoptionid', this.addFundDatas.paymentOption.id);
                formData.append('file', this.addFundDatas.fundProofFile ? this.Images : '');
                this.$http.post(this.$baseurl+'wallet/fund/business', formData,{
                    headers: {
                        'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken"),
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then((res) => {
                    //Perform Success Action
                    // console.log('tyy',res.data);
                    if(res.data.code == '0100-000') {
                        this.$bvModal.hide('add_fund');
                        this.$refs.loader.hide();
                        this.refreshFundTable();
                        $toast.success('Your request will be approved shortly!',  {
                            position:'top-right'
                        });
                    } else {
                        this.$refs.loader.hide();
                        $toast.error(res.message,  {
                            position:'top-right'
                        });
                    }
                })
                .catch((error) => {
                    // error.response.status Check status code
                    this.$refs.loader.hide();
                    // console.log('yuyu',error.response);
                    $toast.error(error.response.data.message,  {
                        position:'top-right'
                    });
                });
            }
        },

        setCurrency(defaultCurrency){
            // console.log(defaultCurrency);
            this.selectedCurrency = defaultCurrency.currency;
        },

        modalShow(){
            this.businessData();
            this.getWalletData();
            this.paymentOption();

            this.addFundDatas.associatedWallet = null;
            this.addFundDatas.paymentOption = null;
            this.addFundDatas.paymentReference = '';
            this.addFundDatas.addAmount = '';
            this.addFundDatas.fundProofFile = null;
            this.selectedCurrency = '';
        },

        async businessData(){
            try {
                let paramData = {
                    sorting : 'asc',
                    sortby : 'id',
                    pagesize : '20',
                    pagenumber : '1'
                }
                const response = await this.$http.get(this.$baseurl+"business/list", { 
                    params: paramData,
                    headers: {
                        'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken")
                    }
                });
                // JSON responses are automatically parsed.
                // console.log(response);
                response.data.details.map((item) => {
                    if(item.businessid == sessionStorage.getItem("businessId")){
                        this.selectedBusinessName = item.name
                    }
                    // let businessLists = {
                    //     id : item.businessid,
                    //     name: item.name
                    // };
                    // this.businessNameOptions.push(businessLists);
                });                
            } catch (error) {
                console.log(error);
            }
        },

        async getWalletData(){
            try {
                const response = await this.$http.get(this.$baseurl+"wallet/business/"+sessionStorage.getItem("businessId"), { 
                    headers: {
                        'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken")
                    }
                });
                // JSON responses are automatically parsed.
                // console.log(response);
                this.walletOptions = [];
                if(response.data.code == '0100-000'){
                    response.data.details.map((item) => {
                        let walletLists = {
                            id : item.walletid,
                            name : item.name,
                            currency : item.currencycode
                        };
                        this.walletOptions.push(walletLists);
                    }); 
                }               
            } catch (error) {
                console.log(error);
            }
        },

        async paymentOption(){
            try {
                const response = await this.$http.get(this.$baseurl+"payment/options", { 
                    headers: {
                        'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken")
                    }
                });
                // JSON responses are automatically parsed.
                // console.log(response);
                this.paymentOptions = [];
                if(response.data.code == '0100-100'){
                    response.data.details.map((item) => {
                        let lists = {
                            id : item.id,
                            name : item.name
                        };
                        this.paymentOptions.push(lists);
                    }); 
                }               
            } catch (error) {
                console.log(error);
            }
        },

        async downloadfile(data){
            const transactionid = data.item.id;
            const reference = data.item.reference;
            
            try {
                const response = await this.$http.get(this.$baseurl+"transactions/funds/transaction/"+transactionid, { 
                    responseType: 'blob',
                    headers: {
                        'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken")
                    }
                });
                // JSON responses are automatically parsed.
                // console.log(response);
                // Create a blob from the response data
                const blob = new Blob([response.data], { type: 'application/pdf' });

                // Create a link element
                const link = document.createElement('a');

                // Create a URL for the blob and set it as the href attribute
                link.href = window.URL.createObjectURL(blob);

                // Set the download attribute with a filename
                link.download = reference+'.pdf';

                // Append the link to the document body
                document.body.appendChild(link);

                // Programmatically click the link to trigger the download
                link.click();

                // Remove the link from the document
                document.body.removeChild(link);
            } catch (error) {
                console.log(error);
            }
        },

        async previewPdf(transactionid) {
            try {
                const response = await this.$http.get(this.$baseurl+"transactions/funds/transaction/"+transactionid, { 
                    responseType: 'blob',
                    headers: {
                        'Authorization': 'Bearer '+sessionStorage.getItem("jwtToken")
                    }
                });
                const blob = new Blob([response.data], { type: 'application/pdf' });
                const pdfUrl = URL.createObjectURL(blob);
                window.open(pdfUrl, '_blank');
            } catch (error) {
                console.error('Error fetching PDF:', error);
            }
        },
        formateNumbers(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
    },mounted() {
        if(sessionStorage.getItem("jwtToken")){
            // this.totalRows = this.items.length;
            // this.$root.$refs.FundComponent = this;
        } else {
            this.$swal('Error!', 'Please login to continue', 'error', 'Close');
            this.$router.push({path:'/authentication/page-login'});
        }
    }
}
</script>
